import { useMemo, useState } from 'react';
import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { filterOption } from '../helpers';
import { naturalSortBy } from 'utils/naturalSort';
import { ClearIcon, SuffixIcon } from 'components/SelectChooser/SelectChooser.style';

import { TurbineChooserComponentProps } from './types';
import {
  AtlasGqlFilterOperator,
  useGetSitesWithTurbinesForChooserQuery,
} from 'types/atlas-graphql';

// This might not be used. It seems to be mostly acting as a placeholder as there's no apparent
// time that we would have an active turbine chooser with no site selected.
export const TurbinesByLocationChooser = ({
  disabled,
  // The disabled parameter will prevent loading select options, this disables the input element.
  disableInput = false,
}: TurbineChooserComponentProps) => {
  // this _is not_ a performant query but, as stated above, never seems to actually run
  const { data, loading } = useGetSitesWithTurbinesForChooserQuery({
    skip: disabled,
    variables: {
      filterBy: [
        { key: 'assetTypeNames', operator: AtlasGqlFilterOperator.Equals, values: ['site'] },
      ],
    },
  });

  const locationsWithSortedTurbines = useMemo(() => {
    const sites = data?.assetsWithMetadata?.items ?? [];
    return sites.map(site => {
      // only use turbine children as a site may have other asset types and we don't have a
      // filterBy or sortBy on children, yet
      const turbineChildren = site?.children?.filter(c => c.__typename === 'Turbine') || [];
      // no sortBy on children so sort here
      const sortedTurbines = [...turbineChildren].sort(naturalSortBy('name'));
      return { ...site, turbines: sortedTurbines };
    });
  }, [data]);

  const [chooserState, setChooserState] = useState<SelectValue>(undefined);

  return (
    <Select
      placeholder="Select Turbine..."
      loading={loading}
      allowClear
      showSearch
      onChange={value => setChooserState(value)}
      filterOption={filterOption}
      disabled={disableInput}
      showArrow={chooserState ? false : true}
      clearIcon={<ClearIcon />}
      suffixIcon={<SuffixIcon />}
    >
      {locationsWithSortedTurbines.map(site => (
        <Select.OptGroup key={site.id} label={site.__typename === 'Site' ? site.name : ''}>
          {site.turbines.map(turbine => {
            return (
              turbine &&
              turbine.__typename === 'Turbine' && (
                <Select.Option key={turbine.id} value={turbine.id} title={turbine.name || ''}>
                  {turbine.name}
                </Select.Option>
              )
            );
          })}
        </Select.OptGroup>
      ))}
    </Select>
  );
};

import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RefreshController } from './RefreshController';
import { useRegisterServiceWorker } from './hooks';

/**
 * This is a wrapper around react router that allows the
 * router to control refreshes to get app updates. Most of
 * the logic is in the RefreshController component and documented there.
 * - RL, 4/22/2022
 */

export const Router: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const [shouldForceRefresh, setShouldForceRefresh] = useState<boolean>(false);
  const [routerKey, setRouterKey] = useState<number>(0);

  useRegisterServiceWorker(setShouldForceRefresh);

  return (
    <BrowserRouter key={routerKey} forceRefresh={shouldForceRefresh}>
      <RefreshController
        shouldForceRefresh={shouldForceRefresh}
        routerKey={routerKey}
        setRouterKey={setRouterKey}
      />
      {children}
    </BrowserRouter>
  );
};

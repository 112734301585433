import { useState, useEffect } from 'react';
import { useApolloContext, useMutation } from 'utils/apollo';
import { DeleteOutlined } from '@ant-design/icons';
import { Modal, message } from 'antd';
import styled from 'styled-components';
import { DAMAGE_DELETE } from 'utils/access-control/rules';
import { CanRender } from 'utils/usePermissions';
import { DamageNumberText } from 'components/data/helpers';
import { Button, MenuButton } from 'components/ui';
import { DeleteDamageModal } from './DeleteDamageModal';
import DELETE_DAMAGE from './DeleteDamage.gql';

const StyledWarning = styled.div`
  font-size: 1.1rem;
`;

// escape can get through because it's used for a modal close by antd/rc-dialog
function keyFilter(e) {
  const { key } = e;
  if (key !== 'Escape') {
    e.stopPropagation();
  }
}

function addKeyboardListeners() {
  window.addEventListener('keydown', keyFilter, { capture: true });
  window.addEventListener('keyup', keyFilter, { capture: true });
}

function removeKeyboardListeners() {
  window.removeEventListener('keydown', keyFilter, { capture: true });
  window.removeEventListener('keyup', keyFilter, { capture: true });
}

export function DeleteDamage({ damage, onDelete = null, forceIdCheck, type }) {
  const { papiClient } = useApolloContext();
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [deleteDamage, { loading: isDeleting }] = useMutation(DELETE_DAMAGE, {
    variables: { branchGlobalId: damage.id },
    optimisticResponse: { removeDamage: true },
    update(_, { data: { removeDamage: success } }) {
      if (!success) return;
      papiClient.cache.evict(damage.id);
      papiClient.cache.gc();
    },
  });

  useEffect(() => {
    if (visible) addKeyboardListeners();
    else removeKeyboardListeners();

    return function cleanup() {
      removeKeyboardListeners();
    };
  }, [visible]);

  const handleDelete = async () => {
    try {
      await deleteDamage();
      setVisible(false);
      onDelete && onDelete();
    } catch (e) {
      console.error(e);
      message.error('There was a problem deleting the damage.');
    }
  };

  const handleInput = ({ target }) => {
    const value = target.value.toUpperCase();
    if (value === damage.number) setDisabled(false);
    if (!disabled && value !== damage.number) setDisabled(true);
  };

  // FIXME: Update all instances of this component to remove `<CanRender .../>` wrapper
  return (
    <CanRender rules={[DAMAGE_DELETE]}>
      {type === 'button' ? (
        <Button
          _version={4}
          id="delete-damage-button"
          title="Delete damage"
          danger
          icon={<DeleteOutlined />}
          onClick={() => setVisible(true)}
        >
          Delete
        </Button>
      ) : (
        <MenuButton
          _version={4}
          id="delete-damage-link"
          icon={<DeleteOutlined />}
          onClick={() => setVisible(true)}
          type="link"
          danger
        >
          Delete Damage
        </MenuButton>
      )}

      {damage && damage.tasks && damage.tasks.length ? (
        <Modal
          visible={visible}
          destroyOnClose
          onCancel={() => setVisible(false)}
          title="Unable to delete damage"
          footer={
            <Button
              _version={4}
              data-testid="delete-damage-ok-button"
              id="delete-damage-ok-button"
              onClick={() => setVisible(false)}
            >
              OK
            </Button>
          }
        >
          <StyledWarning>
            This damage cannot be deleted because it is currently associated with&nbsp;
            {damage.tasks.length} {damage.tasks.length > 1 ? 'tasks' : 'task'}. To delete this
            damage first delete any tasks that reference damage{' '}
            <strong>
              <DamageNumberText>{damage.number}</DamageNumberText>
            </strong>
            .
          </StyledWarning>
        </Modal>
      ) : (
        <DeleteDamageModal
          forceIdCheck={forceIdCheck}
          isDeleting={isDeleting}
          disabled={disabled}
          visible={visible}
          damageNumber={damage.number}
          updateVisible={setVisible}
          onDelete={handleDelete}
          onChange={handleInput}
          onCancel={() => setVisible(false)}
        />
      )}
    </CanRender>
  );
}

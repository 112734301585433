import { useCallback } from 'react';

import { ButtonGroup } from 'components/ui';
import { ZoomControl } from './Zoom';
import { RotateControl } from './Rotate';
import { ResetControl } from './Reset';
import { DownloadButton } from 'components/DownloadButton';
import { CopyButton } from 'components/CopyButton';
import { Levels } from './Levels';
import { Controls as StyledControls, Panel } from './Controls.style';

/**
 * This component is deprecated and will be removed during cleanup of
 * rtv1:new_image_viewer
 *
 * Further maintenance should not be needed, check with Riley with any questions.
 */

interface ControlsProps {
  onChange: (arg: any) => void;
  copyText: string;
  downloadHref: string;
  children: React.ReactNode;
  value?: {
    rotate: boolean;
    levels: { url: string; width: number; height: number }[];
  };
  homography?: number[];
}
export function Controls({
  onChange,
  copyText,
  downloadHref,
  children,
  value,
  homography,
  ...props
}: ControlsProps) {
  const rotate = value?.rotate;
  const levels = value?.levels;

  const handleChange = useCallback(
    e => {
      onChange && onChange(e);
    },
    [onChange]
  );

  return (
    <StyledControls {...props}>
      <Panel>
        {/* @ts-ignore V4 of antd button doesn't have children (in the TS definition), but not wanting to change this to V5 at this point */}
        <ButtonGroup _version={4} style={{ margin: '0 0.5rem 0.5rem 0' }}>
          <ZoomControl onChange={handleChange} />
          <RotateControl onChange={handleChange} value={rotate} disabled={!homography} />
          <ResetControl onChange={handleChange} />

          {downloadHref && <DownloadButton href={downloadHref} />}

          {copyText && <CopyButton value={copyText} legacy={true} />}
        </ButtonGroup>

        {children}
      </Panel>

      <Panel style={{ textAlign: 'right' }}>
        {levels ? <Levels defaultValue={levels} onChange={handleChange} /> : null}
      </Panel>
    </StyledControls>
  );
}

export default Controls;

import MarkerClusterGroup from 'react-leaflet-cluster';
import { divIcon, point, MarkerCluster, DivIcon } from 'leaflet';

const createCustomClusters = (cluster: MarkerCluster): DivIcon => {
  const childCount = cluster.getChildCount();
  const size = childCount < 10 ? 'small' : childCount < 100 ? 'medium' : 'large';

  return divIcon({
    // div + span are required for proper styling 🙄
    html: `<div><span>${childCount}</span></div>`,
    className: `marker-cluster marker-cluster-${size}`,
    iconSize: point(40, 40),
  });
};

interface IMarkerClusterGroupLayerProps {
  layer: React.ReactNode;
  spiderfy?: boolean;
  enableClustering?: boolean;
}

export const MarkerClusterGroupLayer: React.FunctionComponent<IMarkerClusterGroupLayerProps> = ({
  layer,
  spiderfy = false,
  enableClustering,
}) => {
  return (
    <>
      {enableClustering ? (
        <MarkerClusterGroup
          disableClusteringAtZoom={13}
          spiderfyOnMaxZoom={spiderfy}
          iconCreateFunction={createCustomClusters}
        >
          {layer}
        </MarkerClusterGroup>
      ) : (
        layer
      )}
    </>
  );
};

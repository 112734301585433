import styled from 'styled-components';
import { Tag } from 'antd';
import { AssetColorStatus, getAssetColorStatus } from 'utils/color/helpers';
import COLORS from 'utils/color/definitions';

interface IStatusTagProps {
  value: string;
}

export const StatusTag: React.FunctionComponent<IStatusTagProps> = ({ value, ...props }) => {
  // FIXME JD: Once we've removed all of the old PAPI-dependent turbine tables, remove this logic.
  // Account for older usage of the StatusTag: 'ACTIVE' and 'INACTIVE' are valid inputs that should be mapped to 'Online' and 'Offline' respectively.
  const displayString = value === 'ACTIVE' ? 'Online' : value === 'INACTIVE' ? 'Offline' : value;
  return (
    <StyledTag color={getAssetColorStatus(displayString as AssetColorStatus)} {...props}>
      {displayString.toUpperCase()}
    </StyledTag>
  );
};

/**
 * The color prop for antd's `<Tag />` is for the backound color, but there is
 * no text or border color prop. When the background is white, set the text and
 * border to black.
 */
const StyledTag = styled(Tag)<{ color: string }>`
  ${({ color }) =>
    color === COLORS.WHITE &&
    `
    border: 1px solid ${COLORS.BLACK};
    color: ${COLORS.BLACK};
    font-size: 10px;
  `}
`;

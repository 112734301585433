import { Link } from 'react-router-dom';
import { get } from 'lodash';

import { sortBy, None, getFilterDropdown } from './helpers';
import { BLADE_STATUS_LABELS } from 'utils/constants';
import { StatusTag } from 'components/StatusTag';
import { TDisplayColumnData } from './types';

export const label: TDisplayColumnData = {
  key: 'label',
  title: 'Blade Label',
  dataIndex: 'label',
  filterProperty: 'label',
  sorter: sortBy('label'),
  width: 10,
};

export const atlasPosition: TDisplayColumnData = {
  key: 'position',
  title: 'Position',
  dataIndex: 'position',
  filterProperty: 'position',
  sorter: sortBy('position'),
  width: 10,
};

export const serial: TDisplayColumnData = {
  key: 'serial',
  title: 'Serial No.',
  dataIndex: 'serial',
  filterProperty: 'serial',
  sorter: sortBy('serial'),
  get: record => get(record, 'serial'),
  render: (serial: any, blade: { id: any; label: any }) => {
    if (!blade) return <None />;
    const { id, label } = blade;
    return <Link to={`/blades/${id}`}>{serial || <i>Blade {label} (No Serial)</i>}</Link>;
  },
};

export const length: TDisplayColumnData = {
  key: 'length',
  title: 'Length',
  dataIndex: 'length',
  sorter: sortBy('length'),
  get: record => get(record, 'length'),
  render: (length: any) => {
    return length ? `${length}m` : <None />;
  },
};

export const manufacturer: TDisplayColumnData = {
  key: 'manufacturer',
  title: 'Manufacturer',
  dataIndex: 'manufacturer',
  filterProperty: 'manufacturer',
  sorter: sortBy('manufacturer'),
  get: record => get(record, 'manufacturer'),
  render: (manufacturer: any) => (manufacturer ? manufacturer : <None />),
};

export const make: TDisplayColumnData = {
  key: 'make',
  title: 'Make',
  dataIndex: 'make',
  filterProperty: 'make',
  sorter: sortBy('make'),
  get: record => get(record, 'make'),
  render: (make: any) => (make ? make : <None />),
};

export const location: TDisplayColumnData = {
  key: 'turbine.location.name',
  title: 'Site',
  filterProperty: 'turbine.location.name',
  sorter: sortBy('turbine.location.name'),
  get: record => get(record, ['turbine', 'location', 'name']),
  dataIndex: ['turbine', 'location'],
  render: (location: any) => {
    const { id = '', name = '' } = location || {};
    return [id, name].every(Boolean) ? <Link to={`/sites/${id}`}>{name}</Link> : <None />;
  },
};

export const turbine: TDisplayColumnData = {
  key: 'turbine.name',
  title: 'Turbine',
  filterProperty: 'turbine.name',
  sorter: sortBy('turbine.name'),
  get: record => get(record, ['turbine', 'name']),
  dataIndex: 'turbine',
  render: (turbine: any) => {
    const { id = '', name = '' } = turbine || {};
    return [id, name].every(Boolean) ? <Link to={`/turbines/${id}`}>{name}</Link> : <None />;
  },
};

export const model: TDisplayColumnData = {
  key: 'model',
  title: 'Model',
  dataIndex: 'model',
  filterProperty: 'model',
  sorter: sortBy('model'),
  get: record => get(record, 'model'),
  render: (model: any) => (model ? model : <None />),
};

const statusFilterConfig = {
  key: 'status',
  type: 'string',
  values: Object.keys(BLADE_STATUS_LABELS),
};

export const status: TDisplayColumnData = {
  key: 'status',
  title: 'Status',
  dataIndex: 'status',
  sorter: sortBy('status'),
  get: record => get(record, 'status'),
  filters: Object.keys(BLADE_STATUS_LABELS).map(key => ({
    text: get(BLADE_STATUS_LABELS, key),
    value: key,
  })),
  filterProperty: 'status',
  filterDropdown: getFilterDropdown(statusFilterConfig),
  render(status: string | number) {
    const statusLabel = get(BLADE_STATUS_LABELS, status);
    return statusLabel ? <StatusTag value={statusLabel} /> : <None />;
  },
};

import { useEffect } from 'react';
import { useAuthContext } from 'utils/auth';

function Logout() {
  const { logout } = useAuthContext();

  useEffect(() => {
    logout({ broadcast: true });
  }, [logout]);

  return null;
}

export default Logout;

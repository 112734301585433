import { useMemo } from 'react';

import { Select } from 'antd';

import { useGetAssetTypesMinimalQuery } from 'types/atlas-graphql';
import { useAssetChooserState } from 'components/choosers/AssetChooser/useAssetChooserState';
import { SuffixIcon, ClearIcon } from '../styles';
import { filterOption } from '../../helpers';
import { snakeCase } from 'lodash';

export interface IComponentTypeChooserProps {
  disabled: boolean;
  value?: string[]; // Currently selected items
  onChange: any;
  mode?: 'multiple' | 'tags' | undefined;
  allowedOptions?: string[];
}

export const ComponentTypeChooser: React.FunctionComponent<IComponentTypeChooserProps> = ({
  disabled,
  value,
  onChange,
  mode = 'multiple',
  allowedOptions,
}) => {
  const { data, loading } = useGetAssetTypesMinimalQuery({
    skip: disabled,
    variables: {
      excludeUninstantiated: true,
    },
  });

  const sortedComponentTypes = useMemo(() => {
    if (data) {
      const newTypes = data?.assetTypes ?? [];
      // We want to display all non-site and non-turbine types.
      const filteredTypes = newTypes.filter(
        t =>
          !['Site', 'Turbine'].includes(t.name) &&
          (!allowedOptions || allowedOptions.includes(t.name))
      );
      return filteredTypes.sort((a, b) => (a?.name < b?.name ? -1 : 1));
    }
  }, [data, allowedOptions]);

  const { handleChange, setVisible, chooserState } = useAssetChooserState({
    onChange,
    value,
  });

  return (
    <Select
      id="component-type-chooser"
      placeholder="Select Component Type..."
      loading={loading}
      allowClear
      showSearch
      filterOption={filterOption}
      mode={mode}
      maxTagCount={1}
      value={chooserState}
      onChange={handleChange}
      onDropdownVisibleChange={setVisible}
      disabled={disabled}
      showArrow={chooserState ? false : true}
      clearIcon={<ClearIcon />}
      suffixIcon={<SuffixIcon />}
    >
      {sortedComponentTypes?.map(component => (
        <Select.Option
          key={component.name}
          className="component-type-chooser-option"
          value={snakeCase(component.name)}
        >
          {component.name}
        </Select.Option>
      ))}
    </Select>
  );
};

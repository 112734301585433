import PageNotFoundRedirect from 'components/PageNotFoundRedirect';
import { useAccountContext } from 'utils/account/AccountContext';

export const flags = {
  ALERT_MANAGER: 'ALERT_MANAGER',
  COLLABORATORS: 'COLLABORATORS',
  DAMAGE_SCHEMA: 'DAMAGE_SCHEMA',
  DEVICE_FIRMWARE_MANAGEMENT: 'DEVICE_FIRMWARE_MANAGEMENT',
  HORIZON_DAMAGES: 'HORIZON_DAMAGES',
  INSPECTION_UPLOAD: 'INSPECTION_UPLOAD',
  LEGACY_DAMAGES: 'LEGACY_DAMAGES',
  SISENSE: 'SISENSE',
  WORK_LOGS: 'WORK_LOGS',
  WORK_ORDERS: 'WORK_ORDERS',
  MAXIMO_SRS: 'MAXIMO_SRS',
};

export const useFeatures = () => {
  const { features } = useAccountContext();

  // by the time we've gotten here, the features are already loaded, so we can
  // hardcode the `loading` flag. there should be an effort to remove all checks
  // for features loading... at another time.
  return { features, loading: false };
};

export interface FeatureFlagProps {
  children: React.ReactNode;
  errorMessageIfNoAccess?: string;
  flag: string;
  redirectUrlIfNoAccess?: string;
}

export const FeatureFlag = ({
  children,
  errorMessageIfNoAccess,
  flag,
  redirectUrlIfNoAccess,
}: FeatureFlagProps) => {
  const { features } = useFeatures();

  if (!features[flag]) {
    if (redirectUrlIfNoAccess) {
      return (
        <PageNotFoundRedirect
          errorMessage={errorMessageIfNoAccess}
          redirectUrl={redirectUrlIfNoAccess}
        />
      );
    }
    return null;
  }

  return <>{children}</>;
};

import { Fragment } from 'react';
import { Button, Tooltip } from 'components/ui';

interface PrioritizeButtonsProps {
  rowReordering: {
    rowCount: number;
    priorityLoading?: boolean;
    disableSavePriority?: boolean;
    onSavePriority?: any;
    onCancelPriority?: any;
    onOpenPriority?: any;
    isPrioritizing?: boolean;
    changePriorityDisabledText?: string;
  };
}

export function PrioritizeButtons({ rowReordering }: PrioritizeButtonsProps) {
  const {
    rowCount,
    priorityLoading,
    disableSavePriority,
    onSavePriority,
    onCancelPriority,
    onOpenPriority,
    isPrioritizing,
    changePriorityDisabledText,
  } = rowReordering || {};

  return onSavePriority && rowCount > 1 ? (
    isPrioritizing ? (
      <Fragment>
        <Button _version={4} onClick={onCancelPriority} disabled={priorityLoading}>
          Cancel
        </Button>
        <Button
          _version={4}
          type="primary"
          onClick={onSavePriority}
          loading={priorityLoading}
          disabled={disableSavePriority}
        >
          Save Priority
        </Button>
      </Fragment>
    ) : (
      <Tooltip title={changePriorityDisabledText}>
        <Button
          _version={4}
          onClick={onOpenPriority}
          disabled={changePriorityDisabledText !== undefined}
        >
          Change Priority
        </Button>
      </Tooltip>
    )
  ) : null;
}

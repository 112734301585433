import styled from 'styled-components';

export const StyleOptionContainer = styled.div`
  display: flex;
  width: 100%;

  .ant-switch {
    margin-right: 0.5rem;
    width: 44px;
  }

  > div {
    flex-grow: 1;
  }

  p {
    margin-bottom: 0.5rem;
  }

  .ant-select {
    width: 100%;
  }
`;

import { User } from '@auth0/auth0-react';

const getHeap = () => (typeof window !== 'undefined' ? (window as any).heap : undefined);

const setHeapUser = ({ email, sub }: User) => {
  try {
    getHeap()?.identify(sub);
    getHeap()?.addUserProperties({ email });
  } catch (error) {
    if (window.location.host.match('skyspecs.com') && !navigator.webdriver) {
      console.error('heap failed to load');
    }
  }
};

const setHeapOrganization = ({ orgId, orgName }: { orgId: string; orgName?: string | null }) => {
  try {
    // Always clear before adding event properties https://developers.heap.io/reference/addeventproperties
    getHeap()?.clearEventProperties();
    getHeap()?.addEventProperties({ 'Organization ID': orgId, 'Organization Name': orgName });
  } catch (error) {
    if (window.location.host.match('skyspecs.com') && !navigator.webdriver) {
      console.error('heap failed to load');
    }
  }
};

const addHeapEvent = (eventName: string, eventProperties?: Record<string, any>) => {
  try {
    getHeap()?.track(eventName, eventProperties);
  } catch (e) {
    if (window.location.host.match('skyspecs.com') && !navigator.webdriver) {
      console.error('heap failed to load');
    }
  }
};

export { getHeap, setHeapUser, setHeapOrganization, addHeapEvent };

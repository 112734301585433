import { useCallback } from 'react';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { StyledButton } from '../ImageViewer.style';

type ZoomProps = {
  onChange: (data: any) => void;
};

export const Zoom: React.FunctionComponent<ZoomProps> = ({ onChange }) => {
  const handleClick = useCallback(
    ({ currentTarget: { value } }) => {
      onChange({ [value]: true });
    },
    [onChange]
  );

  return (
    <>
      <StyledButton
        data-testid="image-controls-zoom-in"
        title="Zoom In"
        value="zoomIn"
        onClick={handleClick}
      >
        <ZoomInOutlined />
      </StyledButton>
      <StyledButton
        data-testid="image-controls-zoom-out"
        title="Zoom Out"
        value="zoomOut"
        onClick={handleClick}
      >
        <ZoomOutOutlined />
      </StyledButton>
    </>
  );
};

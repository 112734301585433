import React, { forwardRef } from 'react';
import { Button as ButtonV4, ButtonProps as ButtonPropsV4 } from 'antd';
import { ButtonGroupProps as ButtonGroupPropsV4 } from 'antd/lib/button/button-group';
import { Button as ButtonV5, ButtonProps as ButtonPropsV5 } from 'antd5';
import { ButtonGroupProps as ButtonGroupPropsV5 } from 'antd5/lib/button/button-group';
import { Version4Props, Version5Props } from '../types';

interface PropsV5 extends Version5Props, ButtonPropsV5 {}
interface PropsV4 extends Version4Props, ButtonPropsV4 {}
type Props = PropsV5 | PropsV4;

const Button = forwardRef<HTMLButtonElement, any>(function Button(
  { _version, ...props }: Props,
  ref
) {
  if (_version === 4) {
    const { shape } = props;
    return <ButtonV4 ref={ref} {...props} shape={shape === 'default' ? undefined : shape} />;
  }

  return <ButtonV5 ref={ref} {...props} />;
});

interface GroupPropsV5 extends Version5Props, ButtonGroupPropsV5 {}
interface GroupPropsV4 extends Version4Props, ButtonGroupPropsV4 {}
type ButtonGroupProps = GroupPropsV5 | GroupPropsV4;

export function ButtonGroup({ _version, ...props }: ButtonGroupProps) {
  if (_version === 4) {
    return <ButtonV4.Group {...props} />;
  }
  return <ButtonV5.Group {...props} />;
}

const ButtonNamespace = Object.assign(Button, { Group: ButtonGroup });

export { ButtonNamespace as Button };

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Popover, Table, Progress } from 'antd';

import {
  renderAssetSite,
  renderAssetTurbine,
  renderAssetName,
  renderAssetNameWithAncestors,
  getColumnName,
} from 'components/data/assets/assetColumnRenderers';
import { startCase } from 'utils/format';
import { TASK_STATUSES } from 'utils/constants';
import { ModalLink } from 'utils/router';

import * as taskData from 'components/data/tasks/tasks';
import { CheckIcon } from 'components/Icons';

import { None, DamageNumberText } from 'components/data/helpers';
import { READABLE_CHAMBER_OPTIONS } from 'utils/constants';
import { TDisplayColumnData } from '../data/types';
import { get } from 'lodash';
import { TAnyAssetType } from 'components/data/assets/types';
import { AtlasGqlBlade, AtlasGqlAsset } from 'types/atlas-graphql';
import {
  getAssetByType,
  getUniqueIdentifier,
} from 'horizon/components/Assets/AssetDetails/helpers';
import { StatusTag } from 'components/StatusTag';
import { DamageNumber, DamageLink } from 'horizon/components/Damages/utils';

const DamageNumberContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const { COMPLETE, IN_PROGRESS } = TASK_STATUSES;

export const removeAssetLink = (column: TDisplayColumnData) => {
  switch (column.key) {
    case 'site':
      return {
        ...column,
        render: (asset: AtlasGqlAsset) => {
          const siteAsset = getAssetByType(asset, 'Site');
          return siteAsset ? getColumnName(siteAsset) : <None />;
        },
      };
    case 'turbine':
      return {
        ...column,
        render: (asset: AtlasGqlAsset) => {
          const turbineAsset = getAssetByType(asset, 'Turbine');
          return turbineAsset ? getColumnName(turbineAsset) : <None />;
        },
      };
    case 'blade':
      return {
        ...column,
        render: (asset: AtlasGqlAsset) => {
          const bladeAsset = getAssetByType(asset, 'Blade') as AtlasGqlBlade | undefined;
          return bladeAsset ? (
            bladeAsset.uniqueIdLabel ??
              `${bladeAsset.position ?? ''} ${bladeAsset.serial ? `(#${bladeAsset.serial})` : ''}`
          ) : (
            <None />
          );
        },
      };
  }
};

// takes in a linked asset column renderer function (for site, turbine, and all other asset types)
// and returns the asset as a text string (without a link)
export const removeAssetLinkGeneric = (column: TDisplayColumnData) => {
  switch (column.key) {
    case 'site':
      return {
        ...column,
        render: (asset: AtlasGqlAsset) => {
          const site = getAssetByType(asset, 'Site');
          return site ? getUniqueIdentifier(site) : <None />;
        },
      };
    case 'turbine':
      return {
        ...column,
        render: (asset: AtlasGqlAsset) => {
          const turbine = getAssetByType(asset, 'Turbine');
          return turbine ? getUniqueIdentifier(turbine) : <None />;
        },
      };
    default:
      return {
        ...column,
        render: (asset: AtlasGqlAsset) =>
          asset ? asset?.uniqueIdLabel ?? getUniqueIdentifier(asset) : <None />,
      };
  }
};

export const number: TDisplayColumnData = {
  title: 'Damage ID',
  key: 'number',
  filterProperty: 'number',
  render: (damage: any) => {
    const { id, number, observations = [{}] } = damage || {};
    const [{ picture = {} }] = observations;
    return picture.id ? (
      <ModalLink
        data-testid="damage-image-link"
        className="damage-image-link"
        to={`/pictures/${picture.id}?damages=${id}`}
      >
        <DamageNumberText>{number}</DamageNumberText>
      </ModalLink>
    ) : (
      <DamageNumberText>{number}</DamageNumberText>
    );
  },
};

export const horizonDamageNumber: TDisplayColumnData = {
  title: 'Damage ID',
  key: 'id',
  dataIndex: 'id',
  render: (id: string) => <DamageNumber damageId={id} />,
};

export const horizonDamageLink: TDisplayColumnData = {
  title: 'Damage ID',
  key: 'id',
  dataIndex: 'id',
  render: (id: string) => <DamageLink damageId={id} target={'_blank'} />,
};

export const numberAndConfirmed: TDisplayColumnData = {
  ...number,
  render: (damage: any) => {
    const { id, number, confirmed, isConfirmed, observations = [{}] } = damage || {};
    const [{ picture = {} }] = observations;

    return (
      <DamageNumberContainer>
        {picture?.id ? (
          <ModalLink
            data-testid="damage-image-link"
            className="damage-image-link"
            to={`/pictures/${picture?.id}?damages=${id}`}
          >
            <DamageNumberText>{number}</DamageNumberText>
          </ModalLink>
        ) : (
          <DamageNumberText>{number}</DamageNumberText>
        )}
        {confirmed || isConfirmed ? <CheckIcon /> : null}
      </DamageNumberContainer>
    );
  },
};

export const isConfirmed: TDisplayColumnData = {
  key: 'isConfirmed',
  title: 'Confirmation Status',
  dataIndex: 'isConfirmed',
  filterProperty: 'isConfirmed',
  className: 'hidden-column',
};

const taskStatusColumns = ['taskNumber', 'type', 'status', 'dueDate', 'damageId', 'damageDate'].map(
  key => get(taskData, key)
);

export const taskStatus: TDisplayColumnData = {
  title: 'Tasks',
  key: 'status',
  render: (damage: any, popOverProps = {}) => {
    const tasks = damage?.tasks ?? [];

    // Pass along the damage info so we don't have to redundantly ask for it in the query
    return tasks?.length ? (
      <Popover
        content={
          <Table
            columns={taskStatusColumns}
            dataSource={tasks.map((t: any) => ({ ...t, damage }))}
            size="small"
            rowKey="id"
            pagination={false}
          />
        }
        {...popOverProps}
      >
        <Progress
          type="circle"
          percent={
            (tasks.filter(
              (t: { status: string }) => t.status === COMPLETE || t.status === IN_PROGRESS
            ).length /
              tasks.length) *
            100
          }
          success={{
            percent:
              (tasks.filter((t: { status: string }) => t.status === COMPLETE).length /
                tasks.length) *
              100,
          }}
          format={() => <b>{tasks.length}</b>}
          width={25}
        />
      </Popover>
    ) : (
      <None />
    );
  },
};

export const site: TDisplayColumnData = {
  title: 'Site',
  key: 'site',
  dataIndex: 'location',
  render: (location: any) => {
    return location ? (
      <Link to={`/sites/${location.id}`}>{startCase(location.name)}</Link>
    ) : (
      <None />
    );
  },
};

export const siteAtlas: TDisplayColumnData = {
  title: 'Site',
  key: 'site',
  dataIndex: 'asset',
  render: renderAssetSite,
};

export const turbine: TDisplayColumnData = {
  title: 'Turbine',
  key: 'turbine',
  dataIndex: 'turbine',
  render: (turbine: any) =>
    turbine ? <Link to={`/turbines/${turbine.id}`}>{turbine.name}</Link> : <None />,
};

export const turbineAtlas: TDisplayColumnData = {
  title: 'Turbine',
  key: 'turbine',
  dataIndex: 'asset',
  render: (asset: AtlasGqlAsset) => renderAssetTurbine(asset, 'damages'),
};

export const assetColumn: TDisplayColumnData = {
  key: 'asset',
  title: 'Asset',
  dataIndex: 'asset',
  render: (asset: AtlasGqlAsset) => renderAssetNameWithAncestors(asset, 'damages'),
};

export const assetTypeColumn: TDisplayColumnData = {
  key: 'assetType',
  title: 'Asset Type',
  dataIndex: 'asset',
  render: (asset: AtlasGqlAsset) => asset?.assetType?.name ?? <None />,
};

export const assetStatusColumn: TDisplayColumnData = {
  key: 'assetStatus',
  title: 'Asset Status',
  dataIndex: 'asset',
  render: (asset: AtlasGqlAsset) =>
    asset?.assetType?.name &&
    !['Site', 'Turbine'].includes(asset.assetType.name) &&
    asset?.assetStatus ? (
      <StatusTag value={asset.assetStatus} />
    ) : (
      <None />
    ),
};

export const assetTypeName = (asset: AtlasGqlAsset | undefined | null): TDisplayColumnData => {
  return {
    key: 'assetTypeName',
    title: asset?.assetType?.name,
    dataIndex: 'asset',
    render: renderAssetName,
  };
};

export const blade: TDisplayColumnData = {
  title: 'Blade',
  key: 'blade',
  dataIndex: ['blade', 'label'],
  render: (label: any, damage: any) => {
    const { blade } = damage || {};
    return [label, blade].every(Boolean) ? (
      <Link to={`/blades/${blade.id}`}>
        {startCase(label)} {blade.serial ? `(#${blade.serial})` : ''}
      </Link>
    ) : (
      <None />
    );
  },
};

export const bladeAtlas: TDisplayColumnData = {
  title: 'Blade',
  key: 'blade',
  dataIndex: 'asset',
  render: (asset: TAnyAssetType) => {
    let blade: TAnyAssetType | null = null;
    if (asset?.__typename === 'Blade') {
      blade = asset;
    }
    return blade ? (
      <Link to={`/assets/${asset.id}`}>
        {blade.uniqueIdLabel ??
          `${(asset as AtlasGqlBlade).position ? (asset as AtlasGqlBlade).position : ''} ${
            (asset as AtlasGqlBlade).serial ? `(#${(asset as AtlasGqlBlade).serial})` : ''
          }`}
      </Link>
    ) : (
      <None />
    );
  },
};

export const bladeSurface: TDisplayColumnData = {
  title: 'Surface',
  key: 'surface',
  render: (record: any) => {
    // FIXME: Falling back to picture.bladeSide is temporary until all observations point to observation heads.
    // Getting bladeSide from picture has risk of returning effectively "incorrect" data.
    const bladeSide =
      record?.observations?.[0]?.bladeSide || record?.observations?.[0]?.picture?.bladeSide;
    const bladeChamber = record?.observations?.[0]?.picture?.bladeChamber;

    const surface = bladeChamber || bladeSide;
    return surface ? startCase(surface) : <None />;
  },
};

export const bladeSide: TDisplayColumnData = {
  title: 'Image Blade Side',
  key: 'imageBladeSide',
  render: (record: any) => {
    // FIXME: Falling back to picture.bladeSide is temporary until all observations point to observation heads.
    // Getting bladeSide from picture has risk of returning effectively "incorrect" data.
    const bladeSide =
      record?.observations?.[0]?.bladeSide ||
      record?.observations?.[0]?.picture?.bladeSide ||
      record?.observations?.[0]?.picture?.target?.bladeSide;
    return bladeSide ? startCase(bladeSide) : <None />;
  },
};

export const bladeChamber: TDisplayColumnData = {
  title: 'Image Blade Chamber',
  key: 'imageBladeChamber',
  render: (record: any) => {
    const bladeChamber =
      record?.observations?.[0]?.picture?.bladeChamber ||
      record?.observations?.[0]?.picture?.target?.bladeChamber;
    return bladeChamber ? get(READABLE_CHAMBER_OPTIONS, bladeChamber)?.label : <None />;
  },
};

export const viewDamage: TDisplayColumnData = {
  key: 'damage',
  title: 'Damage',
  render: (damage: any) => {
    const picture = damage?.observations?.[0]?.picture;
    if (!picture) return <None />;
    return (
      <ModalLink to={`/pictures/${picture.id}?damages=${damage.id}`}>
        <DamageNumberText>{damage.number}</DamageNumberText>
      </ModalLink>
    );
  },
};

export const height: TDisplayColumnData = {
  title: 'Height',
  key: 'height',
  render: (record: any) => {
    const height = record?.observations?.[0]?.picture?.height;
    return height ? `${height}m` : <None />;
  },
};

export const heightAtlas: TDisplayColumnData = {
  title: 'Height',
  key: 'height',
  render: (record: any) => {
    const height = record?.observations?.[0]?.picture?.target?.height;
    return height ? `${height}m` : <None />;
  },
};

export const direction: TDisplayColumnData = {
  title: 'Direction',
  key: 'direction',
  render: (record: any) => {
    const direction =
      record?.observations?.[0]?.picture?.towerSide ||
      record?.observations?.[0]?.picture?.transitionPieceSide;
    return direction ? startCase(direction) : <None />;
  },
};

export const directionAtlas: TDisplayColumnData = {
  title: 'Direction',
  key: 'direction',
  render: (record: any) => {
    const direction =
      record?.observations?.[0]?.picture?.target?.towerSide ||
      record?.observations?.[0]?.picture?.target?.transitionPieceSide;
    return direction ? startCase(direction) : <None />;
  },
};

export const filename: TDisplayColumnData = {
  title: 'Image Filename',
  key: 'filename',
  render: (record: any) => {
    const filename = record?.observations?.[0]?.picture?.filename;
    return filename ? filename : <None />;
  },
};

export const schema: TDisplayColumnData = {
  title: 'Damage Schema',
  key: 'schema',
  render: (record: any) => record?.schema ?? <None />,
};

export const bladeMake: TDisplayColumnData = {
  key: 'make',
  title: 'Blade Manufacturer',
  dataIndex: 'asset.make',
  getFromColumn: true,
  get: record => get(record, ['asset', 'make']),
  render: (make: any) => (make ? make : <None />),
};

import { getPrimaryObservationGroupForDamage } from 'horizon/components/Damages/utils';
import {
  TASK_TYPE_OTHER,
  TASK_TYPE_REPAIR,
  TASK_TYPE_INSPECT,
  TASK_TYPE_INTERNAL_BLADE_INSPECTION,
  TASK_TYPE_INSPECTION,
  REPAIR_TASK,
  INSPECT_TASK,
  INTERNAL_BLADE_INSPECTION_TASK,
  OTHER_TASK,
  INSPECTION_TASK,
} from './constants';
import moment from 'moment';
import { getTaskTargetInput } from 'components/CreateTask/helpers';

export function getTaskInput(task = {}) {
  const {
    id,
    number,
    damage,
    turbine,
    blade,
    entity,
    workOrder,
    type,
    __typename,
    ...validTaskAttrs
  } = task;

  const assetId = damage?.assetId || damage?.blade?.id || damage?.turbine?.id;
  // If the response is coming from PAPI, id can stand in for damageBranchId
  const branchId = damage?.damageBranchId || damage?.id;
  const damageId = damage?.globalId || damage?.id;
  const horizonDamageId = damage?.horizonDamageId ?? null;
  const inspectionId = getPrimaryObservationGroupForDamage(damage)?.inspectionId;
  // We can't attempt to send HorizonDamageId if it doesn't exist.
  // So check for that first, and fall back to legacy damage if it's not present.
  const atlasCreateParams =
    assetId && horizonDamageId
      ? { assetId, damageBranchId: branchId, damageId, horizonDamageId }
      : assetId && damageId
        ? { assetId, damageBranchId: branchId, damageId }
        : {};

  switch (type) {
    case TASK_TYPE_REPAIR:
      return {
        ...validTaskAttrs,
        type: REPAIR_TASK,
        assetId: (damage.turbine || {}).id,
        ...atlasCreateParams,
        ...(inspectionId || horizonDamageId
          ? { targets: getTaskTargetInput({ inspectionId, horizonDamageId }) }
          : {}),
      };

    case TASK_TYPE_INSPECT:
      return {
        ...validTaskAttrs,
        type: INSPECT_TASK,
        assetId: (damage.turbine || {}).id,
        ...atlasCreateParams,
        ...(inspectionId || horizonDamageId
          ? { targets: getTaskTargetInput({ inspectionId, horizonDamageId }) }
          : {}),
      };

    case TASK_TYPE_INTERNAL_BLADE_INSPECTION:
      return { ...validTaskAttrs, type: INTERNAL_BLADE_INSPECTION_TASK, assetId: blade.id };

    case TASK_TYPE_OTHER:
      return { ...validTaskAttrs, type: OTHER_TASK, assetId: turbine.id };

    case TASK_TYPE_INSPECTION:
      return { ...validTaskAttrs, type: INSPECTION_TASK, assetId: turbine.id };

    default:
      throw new Error(`Invalid task type: ${type}`);
  }
}

export function getTaskKeyFromType(type) {
  switch (type) {
    case TASK_TYPE_REPAIR:
      return REPAIR_TASK;

    case TASK_TYPE_INSPECT:
      return INSPECT_TASK;

    case TASK_TYPE_INTERNAL_BLADE_INSPECTION:
      return INTERNAL_BLADE_INSPECTION_TASK;

    case TASK_TYPE_OTHER:
      return OTHER_TASK;

    case TASK_TYPE_INSPECTION:
      return INSPECTION_TASK;

    default:
      throw new Error(`Invalid task type: ${type}`);
  }
}

export function getInitialSort({ columnKey, order }) {
  return { value: { sortOrder: { columnKey, order } } };
}

export function getInitialFilter({ columnKey, value }) {
  return { value: { filters: { [columnKey]: value } } };
}

export const isOverdue = ({ status, dueDate }) =>
  status !== 'COMPLETE' && moment(dueDate).isBefore(moment().subtract(1, 'days'));

export const sleep = async ms => await new Promise(resolve => setTimeout(resolve, ms));

export const NOOP = () => {};

import styled from 'styled-components';
import { Breadcrumb, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';

const TitleCrumb = styled.h1`
  font-size: 1.2em;
  display: inline;
`;

const StyledLink = styled(Link)`
  font-size: 1.2em;
`;

const StyledTitle = styled.span`
  font-size: 1.2em;
`;

const StyledSkeleton = styled(Skeleton)`
  &.ant-skeleton {
    display: inline;
    vertical-align: text-top;
  }
  .ant-skeleton-content .ant-skeleton-title {
    margin: 0;
  }
`;
const LoadingState = () => (
  <StyledSkeleton loading active paragraph={false} title={{ width: 50 }} />
);

export interface BreadcrumbItemProps {
  title: string;
  href?: string;
}

interface BreadcrumbsProps extends BreadcrumbProps {
  items: BreadcrumbItemProps[];
  loading?: boolean;
}

const Breadcrumbs = ({ items, loading, ...props }: BreadcrumbsProps) => {
  return (
    <Breadcrumb {...props}>
      {items &&
        items.map((item, idx, arr) => {
          const { title, href } = item;

          // Even if loading, show title if its non-dynamic.
          const isConstant = title && !title.toString().match('undefined');

          // Show a link if href provided.
          // href will usually be non-null even when loading, so need to check loading state here as well.
          const innerContent =
            href && !loading ? (
              <StyledLink to={href}>{title}</StyledLink>
            ) : (
              <StyledTitle>{title}</StyledTitle>
            );

          const content = loading && !isConstant ? <LoadingState /> : innerContent;

          // Emphasize the last item in the breadcrumb list
          const isLast = idx === arr.length - 1;

          const key = `${item.title}__${idx}`;

          return (
            <Breadcrumb.Item data-testid={`breadcrumb-link-${key}`} key={key}>
              {isLast ? <TitleCrumb>{content}</TitleCrumb> : content}
            </Breadcrumb.Item>
          );
        })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;

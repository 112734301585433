import { Link } from 'react-router-dom';
import { isEqual, flatten, startCase, orderBy, round, sortBy, isFinite } from 'lodash';

import { getUniqueIdentifier } from 'horizon/components/Assets/AssetDetails/helpers';
import { getOrderedAncestors } from 'components/data/assets/assetColumnRenderers';

import { DamageNumberText, None } from 'components/data/helpers';
import {
  AtlasGqlHorizonDamage,
  AtlasGqlHorizonDamageObservation,
  AtlasGqlHorizonDamageObservationGroup,
  AtlasGqlPropagationType,
  AtlasGqlHorizonAnnotation,
  AtlasGqlInspection,
  AtlasGqlTargetTower_External,
  AtlasGqlTargetTransition_Piece,
  AtlasGqlAsset,
  AtlasGqlTaskTarget,
  AtlasGqlTaskTargetType,
  AtlasGqlTask,
} from 'types/atlas-graphql';

import {
  BladeTarget,
  PictureTarget,
  TowerOrTransitionPieceTarget,
} from 'horizon/routes/Inspections/types';
import { Divider } from 'antd';
import { RecursivePartial } from 'utils/types';
import { ModalLink } from 'utils/router';

export const getObservationType = (
  observation: AtlasGqlHorizonDamageObservation | null | undefined
): string => {
  const inspection = observation?.inspection;
  const vendorName = inspection?.vendor?.name ?? '';
  const typeName = inspection?.type?.name ?? '';

  return vendorName && typeName ? `${vendorName} — ${typeName}` : '';
};

export const getObservationGroupName = (
  observation: AtlasGqlHorizonDamageObservation | null | undefined
): string => {
  const inspectionDate = observation?.inspection?.inspectionDate;
  const observationType = getObservationType(observation);

  return inspectionDate && observationType ? `${inspectionDate} — ${observationType}` : '';
};

export const _propagationTypeMapping = Object.fromEntries(
  Object.values(AtlasGqlPropagationType).map(t => [t, startCase(t)])
);

// Mapping of Gql Propagation Type enum to render-friendly values
export const propagationTypeMapping = {
  // TODO: remove 'Progression' .filter()
  ...Object.fromEntries(
    Object.values(AtlasGqlPropagationType)
      .filter(type => !type.includes('Progression'))
      .filter(type => !type.includes('Propagation'))
      .map(t => [t, startCase(t)])
  ),
  // Sort propagation values in order of severity
  ...Object.fromEntries(
    [
      AtlasGqlPropagationType.PropagationLight,
      AtlasGqlPropagationType.PropagationMedium,
      AtlasGqlPropagationType.PropagationHeavy,
    ].map(t => [t, startCase(t).split(' ').join(' - ')])
  ),
};

// Mapping of Gql Propagation type enum to render-friendly values, omitting the "Propagation" prefix
export const propagationTypeMappingShortened = Object.fromEntries(
  Object.entries(propagationTypeMapping).map(([k, v]) => [k, v.replace('Propagation - ', '')])
);

export const truncatePropagationNotes = (notes: string, maxLength: number = 256) =>
  notes.length > maxLength ? `${notes.substring(0, maxLength + 1)}...` : notes;

export const getObservationsFromDamage = (damage: AtlasGqlHorizonDamage | null | undefined) => {
  return (damage?.observationGroups ?? []).reduce((acc, value) => {
    const observationsInGroup = (value?.observations ?? []).filter(
      (o): o is AtlasGqlHorizonDamageObservation => !!o
    );
    return [...acc, ...observationsInGroup];
  }, [] as AtlasGqlHorizonDamageObservation[]);
};

export const getObservationsFromObservationGroups = (
  observationGroups: (AtlasGqlHorizonDamageObservationGroup | null | undefined)[]
) => flatten(observationGroups.map(group => group?.observations ?? []));

export const getPrimaryObservationGroupForDamage = (
  damage: AtlasGqlHorizonDamage | null | undefined
): AtlasGqlHorizonDamageObservationGroup | null =>
  (damage?.observationGroups ?? []).find(og => og?.isPrimaryObservationGroup) ??
  orderBy(
    damage?.observationGroups ?? [],
    [og => new Date(og?.observations?.[0]?.inspection?.inspectionDate ?? '')],
    ['desc']
  )?.[0];

export const getPrimaryObservationForDamage = (
  damage: AtlasGqlHorizonDamage | null | undefined
): AtlasGqlHorizonDamageObservation | null => {
  // get the observation group marked as primary or fall back to the most recent group
  const observationGroup = getPrimaryObservationGroupForDamage(damage);

  // return the primary observation within that group
  return observationGroup ? getPrimaryObservationForObservationGroup(observationGroup) : null;
};

export const getPrimaryObservationForObservationGroup = (
  observationGroup: AtlasGqlHorizonDamageObservationGroup | null | undefined
): AtlasGqlHorizonDamageObservation | null => {
  // get the observation by source observation id or fall back to the first observation
  const observation =
    (observationGroup?.observations ?? []).find(
      o => o?.id === observationGroup?.sourceObservationId
    ) ?? observationGroup?.observations?.[0];

  // return the primary observation
  return observation ?? null;
};

export const getObservationGroupByInspectionId = (
  damage: AtlasGqlHorizonDamage | null | undefined,
  inspectionId: string
): AtlasGqlHorizonDamageObservationGroup | null | undefined =>
  damage?.observationGroups?.find(g => g?.inspectionId === inspectionId);

// Given an array of task targets, returns the inspection id
// of the inspection task target (or undefined)
export const getTaskTargetInspectionId = (
  targets: RecursivePartial<AtlasGqlTaskTarget>[]
): string | undefined => {
  const inspectionTarget: Partial<AtlasGqlInspection> | undefined = targets?.find(
    ({ targetType }) => targetType === AtlasGqlTaskTargetType.Inspection
  )?.target as AtlasGqlInspection;

  return inspectionTarget?.id;
};

/**
 * In some cases, we want to render the attributes of the task target's observation
 * group (i.e. the primary group at the time of task creation). This function finds that
 * group and sets its attributes as `primaryObservationGroupAttrs` for rendering convenience.
 * This should only be called when task locking is enabled.
 */
export const mapTargetObservationGroupAttrsToPrimary = (task: AtlasGqlTask) => {
  if (
    'targets' in task &&
    task.targets?.length &&
    'horizonDamage' in task &&
    !!task.horizonDamage
  ) {
    const targetInspectionId = getTaskTargetInspectionId(task.targets);
    const damage = task.horizonDamage as AtlasGqlHorizonDamage;
    const targetObservationGroupAttrs =
      (targetInspectionId &&
        getObservationGroupByInspectionId(damage, targetInspectionId)?.groupAttrs) ||
      damage.primaryObservationGroupAttrs;

    return {
      ...task,
      horizonDamage: {
        ...task.horizonDamage,
        primaryObservationGroupAttrs: targetObservationGroupAttrs,
      },
    };
  }

  return task;
};

// Get all possible observations for linking from inspections, filtered to be valid
// (same assetId/schemaId, but different damageId than the primaryObservation)
export const getObservationsForLinking = (
  damage: AtlasGqlHorizonDamage | null | undefined,
  inspections: AtlasGqlInspection[]
): AtlasGqlHorizonDamageObservation[] => {
  const primaryObservation = getPrimaryObservationForDamage(damage);
  return flatten(
    inspections.map(({ horizonDamageObservations }) => horizonDamageObservations)
  ).filter(
    observation =>
      observation &&
      observation.assetId === primaryObservation?.assetId &&
      observation.schema?.id === primaryObservation?.schema?.id &&
      observation.damageId !== primaryObservation?.damageId
  ) as AtlasGqlHorizonDamageObservation[];
};

export const getPictureTargetDistance = (target: PictureTarget | undefined): number | undefined => {
  const distance = Number(
    (target as BladeTarget)?.radialDistance ?? (target as TowerOrTransitionPieceTarget)?.height
  );
  return isFinite(distance) ? distance : undefined;
};

// TODO: add schema attributes for other asset types besides blades
export const getAttrsDistance = (attrs: { [key: string]: any } | undefined): number | undefined => {
  const distance = Number(attrs?.['Distance']);
  return isFinite(distance) ? distance : undefined;
};

export const getPictureTargetSide = (target: PictureTarget | undefined): string | undefined => {
  const side =
    (target as BladeTarget)?.bladeSide ??
    (target as AtlasGqlTargetTower_External)?.towerSide ??
    (target as AtlasGqlTargetTransition_Piece)?.transitionPieceSide;
  return side ? startCase(side) : undefined;
};

// TODO: add schema attributes for other asset types besides blades
export const getAttrsSide = (attrs: { [key: string]: any } | undefined): string | undefined => {
  const side = attrs?.['Blade Side'];
  return side ? startCase(side) : undefined;
};

export const sortSuggestions = (
  damage: AtlasGqlHorizonDamage,
  suggestions: AtlasGqlHorizonDamageObservation[]
) => {
  const damageAttrs = damage.primaryObservationGroupAttrs;
  const primaryObservation = getPrimaryObservationForDamage(damage);

  const damageDistance =
    getAttrsDistance(damageAttrs) ??
    getPictureTargetDistance(primaryObservation?.picture?.target) ??
    0;
  const damageSide =
    getAttrsSide(damageAttrs) ?? getPictureTargetSide(primaryObservation?.picture?.target) ?? '';

  // sort observations by
  // - closest to given distance (blade) or height (tower/transition piece)
  // - closest to given side (blade/tower/transition piece)
  return sortBy(suggestions, [
    observation => {
      const observationDistance =
        getAttrsDistance(observation.attrs) ??
        getPictureTargetDistance(observation.picture?.target) ??
        0;
      return Math.abs(damageDistance - observationDistance);
    },
    observation => {
      const observationSide =
        getAttrsSide(observation.attrs) ?? getPictureTargetSide(observation.picture?.target) ?? '';
      return damageSide === observationSide ? 0 : observationSide;
    },
  ]);
};

const _getOldestOrNewestObservationForDamage = (
  damage: AtlasGqlHorizonDamage,
  position: 'oldest' | 'newest'
): AtlasGqlHorizonDamageObservation | null => {
  // sort observation groups ascending or descending to find the oldest or newest group
  const observationGroup = orderBy(
    damage?.observationGroups ?? [],
    [og => new Date(og?.observations?.[0]?.inspection?.inspectionDate ?? '')],
    position === 'oldest' ? ['asc'] : ['desc']
  )?.[0];

  // return the primary observation within that group
  return observationGroup ? getPrimaryObservationForObservationGroup(observationGroup) : null;
};

export const getOldestObservationForDamage = (
  damage: AtlasGqlHorizonDamage
): AtlasGqlHorizonDamageObservation | null =>
  _getOldestOrNewestObservationForDamage(damage, 'oldest');

export const getNewestObservationForDamage = (
  damage: AtlasGqlHorizonDamage
): AtlasGqlHorizonDamageObservation | null =>
  _getOldestOrNewestObservationForDamage(damage, 'newest');

export const renderAttribute = (
  attrs: { [key: string]: any },
  key: string,
  unit: string = ''
): string | JSX.Element => {
  return attrs?.[key] ? `${formatAttribute(attrs[key])}${unit}` : <None />;
};

/**
 * Hack for getting legacy damage id from the picture data
 * by matching coordinates. This is intended for temporary
 * use for constructing links while both damage systems
 * are still in place.
 */
export const getLegacyDamageIdForObservation = (
  observation?: AtlasGqlHorizonDamageObservation | null
): string | undefined =>
  observation?.picture?.damages?.find(
    ({ observations }) =>
      (observations as AtlasGqlHorizonAnnotation[]).findIndex(({ coordinates }) =>
        isEqual(coordinates, observation?.coordinates)
      ) !== -1
  )?.id;

export const formatDamageId = ({ id }: Partial<AtlasGqlHorizonDamage>): string =>
  id?.split('-')[0].toUpperCase();

export const formatObservationId = ({ id }: Partial<AtlasGqlHorizonDamageObservation>): string =>
  id?.split('-')[0].toUpperCase();

export const formatAttribute = (value: number | string): number | string =>
  typeof value === 'number' ? round(value, 2) : value;

export const DamageNumber = ({ damageId }: { damageId: string }) => (
  <DamageNumberText>{formatDamageId({ id: damageId })}</DamageNumberText>
);

export const DamageLink = ({ damageId, target }: { damageId: string; target?: string }) => (
  <Link to={`/damages2/${damageId}`} target={target} className="testid-damage-link">
    <DamageNumberText>{formatDamageId({ id: damageId })}</DamageNumberText>
  </Link>
);

export const ObservationLink = ({
  observationId,
  pictureId,
  target,
}: {
  observationId: string;
  pictureId: string | undefined;
  target?: string;
}) =>
  pictureId ? (
    <ModalLink to={`/pictures/${pictureId}?observation=${observationId}&version=2`} target={target}>
      <DamageNumberText>{formatObservationId({ id: observationId })}</DamageNumberText>
    </ModalLink>
  ) : (
    <DamageNumberText>{formatObservationId({ id: observationId })}</DamageNumberText>
  );

export const AssetLink = ({ asset, target }: { asset: AtlasGqlAsset; target?: string }) => {
  return (
    <>
      {getOrderedAncestors(asset).map(a => (
        <span key={a?.id}>
          <Link to={`/assets/${a?.id}`} target={target}>
            {asset && getUniqueIdentifier(a)}
          </Link>
          <Divider type="vertical" />
        </span>
      ))}
      <Link to={`/assets/${asset?.id}`} target={target}>
        {asset && (asset?.uniqueIdLabel ?? getUniqueIdentifier(asset))}
      </Link>
    </>
  );
};

export const InspectionLink = ({
  observation,
  target,
}: {
  observation: AtlasGqlHorizonDamageObservation;
  target?: string;
}) => {
  const inspectionId = observation.inspection?.id;
  const inspectionText = getObservationGroupName(observation);

  return inspectionId ? (
    <Link to={`/inspections/${inspectionId}`} target={target}>
      {inspectionText}
    </Link>
  ) : (
    <>{inspectionText}</>
  );
};

// Utils for sorting observation groups by date and determining which is source/target
export const sortGroupsByAttrDateIfPresent = (
  g1: AtlasGqlHorizonDamageObservationGroup,
  g2: AtlasGqlHorizonDamageObservationGroup
): -1 | 0 | 1 => {
  const g1Date = g1?.groupAttrs?.Date || g1?.groupAttrs?.date || undefined;
  const g2Date = g2?.groupAttrs?.Date || g2?.groupAttrs?.date || undefined;

  if (g1Date && g2Date) {
    try {
      const g1d = new Date(g1Date).getTime();
      const g2d = new Date(g2Date).getTime();
      return g1d > g2d ? 1 : g2d > g1d ? -1 : 0;
    } catch (ex) {
      console.error('Error parsing dates for comparison:  ' + ex);
    }
  } else if (g1Date) {
    return 1;
  } else if (g2Date) {
    return -1;
  }
  return 0;
};

export const findSourceAndTargetFromSortedList = (
  sorted: AtlasGqlHorizonDamageObservationGroup[],
  newInspectionId: string
): {
  source: AtlasGqlHorizonDamageObservationGroup | undefined;
  target: AtlasGqlHorizonDamageObservationGroup | undefined;
} => {
  for (const [index, group] of sorted.entries()) {
    if (group?.inspectionId === newInspectionId) {
      if (index === 0) {
        return {
          source: group,
          target: sorted[1],
        };
      } else {
        return {
          source: sorted[index - 1],
          target: group,
        };
      }
    }
  }
  return { source: undefined, target: undefined };
};

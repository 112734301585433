import { useState, useMemo, FC, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from 'components/ui';
import { SelectableAction, useSelectableRecords } from 'utils/selectable';
import { TABLE_SCROLL_WIDTH_SMALL } from 'utils/constants';
import DataTable from 'components/DataTable';
import { name, status, make, model, location } from 'components/data/turbines';
import { LocationChooser } from 'components/choosers';
import { useGetTurbineListChooserQuery } from 'types/atlas-graphql';

const Control = styled.div`
  .ant-select {
    min-width: 200px;
  }
`;

const columns = [name, location, status, make, model];

interface BulkTurbineChooserProps {
  onChange: (turbines: any[]) => void;
}

const BulkTurbineChooser: FC<BulkTurbineChooserProps> = ({ onChange }) => {
  const [locationId, setLocationId] = useState<string | undefined>(undefined);
  const { selected, rowSelection, onClearSelection } = useSelectableRecords();
  const { data, loading } = useGetTurbineListChooserQuery();

  const turbines = data?.turbines ?? [];

  const filteredTurbines = useMemo(
    () => turbines.filter(turbine => (locationId ? turbine.location?.id === locationId : true)),
    [turbines, locationId]
  );

  const handleAdd = useCallback(() => onChange(selected), [selected]);

  const handleLocationChange = useCallback((value: string | string[]) => {
    setLocationId(value instanceof Array ? value[0] : value);
  }, []);

  return (
    <DataTable
      loading={loading}
      scrollWidth={TABLE_SCROLL_WIDTH_SMALL}
      size="small"
      columns={columns}
      data={filteredTurbines}
      rowSelection={rowSelection}
      controls={
        <Control>
          <LocationChooser value={locationId} onChange={handleLocationChange} disabled={loading} />
        </Control>
      }
      actions={
        <SelectableAction selected={selected} onClear={onClearSelection}>
          <Button type="primary" disabled={!selected.length} onClick={handleAdd}>
            Add
          </Button>
        </SelectableAction>
      }
    />
  );
};

export default BulkTurbineChooser;

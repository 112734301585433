import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'components/ui';
import COLORS from 'utils/color/definitions';

const addDownloadParam = (href: string) => {
  const url = new URL(href);
  url.searchParams.append('dl', 'true');
  url.searchParams.append('raw', 'true');
  return url.toString();
};

type DownloadButtonProps = {
  href: string;
  children?: React.ReactNode;
  useIconButton?: boolean;
};

export const DownloadButton: React.FunctionComponent<DownloadButtonProps> = ({
  href,
  children,
  useIconButton,
  ...props
}) =>
  useIconButton ? (
    <Button
      title="Download Image"
      id="ol-control-download"
      href={addDownloadParam(href)}
      icon={<DownloadOutlined style={{ color: COLORS.BLACK }} />}
    />
  ) : (
    <Button title="Download" id="ol-control-download">
      <a href={addDownloadParam(href)}>
        <DownloadOutlined />
        {children}
      </a>
    </Button>
  );

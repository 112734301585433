import {
  AtlasGqlHorizonDamage,
  AtlasGqlHorizonDamageObservation,
  useGetHorizonDamagePictureQuery,
} from 'types/atlas-graphql';

import { Spinner, Tooltip } from 'components/ui';
import { orderBy } from 'lodash';

import {
  StyledDiv,
  StyledCarouselWrapper,
  StyledQuestionCircleTwoTone,
} from './HorizonDamageImage.style';
import COLORS from 'utils/color/definitions';

import { usePermissions } from 'utils/usePermissions';
import { WORK_ORDER_COLLABORATOR } from 'utils/access-control/roles';

import {
  getAttrsDistance,
  getObservationGroupByInspectionId,
  getPictureTargetDistance,
  getPrimaryObservationGroupForDamage,
} from 'horizon/components/Damages/utils';
import { ThumbnailCarousel } from 'horizon/components/Images/ImageThumbnail/ThumbnailCarousel';

type ImageProps = {
  damageId: string;
  inspectionIdOfGroupToRender?: string;
};

export const HorizonDamageImage: React.FunctionComponent<ImageProps> = ({
  damageId,
  inspectionIdOfGroupToRender,
}) => {
  const { HasRole } = usePermissions();
  const isWoCollaborator = HasRole({ role: WORK_ORDER_COLLABORATOR });

  // get picture data
  const { data, loading } = useGetHorizonDamagePictureQuery({
    variables: { input: { id: damageId } },
  });

  const damage = data?.getHorizonDamage && (data?.getHorizonDamage as AtlasGqlHorizonDamage);
  const observationGroup =
    (damage &&
      inspectionIdOfGroupToRender &&
      getObservationGroupByInspectionId(damage, inspectionIdOfGroupToRender)) ||
    getPrimaryObservationGroupForDamage(damage);
  const observations = orderBy(
    observationGroup?.observations?.filter((o): o is AtlasGqlHorizonDamageObservation => !!o),
    [
      observation =>
        getAttrsDistance(observation?.attrs) ??
        getPictureTargetDistance(observation?.picture?.target),
      observation => observation?.attrs?.Severity ?? 0,
    ],
    ['asc', 'desc']
  );
  const sourceObservationIndex =
    observations.findIndex(o => o.id === observationGroup?.sourceObservationId) ?? 0;

  return (
    <StyledDiv>
      {loading ? (
        <Spinner />
      ) : (
        observations && (
          <StyledCarouselWrapper data-testid="horizon-damage-carousel" innerHeight={'unset'}>
            <ThumbnailCarousel
              thumbnails={observations.map(o => ({
                picture: o.picture,
                observations: [o],
                scaleFilter: 1,
              }))}
              initialSlideIndex={sourceObservationIndex}
              allowZoom={true}
            />
            {!isWoCollaborator && (damage?.observationGroups ?? []).length > 1 && (
              <Tooltip
                title={
                  <>
                    {observations.length === 1 ? 'This image is' : 'These images are'} from the
                    primary observation group
                    {inspectionIdOfGroupToRender ? ' at the time of task creation' : ''}. To see the
                    full history of this damage, click on the Damage ID.
                  </>
                }
              >
                <StyledQuestionCircleTwoTone
                  twoToneColor={COLORS.TEAL}
                  $isInCarousel={observations.length > 1}
                />
              </Tooltip>
            )}
          </StyledCarouselWrapper>
        )
      )}
    </StyledDiv>
  );
};

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const StyledContent = styled.div`
  width: 25rem;
  margin: -0.75rem -1rem;
`;

export const StyledList = styled.ul`
  padding-left: 0;
  list-style-type: none;
  margin: 0;
  max-height: 300px;
  overflow-y: auto;
`;

interface LiProps {
  hasLink?: boolean;
  unread?: boolean;
}

export const StyledItem = styled.li<LiProps>`
  color: ${({ theme }) => theme.textColor};
  padding: ${({ hasLink }) => (hasLink ? 0 : '1rem')};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  display: flex;
  align-items: center;
  ${({ unread }) =>
    unread
      ? css`
          font-weight: bold;
          color: inherit;
        `
      : ''};
  .anticon.status-icon {
    align-self: flex-start;
    margin: 0.3rem 1rem 0 0;
    position: relative;
    ${({ unread }) =>
      unread
        ? css`
            &::after {
              content: '';
              width: 0.75rem;
              height: 0.75rem;
              background: ${({ theme }) => theme.blue};
              display: block;
              bottom: -2px;
              position: absolute;
              right: -2px;
              border-radius: 50%;
            }
          `
        : ''};
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const StyledLink = styled(Link)`
  padding: 1rem;
  color: inherit;
  width: 100%;
  display: flex;
  &:hover {
    color: inherit;
    background-color: rgba(43, 173, 227, 0.1);
  }
`;

export const StyledHeader = styled.span`
  text-transform: uppercase;
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  background-color: #fafafa;
`;

export const StyledMessage = styled.div`
  flex: 1;
  p {
    margin-bottom: 0;
  }
`;

export const StyledDate = styled.p`
  font-size: 0.75rem;
  flex: 0 0 auto;
  align-self: flex-start;
  margin-top: 0.1rem;
`;

import styled from 'styled-components';

export default styled.button`
  background: none;
  color: ${(props: any) => (props.color ? props.color : props.theme.blue)};
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
`;

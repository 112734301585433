import { Modal, Form, Input } from 'antd';
import { Button, ModalActionButtons } from 'components/ui';
import {
  ExternalServiceRequestChooser,
  getTurbinesFromTasks,
} from 'components/choosers/ExternalServiceRequestChooser';
import { useState } from 'react';

interface IWorkOrderNameEditorProps {
  workOrder: any;
  onSave: (name: string) => void;
}
export const WorkOrderNameEditor: React.FunctionComponent<IWorkOrderNameEditorProps> = ({
  workOrder,
  onSave,
}) => {
  const [form] = Form.useForm();

  const turbineList = getTurbinesFromTasks(workOrder.tasks);
  const [visible, setVisible] = useState(true);
  const [name, setName] = useState(workOrder.name);
  const assetIdForMaximo = turbineList.length === 1 ? turbineList[0] : '';
  function handleClose() {
    setVisible(false);
  }

  const handleExternalServiceRequestChange = (value: string, option: string) => {
    setName(value + ' ' + option);
  };
  const handleSave = () => {
    setVisible(false);
    onSave(name);
  };
  return (
    <Modal title={'Edit Work Order Name'} visible={visible} footer={null} onCancel={handleClose}>
      <Form layout="vertical" form={form}>
        <Form.Item label="Maximo Service Request Number" name="serviceRequestNumber">
          <ExternalServiceRequestChooser
            assetId={assetIdForMaximo}
            disableForMultipleTurbines={turbineList.length > 1 ? true : false}
            onChange={handleExternalServiceRequestChange}
          />
        </Form.Item>
        <Form.Item
          label="Name"
          rules={[{ required: true, message: 'Please give this work order a name.' }]}
        >
          <Input
            data-testid="name-input"
            placeholder="Name"
            value={name}
            style={{ marginRight: '2rem' }}
            onChange={e => setName(e.target.value)}
          />
        </Form.Item>
        <ModalActionButtons>
          <Button _version={4} type="primary" onClick={handleSave}>
            Edit
          </Button>
          <Button _version={4} onClick={handleClose}>
            Cancel
          </Button>
        </ModalActionButtons>
      </Form>
    </Modal>
  );
};

import { createContext, useContext } from 'react';
import { Auth0ContextInterface, LogoutOptions } from '@auth0/auth0-react';

interface AuthHeader {
  Authorization: string;
}

export interface LogoutArgs extends LogoutOptions {
  broadcast?: boolean;
}

interface AuthContextType extends Auth0ContextInterface {
  logout: (options?: LogoutArgs) => void;
  getAuthHeader: () => Promise<AuthHeader>;

  /***
   * Debug counter, used with `window.incrementCounter` to cause a React refresh.
   */
  counter: number;
}

const defaultAuth: AuthContextType = {
  getAccessTokenSilently: () => Promise.reject('getAccessTokenSilently not set') as any,
  getAccessTokenWithPopup: () => Promise.reject('getAccessTokenWithPopup not set'),
  getAuthHeader: () => Promise.reject('getAuthHeader not set'),
  getIdTokenClaims: () => Promise.reject('getIdTokenClaims not set'),
  handleRedirectCallback: () => Promise.reject('handleRedirectCallback not set'),
  isAuthenticated: false,
  isLoading: false,
  loginWithPopup: () => Promise.reject('loginWithPopup not set'),
  loginWithRedirect: () => Promise.reject('loginWithRedirect not set'),
  logout: () => {},
  counter: 0,
};

const AuthContext = createContext<AuthContextType>(defaultAuth);

export default AuthContext;

export function useAuthContext() {
  return useContext(AuthContext);
}

import { useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import { clamp } from 'lodash';
import { AtlasGqlHorizonDamage } from 'types/atlas-graphql';
import { BladeSide, ColorCodingAttribute } from './BladeDamagePlotContainer';
import { Axes } from './Axes';
import { EdgeShape } from './EdgeShape';
import { SideShape } from './SideShape';
import { ColorCodingMap, mapEdgeDamagesToPoints, mapSideDamagesToPoints } from './pointUtils';

type BladeDamagePlotProps = {
  bladeSide: BladeSide;
  visible: boolean;
  damages: AtlasGqlHorizonDamage[];
  loading: boolean;
  shapeWidths: number[];
  bladeLength: number;
  xAxisMax?: number; // if provided, x-axis range will be 0 - xAxisMax, otherwise 0 - bladeLength
  yAxisMax?: number; // if provided, y-axis range will be 0 - yAxisMax, otherwise 0 - max(shapeWidths)
  colorCodingAttribute?: ColorCodingAttribute;
  colorCodingMap?: ColorCodingMap;
  allowAreaSelection: boolean;
  onAreaSelection: (params: {
    bladeSide: BladeSide | null;
    distanceRange: [number, number] | null;
    chordRange?: [number, number] | null;
  }) => void;
};

const [width, height] = [1000, 100];
const [marginTop, marginRight, marginBottom, marginLeft] = [2, 2, 20, 20];

export const BladeDamagePlot: React.FunctionComponent<BladeDamagePlotProps> = ({
  bladeSide,
  visible,
  damages = [],
  loading,
  shapeWidths,
  bladeLength,
  xAxisMax,
  yAxisMax,
  colorCodingAttribute,
  colorCodingMap,
  allowAreaSelection,
  onAreaSelection,
}) => {
  const brushRef = useRef<SVGGElement>(null);

  // Projects a set of blade shape widths by percent radial distance to [x,y] coordinate pairs
  const { shapePoints, maxBladeWidth }: { shapePoints: [number, number][]; maxBladeWidth: number } =
    useMemo(() => {
      const maxBladeWidth = yAxisMax ?? Math.max(...shapeWidths);
      return {
        shapePoints: [BladeSide.LeadingEdge, BladeSide.TrailingEdge].includes(bladeSide)
          ? []
          : shapeWidths.map((w, i) => [(i * bladeLength) / 100, (100 * w) / maxBladeWidth]),
        maxBladeWidth,
      };
    }, [shapeWidths, bladeSide, yAxisMax]);

  // Create d3 scales for radial distance (x) and chord % (y)
  const xScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([0, xAxisMax ?? bladeLength])
        .range([marginLeft, width - marginRight]),
    [bladeLength, xAxisMax, width]
  );
  const yScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([0, 100])
        .range(
          bladeSide === BladeSide.SuctionSide
            ? [marginTop, height - marginBottom]
            : [height - marginBottom, marginTop]
        ),
    [bladeSide, height]
  );

  // Once the brush element exists, set up its handler function
  useEffect(() => {
    if (brushRef.current) {
      // Returns the width of the blade at a given distance (for calculating chord)
      const getWidthAtDistance = (distance: number) =>
        shapeWidths[Math.round((100 * distance) / bladeLength)];

      const xRange = xScale.range();
      const yRange = yScale.range();

      /**
       * Handler for area selection: gets the distance and chord (if PS or SS) range of the selection
       * and passes these, along with the blade side, to the area selection handler function in the container
       */
      const areaSelection = d3
        .brush()
        .extent([
          [xRange[0], yRange[bladeSide === BladeSide.SuctionSide ? 0 : 1]],
          [xRange[1], yRange[bladeSide === BladeSide.SuctionSide ? 1 : 0]],
        ])
        .on('end', ({ selection }: d3.D3BrushEvent<unknown>) => {
          if (selection) {
            const [[distanceMin, yMin], [distanceMax, yMax]] = (
              selection as [[number, number], [number, number]]
            ).map(([x, y]) => [
              clamp(xScale.invert(x), 0, bladeLength),
              clamp(yScale.invert(y), 0, 100),
            ]);

            if ([BladeSide.PressureSide, BladeSide.SuctionSide].includes(bladeSide)) {
              // Calculate chord range from y-axis pixel range and the blade width arrays
              const [widthAtDistanceMin, widthAtDistanceMax] = [distanceMin, distanceMax].map(
                getWidthAtDistance
              );
              const chordMin = Math.min(
                yMin / (widthAtDistanceMin / maxBladeWidth),
                yMin / (widthAtDistanceMax / maxBladeWidth)
              );
              const chordMax = Math.max(
                yMax / (widthAtDistanceMin / maxBladeWidth),
                yMax / (widthAtDistanceMax / maxBladeWidth)
              );

              onAreaSelection({
                bladeSide,
                distanceRange: [distanceMin, distanceMax],
                chordRange:
                  bladeSide === BladeSide.PressureSide
                    ? [chordMax, chordMin]
                    : [chordMin, chordMax],
              });
            } else {
              onAreaSelection({
                bladeSide,
                distanceRange: [distanceMin, distanceMax],
              });
            }
          } else {
            onAreaSelection({
              bladeSide: null,
              distanceRange: null,
              chordRange: null,
            });
          }
        });

      d3.select<SVGElement, unknown>(brushRef.current).call(areaSelection);
    }
  }, [allowAreaSelection, brushRef.current, xScale, yScale, bladeSide, damages]);

  /**
   * Map damages with distance and chord attributes to svg shapes at the correct [x,y] coordinates.
   * Any damages without distance, and any PS/SS damages without chord will be omitted.
   * (see ./pointUtils for detail)
   */
  const damagePoints: JSX.Element[] = useMemo(
    () =>
      damages
        .map(
          [BladeSide.LeadingEdge, BladeSide.TrailingEdge].includes(bladeSide)
            ? mapEdgeDamagesToPoints({
                xScale,
                yScale,
                colorCodingAttribute,
                colorCodingMap,
              })
            : mapSideDamagesToPoints({
                widths: shapeWidths,
                maxWidth: maxBladeWidth,
                length: bladeLength,
                xScale,
                yScale,
                colorCodingAttribute,
                colorCodingMap,
              })
        )
        .filter((point): point is JSX.Element => !!point),
    [bladeSide, damages, shapeWidths, xScale, yScale, colorCodingAttribute]
  );

  /**
   * TODO: curve along bottom of side shape, translate y coordinates accordingly
   */

  return (
    <svg
      key={bladeSide}
      viewBox={`0 0 ${width} ${height}`}
      className={visible ? '' : 'hidden'}
      opacity={loading || !!damagePoints.length ? 1 : 0.5}
    >
      {!!shapePoints.length &&
      [BladeSide.PressureSide, BladeSide.SuctionSide].includes(bladeSide) ? (
        <SideShape xScale={xScale} yScale={yScale} points={shapePoints} />
      ) : (
        <EdgeShape />
      )}
      <Axes
        xScale={xScale}
        yScale={yScale}
        bladeSide={bladeSide}
        maxWidth={maxBladeWidth}
        height={height}
      />
      <g fill="none" stroke="currentColor" strokeWidth="1.5">
        {...damagePoints}
      </g>
      <g transform={`translate(54, 12)`} fill="#0000008C">
        <text
          style={{
            fontSize: '10px',
            textAnchor: 'middle',
          }}
        >
          {bladeSide}
        </text>
      </g>
      {!loading && !damagePoints.length && (
        <g transform={`translate(${width / 2}, 46)`} fill="#0000008C">
          <text
            style={{
              fontSize: '14px',
              textAnchor: 'middle',
            }}
          >
            No Data
          </text>
        </g>
      )}
      {allowAreaSelection && <g ref={brushRef} />}
    </svg>
  );
};

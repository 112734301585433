import { Link } from 'react-router-dom';
import { Radio, Tag } from 'antd';
import { StatusTag } from 'components/StatusTag';
import { standardInput } from 'utils/editable';
import { sortBy, None, defaultNone, getOnFilter, getFilterDropdown } from './helpers';
import { get } from 'lodash';
import {
  TURBINE_EDIT_MAKE,
  TURBINE_EDIT_MODEL,
  TURBINE_EDIT_STATUS,
} from 'utils/access-control/rules';

const NONE = <None />;

export const name = {
  key: 'name',
  title: 'Turbine',
  render(record) {
    const { id, name } = record || {};
    return [id, name].every(Boolean) ? <Link to={`/turbines/${id}`}>{name}</Link> : NONE;
  },
  sorter: sortBy('name'),
  get: record => get(record, 'name'),
  filterProperty: 'name',
};

const statusFilterConfig = {
  key: 'status',
  type: 'string',
  options: ['ACTIVE', 'INACTIVE'],
  exactMatch: true,
};

export const status = {
  key: 'status',
  title: 'Status',
  dataIndex: 'status',
  render: status => (status ? <StatusTag value={status} /> : NONE),
  sorter: sortBy('status'),
  get: record => get(record, 'status'),
  filters: [
    { text: 'Online', value: 'ACTIVE' },
    { text: 'Offline', value: 'INACTIVE' },
  ],
  onFilter: getOnFilter(statusFilterConfig),
  filterDropdown: getFilterDropdown(statusFilterConfig),
  editRule: TURBINE_EDIT_STATUS,
  edit(record, { onChange, ...props }) {
    if (!record) return <None message="Unable to edit" />;
    const { status } = record;
    return (
      <Radio.Group
        defaultValue={status}
        onChange={
          onChange &&
          (e =>
            onChange({
              status: e.target.value,
            }))
        }
        {...props}
      >
        <Radio value="ACTIVE">Online</Radio>
        <Radio value="INACTIVE">Offline</Radio>
      </Radio.Group>
    );
  },
};

export const make = {
  key: 'make',
  title: 'Make',
  dataIndex: 'make',
  render: defaultNone(),
  sorter: sortBy('make'),
  get: record => get(record, 'make'),
  filterProperty: 'make',
  onFilter: getOnFilter({ key: 'make', type: 'string' }),
  editRule: TURBINE_EDIT_MAKE,
  edit: standardInput('make'),
};

export const model = {
  key: 'model',
  title: 'Model',
  dataIndex: 'model',
  render: defaultNone(),
  sorter: sortBy('model'),
  get: record => get(record, 'model'),
  filterProperty: 'model',
  onFilter: getOnFilter({ key: 'model', type: 'string' }),
  editRule: TURBINE_EDIT_MODEL,
  edit: standardInput('model'),
};

export const location = {
  key: 'location.name',
  title: 'Site',
  dataIndex: 'location',
  render: location => {
    const { id, name } = location || {};
    return [id, name].every(Boolean) ? <Link to={`/sites/${id}`}>{name}</Link> : NONE;
  },
  sorter: sortBy('location.name'),
  get: record => get(record, ['location', 'name']),
  filterProperty: 'location.name',
};

export const atlasLocation = {
  key: 'parent.name',
  title: 'Site',
  dataIndex: 'parent',
  render: parent => {
    const { id, name } = parent || {};
    return [id, name].every(Boolean) ? <Link to={`/sites/${id}`}>{name}</Link> : NONE;
  },
  sorter: sortBy('parent.name'),
  get: record => get(record, ['parent']),
  filterProperty: 'parent.name',
};

export const inspections = {
  key: 'inspections.length',
  title: 'Inspections',
  dataIndex: 'inspections.length',
  render(count, turbine) {
    const { id } = turbine || {};
    return count ? <Link to={`/turbines/${id}/inspections`}>{count}</Link> : NONE;
  },
};

const auxiliaryComponentDict = {
  VORTEX_GENERATORS: 'Vortex Generators',
  SERRATIONS: 'Serrations',
  SPOILERS: 'Spoilers',
  CHORD_EXTENSIONS: 'Chord Extensions',
  LPS_DIVERTER_STRIPS: 'LPS Diverter Strips',
  LEADING_EDGE_PROTECTION: 'Leading Edge Protection',
};

import { Children, cloneElement, useCallback } from 'react';
import { useAccountContext } from 'utils/account/AccountContext';

function renderIfCanAccess({
  render,
  fallbackRender,
  children,
  rules = [],
  CanAccess,
  notRoles = [],
  HasRole,
  ...props
}) {
  if ((rules.length && !CanAccess({ rules })) || (notRoles.length && notRoles.some(HasRole))) {
    return fallbackRender ? fallbackRender() : null;
  }

  if (render) {
    return render(props);
  }

  return Children.toArray(children).map(child => cloneElement(child, props));
}

export function CanRender(props) {
  const { CanAccess, HasRole } = usePermissions(props);

  return renderIfCanAccess({ CanAccess, HasRole, ...props });
}

export function usePermissions() {
  const { customer, user } = useAccountContext();
  const customerId = customer?.id;

  function CanRender(props) {
    return renderIfCanAccess({ CanAccess, HasRole, ...props });
  }

  // rules array that is passed in is treated as OR's
  // i.e. [TASK_EDIT, TASK_DELETE] is TASK_EDIT || TASK_DELETE
  const CanAccess = useCallback(
    ({ rules } = {}) => {
      if (!rules || !rules.length || !user) {
        return false;
      }

      if (rules.every(rule => rule === true)) {
        // hack we are supporting, if the 'rule' is exactly `true` let it
        // through (only happens in analyst app right now I think)
        return true;
      }
      const role = user.roles.find(role => role.organizationId === customerId);
      if (!role || !role.actions || !role.actions.length) {
        return false;
      }

      // if the size of the difference set is less than size of the rules array
      // then the OR condition has been satisfied as at least one of those has
      // matched the permission set
      const reducedRuleSet = [rules, role.actions].reduce((a, b) => {
        return a.filter(c => !b.includes(c));
      });
      return reducedRuleSet.length < rules.length;
    },
    [user, customerId]
  );

  const HasRole = useCallback(
    ({ role } = {}) => {
      if (!user) {
        return false;
      }

      const userRole = user.roles.find(role => role.organizationId === customerId);
      return userRole?.name === role;
    },
    [user, customerId]
  );

  return { CanAccess, CanRender, HasRole };
}

import { createContext, useContext } from 'react';
import { PaneSide, CardState } from './types';
import {
  AtlasGqlHorizonDamage,
  AtlasGqlPictureUpdateInput,
  AtlasGqlUpdatePicturesMutation,
  Exact,
} from 'types/atlas-graphql';
import { MutationFunctionOptions } from '@apollo/client';

type ImageViewerContextType = {
  isPictureReview: boolean;
  leftPaneDamage?: Partial<AtlasGqlHorizonDamage>;
  rightPaneDamage?: Partial<AtlasGqlHorizonDamage>;
  setDamage: (paneSide: PaneSide, newDamage: AtlasGqlHorizonDamage) => void;
  setPicture: (paneSide: PaneSide, newPictureId: string | null, newObservationId?: string) => void;
  setObservation: (paneSide: PaneSide, newObservationId: string | null) => void;
  setFilter: (paneSide: PaneSide, newFilter: string) => void;
  setCardState: (paneSide: PaneSide, updatedCardState: Partial<CardState>) => void;
  updatePictures: (
    options?:
      | MutationFunctionOptions<
          AtlasGqlUpdatePicturesMutation,
          Exact<{
            picturesToUpdate: AtlasGqlPictureUpdateInput | AtlasGqlPictureUpdateInput[];
          }>
        >
      | undefined
  ) => void;
  updatePicturesLoading: boolean;
  navigationDisabled: boolean;
  setNavigationDisabled: (disabled: boolean) => void;
  isMerging: boolean;
  setIsMerging: (bool: boolean) => void;
  shouldRefetchLeftObservation: boolean;
  shouldRefetchRightObservation: boolean;
  setShouldRefetchObservation: (paneSide: PaneSide, shouldRefetch: boolean) => void;
  shouldRefetchLeftImage: boolean;
  shouldRefetchRightImage: boolean;
  setShouldRefetchImage: (paneSide: PaneSide, shouldRefetch: boolean) => void;
};

const defaultImageViewerContext = {
  isPictureReview: false,
  leftPaneDamage: undefined,
  rightPaneDamage: undefined,
  setDamage: () => {},
  setPicture: () => {},
  setObservation: () => {},
  setFilter: () => {},
  setCardState: () => {},
  updatePictures: async () => {},
  updatePicturesLoading: false,
  navigationDisabled: false,
  setNavigationDisabled: () => {},
  isMerging: false,
  setIsMerging: () => {},
  shouldRefetchLeftObservation: false,
  shouldRefetchRightObservation: false,
  setShouldRefetchObservation: () => {},
  shouldRefetchLeftImage: false,
  shouldRefetchRightImage: false,
  setShouldRefetchImage: () => {},
};

export const ImageViewerContext = createContext<ImageViewerContextType>(defaultImageViewerContext);

export const useImageViewerContext = () => useContext(ImageViewerContext);

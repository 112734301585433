import {
  AtlasGqlAsset,
  AtlasGqlAssetFieldValue,
  AtlasGqlAssetFieldValueBool,
  AtlasGqlAssetFieldValueDate,
  AtlasGqlAssetFieldValueFloat,
  AtlasGqlAssetFieldValueMultiSelect,
  AtlasGqlAssetFieldValueNumber,
  AtlasGqlAssetFieldValueSelect,
  AtlasGqlAssetFieldValueText,
} from 'types/atlas-graphql';
import moment from 'moment';
import { formatDate } from './format';

export const getAssetPosition = (data: any | undefined): string => {
  if (!data) return '';
  const assetPosition: string = data.fieldValues
    ?.filter((item: AtlasGqlAssetFieldValue) => item.fieldDefinition.name === 'Position')
    .map((item: AtlasGqlAssetFieldValueSelect) => item.selectValue || '')
    .toString();

  return assetPosition;
};

export type AssetFieldValue =
  | AtlasGqlAssetFieldValueText
  | AtlasGqlAssetFieldValueSelect
  | AtlasGqlAssetFieldValueMultiSelect
  | AtlasGqlAssetFieldValueDate
  | AtlasGqlAssetFieldValueNumber
  | AtlasGqlAssetFieldValueBool
  | AtlasGqlAssetFieldValueFloat;

export const getAssetFieldValueString = (
  asset: AtlasGqlAsset,
  fieldDefinitionName: string
): string | null | undefined => {
  const fieldValue: AssetFieldValue | null | undefined = asset.fieldValues?.find(
    (field: AtlasGqlAssetFieldValue) => field.fieldDefinition.name === fieldDefinitionName
  );

  if (!fieldValue) return null;

  switch (fieldValue.__typename) {
    case 'AssetFieldValueText':
      return fieldValue.textValue;
    case 'AssetFieldValueSelect':
      return fieldValue.selectValue;
    case 'AssetFieldValueMultiSelect':
      return fieldValue.multiSelectValue?.join(', ');
    case 'AssetFieldValueDate':
      return formatDate(fieldValue.dateValue);
    case 'AssetFieldValueNumber':
      const n = fieldValue.numberValue;
      return n?.toString() ?? null;
    case 'AssetFieldValueBool':
      const b = fieldValue.boolValue;
      return b?.toString() ?? null;
    case 'AssetFieldValueFloat':
      const f = fieldValue.floatValue;
      return f?.toString() ?? null;
    default:
      console.warn(`Unknown field type: ${fieldValue.fieldType}`);
      return undefined;
  }
};

import styled from 'styled-components';

const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0px ${({ theme }) => theme.gray};
  display: flex;
  flex-direction: column;
  margin: 0 0 0.5rem;
  padding: 1rem;
  min-width: 200px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Title = styled.div`
  font-size: 1.2rem;
`;

export default function DashboardCard({ title, actions, children, ...props }) {
  return (
    <Container data-testid="dashboard-card" {...props}>
      {title || actions ? (
        <Header>
          {title && <Title>{title}</Title>}
          {actions}
        </Header>
      ) : null}

      {children}
    </Container>
  );
}

export const AreaSelectionIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="currentColor"
    style={{ transform: 'translateY(4px)' }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11 2C11 1.44772 11.4477 1 12 1C12.5523 1 13 1.44772 13 2V11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H13V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V13H2C1.44772 13 1 12.5523 1 12C1 11.4477 1.44772 11 2 11H11V2Z" />
    <path d="M22 15H15L17 19H20L22 15Z" />
    <path d="M17 20H20V22H17V20Z" />
  </svg>
);

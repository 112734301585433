import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  margin-bottom: 0.5rem;
  .anticon {
    margin-right: 0.5rem;
    vertical-align: middle;
    line-height: 1rem;
  }
`;

interface IDamageDetailsLinkProps {
  id: string;
  type: string;
  className?: string;
}

export const DamageDetailsLink = ({ id, type, className = '' }: IDamageDetailsLinkProps) => (
  <StyledLink
    to={`/damages/${id}`}
    type={type}
    className={`${className} ${type === 'button' ? 'ant-btn' : null}`}
  >
    <EyeOutlined />
    View Details
  </StyledLink>
);

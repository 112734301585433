import moment from 'moment';

/***
 * Force a moment to be treated as UTC by parsing the timezone (make the date
 * local), and then removing the timezone offset.
 *
 * @example
 * const initialDate = moment('2023-08-10T00:00:00.000+02:00');
 * const result = forceMomentToUTC(initialDate);
 * console.log(initialDate.format('YYYY-MM-DD')); // 2023-08-09
 * console.log(result.format('YYYY-MM-DD')); // 2023-08-10
 *
 * @param date
 */
export const forceMomentToUTC = (date: moment.Moment): moment.Moment => {
  return date.parseZone().utcOffset(0, true);
};

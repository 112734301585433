import { DamageTable2 } from 'components/DamageTable2';
import { AtlasGqlAssetType } from 'types/atlas-graphql';

type DamageList2Props = {
  tableId?: string;
  title: string;
  damageIds?: string[];
  assetIds?: string[];
  assetTypes?: AtlasGqlAssetType[];
  controls?: boolean;
  critical?: boolean;
  savedViews?: boolean;
  externalLoading?: boolean;
};

// TODO JZ: consolidate this with DamageTable2?
export const DamageList2: React.FunctionComponent<DamageList2Props> = ({
  tableId = 'damage-list-table2',
  title = '',
  damageIds,
  assetIds,
  assetTypes,
  critical,
  controls,
  savedViews,
  externalLoading,
}) => (
  <DamageTable2
    id={tableId}
    data-testid={tableId}
    title={title}
    damageIds={damageIds}
    assetIds={assetIds}
    assetTypes={assetTypes}
    critical={critical}
    controls={controls}
    savedViews={savedViews}
    externalLoading={externalLoading}
  />
);

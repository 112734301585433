import { Fragment } from 'react';
import { Divider } from 'antd';
import { Tooltip } from 'components/ui';
import naturalSort from 'utils/naturalSort';
import { get, identity, truncate, compact } from 'lodash';
import { getOnFilter } from './filters';
import { StyledKey, StyledInfoCircle, TruncatedTooltip, TooltipGrid } from './helpers.style';

export const ASSET_TYPENAMES = {
  siteLocation: 'Location',
  site: 'Site',
  turbine: 'Turbine',
  blade: 'Blade',
  asset: 'GenericAsset',
};

export const sortBy =
  (...keys) =>
  (a, b) => {
    return keys.reduce((prev, key, idx) => {
      const aValue = get(a, key, null);
      const bValue = get(b, key, null);
      const sortValue = aValue || bValue ? naturalSort(aValue, bValue) : 0;

      // sortValue / (idx + 1) ensures sorting hierarchy.
      return prev + (sortValue / (idx + 1) || 0);
    }, 0);
  };

// disable filters and sorting when prioritizing
export const removeFilter = originalColumn => {
  // Remove all filter-related fields from the column and return the rest of the contents of the column
  const { filters, filterDropdown, filterProperty, ...column } = originalColumn;
  return column;
};

export const None = ({ message = 'none', ...rest }) => <em {...rest}>{message}</em>;

export const strong = key => <StyledKey>{key}</StyledKey>;

export const defaultNone =
  (renderFn = identity) =>
  (value, ...args) =>
    value ? renderFn(value, ...args) : <None />;

// approximates the logic used to render a record into a column in the `Table` component
export const renderColumn = (column, record, index) => {
  const { render, dataIndex, get: columnGet, getFromColumn } = column;

  if (dataIndex && render && columnGet && getFromColumn) {
    return render(columnGet(record, dataIndex), record, index);
  }

  if (dataIndex && render) {
    return render(get(record, dataIndex), record, index);
  }

  if (render) {
    return render(record, record, index);
  }

  if (dataIndex) {
    return get(record, dataIndex);
  }

  return <None />;
};

export const renderWorkContainerField = column => {
  const { render, defaultValue } = column;

  if (render) {
    return render(defaultValue, column);
  }

  return <None key={column.key} />;
};

export const truncateTooltipper = (text, length = 30) => {
  if (!text) return <None />;
  if (text.length <= length) {
    return text;
  }
  return (
    <div>
      <Tooltip placement="topRight" title={truncate(text, { length: 300 })}>
        <TooltipGrid>
          <TruncatedTooltip>{text}</TruncatedTooltip>
          <StyledInfoCircle />
        </TooltipGrid>
      </Tooltip>
    </div>
  );
};

export const moreTooltipper = ([item, ...otherItems], tooltipStyle = {}) => {
  const compactedItems = compact(otherItems);
  return !item ? (
    <None />
  ) : (
    <Fragment>
      {item}
      {Boolean(compactedItems.length) && (
        <Tooltip
          title={
            <Fragment>
              {compactedItems.map(item => (
                <div key={item}>{item}</div>
              ))}
            </Fragment>
          }
          overlayStyle={tooltipStyle}
        >
          <em>{` + ${otherItems.length} more`}</em>
        </Tooltip>
      )}
    </Fragment>
  );
};

export const divide = items =>
  items
    .filter(Boolean)
    .reduce((prev, curr, i) => [prev, i > 0 && <Divider key={i} type="vertical" />, curr], []);

export const isDate = value => typeof value === 'string' && value.match(/\d{4}-\d{2}-\d{2}/);

export const getAssetDisplayName = asset => {
  if (asset.__typename === ASSET_TYPENAMES.siteLocation) {
    return asset.name;
  }

  if (asset.__typename === ASSET_TYPENAMES.turbine) {
    const { name: turbineName, location } = asset;
    const { name: locationName } = location;
    return `${locationName} | ${turbineName}`;
  }

  if (asset.__typename === ASSET_TYPENAMES.blade) {
    const { label, turbine = {} } = asset;
    const { name: turbineName, location } = turbine || {};
    const { name: locationName } = location || {};

    if (turbine) {
      return `${locationName} | ${turbineName} | Blade ${label}`;
    }

    return `Blade ${label}`;
  }

  return <None />;
};

/**
 * Using the column def for a damage attribute as the starting point, modify it's methods to work
 * with a nested dataIndex (damage.attrs.Severity vs. attrs.Severity for example)
 * @param {*} damageColumn
 * @param {*} dataIndex
 */
export const getNestedDamageAttributeColumn = (damageColumn, dataIndex) => {
  if (!damageColumn) {
    return null;
  }
  // config isn't something that is on the damage column def by default, it probably needs to be added
  // in by whatever is calling this, it also may be okay to just add it into the `dynamic.js` damage data cols but holding off on that
  let { config = {} } = damageColumn;

  // For the PAPI Unplanned Tasks Table we want an exactMatch filter for Type.
  if (damageColumn.key === 'Type') {
    config = { ...config, exactMatch: true };
  }

  const type = config.type || !damageColumn.type ? {} : { type: damageColumn.type };

  return {
    ...damageColumn,
    dataIndex,
    sorter: sortBy(dataIndex),
    get: record => get(record, dataIndex),
    filterProperty: dataIndex,
    onFilter: getOnFilter({ ...config, key: dataIndex.join('.'), ...type }),
  };
};

import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useAuthContext } from 'utils/auth';
import { Spinner } from 'components/ui';

export const AuthRedirect = () => {
  const { replace } = useHistory();
  const { isAuthenticated, loginWithRedirect } = useAuthContext();
  const { search } = useLocation();

  // the `returnTo` and `state` values are set in Session before redirecting here
  const { returnTo: _returnTo, state } = queryString.parse(search);

  useEffect(() => {
    const returnTo = typeof _returnTo === 'string' ? _returnTo : undefined;

    if (isAuthenticated) {
      return replace(returnTo ?? '/', {
        state: state && typeof state === 'string' && state !== '{}' ? JSON.parse(state) : undefined,
      });
    }

    (async () => {
      await loginWithRedirect({
        appState: {
          returnTo,
          state: state ?? undefined,
        },
      });
    })();
  }, [isAuthenticated, loginWithRedirect, replace, _returnTo, state]);

  return <Spinner />;
};

import { useContext, useState, useEffect } from 'react';
import { useQuery } from 'utils/apollo';
import { usePrevious } from 'utils/hooks';
import { isEqual, merge } from 'lodash';

import { getFilterBy, getSortBy, getMultiSortBy, setUrlParams } from './paging';
import { DataTableContext } from './index';
import { TUseServerDataInput, TDataTableContext } from './types';

export function useServerData(input: TUseServerDataInput) {
  const { setTotalCount, query, transform, columns, routeProps, onDataQueryChange, rowSelection } =
    input;

  const {
    pagination,
    filteredInfo,
    sortedInfo,
    updatePagination,
    initialRouteVariables,
    multiSortDef,
  }: TDataTableContext = useContext(DataTableContext);

  const [isInitialFetch, setIsInitialFetch] = useState(true);

  const {
    location,
    history,
    // Defines the client to route the request to
    client = undefined,
    // An array of filter parameters that will always be applied to requests coming from this table
    universalFilter = [],
    universalSortBy = [],
    usePartialData = false,
    notifyOnNetworkStatusChange = false,
    variables: propRouteVariables,
    skip = false,
    context,
  } = routeProps ?? {};
  const previousRouteProps = usePrevious(routeProps);

  const routeVariables = isInitialFetch
    ? merge(initialRouteVariables, propRouteVariables)
    : propRouteVariables;

  const filterBy = getFilterBy({ filters: filteredInfo, columns });
  const sortBy = getSortBy(sortedInfo);
  const multiSortBy = getMultiSortBy(multiSortDef);

  const total: number = pagination && pagination.total ? pagination.total : 0;
  const pageSize: number = pagination && pagination.pageSize ? pagination.pageSize : 10;
  const current: number = pagination && pagination.current ? pagination.current : 1;
  const offset: number = pageSize * (current - 1);
  const cursorPagination: Boolean = context?.cursorPagination ?? false;

  const variables = {
    ...routeVariables,
    filterBy: [...universalFilter, ...filterBy],
    sortBy: [...universalSortBy, ...sortBy, ...multiSortBy],
    ...(cursorPagination
      ? { after: String(offset), first: pageSize }
      : { limit: pageSize, offset }),
  };

  const {
    data = {},
    loading,
    networkStatus,
    refetch,
  } = useQuery(query, {
    client,
    context,
    notifyOnNetworkStatusChange,
    returnPartialData: usePartialData,
    variables,
    skip,
    onCompleted: data => {
      const dataForFindCount = transform ? transform(data) : data || {};
      // conditional to support GQL Connection spec query structure
      const totalCount =
        dataForFindCount?.totalCount ?? dataForFindCount?.pageInfo?.totalCount ?? 0;
      if (setTotalCount) {
        setTotalCount(totalCount);
      }
      const order = sortedInfo?.order;
      const columnKey = sortedInfo?.columnKey;

      // url updates to correspond to the new thing we are looking at
      setUrlParams(
        {
          ...routeVariables,
          filters: filteredInfo,
          pagination: { pageSize, current },
          sortOrder: { order, columnKey },
        },
        location,
        history
      );

      // only know the total rows after we get data back so makes sense to update that state here all the time I think
      const currentIndex = pageSize * (current - 1);
      updatePagination({
        ...pagination,
        total: totalCount,
        current: currentIndex >= totalCount ? 1 : current,
      });

      // keep interested parties synced up with the current state of the filter / sort situation
      if (onDataQueryChange) {
        onDataQueryChange({ ...routeVariables, totalCount, filterBy, sortBy, isInitialFetch });
      }

      setIsInitialFetch(false);
    },
  });

  // transform data
  const transformedData = transform ? transform(data) : data || {};
  const items = transformedData.items ?? [];
  const queryTotal = transformedData.totalCount ?? transformedData.pageInfo?.totalCount ?? 0;

  // update pagination count (if total updates on a refetch)
  useEffect(() => {
    if (total !== queryTotal) {
      updatePagination({
        ...pagination,
        total: queryTotal,
      });
      setTotalCount && setTotalCount(queryTotal);
    }
  }, [pagination, total, queryTotal, updatePagination, setTotalCount]);

  // Reset pagination when universal filter changes
  // TODO: This effect runs a lot because variables is a dependency, this could be optimized but there's dragons
  // in DataTable that need to be slain first. This works for now, and should be okay as long as universalFilter stays small.
  useEffect(() => {
    // If we don't have a previous route prop or we skipped it, we can't compare the filters
    if (!previousRouteProps || previousRouteProps?.skip) {
      return;
    }

    if (
      !isEqual(routeProps?.universalFilter, previousRouteProps?.universalFilter) ||
      !isEqual(routeProps?.variables, previousRouteProps?.variables)
    ) {
      updatePagination({ current: 1 });
      refetch({
        ...variables,
        offset: 0,
      });
      rowSelection?.onChange?.([], []);
    }
  }, [
    routeProps?.universalFilter,
    previousRouteProps?.universalFilter,
    routeProps?.variables,
    previousRouteProps?.variables,
    refetch,
    variables,
    rowSelection,
    updatePagination,
    previousRouteProps?.skip,
  ]);

  return {
    client,
    data: items,
    loading,
    networkStatus,
    refetch,
    filterBy,
  };
}

import { useCallback } from 'react';

import { Button } from 'components/ui';
import { omit } from 'lodash';

import { SelectableAction, useSelectableRecords } from 'utils/selectable';
import {
  numberAndConfirmed,
  isConfirmed,
  bladeSide,
  bladeAtlas,
  turbineAtlas,
  siteAtlas,
} from 'components/damages/static';
import { AssetChooser } from '../index';
import { useManagedAssetChooser } from 'components/choosers/AssetChooser/useManagedAssetChooser';

import { TABLE_SCROLL_WIDTH_SMALL } from 'utils/constants';
import { DamageTable } from 'components/DamageTable';
import { DAMAGE_TABLE_STORAGE_KEY } from 'components/DataTable/Views/helpers/tableStorageKeys';

import damageListQuery from 'queries/DamageList.atlas.gql';
import { AtlasGqlDamage, AtlasGqlHorizonDamage } from 'types/atlas-graphql';
import { generateDamageTableFilters } from '../../../horizon/routes/Inspections/DamageList/utils';
import { TQueryFilter } from '../../DataTable/types';
import { useFeatures } from 'utils/features';
import { DamageChooser2 } from './DamageChooser2';
import { useNeitherDamagesFlagAlert } from 'horizon/components/Damages/useNeitherDamagesFlagAlert';

const columns = [numberAndConfirmed, isConfirmed, siteAtlas, turbineAtlas, bladeAtlas, bladeSide];

type IDamageChooserProps = {
  onSubmit: Function;
  tableProps?: any;
};

export const DamageChooser: React.FunctionComponent<IDamageChooserProps> = ({
  onSubmit,
  ...tableProps
}) => {
  const { HORIZON_DAMAGES, LEGACY_DAMAGES } = useFeatures().features;
  useNeitherDamagesFlagAlert();

  const { selected, rowSelection, onClearSelection } = useSelectableRecords();
  const { onChange: handleAssetChooserChange, locationIds, turbineIds } = useManagedAssetChooser();

  const universalFilters: TQueryFilter[] = generateDamageTableFilters({
    siteIds: locationIds,
    turbineIds,
  });

  function transformDamages(input: any) {
    return input.damages || [];
  }

  const handleAdd = useCallback(
    (values: any) => {
      onSubmit(
        values.map((item: AtlasGqlDamage | AtlasGqlHorizonDamage) => {
          const noKeyItem = omit(item, 'key');
          const assetId = item?.asset?.id || item.assetId;
          const damageBranchId = 'branchGlobalId' in item ? item?.branchGlobalId : '';
          const horizonDamageId = item?.__typename === 'HorizonDamage' ? item.id : '';

          if (assetId && (damageBranchId || horizonDamageId)) {
            return { ...noKeyItem, assetId, damageBranchId, horizonDamageId };
          }
          return noKeyItem;
        })
      );
    },
    [onSubmit, selected]
  );

  const addAction = (
    <SelectableAction selected={selected} onClear={onClearSelection}>
      <Button
        _version={4}
        type="primary"
        id="damage-chooser-add-damage-button"
        disabled={!selected.length}
        onClick={() => handleAdd(selected)}
      >
        Add
      </Button>
    </SelectableAction>
  );

  if (HORIZON_DAMAGES) {
    return <DamageChooser2 controls={true} handleAdd={handleAdd} />;
  } else if (LEGACY_DAMAGES) {
    return (
      <DamageTable
        schema={undefined}
        id="damage-chooser-table"
        scrollWidth={TABLE_SCROLL_WIDTH_SMALL}
        size="small"
        columns={columns}
        storageKey={DAMAGE_TABLE_STORAGE_KEY}
        showColumnPicker={true}
        dataQuery={damageListQuery}
        transform={transformDamages}
        routeProps={{
          universalFilter: universalFilters,
        }}
        rowSelection={rowSelection}
        controls={
          <AssetChooser
            width={'50%'}
            onChange={handleAssetChooserChange}
            bladeIds={[]}
            turbineIds={turbineIds}
            locationIds={locationIds}
            callerManagedState={true}
            settings={{ blade: { visible: false } }}
            small={false}
            block={false}
          />
        }
        actions={addAction}
        defaultActiveTabKey="SEVERE"
        {...tableProps}
      />
    );
  }

  return null;
};

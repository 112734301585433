import { useCallback, useEffect, useMemo } from 'react';
import { Checkbox, Modal } from 'antd';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Button, ModalActionButtons } from 'components/ui';
import { Customer, OptionTitle, Section, Title } from './OrgMismatch.style';
import COLORS from 'utils/color/definitions';
import { useSafeState } from 'utils/hooks';
import { storage } from 'utils/storage';
import { AccountDataMessageTypes, channel, reinitializeApp } from '../../../utils/account/channels';
import { Customer as CustomerType } from 'utils/types';
import { useApolloContext } from 'utils/apollo';
import { useAccountContext } from 'utils/account/AccountContext';
import { useCustomerIdMismatch } from 'utils/account/hooks';

function OrgMismatchModal() {
  const { resetStore } = useApolloContext();
  const [customerIdMismatch] = useCustomerIdMismatch();
  const { customers, setCustomerId } = useAccountContext();
  const [revertLoading, setRevertLoading] = useSafeState<boolean>(false);
  const [continueLoading, setContinueLoading] = useSafeState<boolean>(false);
  const [applyToAll, setApplyToAll] = useSafeState<boolean>(false);

  const localStorageCustomerId = storage.getItem('customerId');
  const [newCustomerId, setNewCustomerId] = useSafeState<string>(localStorageCustomerId);

  const existingCustomer = useMemo(() => {
    const customerId = storage.getItem('customerId:active');
    return customers.find(c => c.id === customerId);
  }, [customers]);

  const newCustomer = useMemo(() => {
    return customers.find(c => c.id === newCustomerId);
  }, [customers, newCustomerId]);

  const updateAllOpenInstances = useCallback(
    (customerId: string) => {
      if (applyToAll) {
        // posting a message will let the other browser windows and tabs know
        // they may need to refresh to reflect the selected customer
        channel.postMessage({
          type: AccountDataMessageTypes.ORG_MISMATCH,
          customerId: customerId,
        });
      }
    },
    [applyToAll]
  );

  const changeCustomer = useCallback(
    (customer: CustomerType | undefined) => {
      if (customer) {
        setCustomerId(customer.id);
        updateAllOpenInstances(customer.id);
      }

      setApplyToAll(false);
    },
    [setApplyToAll, setCustomerId, updateAllOpenInstances]
  );

  const revertCustomer = useCallback(() => {
    setRevertLoading(true);
    // adding a timeout allows the loading flag to be reflected in the buttons,
    // which helps prevent double-clicking and shows proper status
    setTimeout(() => {
      changeCustomer(existingCustomer);
      setRevertLoading(false);
    }, 500);
  }, [changeCustomer, existingCustomer, setRevertLoading]);

  const continueToCustomer = useCallback(async () => {
    setContinueLoading(true);
    await resetStore();
    changeCustomer(newCustomer);
    reinitializeApp();
    setContinueLoading(false);
  }, [changeCustomer, newCustomer, resetStore, setContinueLoading]);

  // when the modal has already loaded and contains the new customer, when the
  // user changes the customer again in another browser tab, this will make sure
  // the new customer is the latest selected one
  const refreshCustomer = useCallback(() => {
    setNewCustomerId(storage.getItem('customerId'));
  }, [setNewCustomerId]);

  useEffect(() => {
    window.addEventListener('focus', refreshCustomer);
    return () => {
      window.removeEventListener('focus', refreshCustomer);
    };
  }, [refreshCustomer]);

  const loading = revertLoading || continueLoading;

  return (
    <Modal
      closable={false}
      destroyOnClose={true}
      footer={
        <>
          <Section>
            <Checkbox
              checked={applyToAll}
              disabled={loading}
              onChange={e => setApplyToAll(e.target.checked)}
            >
              Apply selection to all open Horizon windows and tabs
            </Checkbox>
          </Section>

          <ModalActionButtons>
            <Button
              _version={4}
              disabled={loading}
              onClick={revertCustomer}
              loading={revertLoading}
            >
              Stay with {existingCustomer?.name}
            </Button>
            <Button
              _version={4}
              disabled={loading}
              onClick={continueToCustomer}
              loading={continueLoading}
            >
              Continue to {newCustomer?.name}
            </Button>
          </ModalActionButtons>
        </>
      }
      title={
        <>
          <ExclamationCircleTwoTone twoToneColor={COLORS.SEV_LESS_COLOR} />
          <Title>Organization Mismatch!</Title>
        </>
      }
      visible={customerIdMismatch}
    >
      <Section>
        You have switched to another organization in a separate browser window or tab. To avoid
        unintended data integration across organizations, you have two options to move forward.
      </Section>

      <Section>
        <OptionTitle>Option 1</OptionTitle>

        <div>
          Remain with the organization you were viewing:{' '}
          <Customer>{existingCustomer?.name}</Customer>
        </div>
      </Section>

      <Section>
        <OptionTitle>Option 2</OptionTitle>

        <div>
          Refresh this page to continue with new organization:{' '}
          <Customer>{newCustomer?.name}</Customer>
        </div>
      </Section>
    </Modal>
  );
}

export default OrgMismatchModal;

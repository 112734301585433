import { useState, useMemo, useEffect } from 'react';
import { useFleetMapQuery } from 'types/papi-graphql';
import { AtlasGqlTurbine, useGetFleetMapDataQuery } from 'types/atlas-graphql';
import { TTurbineProps } from './types';
import { TurbinePointMap } from './TurbinePointMap';
import { TQueryFilter } from 'components/DataTable/types';
import FleetMapDetail from 'components/FleetMapDetail';
import { TURBINE_ICON, MARKER_BLUE } from 'utils/constants';
import { TImportedTurbineMappedField } from 'horizon/components/Assets/CreateAssetsModal/types';
import { useApolloContext } from 'utils/apollo';

interface IFleetMapProps {
  parentId?: string;
  satelliteView?: boolean;
  importedTurbines?: Array<AtlasGqlTurbine>;
  previewMode?: boolean;
  setTurbineToUpdate?: (turbineToUpdate: TImportedTurbineMappedField) => void;
}

/**
 * @property parentId - optional - parent asset (site) uniqe id. Do not use
 *                                  if getting entire fleet map for a given
 *                                  customer or inserting static turbine data.
 * @satelliteView: - optional    - if true, map displays with satellite view
 * @importedTurbine: - optional  - an array of turbines to display on the map
 *                                  instead of requesting turbine data from server
 * @previewMode: - optional      - if true, apply functional and styling changes such
 *                                  as increasing map height
 * @setTurbineToUpdate - optional - if preview mode is true, also send this setter
 *                                  to enable real-time location updates
 *                                  when dragging and dropping pins
 */
// create interface that takes in string parentId
export const FleetMap: React.FunctionComponent<IFleetMapProps> = ({
  parentId,
  satelliteView,
  importedTurbines,
  previewMode = false,
  setTurbineToUpdate,
}) => {
  const { papiClient } = useApolloContext();
  const [turbines, setTurbines] = useState<Array<AtlasGqlTurbine>>(importedTurbines || []);

  useEffect(() => {
    if (importedTurbines) {
      // force update when imported turbines changes to persist changes from
      // map preview drag and drop
      setTurbines(importedTurbines);
    }
  }, [importedTurbines]);

  const contentHeight = previewMode ? '70vh' : '35rem';

  const hasParentId = !!parentId;
  const hasPosition = (x: AtlasGqlTurbine) => x.latLng && x.latLng.length === 2;
  const getTurbineProps = (turbine: AtlasGqlTurbine): TTurbineProps => {
    const [lat, lng] = turbine.latLng as number[];
    const site = turbine?.parent || turbine?.location;

    return {
      key: turbine.id,
      position: { lat, lng },
      content: <FleetMapDetail turbine={turbine} previewMode={previewMode} location={site} />,
      name: turbine.name ?? undefined,
    };
  };
  const filteredTurbines = turbines.filter(hasPosition).map(getTurbineProps);

  const filters: TQueryFilter[] = useMemo(() => {
    const filters: TQueryFilter[] = [];
    filters.push({
      key: 'assetTypeNames',
      values: ['turbine'],
    });
    if (parentId) {
      const parentIDObj = {
        key: 'childrenOfIds',
        values: [parentId],
      };
      filters.push(parentIDObj);
    }

    return filters;
  }, [parentId]);

  // TODO: AN If an org has too many turbines, this query returns too much
  // data and the application will crash. Must optimize assetsWithMetaData
  // and the Turbine resolver to prevent this.
  const { loading } = useGetFleetMapDataQuery({
    variables: {
      // @ts-ignore AN filter types don't exactly match but this works, is ok for now
      filterBy: filters,
    },
    skip: !!importedTurbines || !hasParentId,
    onCompleted: data => {
      if (data.assetsWithMetadata?.items) {
        // @ts-ignore AN turbine types don't exactly match but this works, is ok for now
        setTurbines(data.assetsWithMetadata.items);
      }
    },
  });

  const { loading: legacyLoading } = useFleetMapQuery({
    client: papiClient,
    skip: !!importedTurbines || hasParentId,
    onCompleted: data => {
      if (data?.turbines) {
        // @ts-ignore AN turbine types don't exactly match but this works, is ok for now
        setTurbines(data.turbines);
      }
    },
  });

  return (
    // @ts-ignore AN child component is not TS yet
    <TurbinePointMap
      loading={loading || legacyLoading}
      items={filteredTurbines}
      icon={previewMode ? MARKER_BLUE : TURBINE_ICON}
      height={contentHeight}
      mapHeight={contentHeight}
      sideList={previewMode ? true : false}
      satelliteView={satelliteView}
      enableClustering
      // @ts-ignore - child component is not yet TS-ified AN 10/28/22
      setTurbineToUpdate={setTurbineToUpdate}
      isDraggable={previewMode ? true : false}
    />
  );
};

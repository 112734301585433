import { createContext, useContext } from 'react';
import { AccountContextType } from './types';

export const defaultAccountContext: AccountContextType = {
  customer: null,
  customers: [],
  features: {},
  hasReleaseToggle: () => false,
  loading: false,
  setCustomerId: () => {},
  user: null as any,
  refetchUser: () => {},
};

export const AccountContext = createContext<AccountContextType>(defaultAccountContext);

export function useAccountContext() {
  return useContext(AccountContext);
}

import { useCallback } from 'react';
import { useApolloContext, useMutation } from 'utils/apollo';
import styled from 'styled-components';
import gql from 'graphql-tag';

import { usePermissions } from 'utils/usePermissions';
import { DAMAGE_EDIT_ATTRIBUTES } from 'utils/access-control/rules';
import { useDamageAttrs } from 'components/damages/withDamages';

import { DamageAttributes, MODES } from './DamageAttributes';

import ADD_USER_DAMAGE from 'components/damages/DamageEdit/AddUserDamage.gql';
import RESET_DAMAGE_ATTRS from 'horizon/queries/ResetDamageAttrs.gql';

const AttributeContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

const StyledAttributes = styled(DamageAttributes)`
  display: flex;
  width: 100%;
  transition: margin-left ease-out 0.2s;
  overflow: hidden;
  white-space: nowrap;
  ${({ currentMode, mode }) =>
    currentMode !== mode ? 'margin-left: 100%; position: absolute; z-index: -1;' : ''};
`;

export function DamageAttributesController({
  damage,
  schemaId,
  potentialAttrs,
  loading: loadingProp,
  mode,
  setMode,
  hideDetails,
}) {
  const { papiClient } = useApolloContext();
  const { loading: loadingPermissions, CanAccess } = usePermissions();

  const { id: damageId } = damage || {};

  const { damageColumns, loading: loadingAttrs } = useDamageAttrs(schemaId);

  const writeDamageFragment = useCallback(
    ({ attrs, branchSlug, version }) => {
      // Have to reach over to the other apollo client cache for now since
      // this mutation happens on atlasClient but damages live in papiClient
      papiClient.writeFragment({
        id: `Damage:${damageId}`,
        fragment: gql`
          fragment DamageAttrs on Damage {
            __typename
            number
            attrs
          }
        `,
        data: {
          attrs,
          number: `${branchSlug.toUpperCase()}-${version}`,
          __typename: 'Damage',
        },
      });
    },
    [damageId, papiClient]
  );

  const [onSave, { loading: saving }] = useMutation(ADD_USER_DAMAGE, {
    onCompleted() {
      setMode(MODES.VIEW);
    },
    update(_, { data }) {
      writeDamageFragment(data.addUserDamage);
    },
  });

  const [onReset, { loading: resetting }] = useMutation(RESET_DAMAGE_ATTRS, {
    update(_, { data }) {
      writeDamageFragment(data.resetDamageToVendor);
    },
  });

  const handleOnSave = useCallback(
    ({ attrs, schemaId }) => {
      const {
        observations: [
          {
            id: observationId,
            coordinates,
            picture: { id: pictureId },
          },
        ],
      } = damage;
      return onSave({
        variables: {
          damage: {
            branchGlobalId: damageId,
            schemaId,
            attrs,
            annotations: [
              {
                branchGlobalId: observationId,
                coordinates,
                pictureId,
              },
            ],
          },
        },
      });
    },
    [onSave, damage, damageId]
  );

  const handleOnReset = useCallback(() => {
    onReset({ variables: { branchGlobalId: damageId } });
  }, [onReset, damageId]);

  const handleOnCancel = useCallback(() => setMode(MODES.VIEW), [setMode]);

  const loading = loadingProp || loadingAttrs || loadingPermissions;

  const props = {
    currentMode: mode,
    loading,
    damage,
    schemaId,
    potentialAttrs,
    damageColumns,
    onSave: handleOnSave,
    onReset: !hideDetails ? handleOnReset : null,
    saving: saving || resetting,
    onCancel: handleOnCancel,
  };

  // both modes are rendered in this usage so we can animate between the state change
  return (
    <AttributeContainer>
      <StyledAttributes data-testid="attr-view" {...props} mode={MODES.VIEW} />
      {CanAccess({ rules: [DAMAGE_EDIT_ATTRIBUTES] }) && (
        <StyledAttributes data-testid="attr-edit" {...props} mode={MODES.EDIT} />
      )}
    </AttributeContainer>
  );
}

import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  button {
    visibility: hidden;
  }

  &:hover button {
    visibility: visible;
  }
`;

export const TextSpan = styled.span`
  margin-right: 0.5em;
`;

import { useRef } from 'react';
import { Button } from 'components/ui';
import { UnorderedListOutlined } from '@ant-design/icons';
import { orderBy } from 'lodash';
import { ColorCodingMap, NullShapeAndColor } from '../pointUtils';
import { LegendPopover } from './ColorCodingLegend.style';
import { ColorCodingAttribute } from '../BladeDamagePlotContainer';
import { useIsInView } from 'utils/hooks';

type ColorCodingLegendProps = {
  colorCodingAttribute: ColorCodingAttribute;
  colorCodingMap: ColorCodingMap;
};

export const ColorCodingLegend: React.FunctionComponent<ColorCodingLegendProps> = ({
  colorCodingAttribute,
  colorCodingMap,
}) => {
  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const isScrolledToTop: boolean = useIsInView(topRef);
  const isScrolledToBottom: boolean = useIsInView(bottomRef);

  return (
    <LegendPopover
      placement="topRight"
      getPopupContainer={triggerNode => triggerNode}
      $isScrolledToTop={isScrolledToTop}
      $isScrolledToBottom={isScrolledToBottom}
      content={
        <div>
          <div ref={topRef} />
          <ul>
            {colorCodingMap &&
              orderBy(
                Object.entries(colorCodingMap),
                [0],
                [colorCodingAttribute === ColorCodingAttribute.Type ? 'asc' : 'desc']
              ).map(([value, { ShapeComponent, color }], i) => (
                <li key={i}>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    transform="scale(2)"
                  >
                    <ShapeComponent x={5} y={5} color={color} />
                  </svg>
                  <span>
                    {[ColorCodingAttribute.Severity, ColorCodingAttribute.OpSeverity].includes(
                      colorCodingAttribute
                    )
                      ? colorCodingAttribute
                      : ''}{' '}
                    {value}
                  </span>
                </li>
              ))}
            <li>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                transform="scale(2)"
              >
                <NullShapeAndColor.ShapeComponent x={5} y={5} color={NullShapeAndColor.color} />
              </svg>
              <span>No {colorCodingAttribute}</span>
            </li>
          </ul>
          <div ref={bottomRef} />
        </div>
      }
      arrow={false}
    >
      <Button icon={<UnorderedListOutlined />} />
    </LegendPopover>
  );
};

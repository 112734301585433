import { useEffect, useState } from 'react';

import { Select } from 'antd';

import { Container } from './types';
import {
  AtlasGqlFilterBy,
  AtlasGqlFilterOperator,
  useGetWorkCampaignsChooserLazyQuery,
  useGetWorkOrdersChooserLazyQuery,
} from 'types/atlas-graphql';
import { TASK_STATUSES, TASK_STATUS_LABELS, WORK_CAMPAIGN, WORK_ORDER } from 'utils/constants';

const STATUSES = [
  { status: TASK_STATUSES.PENDING, label: TASK_STATUS_LABELS.PENDING },
  { status: TASK_STATUSES.IN_PROGRESS, label: TASK_STATUS_LABELS.IN_PROGRESS },
  { status: TASK_STATUSES.COMPLETE, label: TASK_STATUS_LABELS.COMPLETE },
];

const getOptions = ({ status, label, containerList = [], containerId }: any) => {
  const options = containerList.filter((container: Container) => container.status === status);
  if (!options) return;
  return (
    <Select.OptGroup key={label} label={label}>
      {options.map((container: Container) => (
        <Select.Option
          key={container.id}
          value={container.id}
          disabled={container.id === containerId}
        >
          {container.number} {container.name && ` - ${container.name}`}
        </Select.Option>
      ))}
    </Select.OptGroup>
  );
};

interface IWorkContainerChooserProps {
  containerId: any;
  type: typeof WORK_CAMPAIGN | typeof WORK_ORDER;
  allowClear?: boolean;
  id?: any;
  labelInValue?: boolean;
  onChange?: any;
  disabled?: boolean;
  // Keep below to continue working with our js files
  props?: any;
}

export const WorkContainerChooser: React.FunctionComponent<IWorkContainerChooserProps> = ({
  containerId,
  type,
  allowClear,
  id,
  labelInValue,
  onChange,
  disabled,
  props,
}) => {
  const placeholder = type === WORK_CAMPAIGN ? 'Select Campaign' : 'Select Work Order';

  const [loading, setLoading] = useState<true | false>();
  const [containerList, setContainerList] = useState<any[] | null>([]);

  const [getWorkCampaigns, { loading: workCampaignsLoading, data: workCampaignsData }] =
    useGetWorkCampaignsChooserLazyQuery({ fetchPolicy: 'no-cache' });
  const [getWorkOrders, { loading: workOrdersLoading, data: workOrdersData }] =
    useGetWorkOrdersChooserLazyQuery({ fetchPolicy: 'no-cache' });

  useEffect(() => {
    // Users should not be able to modify archived work containers in any way
    // So query for only non-archived work containers to populate the dropdown
    const filters: AtlasGqlFilterBy[] = [
      {
        key: 'archived',
        values: [],
        operator: AtlasGqlFilterOperator.IsNull,
      },
    ];
    if (type === WORK_CAMPAIGN) {
      getWorkCampaigns({ variables: { filterBy: filters } });
    } else {
      getWorkOrders({ variables: { filterBy: filters } });
    }
  }, []);

  useEffect(() => {
    if (workCampaignsData && !workCampaignsLoading) {
      const listData = workCampaignsData?.paginatedWorkCampaigns?.items ?? [];
      setContainerList(listData);
      setLoading(workCampaignsLoading);
    }
    // Even though workCampaignsData is technically a dependency, we only really want this to re-run when loading changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workCampaignsLoading]);

  useEffect(() => {
    if (workOrdersData && !workOrdersLoading) {
      const listData = workOrdersData?.paginatedWorkOrders?.items ?? [];
      setContainerList(listData);
      setLoading(workOrdersLoading);
    }
    // Even though workOrdersData is technically a dependency, we only really want this to re-run when loading changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrdersLoading]);

  return (
    <Select
      allowClear={allowClear}
      id={id}
      loading={loading}
      optionFilterProp="children"
      onChange={onChange}
      placeholder={placeholder}
      showSearch
      style={{ minWidth: '15rem' }}
      dropdownMatchSelectWidth={false}
      disabled={disabled}
    >
      {!loading && (
        <>
          {/* @ts-ignore null on this option will allow the user to deselect a campaign */}
          <Select.Option key="no-work-container" value={null} disabled={containerId === null}>
            None
          </Select.Option>
          {STATUSES.map(status => getOptions({ ...status, containerList, containerId }))}
        </>
      )}
    </Select>
  );
};

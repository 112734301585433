import { TDataTableStorageKey } from 'components/DataTable/types';

export const DAMAGE_TABLE_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'DAMAGE_TABLE_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'DAMAGE_TABLE_MULTI_SORT_STORAGE',
};

export const HORIZON_DAMAGE_TABLE_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'HORIZON_DAMAGE_TABLE_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'HORIZON_DAMAGE_TABLE_MULTI_SORT_STORAGE',
};

export const TASK_TABLE_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'TASK_TABLE_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'TASK_TABLE_MULTI_SORT_STORAGE',
};

export const RECURRING_TASK_TABLE_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'RECURRING_TASK_TABLE_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'RECURRING_TASK_TABLE_MULTI_SORT_STORAGE',
};

export const WORK_ORDER_TASK_TABLE_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'WORK_ORDER_TASK_TABLE_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'WORK_ORDER_TASK_TABLE_MULTI_SORT_STORAGE',
};

export const INSPECTION_TABLE_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'INSPECTION_TABLE_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'INSPECTION_TABLE_MULTI_SORT_STORAGE',
};

export const UNPLANNED_TASK_TABLE_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'UNPLANNED_TASK_TABLE_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'UNPLANNED_TASK_TABLE_MULTI_SORT_STORAGE',
};

export const WORK_ORDERS_TABLE_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'WORK_ORDERS_TABLE_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'WORK_ORDERS_TABLE_MULTI_SORT_STORAGE',
};

export const BLADES_TABLE_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'BLADES_TABLE_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'BLADES_TABLE_MULTI_SORT_STORAGE',
};

export const TURBINES_TABLE_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'TURBINES_TABLE_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'TURBINES_TABLE_MULTI_SORT_STORAGE',
};

export const UPLOADED_IMAGES_TABLE_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'UPLOADED_IMAGES_TABLE_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'UPLOADED_IMAGES_TABLE_MULTI_SORT_STORAGE',
};

export const WORK_CAMPAIGNS_TABLE_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'WORK_CAMPAIGNS_TABLE_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'WORK_CAMPAIGNS_TABLE_MULTI_SORT_STORAGE',
};

export const SITES_TABLE_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'SITES_TABLE_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'SITES_TABLE_MULTI_SORT_STORAGE',
};

export const INACTIVE_BLADES_TABLE_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'INACTIVE_BLADES_TABLE_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'INACTIVE_BLADES_TABLE_MULTI_SORT_STORAGE',
};

export const ASSETS_TABLE_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'ASSETS_TABLE_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'ASSETS_TABLE_MULTI_SORT_STORAGE',
};

export const ASSET_DETAILS_TASK_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'ASSET_DETAILS_TASK_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'ASSET_DETAILS_TASK_MULTI_SORT_STORAGE',
};

export const ASSET_ATTRIBUTES_STORAGE_KEY: TDataTableStorageKey = {
  COLUMN_PICKER: 'ASSET_ATTRIBUTES_COLUMN_PICKER_STORAGE',
  MULTI_SORT: 'ASSET_ATTRIBUTES_MULTI_SORT_STORAGE',
};

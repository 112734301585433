import { useEffect, useRef } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

const ONE_HOUR = 60 * 60 * 1000;

/**
 * This hook will register a service worker, which is used to update the app
 * whenever code changes have been released.
 *
 * Note that this hook has no impact when env var `ENABLE_SERVICE_WORKERS` is
 * not true. This is configured in the `vite.config.mts` settings for `VitePWA`.
 *
 * @see https://vite-pwa-org.netlify.app/frameworks/react.html#react
 */
export function useRegisterServiceWorker(setRefresh: (refresh: boolean) => void) {
  const timeout = useRef<NodeJS.Timeout>();

  function onNeedRefresh() {
    console.info('🚀 New content is available! Please refresh.');
    setRefresh(true);
  }

  // we don't actually want to call `updateServiceWorker` from this hook response
  // since we don't want to immediately refresh the window. instead, we want to
  // tell the parent "There is a new version, so refresh when you can."
  useRegisterSW({
    onNeedRefresh,
    onRegisteredSW(_swUrl, registration) {
      console.debug('Service worker has been registered');

      if (registration) {
        // attempt to update the registration periodically
        timeout.current = setInterval(() => {
          // when the registration is unregistered (e.g. manually), stop checking
          // to see if we need to update it
          if (!registration?.active) {
            console.debug('Service worker was unregistered. Refresh to re-register.');
            clearTimeout(timeout.current);
            return;
          }

          console.debug('Checking service worker update');
          // it's possible the service worker could be unregistered (e.g. manually),
          // so to avoid errors, assume it could be null
          // @see https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/update
          registration?.update();
        }, ONE_HOUR);

        // check to see if a SW update has been found
        // @see https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/updatefound_event
        registration.addEventListener('updatefound', () => {
          const sw = registration.installing;
          if (!sw) return;

          console.debug('Service worker update found');
          sw.addEventListener('statechange', () => {
            if (sw.state === 'installed' && navigator.serviceWorker.controller) {
              // at this point, the old content will have been purged and the
              // fresh contnet will have been added to the cache
              onNeedRefresh();
            }
          });
        });
      }
    },
    onRegisterError(error) {
      console.warn('Service worker failed to register:', error);
    },
  });

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [timeout]);
}

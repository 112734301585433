import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useAuthContext } from 'utils/auth';
import { Button, Spinner } from 'components/ui';

import { AtlasGqlAcknowledgement, useUpdateAcknowledgementMutation } from 'types/atlas-graphql';

type Props = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  acknowledgements: AtlasGqlAcknowledgement[];
};

export const AcknowledgementModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  acknowledgements,
}: Props) => {
  const { logout } = useAuthContext();
  const [currentAcknowledgement, setCurrentAcknowledgment] =
    useState<AtlasGqlAcknowledgement | null>(null);
  const [index, setIndex] = useState(0);

  const [updateAcknowledgement, { loading }] = useUpdateAcknowledgementMutation();

  const numOfAcknowledgements = acknowledgements.length;

  useEffect(() => {
    if (numOfAcknowledgements) {
      setCurrentAcknowledgment(acknowledgements[index]);
    }
  }, [numOfAcknowledgements, acknowledgements, index]);

  const handleAccept = async () => {
    if (!currentAcknowledgement?.id) {
      return;
    }

    await updateAcknowledgement({
      variables: {
        input: {
          id: currentAcknowledgement.id,
          accept: true,
        },
      },
    });
    if (index < numOfAcknowledgements - 1) {
      setIndex(index + 1);
    } else {
      setCurrentAcknowledgment(null);
      setShowModal(false);
    }
  };

  return (
    <Modal
      closable={false}
      keyboard={false}
      centered={true}
      visible={showModal}
      destroyOnClose={true}
      mask={true}
      maskClosable={false}
      footer={
        <>
          <Button _version={4} type={'primary'} onClick={() => handleAccept()} disabled={loading}>
            Accept
          </Button>
          <Button _version={4} onClick={() => logout({ broadcast: true })} disabled={loading}>
            Decline
          </Button>
        </>
      }
      title={loading ? '' : currentAcknowledgement?.title}
    >
      {loading ? (
        <Spinner />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: currentAcknowledgement?.text ?? '' }} />
      )}
    </Modal>
  );
};

import { useMemo } from 'react';
import * as d3 from 'd3';
import { BladeSide } from './BladeDamagePlotContainer';

type AxesProps = {
  xScale: d3.ScaleLinear<number, number, never>;
  yScale: d3.ScaleLinear<number, number, never>;
  bladeSide: BladeSide;
  maxWidth: number;
  height: number;
};

export const Axes: React.FunctionComponent<AxesProps> = ({
  xScale,
  yScale,
  bladeSide,
  maxWidth,
  height,
}) => {
  const xRange = useMemo(() => xScale.range(), [xScale]);
  const yRange = useMemo(() => yScale.range(), [yScale]);

  // Create x-axis labels dependent on the scale
  const xTicks = xScale.ticks(5).map(value => ({
    value,
    xOffset: xScale(value),
  }));

  // Create y-axis labels only on PS/SS and only at the ends of the range
  const yTicks = [BladeSide.PressureSide, BladeSide.SuctionSide].includes(bladeSide)
    ? yScale.ticks(1).map(value => ({
        value,
        yOffset: yScale(value),
      }))
    : [];

  return (
    <g fill="#0000008C">
      <path
        d={[
          'M',
          xRange[0],
          yRange[bladeSide === BladeSide.SuctionSide ? 1 : 0],
          'H',
          xRange[1],
        ].join(' ')}
        stroke="#0000008C"
        style={{ transform: 'translateY(2px)' }}
      />
      {xTicks.map(({ value, xOffset }) => (
        <g
          key={value}
          transform={`translate(${xOffset}, ${yRange[bladeSide === BladeSide.SuctionSide ? 1 : 0] + 12})`}
        >
          <line y2={`-${height}`} stroke="#0000001A" style={{ transform: 'translateY(-10px)' }} />
          <text
            key={value}
            style={{
              fontSize: '10px',
              textAnchor: 'middle',
            }}
          >
            {value}m
          </text>
        </g>
      ))}
      <path
        d={['M', xRange[0], yRange[1], 'V', yRange[0]].join(' ')}
        stroke="#0000008C"
        style={{ transform: 'translateX(-2px)' }}
      />
      {yTicks.map(({ value, yOffset }) => (
        <g key={value} transform={`translate(8, ${yOffset})  rotate(90)`}>
          <text
            key={value}
            style={{
              fontSize: '10px',
              textAnchor:
                bladeSide === BladeSide.SuctionSide
                  ? value === 100
                    ? 'end'
                    : 'start'
                  : value === 100
                    ? 'start'
                    : 'end',
            }}
          >
            {value === 100 ? maxWidth : value}m
          </text>
        </g>
      ))}
    </g>
  );
};

import OpenSeadragon from 'openseadragon';

export interface OSDViewerWithSVG extends OpenSeadragon.Viewer {
  svgOverlay: () => {
    node: () => SVGElement;
  };
}

/** OpenSeadragon SVG Overlay plugin 0.0.5
 *  Taken, and minimally modified from:
 * https://github.com/openseadragon/svg-overlay/blob/452e778684d06fb8cc806646b3d2ddc509b29dae/openseadragon-svg-overlay.js
 *
 */
const svgNS = 'http://www.w3.org/2000/svg';

// @ts-ignore
OpenSeadragon.Viewer.prototype.svgOverlay = function () {
  if (this._svgOverlayInfo) {
    return this._svgOverlayInfo;
  }

  // @ts-ignore
  this._svgOverlayInfo = new Overlay(this);
  return this._svgOverlayInfo;
};

const Overlay = function (viewer: OpenSeadragon.Viewer) {
  const self = this;

  this._viewer = viewer;
  this._containerWidth = 0;
  this._containerHeight = 0;

  this._svg = document.createElementNS(svgNS, 'svg');
  this._svg.style.position = 'absolute';
  this._svg.style.left = 0;
  this._svg.style.top = 0;
  this._svg.style.width = '100%';
  this._svg.style.height = '100%';
  this._viewer.canvas.appendChild(this._svg);

  this._node = document.createElementNS(svgNS, 'g');
  this._svg.appendChild(this._node);

  this._viewer.addHandler('animation', function () {
    self.resize();
  });

  this._viewer.addHandler('open', function () {
    self.resize();
  });

  this._viewer.addHandler('rotate', function () {
    self.resize();
  });

  this._viewer.addHandler('resize', function () {
    self.resize();
  });

  this.resize();
};

Overlay.prototype = {
  node: function () {
    return this._node;
  },
  resize: function () {
    if (this._containerWidth !== this._viewer.container.clientWidth) {
      this._containerWidth = this._viewer.container.clientWidth;
      this._svg.setAttribute('width', this._containerWidth);
    }

    if (this._containerHeight !== this._viewer.container.clientHeight) {
      this._containerHeight = this._viewer.container.clientHeight;
      this._svg.setAttribute('height', this._containerHeight);
    }

    const p = this._viewer.viewport.pixelFromPoint(new OpenSeadragon.Point(0, 0), true);
    const zoom = this._viewer.viewport.getZoom(true);
    const rotation = this._viewer.viewport.getRotation();
    // TODO: Expose an accessor for _containerInnerSize in the OSD API so we don't have to use the private variable.
    const scale = this._viewer.viewport._containerInnerSize.x * zoom;
    this._node.setAttribute(
      'transform',
      'translate(' + p.x + ',' + p.y + ') scale(' + scale + ') rotate(' + rotation + ')'
    );
  },
  onClick: function (
    node: HTMLElement,
    handler: OpenSeadragon.EventHandler<OpenSeadragon.MouseTrackerEvent>
  ) {
    // TODO: Fast click for mobile browsers

    new OpenSeadragon.MouseTracker({
      element: node,
      clickHandler: handler,
    }).setTracking(true);
  },
};

export default OpenSeadragon;

const MAX_ZOOM_PIXEL_RATIO = 5;
const SCROLL_ZOOM_FACTOR = 1.25;
export const CLICK_ZOOM_FACTOR = 1.25;

interface DziSchemaImage {
  Overlap: string | number;
  Size: {
    Height: string | number;
    Width: string | number;
  };
  TileSize: string | number;
  Url: string;
}

interface GetOpenSeadragonViewerArgs extends OpenSeadragon.Options {
  doAjaxLoad?: boolean;
  dziImage: DziSchemaImage | null | undefined;
}

export const getTileSources = (dziImage: DziSchemaImage | null | undefined) => {
  const tileSources: OpenSeadragon.TileSourceOptions = {
    getTileUrl: (level, x, y) => {
      // since these URLs are signed (using query params), we can't just
      // append the level/x/y values - we need to inject at the appropriate
      // position in the URL
      return dziImage?.Url.replace('jpg_files/', `jpg_files/${level}/${x}_${y}.jpeg`) ?? '';
    },
    height: Number(dziImage?.Size.Height),
    tileOverlap: Number(dziImage?.Overlap),
    tileSize: Number(dziImage?.TileSize),
    width: Number(dziImage?.Size.Width),
  };

  return tileSources;
};

export function getOpenSeadragonViewer({
  doAjaxLoad,
  dziImage,
  ...osdOptions
}: GetOpenSeadragonViewerArgs) {
  return OpenSeadragon({
    animationTime: 0.2,
    crossOriginPolicy: 'Anonymous',
    gestureSettingsMouse: {
      clickToZoom: false,
      dblClickToZoom: true,
    },
    loadTilesWithAjax: doAjaxLoad,
    maxZoomPixelRatio: MAX_ZOOM_PIXEL_RATIO,
    preserveImageSizeOnResize: true,
    showNavigationControl: false,
    tileSources: getTileSources(dziImage),
    visibilityRatio: 0.5,
    zoomPerClick: CLICK_ZOOM_FACTOR,
    zoomPerScroll: SCROLL_ZOOM_FACTOR,
    ...osdOptions,
  }) as OSDViewerWithSVG;
}

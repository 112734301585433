import { Link, LinkProps, Route, RouteProps } from 'react-router-dom';
import { LocationDescriptorObject, Pathname, Search } from 'history';
import { useFeatures } from 'utils/features';
import { Spinner } from 'components/ui';

export * from 'react-router-dom';

interface ModalLinkProps extends LinkProps {}

export function ModalLink({ to, ...props }: ModalLinkProps) {
  let pathname: Pathname | undefined;
  let search: Search | undefined;
  let state: any;
  let rest: Partial<LocationDescriptorObject> | undefined;

  if (typeof to === 'object') {
    pathname = to.pathname;
    search = to.search;
    state = to.state;
    rest = to;
  } else if (typeof to === 'string') {
    [pathname, search] = to.split('?');
  }

  return (
    <Link
      {...props}
      to={{
        ...rest,
        pathname,
        search,
        state: {
          useModal: true,
          ...state,
        },
      }}
    />
  );
}

export interface FeatureFlagRouteProps extends RouteProps {
  feature: string;
  onNoAccess?: (routeProps: RouteProps) => unknown;
}

export function FeatureFlagRoute({
  component,
  feature,
  onNoAccess,
  ...routeProps
}: FeatureFlagRouteProps) {
  const { features, loading } = useFeatures();

  if (!loading && !features[feature]) {
    if (onNoAccess) {
      onNoAccess(routeProps);
    }
    return null;
  }

  return <Route component={loading ? Spinner : component} {...routeProps} />;
}

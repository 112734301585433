import styled from 'styled-components';
import { Select } from 'antd';

export const CollaboratorSelect = styled(Select)`
  min-width: 10rem;
`;

export const AddCollabContainer = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.backgroundColor};
  }
`;

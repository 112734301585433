import { useRef } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Radio, Input, InputRef } from 'antd5';
import { Button } from 'components/ui';
import {
  CustomFilterPopover,
  CustomFilterContainer,
  SecondaryFilterLabel,
} from 'components/data/helpers/filters.style';
import { AtlasGqlConfirmationStatus } from 'types/atlas-graphql';
import { CustomFilterDropdownProps } from 'horizon/types/TableColumnDef';
import { AutoFocus } from 'horizon/components/AutoFocus';

const noneValue = ' ';
const confirmedValue = [AtlasGqlConfirmationStatus.Confirmed];
const unconfirmedValue = [
  AtlasGqlConfirmationStatus.ConfirmedUpdated,
  AtlasGqlConfirmationStatus.NotConfirmed,
];

export const DamageIdAndStatusFilter: React.FunctionComponent<CustomFilterDropdownProps> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  const inputRef = useRef<InputRef>(null);

  const [damageIdValue] = (selectedKeys.find(({ key }) => key === 'id')?.value ?? ['']) as string;
  const confirmationStatusValue = selectedKeys.find(
    ({ key }) => key === 'confirmationStatus'
  )?.value;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: string = [e.target.value].find(Boolean) ?? '';
    setSelectedKeys([
      ...selectedKeys.filter(({ key }) => key !== 'id'),
      { key: 'id', value: [newValue] },
    ]);
  };

  const handleSubmit = () => confirm({ closeDropdown: true });

  return (
    <AutoFocus elementToFocus={inputRef}>
      <CustomFilterPopover className="ant-table-filter-dropdown">
        <CustomFilterContainer>
          <Input.Search
            ref={inputRef}
            onSearch={handleSubmit}
            onChange={handleSearchChange}
            placeholder="Search"
            value={damageIdValue}
          />
        </CustomFilterContainer>
        <CustomFilterContainer>
          <SecondaryFilterLabel>Confirmation Status</SecondaryFilterLabel>
          <Radio.Group
            options={[
              { label: 'Confirmed', value: true },
              { label: 'Unconfirmed', value: false },
              { label: 'All', value: noneValue },
            ]}
            onChange={({ target }: CheckboxChangeEvent) => {
              if (target.value === true) {
                setSelectedKeys([
                  ...selectedKeys.filter(({ key }) => key !== 'confirmationStatus'),
                  { key: 'confirmationStatus', value: confirmedValue },
                ]);
              } else if (target.value === false) {
                setSelectedKeys([
                  ...selectedKeys.filter(({ key }) => key !== 'confirmationStatus'),
                  { key: 'confirmationStatus', value: unconfirmedValue },
                ]);
              } else {
                setSelectedKeys([
                  ...selectedKeys.filter(({ key }) => key !== 'confirmationStatus'),
                ]);
              }
            }}
            value={
              confirmationStatusValue === confirmedValue
                ? true
                : confirmationStatusValue === unconfirmedValue
                  ? false
                  : noneValue
            }
            optionType="button"
          />
        </CustomFilterContainer>

        <div className="ant-table-filter-dropdown-btns" style={{ borderTop: '1px solid #f0f0f0' }}>
          <Button type="link" size="small" onClick={clearFilters}>
            Reset
          </Button>
          <Button type="primary" size="small" onClick={handleSubmit}>
            OK
          </Button>
        </div>
      </CustomFilterPopover>
    </AutoFocus>
  );
};

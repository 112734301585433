import styled from 'styled-components';

export const ModalActionButtons = styled.div`
  align-items: center;
  display: flex;
  margin-top: 1rem;

  .ant-btn:first-child {
    margin-left: auto;
    margin-right: 0.5rem;
  }
`;

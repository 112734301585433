import styled from 'styled-components';
import { SyncOutlined } from '@ant-design/icons';
import { Table } from 'antd5';
import COLORS from 'utils/color/definitions';

export const RepeatingTaskIcon = styled(SyncOutlined)`
  font-size: 1.15em;
  color: black;
`;

export const CenteredSpan = styled.span`
  display: flex;
  justify-content: center;
`;

export const StyledAttachmentsPopoverTable = styled(Table)`
  .ant-table-row {
    background-color: ${COLORS.WHITE};
  }
`;

import { CloseOutlined, CloseCircleFilled } from '@ant-design/icons';
import { message } from 'antd';
import styled from 'styled-components';

const StyledMessageDiv = styled.div`
  display: flex;
`;

const StyledCloseCircleFilled = styled(CloseCircleFilled)`
  top: 3px;
`;

const StyledCloseOutlined = styled(CloseOutlined)`
  top: 3px;
  margin-left: 8px;
  color: #00000e6;
`;

const StyledMessageContent = styled.span`
  text-align: left;
`;

interface IMultiLineErrorMessage {
  messageContent: string;
}

// Styles an error message for use with AntD's error messages
export const MultiLineErrorMessage = ({ messageContent = '' }: IMultiLineErrorMessage) => {
  return (
    <StyledMessageDiv>
      <StyledCloseCircleFilled />
      <StyledMessageContent>{messageContent}</StyledMessageContent>

      <StyledCloseOutlined
        onClick={() => {
          message.destroy();
        }}
      />
    </StyledMessageDiv>
  );
};

import { partition } from 'lodash';

import { Tooltip } from 'components/ui';
import { AtlasGqlTask, AtlasGqlWorkContainerTypeOption } from 'types/atlas-graphql';
import { TDropdownAction } from 'components/DataTable/types';

type UpdateToPrimaryProps = {
  tasks: AtlasGqlTask[];
  disabled?: boolean;
  onClick?: () => void;
};

export const UpdateToPrimary = ({
  tasks,
  disabled,
  onClick,
}: UpdateToPrimaryProps): TDropdownAction => {
  const [damageTasks, nonDamageTasks] = partition(tasks, task =>
    [
      AtlasGqlWorkContainerTypeOption.InspectTask,
      AtlasGqlWorkContainerTypeOption.RepairTask,
    ].includes(task.type.type)
  );

  return {
    content: (
      <>
        <Tooltip
          title={
            nonDamageTasks.length > 0
              ? 'Only Damage Tasks (Inspect and Repair) can be selected.'
              : damageTasks.length === 1
                ? "The selected Task may not be showing attributes from the Damage's Primary Observation Group. " +
                  'Click to update Damage attributes shown in this Task.'
                : damageTasks.length > 1
                  ? `The selected ${damageTasks.length} Tasks may not be showing attributes from the Damage's Primary Observation Group. ` +
                    `Click to update Damage attributes shown in these ${damageTasks.length} Tasks.`
                  : 'No Tasks selected.'
          }
        >
          Update to Primary
        </Tooltip>
      </>
    ),
    disabled: disabled || tasks.length === 0 || nonDamageTasks.length > 0,
    onClick,
  };
};

import styled from 'styled-components';

export const ControlsContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  &:first-child {
    margin-top: 8px;
  }
`;

export const ThumbnailControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ThumbnailButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  &:nth-of-type(2) {
    margin-left: 8px;
  }
`;

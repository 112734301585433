import { Select } from 'antd';
import {
  AtlasGqlGetTrustedOrganizationsQuery,
  useGetTrustedOrganizationsQuery,
} from 'types/atlas-graphql';
import { useFeatures } from 'utils/features';
import { sortBy } from 'components/data/helpers';

const getOptions = (data: AtlasGqlGetTrustedOrganizationsQuery | undefined): any => {
  if (!data?.trustedOrganizations?.length) return;

  const sortedOrgs = [...data.trustedOrganizations].sort(sortBy('name')) ?? [];

  return sortedOrgs.map(option => {
    return option && option?.id && option?.name ? (
      <Select.Option value={option?.id} key={option?.id}>
        {option?.name}
      </Select.Option>
    ) : null;
  });
};

interface ITrustedOrganizationChooserProps {
  onChange?: any;
}

export const placeholderString = 'Select Organization for Inspection Delivery...';

export const TrustedOrganizationChooser: React.FunctionComponent<
  ITrustedOrganizationChooserProps
> = ({ onChange }) => {
  const { INSPECTION_DELIVERY } = useFeatures().features;

  const { data, loading } = useGetTrustedOrganizationsQuery({
    skip: !INSPECTION_DELIVERY,
  });

  return (
    <Select
      id="trusted-organization-chooser"
      placeholder={placeholderString}
      allowClear
      showSearch
      optionFilterProp="children"
      onChange={onChange}
    >
      {!loading && (
        <>
          {/* @ts-ignore null on this option will allow the user to deselect a campaign */}
          <Select.Option key="no-work-container" value={null}>
            None
          </Select.Option>
          {getOptions(data)}
        </>
      )}
    </Select>
  );
};

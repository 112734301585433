import styled from 'styled-components';
import { Button } from 'components/ui';
import COLORS from 'utils/color/definitions';

export const StyledBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem;
  background: lightyellow;
  border-bottom: 1px solid #eee;
  animation: slide-down 0.4s ease-out;

  .ant-divider {
    height: 1.25em;
    border-width: 2px;
  }

  @keyframes slide-down {
    0% {
      opacity: 0;
      margin-top: -40px;
    }

    100% {
      opacity: 1;
      margin-top: 0;
    }
  }
`;

export const StyledTitle = styled.span`
  margin-right: 0.5rem;
`;

export const ItalicText = styled.span`
  font-style: italic;
`;

export const StyledButton = styled(Button)`
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
  border: none;
  box-shadow: none;
  background: transparent;

  &:hover,
  &:focus {
    background-color: inherit;
  }

  .anticon {
    display: flex;
    color: ${COLORS.TEAL};
  }
`;

export const StyledLink = styled(Button)`
  padding: 0;
`;

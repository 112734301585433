import styled from 'styled-components';
import { Comments } from 'components/comments';

export const Item = styled.div<{ width?: string; flex?: string }>`
  padding-right: 12px;
  flex: ${({ flex }) => flex ?? 'unset'};
  width: ${({ width }) => width ?? 'unset'};

  &.image-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  [class*='Controls'] {
    padding: 2px !important;
  }
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.labelColor};
  font-weight: 700;
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.descriptionColor};

  &:nth-of-type(2) {
    margin-left: 8px;
  }
`;

export const DamageComments = styled(Comments)<{ height?: string }>`
  [data-testid='comments'] {
    overflow-y: auto;
    max-height: ${({ height }) => height ?? '10rem'};
  }

  .ant-comment-inner {
    padding-bottom: 0;
  }

  .ant-comment-content-detail {
    .ant-form-item:nth-child(2) {
      margin-bottom: 0;
    }
  }
`;

import { None } from 'components/data/helpers';

const renderName = (record: any) => {
  const { name } = record || {};
  return name ? name : <None />;
};

export const removeAssetLink = (column: any) => {
  switch (column.key) {
    case 'site':
      return {
        ...column,
        render: (record: any) => renderName(record),
      };
    case 'name':
      return {
        ...column,
        render: (record: any) => renderName(record),
      };
    case 'parent.name':
      return {
        ...column,
        render: (record: any) => renderName(record),
      };
    case 'location.name':
      return {
        ...column,
        render: (record: any) => renderName(record),
      };
    case 'serial':
      return {
        ...column,
        render: (serial: any, blade: any) => {
          if (!blade) return <None />;
          const { label } = blade;
          return serial ? serial : <i>Blade {label} (No Serial)</i>;
        },
      };
  }
};

import { startCase } from 'lodash';
import { TMessageContent } from './types';
import {
  AtlasGqlTaskTargetInput,
  AtlasGqlTaskTargetType,
  GetDamageDocument,
  GetHorizonDamageTasksDocument,
} from 'types/atlas-graphql';

export const messageContent = (input: TMessageContent) => {
  const { type, number, id } = input;
  return (
    <a id={'task-create-success-message'} href={`/tasks/${id}`}>
      {startCase(type)} {number} created. Click to view.
    </a>
  );
};

/**
 * After task creation from a damage, the damage detail query needs to be refetched
 * as the cache will not automatically be updated. When created, damage-related tasks
 * are associated with either a horizonDamageId or a (legacy) damageBranchId, but not both.
 *
 * Given props for the <CreateTask /> component, this helper returns the queries that should
 * be refetched after task creation, to be used for the `refetchQueries` option of `useMutation`.
 */
export const getRefetchQueriesForTaskCreation = ({
  horizonDamageId,
  damageBranchId,
}: {
  horizonDamageId?: string;
  damageBranchId?: string;
}) => {
  if (horizonDamageId) {
    return [
      {
        query: GetHorizonDamageTasksDocument,
        variables: { input: { id: horizonDamageId } },
      },
    ];
  }

  if (damageBranchId) {
    return [
      {
        query: GetDamageDocument,
        variables: { id: damageBranchId },
      },
    ];
  }

  return [];
};

export const getTaskTargetInput = ({
  inspectionId,
  horizonDamageId,
}: {
  inspectionId?: string;
  horizonDamageId?: string;
}): AtlasGqlTaskTargetInput[] =>
  [
    inspectionId && {
      targetId: inspectionId,
      targetType: AtlasGqlTaskTargetType.Inspection,
    },
    horizonDamageId && {
      targetId: horizonDamageId,
      targetType: AtlasGqlTaskTargetType.HorizonDamage,
    },
  ].filter((t): t is AtlasGqlTaskTargetInput => !!t);

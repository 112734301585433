import { Button } from './Button';
import styled from 'styled-components';

export const MenuButton = styled(Button)`
  padding: 0;
  color: inherit;
  height: unset;
  transition: unset;
  &:hover,
  :focus {
    color: inherit;
  }
`;

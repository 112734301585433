import { isEqual, isNil, isNumber, omitBy, pick } from 'lodash';
import moment from 'moment';
import { formatDate } from 'utils/format';

import { StorageManager } from 'utils/storage';
import { TDisplayColumnData } from 'components/data/types';
import { getFilterBy, getSortBy } from 'components/DataTable/paging';
import { TDataTableContext } from 'components/DataTable/types';
import { TExtensionColumn, TSortableColumn, TTableViewFilters } from './types';
import { AtlasGqlTableView, AtlasGqlFilterBy, AtlasGqlSortBy } from 'types/atlas-graphql';

export const updateView = (
  view: AtlasGqlTableView | undefined,
  updateTableView: TDataTableContext['updateTableView'],
  tableViewStorageManager: StorageManager
) => {
  if (view) {
    updateTableView(view);
    tableViewStorageManager.set(view);
  } else {
    updateTableView(undefined);
    tableViewStorageManager.remove();
  }
};

export const mapColumns = ({ key, visible }: TExtensionColumn) => ({
  key,
  visible,
});

export const mapMultiSorts = ({ column, sortDirection }: TSortableColumn) => ({
  key: column.key,
  sort: sortDirection,
});

export const isViewAndTableStateEqual = (
  savedView?: TTableViewFilters,
  tableState?: TTableViewFilters
): boolean => {
  const areFiltersEqual = isEqual(
    omitBy(savedView?.filters ?? {}, isNil),
    omitBy(tableState?.filters ?? {}, isNil)
  );
  const areSortsEqual = isEqual(
    pick(savedView?.sorts ?? {}, ['order', 'columnKey']),
    pick(tableState?.sorts ?? {}, ['order', 'columnKey'])
  );
  const areColumnsEqual = isEqual(
    savedView?.columns ?? [],
    (tableState?.columns ?? []).map(mapColumns)
  );
  const areMultiSortsEqual = isEqual(
    savedView?.multiSorts ?? [],
    (tableState?.multiSorts ?? []).map(mapMultiSorts)
  );

  return areFiltersEqual && areSortsEqual && areColumnsEqual && areMultiSortsEqual;
};

export const validateView = (tableView: AtlasGqlTableView, columnDefs: TDisplayColumnData[]) => {
  // get filters/sorts/columns
  const filters = getFilterBy({
    filters: tableView.filters,
    columns: columnDefs,
  }) as AtlasGqlFilterBy[];
  const sorts = getSortBy(tableView.sorts) as AtlasGqlSortBy[];
  const multiSorts = (tableView.multiSorts ?? []) as AtlasGqlSortBy[];
  const columns = tableView.columns ?? [];

  // get all valid filter/sort keys from the table
  const validKeys = columnDefs.reduce((acc: string[], col: TDisplayColumnData) => {
    const { key, allKeys = [] } = col;
    return [...acc, key, ...allKeys];
  }, []);

  // get all columns with filter values for validation
  const filteredColumnDefs = columnDefs.reduce(
    (acc: TDisplayColumnData[], col: TDisplayColumnData) => {
      const { key, type, filters } = col;

      if (filters && filters.length > 0) {
        let columnType = '';
        if (type) {
          columnType = type;
        } else if (filters.every(val => isNumber(val.text))) {
          columnType = 'number';
        } else if (filters.every(val => moment(val.text).isValid())) {
          columnType = 'date';
        } else {
          columnType = 'string';
        }

        return [...acc, { ...col, type: columnType }];
      }

      return acc;
    },
    []
  );

  const errors: any[] = [];

  // validate filters
  filters.forEach(filter => {
    const { key: filterKey, values: filterValues = [] } = filter;
    const {
      key: columnKey,
      type: columnType,
      filters: columnFilters = [],
    } = filteredColumnDefs.find(col => col.key === filterKey) ?? {};

    if (!validKeys.includes(filterKey)) {
      errors.push({ key: filterKey, value: null, type: 'filter', error: 'invalid key' });
    } else if (columnKey && columnFilters.length > 0) {
      filterValues.forEach(filterValue => {
        let isValid = false;
        if (columnType === 'string') {
          isValid = !!columnFilters.find(val => val.value === filterValue);
        }

        if (filterValue && columnType === 'number') {
          const min = columnFilters[0].text;
          const max = columnFilters[1].text;
          isValid = filterValue >= min && filterValue <= max;
        }

        if (filterValue && columnType === 'date') {
          const min = columnFilters[0].text;
          const max = columnFilters[1].text;
          const exclusiveMax = formatDate(moment(max).add(1, 'day'));
          isValid = filterValue >= min && filterValue < exclusiveMax;
        }

        if (!isValid) {
          errors.push({
            key: filterKey,
            value: filterValue,
            type: 'filter',
            error: 'invalid value',
          });
        }
      });
    }
  });

  // validate sorts
  [...sorts, ...multiSorts].forEach(sort => {
    const { key } = sort;
    if (!validKeys.includes(key)) {
      errors.push({ key, value: null, type: 'sort', error: 'invalid key' });
    }
  });

  // validate columns
  columns.forEach((column: any) => {
    const { key } = column;
    if (!validKeys.includes(key)) {
      errors.push({ key, value: null, type: 'column', error: 'invalid key' });
    }
  });

  return errors;
};

import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { message } from 'antd5';
import { Input, Popover, Tag } from 'antd';
import { Tooltip } from 'components/ui';
import { truncate, get, set, isNil, cloneDeep } from 'lodash';
import { ASSET_TYPENAMES } from '../helpers';
import { TDisplayColumnData, TEditColumnData } from 'components/data/types';
import { CenteredSpan, RepeatingTaskIcon, StyledAttachmentsPopoverTable } from './tasks.style';
import {
  status as commonStatus,
  shortDescription as commonShortDescription,
  description as commonDescription,
  dueDate as commonDueDate,
  completedDate as commonCompletedDate,
} from './common';
import { startCase, formatDate } from 'utils/format';
import { None, sortBy, getOnFilter, getFilterDropdown, truncateTooltipper } from '../helpers';
import TagChooser from 'components/choosers/TagChooser';
import { WorkContainerChooser } from 'components/choosers/WorkContainerChooser';
import { WorkContainerArchivedChooser } from 'components/choosers/WorkContainerArchivedChooser';
import { StatusTag } from 'components/StatusTag';
import {
  TASK_EDIT_DESCRIPTION,
  TASK_EDIT_DUE_DATE,
  TASK_EDIT_COMPLETED_DATE,
  TASK_EDIT_STATUS,
  TASK_EDIT_TAGS,
  TASK_EDIT_VENDOR,
  TASK_EDIT_WORK_ORDER,
} from 'utils/access-control/rules';
import { STANDARD_COLUMN_SCROLL_Y, WORK_ORDER } from 'utils/constants';
import naturalSort from 'utils/naturalSort';
import {
  AtlasGqlAsset,
  AtlasGqlMaintenanceActivityStatus,
  AtlasGqlWorkCampaign,
  AtlasGqlWorkContainer,
  AtlasGqlWorkOrder,
} from 'types/atlas-graphql';
import { TAtlasTaskWithTypeName } from '../types';
import { EWorkContainerArchiveOptions } from 'horizon/routes/WorkOrders/Tasks/TaskList/types';
import { TABLE_SCROLL_WIDTH_SMALL } from 'utils/constants';
import * as ATTACHMENTS_COLUMNS from 'components/data/attachments';
import {
  DamageLink,
  getObservationGroupByInspectionId,
  getTaskTargetInspectionId,
} from 'horizon/components/Damages/utils';
import { useAccountContext } from 'utils/account/AccountContext';
import { useOrganizationVendorRelationships } from 'utils/organization';

export {
  created,
  createdBy,
  createdFrom,
  createdAndCreatedBy,
  deliveryStatus,
  deliveryDate,
  getForOrganization,
} from './common';

export {
  location,
  assetSite as site,
  assetTurbine as turbine,
  assetBlade as blade,
  atlasAssetBlade as atlasBlade,
  removeAssetLink,
} from './taskAssets';

export const shortDescription = {
  ...commonShortDescription,
  onHeaderCell: () => ({
    'data-testid': 'tasks-description-header',
  }),
  defaultFilterOperator: 'CONTAINS',
  editRule: TASK_EDIT_DESCRIPTION,
};

export const shortDescriptionBulkEdit = (disableCheckbox?: boolean) => {
  return {
    ...shortDescription,
    disableCheckbox: disableCheckbox,
  };
};

export const description = {
  ...commonDescription,
  editRule: TASK_EDIT_DESCRIPTION,
};

export const dueDate = {
  ...commonDueDate,
  onHeaderCell: () => ({
    'data-testid': 'tasks-due-date-header',
  }),
  editRule: TASK_EDIT_DUE_DATE,
};

export const dueDateBulkEdit = (disableCheckbox?: boolean) => {
  return {
    ...dueDate,
    disableCheckbox: disableCheckbox,
  };
};

export const completedDate = {
  ...commonCompletedDate,
  onHeaderCell: () => ({
    'data-testid': 'tasks-completed-date-header',
  }),
  editRule: TASK_EDIT_COMPLETED_DATE,
};

export const completedDateBulkEdit = (disableCheckbox?: boolean) => {
  return {
    ...completedDate,
    disableCheckbox: disableCheckbox,
  };
};

export const status = {
  ...commonStatus,
  onHeaderCell: () => ({
    'data-testid': 'tasks-status-header',
  }),
  editRule: TASK_EDIT_STATUS,
};

export const statusBulkEdit = (disableCheckbox?: boolean) => {
  return {
    ...status,
    disableCheckbox: disableCheckbox,
  };
};

export const damageId = {
  key: 'damage.id',
  dataIndex: ['damage', 'id'],
  title: 'Damage',
  filterProperty: 'damage.id',
  get: (record: any) => get(record, ['damage', 'id']),
  render(damageId: any, task: any) {
    const { damage = {} } = task || {};
    const { number = {}, observations: [{ picture: { id: pictureId = '' } = {} } = {}] = [] } =
      damage;
    return [damageId, number, pictureId].every(Boolean) ? (
      <Link to={`/pictures/${pictureId}?damages=${damageId}`}>{number}</Link>
    ) : (
      <None />
    );
  },
};

export const damageDate = {
  key: 'damage.attrs.Date',
  dataIndex: ['damage', 'attrs', 'Date'],
  title: 'Damage Date',
  filterProperty: 'damage.attrs.Date',
  get: (record: any) => get(record, ['damage', 'attrs', 'Date']),
  render(damageDate: any) {
    return damageDate ? formatDate(damageDate) : <None />;
  },
};

export const taskNumber = {
  key: 'taskNumber',
  dataIndex: 'number',
  title: 'Task',
  onHeaderCell: () => ({
    'data-testid': 'tasks-number-header',
  }),
  filterProperty: 'number',
  get: (record: any) => get(record, 'number'),
  render: (number: any, task: AtlasGqlWorkContainer) => {
    const { id } = task || {};
    return [id, number].every(Boolean) ? <Link to={`/tasks/${id}`}>{number}</Link> : <None />;
  },
};

export const workOrder = {
  key: 'workOrder.name',
  title: 'Work Order',
  dataIndex: ['workOrder'],
  sorter: sortBy('workOrder.name'),
  filterProperty: 'workOrder.name',
  get: (record: any) => get(record, ['workOrder', 'name']),
  render: (workOrder: any) => {
    const { id, name } = workOrder || {};
    return [id, name].every(Boolean) ? (
      <Link to={`/work-orders/${id}`}>{truncateTooltipper(name)}</Link>
    ) : (
      <None />
    );
  },
  editRule: TASK_EDIT_WORK_ORDER,
  edit(record: any, { onChange, ...props }: any) {
    const { workOrder } = record;
    // handle unplanned tasks where workOrder is null
    const number = workOrder ? workOrder.number : null;
    const id = workOrder ? workOrder.id : null;

    return (
      <WorkContainerChooser
        defaultValue={number ? { key: number } : undefined}
        containerId={id}
        type={WORK_ORDER}
        onChange={
          onChange &&
          ((value: any) => {
            onChange({
              parentId: value,
            });
          })
        }
        {...props}
      />
    );
  },
};

export const tags = {
  title: 'Tags',
  onHeaderCell: () => ({
    'data-testid': 'tasks-tags-header',
  }),
  dataIndex: 'tags',
  render: (tags: string[]) =>
    tags && tags.length ? tags.map(tag => <Tag key={tag}>{tag}</Tag>) : <None />,
  getInitialValue: (record: any) => (record.tags ? record.tags : []),
  filterProperty: 'tags',
  defaultFilterOperator: 'ARRAY_INCLUDES',
  key: 'tags',
  editRule: TASK_EDIT_TAGS,
  edit(record: any, { onChange, ...props }: any) {
    const { tags: value } = record || {};
    return (
      <TagChooser
        defaultValue={value || []}
        onChange={onChange && ((tags: any) => onChange({ tags }))}
        {...props}
      />
    );
  },
};

export const tagsBulkEdit = (disableCheckbox: boolean) => {
  return {
    ...tags,
    disableCheckbox: disableCheckbox,
  };
};

// FIXME: add a taskTypes query so that these can be retrieved, not hard coded
const taskFilterConfig = {
  key: 'type',
  type: 'string',
  options: ['REPAIR', 'INSPECT', 'INTERNAL_BLADE_INSPECTION', 'OTHER'],
  exactMatch: true,
  values: [],
  filterAttr: undefined,
};

// FIXME JD: Deprecate this column in favor of atlasType as queries get migrated over to Atlas.
export const type = {
  key: 'taskType',
  title: 'Task Type',
  // FIXME: add a taskTypes query so that these can be retrieved, not hard coded
  filters: [
    { text: 'Repair', value: 'REPAIR' },
    { text: 'Inspect', value: 'INSPECT' },
    { text: 'Internal Blade Inspection', value: 'INTERNAL_BLADE_INSPECTION' },
    { text: 'Other', value: 'OTHER' },
    { text: 'Inspection', value: 'INSPECTION' },
  ],
  onFilter: getOnFilter(taskFilterConfig),
  filterDropdown: getFilterDropdown(taskFilterConfig),
  sorter: sortBy('type'),
  get: (record: any) => get(record, 'type'),
  render: (task: any) => {
    const type = task?.type;
    const __typename = task?.__typename;

    const renderType = (t: any) => (t ? startCase(t) : <None />);
    if (type) return renderType(type);
    if (__typename) return renderType(__typename.replace('Task', '').replace('Damage', ''));
    return <None />;
  },
};

export const vendor = {
  key: 'vendor',
  title: 'Vendor',
  dataIndex: 'vendor',
  sorter: sortBy('vendor'),
  filterProperty: 'vendor',
  defaultFilterOperator: 'CONTAINS',
  get: (record: any) => get(record, 'vendor'),
  render: (vendor: any) => {
    return vendor ? truncate(vendor, { length: 15 }) : <None />;
  },
  editRule: TASK_EDIT_VENDOR,
  edit(record: any, { onChange, ...props }: any) {
    return (
      <Input
        style={{ maxWidth: '250px' }}
        defaultValue={record.vendor}
        onChange={onChange && (e => onChange({ vendor: e.target.value }))}
        {...props}
      />
    );
  },
};

export const vendorBulkEdit = (disableCheckbox?: boolean) => {
  return {
    ...vendor,
    disableCheckbox: disableCheckbox,
  };
};

export const archived = (idsToUpdate: string[]): TEditColumnData => {
  return {
    key: EWorkContainerArchiveOptions.archived,
    title: 'Archived',
    get: (record: any) => get(record, EWorkContainerArchiveOptions.archived),
    editRule: TASK_EDIT_WORK_ORDER,
    edit: (record: any, { ...props }: any) => {
      const { disabled } = props;
      return (
        <WorkContainerArchivedChooser
          archivedValue={record.archived}
          disabled={disabled}
          idsToUpdate={idsToUpdate}
          {...props}
        />
      );
    },
  };
};

/*
  Atlas column definitions
*/

export const serverAtlasTaskComponent = (isCollaborator: boolean = false): TDisplayColumnData => {
  return {
    title: 'Component',
    key: 'component',
    sorter: sortBy('asset'),
    onHeaderCell: () => ({
      'data-testid': 'tasks-component-header',
    }),
    render: (data: any) => {
      if (!data) return <None />;
      const { asset } = data;
      const type = asset?.assetType?.name ?? asset?.__typename;

      // If we're a Site or a Turbine we're displaying component info in those columns
      if (!asset || !type || type === 'Site' || type === 'Turbine') {
        return <None />;
      }

      // Build Blades and/or generic assets link
      const { id, uniqueIdLabel } = asset;
      return isCollaborator ? (
        uniqueIdLabel
      ) : (
        <Link
          key={id}
          to={`${type === ASSET_TYPENAMES.blade ? '/blades/' : '/assets/'}${id}/tasks`}
        >
          {uniqueIdLabel}
        </Link>
      );
    },
  };
};

// We want to use this sorter only if the task is directly on a component,
// not on a site or turbine.
const getSortableComponentLabel = (task: any) => {
  const asset = task?.asset;
  if (!asset) return;
  const { __typename: assetType } = asset;
  let label: string | undefined = undefined;

  if (assetType !== 'Site' || assetType !== 'Turbine') {
    label = asset.uniqueIdLabel;
  }
  return label;
};

export const atlasTaskComponentClientSide = (
  isCollaborator: boolean = false
): TDisplayColumnData => {
  const serverSide = serverAtlasTaskComponent(isCollaborator);
  return {
    ...serverSide,
    onHeaderCell: () => ({
      'data-testid': 'tasks-component-header',
    }),
    sorter: (taskA: any, taskB: any) => {
      const labelA = getSortableComponentLabel(taskA);
      const labelB = getSortableComponentLabel(taskB);
      return labelA || labelB ? naturalSort(labelA, labelB) : 0;
    },
  };
};

export const componentStatusColumn: TDisplayColumnData = {
  key: 'componentStatus',
  title: 'Component Status',
  dataIndex: 'asset',
  onHeaderCell: () => ({
    'data-testid': 'tasks-component-status-header',
  }),
  render: (asset: AtlasGqlAsset) =>
    asset?.assetType?.name &&
    !['Site', 'Turbine'].includes(asset.assetType.name) &&
    asset?.assetStatus ? (
      <StatusTag value={asset.assetStatus} />
    ) : (
      <None />
    ),
};

export const atlasDamageId: TDisplayColumnData = {
  key: 'damage.id',
  dataIndex: ['damage', 'id'],
  title: 'Damage',
  // FIXME JDJ: Filtering is currently disabled as the Task service cannot filter by Damage name
  // filterProperty: 'damage.id',
  defaultFilterOperator: 'CONTAINS',
  get: (record: any) => get(record, ['damage', 'id']),
  render: (damageId: any, task: any) => {
    const { damage = {} } = task || {};
    const number = damage?.number;
    const observations = damage?.observations;
    const [{ pictureId = '' } = {}] = observations && observations.length ? observations : [];
    return [damageId, number, pictureId].every(Boolean) ? (
      <Link to={`/pictures/${pictureId}?damages=${damageId}`}>{number}</Link>
    ) : (
      <None />
    );
  },
};

export const atlasDamageNumberLink: TDisplayColumnData = {
  key: 'damage',
  dataIndex: ['damage'],
  title: 'Damage',
  // FIXME JDJ: Filtering is currently disabled as the Task service cannot filter by Damage name
  // filterProperty: 'damage.id',
  defaultFilterOperator: 'CONTAINS',
  get: (record: any) => get(record, ['damage']),
  render: (damage: any, task: any) => {
    const { number, id, observations } = damage ?? {};

    return [id, number, observations].every(Boolean) ? (
      <Link to={`/pictures/${observations[0]?.pictureId}?damages=${id}`}>{number}</Link>
    ) : (
      <None />
    );
  },
};

export const atlasHorizonDamageLink: TDisplayColumnData = {
  key: 'horizonDamage',
  dataIndex: ['horizonDamage'],
  title: 'Damage',
  // FIXME JDJ: Filtering is currently disabled as the Task service cannot filter by Damage name
  // filterProperty: 'horizonDamage.id',
  defaultFilterOperator: 'CONTAINS',
  get: (record: any) => get(record, ['horizonDamage']),
  render: (damage: any, task: any) => {
    return damage?.id ? <DamageLink damageId={damage?.id} /> : <None />;
  },
};

export const serverAtlasTaskSites = (isCollaborator: boolean = false): TDisplayColumnData => {
  return {
    title: 'Site',
    key: 'site',
    dataIndex: 'asset',
    // sorter and filterProperty are needed for sort/filter options to shows up. They are unused otherwise.
    sorter: sortBy('site'),
    onHeaderCell: () => ({
      'data-testid': 'tasks-sites-header',
    }),
    render: (asset: any) => {
      // No asset return None immediately
      if (!asset) return <None />;

      const { __typename: type, ancestors, name, id } = asset;

      // If the task is on a site we'll have the name on the asset itself
      if (type === ASSET_TYPENAMES.site) {
        return isCollaborator ? (
          name
        ) : (
          <Link key={id} to={`/sites/${id}`}>
            {name}
          </Link>
        );
      }

      // If we don't have ancestors return None
      if (!ancestors) {
        return <None />;
      }

      // Find the site in ancestors and use it to build our link
      const site = ancestors.find((record: any) => record.__typename === ASSET_TYPENAMES.site);
      if (!site) return <None />;
      return isCollaborator ? (
        site.name
      ) : (
        <Link key={site.id} to={`/sites/${site.id}/tasks`}>
          {site.name}
        </Link>
      );
    },
  };
};

// Takes a task and allows sorting based on the turbine or site,
// whether the task is directly on a turbine or site, or
// on a component that has a turbine and / or site as a parent.
// 'Site' and 'Turbine' are the only supported parent asset types.
const getSortableParentAssetLabel = (task: any, parentAssetType: 'Site' | 'Turbine') => {
  const asset = task?.asset;
  if (!asset) return;
  const { __typename: assetType } = asset;
  let label: string | undefined = undefined;

  // if task is directly on the turbine or site, get the the turbine or site's label
  if (assetType === parentAssetType) {
    label = asset.uniqueIdLabel;
  } else {
    // if task is on a component that has a turbine and / or site as a parent,
    // get that parent asset's label
    const { ancestors } = asset;
    const parentAsset = ancestors.find((record: any) => record.__typename === parentAssetType);
    if (parentAsset) {
      label = parentAsset.uniqueIdLabel;
    }
  }
  return label;
};

export const clientAtlasTaskSites = (isCollaborator: boolean = false): TDisplayColumnData => {
  const serverColumn = serverAtlasTaskSites(isCollaborator);
  return {
    ...serverColumn,
    onHeaderCell: () => ({
      'data-testid': 'tasks-site-header',
    }),
    sorter: (taskA: any, taskB: any) => {
      // In some cases the task is directly on a site. In other cases, the task is
      // on a component that has a site as its parent. We need to sort in either case.
      const labelA = getSortableParentAssetLabel(taskA, 'Site');
      const labelB = getSortableParentAssetLabel(taskB, 'Site');
      return labelA || labelB ? naturalSort(labelA, labelB) : 0;
    },
  };
};

export const serverAtlasTaskTurbines = (isCollaborator: boolean = false): TDisplayColumnData => {
  return {
    title: 'Turbine',
    key: 'turbine',
    dataIndex: 'asset',
    // sorter and filterProperty are needed for sort/filter options to shows up. They are unused otherwise.
    sorter: sortBy('turbine'),
    onHeaderCell: () => ({
      'data-testid': 'tasks-turbines-header',
    }),
    render: (asset: any) => {
      // No asset return None immediately
      if (!asset) return <None />;

      const { __typename: type, ancestors, name, id } = asset;

      // If the task is on a turbine we'll have the uniqueIdLabel on asset
      if (type === ASSET_TYPENAMES.turbine) {
        return isCollaborator ? (
          name
        ) : (
          <Link key={id} to={`/turbines/${id}/tasks`}>
            {name}
          </Link>
        );
      }

      // If no ancestor somehow return None
      if (!ancestors) return <None />;

      // Otherwise find the turbine information
      const turbine = ancestors.find(
        (record: any) => record.__typename === ASSET_TYPENAMES.turbine
      );
      if (!turbine) return <None />;
      return isCollaborator ? (
        turbine.name
      ) : (
        <Link key={turbine.id} to={`/turbines/${turbine.id}/tasks`}>
          {turbine.name}
        </Link>
      );
    },
  };
};

export const clientAtlasTaskTurbines = (isCollaborator: boolean = false): TDisplayColumnData => {
  const serverColumn = serverAtlasTaskTurbines(isCollaborator);
  return {
    ...serverColumn,
    onHeaderCell: () => ({
      'data-testid': 'tasks-turbine-header',
    }),
    sorter: (taskA: any, taskB: any) => {
      // In some cases the task is directly on a turbine. In other cases, the task is
      // on a component that has a turbine as its parent. We need to sort in either case.
      const labelA = getSortableParentAssetLabel(taskA, 'Turbine');
      const labelB = getSortableParentAssetLabel(taskB, 'Turbine');
      return labelA || labelB ? naturalSort(labelA, labelB) : 0;
    },
  };
};

const atlasType: TDisplayColumnData = {
  key: 'type',
  title: 'Task Type',

  get: (record: any) => get(record, 'type'),
  render: (task: TAtlasTaskWithTypeName) => {
    const type = task?.type;
    const __typename = task?.__typename;

    const renderType = (t: any) =>
      t ? startCase(t.replace('Task', '').replace('Damage', '')) : <None />;
    if (type) return renderType(type?.name);
    if (__typename) return renderType(__typename);
    return <None />;
  },
};

export const serverAtlasType: TDisplayColumnData = {
  ...atlasType,
  onHeaderCell: () => ({
    'data-testid': 'tasks-type-header-server',
  }),
  // FIXME: add a taskTypes query so that these can be retrieved, not hard coded
  filters: [
    { text: 'Repair', value: 'REPAIR_TASK' },
    { text: 'Inspect', value: 'INSPECT_TASK' },
    { text: 'Internal Blade Inspection', value: 'INTERNAL_BLADE_INSPECTION_TASK' },
    { text: 'Other', value: 'OTHER_TASK' },
    { text: 'Inspection', value: 'INSPECTION_TASK' },
  ],
};

export const clientAtlasType: TDisplayColumnData = {
  ...atlasType,
  onHeaderCell: () => ({
    'data-testid': 'tasks-type-header',
  }),
  filters: [
    { text: 'Repair', value: 'REPAIR TASK' },
    { text: 'Inspect', value: 'INSPECT TASK' },
    { text: 'Internal Blade Inspection', value: 'INTERNAL BLADE INSPECTION TASK' },
    { text: 'Other', value: 'OTHER TASK' },
    { text: 'Inspection', value: 'INSPECTION TASK' },
  ],
  onFilter: getOnFilter({ ...taskFilterConfig, key: 'type.name' }),
  filterDropdown: getFilterDropdown(taskFilterConfig),
  sorter: sortBy('type.name'),
};

export function useLegacyVendorColumn(vendor: TDisplayColumnData = atlasVendor) {
  const hasVendorRT = useAccountContext().hasReleaseToggle('vendor-dropdown');
  if (!hasVendorRT) return vendor;

  return {
    ...vendor,
    // append Legacy when using the new vendor org functionality
    title: `${vendor.title} (Legacy)`,
  };
}

const atlasVendor: TDisplayColumnData = {
  key: 'vendor',
  title: 'Vendor',
  onHeaderCell: () => ({
    'data-testid': 'tasks-vendor-header',
  }),
  dataIndex: 'vendor',
  sorter: sortBy('vendor'),
  filterProperty: 'vendor',
  get: (record: any) => get(record, 'vendor'),
  render: (vendor: string) => {
    return vendor ? truncate(vendor, { length: 15 }) : <None />;
  },
};

export function useVendorOrgColumn() {
  const hasVendorRT = useAccountContext().hasReleaseToggle('vendor-dropdown');
  const { errorsString, loading, vendors } = useOrganizationVendorRelationships({
    skip: !hasVendorRT,
  });

  useEffect(() => {
    if (!loading && errorsString) {
      message.warning(`Unable to load vendors for filtering tasks. ${errorsString}`, 8);
    }
  }, [errorsString, loading]);

  if (!hasVendorRT) return null;

  const availableVendors = vendors.map(v => ({
    value: v.name,
    text: v.name,
  }));

  const vendorOrg: TDisplayColumnData = {
    key: 'vendorName',
    title: 'Vendor',
    onHeaderCell: () => ({
      'data-testid': 'tasks-vendor-org-header',
    }),
    dataIndex: ['parent'],
    sorter: sortBy('parent.vendorOrganization.name'),
    filters: availableVendors,
    filterProperty: 'parent.vendorOrganization.name',
    // @ts-ignore i can't even with you
    filterDropdown: getFilterDropdown({
      values: availableVendors,
      type: 'string',
      includeNoneOption: false,
    }),
    render: (parent: AtlasGqlWorkOrder) => {
      return parent?.vendorOrganization?.name ?? <None />;
    },
  };

  return vendorOrg;
}

export const atlasWorkOrder = (columnLength?: number): TEditColumnData => {
  return {
    // NOTE: keys must match the filter defined in the tabs.ts TAB_LIST filter or the Unplanned tab will reset when advancing to the next page.
    key: 'parentName',
    title: 'Work Order',
    dataIndex: ['parent'],
    sorter: sortBy('parent.name'),
    filterProperty: 'parent.name',
    get: (record: any) => get(record, ['parent', 'name']),
    render: (parent: AtlasGqlWorkOrder) => {
      const { id, name } = parent || {};
      return [id, name].every(Boolean) ? (
        <Link to={`/work-orders/${id}`}>
          {truncateTooltipper(name, columnLength ? columnLength : undefined)}
        </Link>
      ) : (
        <None />
      );
    },
    // This column is used for task detail fields, so it needs to be editable
    editRule: TASK_EDIT_WORK_ORDER,
    edit(record: any, { onChange, ...props }: any) {
      const { parent: workOrder } = record;
      // handle unplanned tasks where workOrder is null
      const number = workOrder ? workOrder.number : null;
      const { disabled } = props;

      return (
        <WorkContainerChooser
          defaultValue={number ? { key: number } : undefined}
          containerId={'atlas-work-order'}
          type={WORK_ORDER}
          onChange={
            onChange &&
            ((value: any) => {
              onChange({
                parentId: value,
              });
            })
          }
          disabled={disabled}
          {...props}
        />
      );
    },
    onHeaderCell: () => ({
      'data-testid': 'tasks-work-order-atlas',
    }),
  };
};

export const atlasCampaign = {
  key: 'campaignName',
  title: 'Campaign',
  dataIndex: ['parent', 'parent'],
  sorter: sortBy('parent.parent.name'),
  filterProperty: 'parent.parent.name',
  defaultFilterOperator: 'EQUALS',
  get: (record: any) => get(record, ['parent', 'parent']),
  render: (campaign: AtlasGqlWorkCampaign) => {
    const { id, name } = campaign || {};
    return [id, name].every(Boolean) ? (
      <Link to={`/campaigns/${id}`}>{truncateTooltipper(name)}</Link>
    ) : (
      <None />
    );
  },
};

// Used for the bulk edit modal on the Tasks table
export const bulkEditAtlasWorkOrder = () => {
  return {
    ...atlasWorkOrder(),
    key: 'parentId',
    editRule: TASK_EDIT_WORK_ORDER,
  };
};

export const bulkEditAtlasWorkOrderDynamicCheckbox = (disableCheckbox?: boolean) => {
  return {
    ...bulkEditAtlasWorkOrder(),
    disableCheckbox: disableCheckbox,
  };
};

export const recurrenceIcon: TDisplayColumnData = {
  key: 'recurrence',
  get: (record: any) => record,
  render: (record: any) => {
    const { name, id, status } = record?.maintenanceActivity ?? {};
    if (name) {
      return (
        <Tooltip
          title={
            <span>
              Recurring Task:{' '}
              <a href={`/recurring-task/${id}`}>
                {`${name}${status === AtlasGqlMaintenanceActivityStatus.Ended ? ' (Ended)' : ''}`}
              </a>
            </span>
          }
        >
          <RepeatingTaskIcon />
        </Tooltip>
      );
    }
    return null;
  },
};

export const attachments: TDisplayColumnData = {
  key: 'attachments',
  dataIndex: 'attachments',
  title: 'Attachments',
  render: (attachs: any) => {
    // If we want to add a popover we can reuse the Popover logic in: components/damages/static:taskStatus
    return attachs?.length ? (
      <Popover
        content={
          <StyledAttachmentsPopoverTable
            data-testid="attachments-popover-table"
            columns={[ATTACHMENTS_COLUMNS.name, ATTACHMENTS_COLUMNS.category]}
            dataSource={attachs}
            size="small"
            rowKey="id"
            pagination={false}
            scroll={STANDARD_COLUMN_SCROLL_Y}
            style={{ width: TABLE_SCROLL_WIDTH_SMALL }}
          />
        }
      >
        <CenteredSpan data-testid="attachments-count">{attachs.length}</CenteredSpan>
      </Popover>
    ) : (
      <CenteredSpan>
        <None />
      </CenteredSpan>
    );
  },
  onHeaderCell: () => ({
    'data-testid': 'tasks-attachments-header',
  }),
};

/**
 * This function is specific for columns that we need to build based on damage columns
 * that come from the useDamageAttrs hook.
 * @param {array} damageColumns - array of damage columns returned from useDamageAttrs hook
 * @param {boolean} isHorizonDamage - whether to use legacy or horizonDamage attributes
 * @param {string} columnName - name of the targeted attribute in the damageColumn
 * @param {string} horizonDamageAttrTarget - targeted attribute string in the horizonDamage data object.
 *   example: 'horizonDamage.primaryObservationGroupAttrs.Severity'
 * @param {string} legacyDamageAttrsTarget - targeted attribute string in the legacy data object.
 *   example: 'damage.attrs.Severity'
 * @param {Object} overrideProps - used in cases where you need to change any of the properties after everything else.
 *   example: { title: 'Damage Type' } - will change the title to Damage Type
 */
export const damageColumnBuilder = (
  damageColumns: any[],
  isHorizonDamage: boolean,
  columnName: string,
  horizonDamageAttrTarget: string,
  legacyDamageAttrsTarget: string,
  overrideProps?: any
): TDisplayColumnData | undefined => {
  const column = damageColumns.find((column: any) => column.name === columnName);
  const horizonDamageAttrs = horizonDamageAttrTarget;
  const legacyDamageAttrs = legacyDamageAttrsTarget;
  const targetAttrsDataString = isHorizonDamage ? horizonDamageAttrs : legacyDamageAttrs;
  const splitString = isHorizonDamage
    ? horizonDamageAttrs.split('.')
    : legacyDamageAttrs.split('.');

  // return undefined if field not found in damage schema
  return column
    ? {
        ...column,
        onHeaderCell: () => ({
          'data-testid': `damage-${columnName.toLowerCase()}-header`,
        }),
        dataIndex: splitString,
        sorter: (a, b) => {
          // special case for sorting the severity column by severity, then by critical
          if (splitString[splitString.length - 1] === 'Severity') {
            // makes sure that when Severity is not defined,
            // those results appear above other Severity values
            const _a = isNil(get(a, targetAttrsDataString))
              ? set(cloneDeep(a), targetAttrsDataString, Infinity)
              : a;
            const _b = isNil(get(b, targetAttrsDataString))
              ? set(cloneDeep(b), targetAttrsDataString, Infinity)
              : b;

            // finds critical status of target observation group for secondary sort
            const aCritical = !!getObservationGroupByInspectionId(
              _a.horizonDamage,
              getTaskTargetInspectionId(_a.targets) ?? ''
            )?.groupCritical;
            const bCritical = !!getObservationGroupByInspectionId(
              _b.horizonDamage,
              getTaskTargetInspectionId(_b.targets) ?? ''
            )?.groupCritical;

            return sortBy(targetAttrsDataString, 'targetGroupCritical')(
              { ..._a, targetGroupCritical: aCritical },
              { ..._b, targetGroupCritical: bCritical }
            );
          }

          return sortBy(targetAttrsDataString)(a, b);
        },
        filterProperty: targetAttrsDataString,
        onFilter: getOnFilter({
          ...column.config,
          key: targetAttrsDataString,
          exactMatch: true,
        }),
        get: (record: any) => get(record, targetAttrsDataString),
        render: (_: any, record: any) => {
          if (record?.hasOwnProperty('horizonDamage')) {
            return column.render(_, record);
          }

          return <None />;
        },

        ...overrideProps,
      }
    : undefined;
};

export const getTaskDamageColumns = (
  damageColumns: any[],
  isHorizonDamage: boolean,
  columnNames?: string[]
): (TDisplayColumnData | undefined)[] => {
  const finalNames = columnNames ?? damageColumns.map(column => column.name);

  return finalNames.map(name => {
    const horizonDamageAttrTarget = `horizonDamage.primaryObservationGroupAttrs.${name}`;
    const legacyDamageAttrsTarget = `damage.attrs.${name}`;
    const overrideProps = name === 'Type' ? { title: 'Damage Type' } : {};

    return damageColumnBuilder(
      damageColumns,
      isHorizonDamage,
      name,
      horizonDamageAttrTarget,
      legacyDamageAttrsTarget,
      overrideProps
    );
  });
};

// Used to display the priority number associated with tasks in a work order
export const priorityColumn = (tasks: any): TDisplayColumnData => {
  // Build out the range of possible priorities
  const prioritiesFilterDropdownValues = tasks
    .map((t: any) => {
      // If we don't have a priority, return undefined to filter it out later.
      if (!t.priority) return undefined;
      return { id: t.priority, label: t.priority };
    })
    .filter(Boolean);

  return {
    key: 'priority',
    dataIndex: 'priority',
    title: 'Priority',
    filterProperty: 'priority',
    filterDropdown: getFilterDropdown({
      values: prioritiesFilterDropdownValues,
      type: 'number',
      step: 1,
      includeNoneOption: true,
      noneOptionValue: ' ',
    }),
    onFilter: getOnFilter({
      key: 'priority',
      type: 'number',
      includeNoneOption: true,
      // Properties belowed are unused for this column. getOnFilter expects them.
      filterAttr: undefined,
      exactMatch: true,
      values: undefined,
    }),
    sorter: sortBy('priority'),
    render: (priority: any) => {
      return priority ? priority : <None />;
    },
  };
};

import styled from 'styled-components';

export const Title = styled.div`
  display: inline-block;
  margin-left: 0.5em;
`;

export const Section = styled.div`
  margin-bottom: 1em;
`;

export const OptionTitle = styled.div`
  font-size: 1.1em;
  font-weight: bold;
`;

export const Customer = styled.span`
  font-size: 1.1em;
  font-weight: bold;
`;

import { FC, useCallback } from 'react';
import { DeleteConfirmation, DeleteConfirmationProps } from 'components/DeleteConfirmation';
import { useDeleteCommentMutation } from 'types/atlas-graphql';
import { RefetchConfig } from './refetchConfig';

interface DeleteCommentProps
  extends Pick<DeleteConfirmationProps, 'deleteBtnCss' | 'hideDeleteIcon' | 'type'> {
  id: string;
  refetchConfig?: RefetchConfig;
}

const DeleteComment: FC<DeleteCommentProps> = ({ id, refetchConfig, ...props }) => {
  const [deleteComment] = useDeleteCommentMutation({
    ...refetchConfig,
  });

  const handleDelete = useCallback(async () => {
    await deleteComment({
      variables: {
        input: {
          id,
        },
      },
    });
  }, [id, deleteComment]);

  return (
    <DeleteConfirmation
      title="Delete Comment?"
      id="delete-comment-button"
      onDelete={handleDelete}
      warningContent={<span>Delete this comment?</span>}
      {...props}
    />
  );
};

export default DeleteComment;

import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';

import { TurbineChooser, LocationChooser, BladeChooser } from '../index';
import { useServerDataTableContext } from 'components/DataTable/ServerDataTable';
import { sleep, NOOP } from 'utils/helpers';
import { storage } from 'utils/storage';

import { StyledControls, SelectControl } from './AssetChooser.style';

function getAssetIds(locationIds?: string[], turbineIds?: string[], bladeIds?: string[]) {
  // only send the most significant data point
  if (bladeIds?.length) {
    return bladeIds;
  }
  if (turbineIds?.length) {
    return turbineIds;
  }
  if (locationIds?.length) {
    return locationIds;
  }
  return null;
}

function getAssetState(assetIds?: string[]) {
  if (assetIds) {
    return assetIds instanceof Array ? assetIds : [assetIds];
  }
  return undefined;
}

interface IAssetChooser {
  locationIds?: string[];
  turbineIds?: string[];
  bladeIds?: string[];
  // if true, means the caller is responsible for passing (back) in locationIds, turbineIds, bladeIds as they change
  callerManagedState?: boolean;
  settings?: any;
  onChange: ({ locationIds, turbineIds, bladeIds, assetIds }: any) => void;
  loadState?: any;
  block?: boolean;
  small?: boolean;
  width?: string;
  disabled?: boolean;
  handleClearAssetDropdowns?: any;
}

export function AssetChooser({
  locationIds: selectedLocationIds,
  turbineIds: selectedTurbineIds,
  bladeIds: selectedBladeIds,
  callerManagedState = false,
  settings: {
    location: { multiple: multipleLocations = true, visible: visibleLocations = true } = {},
    turbine: { multiple: multipleTurbines = true, visible: visibleTurbines = true } = {},
    blade: { multiple: multipleBlades = true, visible: visibleBlades = false } = {},
  } = {},
  onChange,
  loadState = NOOP,
  block = false,
  small = false,
  width = '100%',
  disabled = false,
  handleClearAssetDropdowns = () => {},
}: IAssetChooser) {
  const { loading } = useServerDataTableContext().context;
  const [initialized, setInitialized] = useState(false);
  const [locationIds, setLocationIds] = useState<string[] | undefined>(selectedLocationIds);
  const [turbineIds, setTurbineIds] = useState(getAssetState(selectedTurbineIds));
  const [bladeIds, setBladeIds] = useState(getAssetState(selectedBladeIds));

  function updateAssetStates() {
    const locationAssetState = getAssetState(selectedLocationIds);
    if (!isEqual(locationAssetState, locationIds)) {
      setLocationIds(locationAssetState);
    }

    const turbineAssetState = getAssetState(selectedTurbineIds);
    if (!isEqual(turbineAssetState, turbineIds)) {
      setTurbineIds(turbineAssetState);
    }

    const bladeAssetState = getAssetState(selectedBladeIds);
    if (!isEqual(bladeAssetState, bladeIds)) {
      setBladeIds(bladeAssetState);
    }
  }

  useEffect(() => {
    const doLoad = async () => {
      const state = loadState();
      // If state exists and orgs are mismatched we need to clear the asset dropdowns
      if (state && state?.orgId !== storage.getItem('customerId')) {
        handleReseting();
      } else if (state) {
        handleLocationChange(state?.locationIds);
        await sleep(500);
        handleTurbineChange(state?.turbineIds);
        await sleep(500);
        handleBladeChange(state?.bladeIds);
      }
      setInitialized(true);
    };
    doLoad();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // if the caller is managing state (DamageList) this keeps things in sync
  useEffect(
    () => {
      if (initialized && callerManagedState) {
        updateAssetStates();
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedLocationIds, selectedTurbineIds, selectedBladeIds, callerManagedState, initialized]
  );

  // Call onChange when an asset selection has changed
  useEffect(
    () => {
      onChange({
        locationIds,
        turbineIds,
        bladeIds,
        assetIds: getAssetIds(locationIds, turbineIds, bladeIds),
        // getItem returns null usually. Use undefined to fit pattern for rest of Ids
        orgId: storage.getItem('customerId') ?? undefined,
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationIds, turbineIds, bladeIds]
  );

  const handleLocationChange = (locationIds: string[]) => {
    setLocationIds(locationIds);
    setTurbineIds(undefined);
    setBladeIds(undefined);
  };

  const handleTurbineChange = (turbineIds: string[]) => {
    setTurbineIds(turbineIds);
    setBladeIds(undefined);
  };

  const handleBladeChange = (bids: string[]) => {
    setBladeIds(bids);
  };

  const handleReseting = () => {
    setLocationIds(undefined);
    setTurbineIds(undefined);
    setBladeIds(undefined);
    handleClearAssetDropdowns();
  };

  const [locationId, hasMultipleLocationIds] = locationIds || [];
  const [turbineId, hasMultipleTurbineIds] = turbineIds || [];

  // NOTE: Our disable state for Turbines and Blades makes an assumption that Turbines will always need a Location and Blades will always need a Turbine. If this changes we'll need to update this logic to take this into account.
  const [disableTurbines, setDisableTurbines] = useState(true);
  const [disableBlades, setDisableBlades] = useState(true);

  useEffect(() => {
    setDisableTurbines(locationId !== undefined ? false : true);
    setDisableBlades(turbineId !== undefined ? false : true);
  }, [locationId, turbineId]);

  return (
    <StyledControls block={block} width={width}>
      {visibleLocations && (
        <SelectControl block={block} small={small}>
          <LocationChooser
            disabled={Boolean(disabled || loading)}
            value={locationIds}
            onChange={handleLocationChange}
            mode={multipleLocations ? 'multiple' : undefined}
          />
        </SelectControl>
      )}

      {visibleTurbines && (
        <SelectControl block={block} small={small}>
          <TurbineChooser
            disabled={Boolean(!locationId || hasMultipleLocationIds || loading)}
            disableInput={Boolean(disabled || hasMultipleLocationIds || disableTurbines || loading)}
            locationId={locationId}
            value={turbineIds}
            onChange={handleTurbineChange}
            mode={multipleTurbines ? 'multiple' : undefined}
          />
        </SelectControl>
      )}

      {visibleBlades && (
        <SelectControl block={block} small={small}>
          <BladeChooser
            disabled={Boolean(!turbineId || hasMultipleTurbineIds || disableBlades || loading)}
            turbineId={turbineId}
            value={bladeIds}
            onChange={handleBladeChange}
            mode={multipleBlades ? 'multiple' : undefined}
          />
        </SelectControl>
      )}
    </StyledControls>
  );
}

export default AssetChooser;

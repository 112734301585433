import { Popconfirm, Divider } from 'antd';
import styled from 'styled-components';

import { sortBy, None, truncateTooltipper } from './helpers';
import { formatUnixTimestamp } from 'utils/format';

const ActionButton = styled.button`
  background: none;
  color: ${({ theme }) => theme.blue};
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
`;

export const name = {
  title: 'File Name',
  key: 'name',
  sorter: sortBy('name'),
  ellipsis: true,
  render: (record: any) => {
    const { url, name } = record || {};

    return [url, name].every(Boolean) ? (
      <a href={url} rel="noopener noreferrer" target="_blank" data-testid="attachment-name">
        {name}
      </a>
    ) : (
      <None />
    );
  },
};

// FIXME Jo - This is a placeholder until Category is implemented in the Attachments Service.
// This column will likely need to be updated once this field actually exists on Attachments.
export const category = {
  title: 'Category',
  key: 'category',
  sorter: sortBy('category'),
  render: (record: any) => {
    const { category } = record || {};
    const name = category?.name;
    return name ? truncateTooltipper(name, 40) : <None />;
  },
};

export const description = {
  title: 'Description',
  key: 'description',
  sorter: sortBy('description'),
  render: (record: any) => {
    const { description } = record || {};
    // em elements are styled in the same way as None. We want different content, so wrap it in an em.
    return description ? truncateTooltipper(description, 35) : <em>no description</em>;
  },
};

export const created = {
  title: 'Date Added',
  key: 'created',
  sorter: sortBy('created'),
  render: (record: any) => {
    const { created } = record || {};
    // created is a millisecond timestamp as a string, so we need to trim 3 characters off the end
    // so that it works with moment
    return created ? <>{formatUnixTimestamp(created.slice(0, -3))}</> : <None />;
  },
};

export const actions = ({
  onRemove,
  onModalOpen,
  isRemoveVisible = true,
}: {
  onRemove: (record: any) => void;
  onModalOpen: (record: any) => void;
  isRemoveVisible: boolean;
}) => ({
  title: 'Actions',
  width: '25%',
  render: (record: any) => {
    if (!record) return <None message="N/A" />;
    const { canDelete, canUpdate } = record?.permissions;

    return (
      <>
        <a href={`${record.url}`} download={record.name} target="_blank" rel="noopener noreferrer">
          Download
        </a>
        {onRemove && canDelete && isRemoveVisible && (
          <>
            <Divider type="vertical" />
            <Popconfirm title="Sure to remove?" onConfirm={() => onRemove(record)}>
              <ActionButton>Remove</ActionButton>
            </Popconfirm>
          </>
        )}
        {canUpdate && isRemoveVisible && (
          <>
            <Divider type="vertical" />
            <ActionButton data-testid="edit-attachment" onClick={() => onModalOpen(record)}>
              Edit
            </ActionButton>
          </>
        )}
      </>
    );
  },
});

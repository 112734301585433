import styled from 'styled-components';

export const ProgressContainer = styled.div`
  position: absolute;
  top: 2.5rem;
  right: 0;
  z-index: 100;

  > button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 101;

    button {
      border: none;
      box-shadow: none;
    }
  }
`;

export const ProgressElement = styled.div`
  margin: 1rem;
  padding: 1rem;
  background: white;
  box-shadow: 2px 2px 5px 0px ${({ theme }) => theme.gray};
  line-height: 1rem;
  position: relative;
  width: 250px;
`;

export const ActionButtons = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 101;

  button {
    border: none;
    box-shadow: none;
  }
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
`;

export const Summary = styled.p`
  flex: 0 0 auto;
  margin: 0 0.5rem 0 0;
`;

import { Progress } from 'antd5';
import { Popover } from 'antd'; // Intentionally left on antd v4 for style consistency
import { StyledTasksPopoverTable } from './Popovers.style';

import { None } from 'components/data/helpers';
import * as taskData from 'components/data/tasks/tasks';
import { STANDARD_COLUMN_SCROLL_Y, TABLE_SCROLL_WIDTH_SMALL } from 'utils/constants';
import { archivedRowClassName } from 'components/DataTable/utils';
import { AtlasGqlTask, AtlasGqlTaskStatus } from 'types/atlas-graphql';

import { useFeatures } from 'utils/features';

type TasksPopoverTableProps = {
  tasks: AtlasGqlTask[];
};

export const TasksPopoverTable: React.FunctionComponent<TasksPopoverTableProps> = ({ tasks }) => {
  const { WORK_ORDERS } = useFeatures().features;
  return tasks?.length ? (
    <Popover
      content={
        // @ts-ignore - It doesn't like some of the column definitions, I think
        // because we're reusing column defs that are used in our DataTables, while
        // this is a simple Table. However, the table is working as expected - AN 12/12/23
        <StyledTasksPopoverTable
          data-testid="tasks-popover-table"
          columns={[
            taskData.taskNumber,
            ...(WORK_ORDERS ? [taskData.atlasWorkOrder(15)] : []),
            taskData.clientAtlasType,
            taskData.status,
            taskData.dueDate,
            taskData.created,
          ]}
          dataSource={tasks}
          size="small"
          rowKey="id"
          pagination={false}
          scroll={STANDARD_COLUMN_SCROLL_Y}
          style={{ width: TABLE_SCROLL_WIDTH_SMALL }}
          rowClassName={archivedRowClassName}
        />
      }
    >
      <Progress
        data-testid="tasks-count"
        type="circle"
        percent={
          (tasks.filter(
            t =>
              t.status === AtlasGqlTaskStatus.Complete || t.status === AtlasGqlTaskStatus.InProgress
          ).length /
            tasks.length) *
          100
        }
        success={{
          percent:
            (tasks?.filter(t => t.status === AtlasGqlTaskStatus.Complete).length / tasks?.length) *
            100,
        }}
        format={() => <b>{tasks?.length}</b>}
        size={25}
      />
    </Popover>
  ) : (
    <None />
  );
};

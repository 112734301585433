import UAParser from 'ua-parser-js';

export function setTitle(organization: string | null) {
  document.title = `Horizon ${organization ? `· ${organization} ` : ''}· SkySpecs`;
}

export function isIE() {
  const parser = new UAParser();
  return parser.getBrowser().name === 'IE';
}

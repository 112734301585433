import { Form, FormInstance, Input } from 'antd5';
import { AttachmentCategoryChooser } from 'components/RecordAttachments/AttachmentCategoryChooser';
import { StyledItem } from './EditAttachmentForm.style';

interface EditAttachmentFormProps {
  form: FormInstance<any>;
  handleChange: any;
  categoryOnChange: any;
  defaultCategoryId: string | undefined;
  parentType: any;
  defaultDescription: string | undefined | null;
  disabled?: boolean;
}

export const EditAttachmentForm: React.FunctionComponent<EditAttachmentFormProps> = ({
  form,
  handleChange,
  categoryOnChange,
  defaultCategoryId,
  parentType,
  defaultDescription,
  disabled = false,
}) => {
  return (
    <Form
      form={form}
      layout="vertical"
      onChange={handleChange}
      // Need to specify that the form shouldn't preserve its state, as this doesn't get cleared out even if the modal destroys itself on close.
      preserve={false}
    >
      <StyledItem label="Category" name="category">
        <AttachmentCategoryChooser
          onChange={categoryOnChange}
          type={parentType}
          defaultCategoryId={defaultCategoryId}
          disabled={disabled}
        />
      </StyledItem>
      <StyledItem label="Description" name="description" initialValue={defaultDescription}>
        <Input
          data-testid="edit-attachment-description"
          placeholder="No description"
          allowClear={true}
          disabled={disabled}
        />
      </StyledItem>
    </Form>
  );
};

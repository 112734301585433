import { useCallback } from 'react';
import { UndoOutlined } from '@ant-design/icons';
import { Button } from 'components/ui';

export const RotateControl = ({ onChange, disabled, value = false }) => {
  const handleClick = useCallback(() => {
    onChange({ rotate: !value });
  }, [value, onChange]);

  return (
    <Button
      _version={4}
      id="ol-control-rotate"
      data-testid="ol-control-rotate"
      title="Rotate"
      type={value ? 'primary' : 'default'}
      onClick={handleClick}
      disabled={disabled}
    >
      <UndoOutlined />
    </Button>
  );
};

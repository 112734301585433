import { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import { getBounds } from 'utils/geo';

import StyledMap from 'components/Map';
import { PointsList, PointsLayer } from './';

/**
 * -- FIXME ---
 * Currently we need to disable clustering during testing. Updating react-leaflet and plugins
 * should resolve this.
 * https://github.com/yuzhva/react-leaflet-markercluster/issues/85
 */

const { NODE_ENV } = process.env;

export const TurbinePointMap = ({
  items,
  enableClustering,
  getIcon,
  icon,
  iconProps,
  loading,
  height = '35rem',
  spiderfy = false,
  satelliteView = false,
  mapHeight,
  sideList = false,
  setTurbineToUpdate,
  isDraggable,
}) => {
  const [selectedTurbine, setSelectedTurbine] = useState();
  /*
    Using a ref to access the map instance is no longer possible
    Saving a copy of the map instance to state instead
    https://github.com/PaulLeCam/react-leaflet/issues/841
  */
  const [mapRef, setMapRef] = useState();
  const [mapRefReady, setMapRefReady] = useState(false);
  const [isInitial, setIsInitial] = useState(false);
  const [hasDraggingStarted, setHasDraggingStarted] = useState(false);

  const getPosition = x => x.position;

  const getMarkerBounds = layers => {
    if (!layers.length) return undefined;

    const bounds = getBounds(layers.map(getPosition)).pad(0.1);

    const { _northEast, _southWest } = bounds;

    return [
      [_northEast.lat, _northEast.lng],
      [_southWest.lat, _southWest.lng],
    ];
  };

  const MAX_ZOOM_LEVEL = 18;
  const SELECTED_TURBINE_INITIAL = 0;

  function handleItemClick(item, index) {
    const { lat, lng } = item?.position;
    setSelectedTurbine(index);
    // use the map reference to call the flyTo method to send the
    // user to the item they clicked on in the left nav.
    mapRef.flyTo({ lat, lng }, MAX_ZOOM_LEVEL);
  }

  // returns an array of array pairs. used by react-leaflet to set the bounds of the map
  // example return looks like [[80, 80],[90,90]]
  function getSelectedItemBounds(selectedTurbineIndex) {
    if (selectedTurbine >= 0) {
      const selectedItemArray = items.filter((_i, index) => {
        return index === selectedTurbineIndex;
      });

      return getMarkerBounds(selectedItemArray);
    }
  }

  const listColSpan = sideList ? 4 : 0;
  const mapColSpan = sideList ? 20 : 24;

  const isInitialRenderInPreview = sideList && mapRefReady && !hasDraggingStarted;
  const readyToFitInitialBounds =
    sideList && selectedTurbine === SELECTED_TURBINE_INITIAL && isInitial;

  /*  
      this should only ever be called once, to set the initially selected turbine
      and then to mark that this is the initial logic for setup. only for create
      asset preview map.
   */
  useEffect(() => {
    if (isInitialRenderInPreview) {
      setSelectedTurbine(SELECTED_TURBINE_INITIAL);
      setIsInitial(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, mapRefReady, sideList, items, hasDraggingStarted]);

  // this will be invoked if the initial useEffect has happened, we set the maps bounds
  // to the bounds of the first item in the array. only for create asset preview map.
  useEffect(() => {
    if (readyToFitInitialBounds) {
      const initialBounds = getSelectedItemBounds(SELECTED_TURBINE_INITIAL);
      mapRef.fitBounds(initialBounds);
      setIsInitial(false);
    }
  }, [isInitial, mapRef, sideList, selectedTurbine]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card bodyStyle={{ height, padding: 0 }} loading={loading} style={{ width: '100%' }}>
      <Row>
        <Col span={listColSpan}>
          {sideList && (
            <PointsList
              mapHeight={mapHeight}
              data={items}
              onItemClick={handleItemClick}
              selectedTurbineIndex={selectedTurbine}
            ></PointsList>
          )}
        </Col>
        <Col span={mapColSpan}>
          <StyledMap
            mapRef={mapRef}
            setMapRef={setMapRef}
            mapRefReady={mapRefReady}
            setMapRefReady={setMapRefReady}
            // If selectedTurbine is set, fit bounds to map center to zoom to selected item
            bounds={
              selectedTurbine >= 0 ? getSelectedItemBounds(selectedTurbine) : getMarkerBounds(items)
            }
            enableClustering={NODE_ENV === 'test' ? undefined : enableClustering}
            spiderfy={spiderfy}
            satelliteView={satelliteView}
            height={height}
            sideList={sideList}
            layersControl={true}
          >
            <PointsLayer
              mapRef={mapRef}
              items={items}
              getIcon={getIcon}
              icon={icon}
              iconProps={iconProps}
              selectedTurbineIndex={selectedTurbine}
              setSelectedTurbine={setSelectedTurbine}
              setTurbineToUpdate={setTurbineToUpdate}
              isDraggable={isDraggable}
              setHasDraggingStarted={setHasDraggingStarted}
            />
            <PointsLayer
              mapRef={mapRef}
              items={items}
              getIcon={getIcon}
              icon={icon}
              iconProps={iconProps}
              selectedTurbineIndex={selectedTurbine}
              setSelectedTurbine={setSelectedTurbine}
              setTurbineToUpdate={setTurbineToUpdate}
              isDraggable={isDraggable}
              setHasDraggingStarted={setHasDraggingStarted}
              isLabelLayer={true}
            />
          </StyledMap>
        </Col>
      </Row>
    </Card>
  );
};

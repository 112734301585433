import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { useUploadContext } from 'components/FileUploader';

/**
 * This component controls the router's refresh behavior.
 * Since it depends on router hooks, this logic must happen
 * in a child component of the router, rather than in the
 * router wrapper itself.
 *
 * Changing forceRefresh in state while the router is
 * mounted is not sufficient; it must be remounted, so this
 * is a two step process. When a new version of the app is available:
 *  1. The router is remounted on the user's next naviagtion.
 *     This is done during navigation to prevent it from affecting
 *     the user's workflow (by remounting children, etc.).
 *  2. Once the router is remounted, any navigation will cause a
 *     full refresh, completing the app update.
 * - RL, 4/22/2022
 */

const ONE_DAY = 24 * 60 * 60 * 1000;

export const RefreshController: React.FunctionComponent<{
  shouldForceRefresh: boolean;
  routerKey: number;
  setRouterKey: (key: number) => void;
}> = ({ shouldForceRefresh, routerKey, setRouterKey }) => {
  const { uploading } = useUploadContext();
  const { block } = useHistory();

  /**
   * On the first naviagation after a new version is available,
   * the router's key prop is updated, causing it to remount and
   * enabling refresh on the following navigation.
   */
  useEffect(() => {
    let unblock: () => void;
    if (shouldForceRefresh && routerKey === 0 && !uploading) {
      unblock = block(() => {
        setRouterKey(1);
        unblock();
      });
    }
  }, [block, shouldForceRefresh, routerKey, setRouterKey, uploading]);

  useEffect(() => {
    let timeoutId: number | undefined;

    if (shouldForceRefresh && !timeoutId) {
      /**
       * If the user does not navigate to a new page for 24 hours,
       * prompt refresh
       */
      timeoutId = Number(
        setTimeout(() => {
          const hide = message.info(
            <>
              A new version of the application is available!{' '}
              <a onClick={() => window.location.reload()}>Please refresh</a>
              <CloseCircleFilled
                style={{ margin: '0 0 0 .5em', color: 'inherit' }}
                onClick={() => hide()}
              />
            </>,
            0
          );
        }, ONE_DAY)
      );
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [shouldForceRefresh]);

  return <></>;
};

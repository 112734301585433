import styled from 'styled-components';

export const Controls = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(20%, 200px);
  grid-gap: 1rem;
  pointer-events: none;
`;

export const Panel = styled.div`
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
`;

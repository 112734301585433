import { Empty } from 'antd';
import { isEmpty, omitBy } from 'lodash';
import { Button } from 'components/ui';

type EmptyStateProps = {
  loading?: boolean;
  emptyText?: string;
  filteredInfo?: any;
  onClearSortAndFilter?: () => void;
};

export const EmptyState: React.FunctionComponent<EmptyStateProps> = ({
  loading = false,
  emptyText: emptyTextProp = undefined,
  filteredInfo = undefined,
  onClearSortAndFilter = () => {},
}) => {
  const emptyText = !isEmpty(omitBy(filteredInfo, isEmpty)) ? (
    <Button _version={4} type="primary" onClick={onClearSortAndFilter}>
      Clear filters
    </Button>
  ) : (
    emptyTextProp
  );

  return (
    <Empty description={loading ? 'Loading...' : undefined} image={Empty.PRESENTED_IMAGE_SIMPLE}>
      {loading || emptyText}
    </Empty>
  );
};

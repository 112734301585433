import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { StatusTag } from 'components/StatusTag';

const LinkLabel = styled.span`
  font-size: 0.9rem;
  min-width: 4rem;
  margin-right: 0.5rem;
`;

const LinkTitle = styled.span`
  font-size: 0.9rem;
`;

const StaticLabel = styled.span`
  font-size: 0.9rem;
  margin: 0;
`;

const RowContainer = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

export default function FleetMapDetail({ turbine, location, previewMode }) {
  const [lat, lng] = turbine?.latLng ?? [];
  return (
    <Fragment>
      {location ? (
        <RowContainer>
          <LinkLabel>Site:</LinkLabel>
          <Link to={`/sites/${location.id}`}>
            <LinkTitle>{location.name}</LinkTitle>
          </Link>
        </RowContainer>
      ) : null}
      {turbine ? (
        <Fragment>
          <RowContainer>
            <LinkLabel>Turbine:</LinkLabel>
            {previewMode ? (
              <StaticLabel>{turbine.name}</StaticLabel>
            ) : (
              <Link to={`/turbines/${turbine.id}`}>
                <LinkTitle>{turbine.name}</LinkTitle>
              </Link>
            )}
          </RowContainer>
          <RowContainer>
            <LinkLabel />
            {turbine.status ? <StatusTag value={turbine.status} /> : null}
          </RowContainer>
          {previewMode && (
            <Fragment>
              <RowContainer>
                <LinkLabel>Lat:</LinkLabel>
                <StaticLabel>{lat}</StaticLabel>
              </RowContainer>
              <RowContainer>
                <LinkLabel>Lng:</LinkLabel>
                <StaticLabel>{lng}</StaticLabel>
              </RowContainer>
            </Fragment>
          )}
        </Fragment>
      ) : null}
    </Fragment>
  );
}

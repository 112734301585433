import { Popover } from 'antd'; // Intentionally left on antd v4 for style consistency
import { StyledPopoverTable } from './Popovers.style';

import { None } from 'components/data/helpers';
import { STANDARD_COLUMN_SCROLL_Y, TABLE_SCROLL_WIDTH_SMALL } from 'utils/constants';
import { AtlasGqlInspection } from 'types/atlas-graphql';
import * as inspectionData from 'components/data/inspections';

type InspectionsPopoverTableProps = {
  inspections: AtlasGqlInspection[];
};

export const InspectionsPopoverTable: React.FunctionComponent<InspectionsPopoverTableProps> = ({
  inspections,
}) => {
  return inspections.length ? (
    <Popover
      content={
        <StyledPopoverTable
          data-testid="inspections-popover-table"
          columns={[
            { ...inspectionData.date, defaultSortOrder: 'descend' },
            inspectionData.type,
            inspectionData.vendor,
          ]}
          dataSource={inspections}
          size="small"
          rowKey="id"
          pagination={false}
          scroll={STANDARD_COLUMN_SCROLL_Y}
          style={{ width: TABLE_SCROLL_WIDTH_SMALL }}
        />
      }
    >
      {inspections.length}
    </Popover>
  ) : (
    <None />
  );
};

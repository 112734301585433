import { createContext, useCallback, useContext, useEffect } from 'react';
import { DocumentNode, NetworkStatus } from '@apollo/client';

import { TTableColumnDef } from 'horizon/types/TableColumnDef';

import { EmptyState } from './EmptyState';
import { useServerData } from './useServerData';
import { filtersHaveChanged, useServerDataTableContextState } from './utils';
import { DataTableProps } from './index';
import { TQueryFilter, TServerDataTableContext } from './types';
import { useUniqueId } from 'utils/hooks';
import { archivedRowClassName } from './utils';
import { StyledServerTable } from './DataTable.style';

/**
 * "The danger is unleashed only if you substantially disturb this place physically.
 * This place is best shunned and left uninhabited."
 */
export const ServerDataTableContext = createContext<{
  context: TServerDataTableContext;
  setContext: React.Dispatch<React.SetStateAction<TServerDataTableContext>>;
}>({
  context: { tableFilters: [] },
  setContext: _context => {},
});

export function useServerDataTableContext() {
  return useContext(ServerDataTableContext);
}

interface ServerDataTableProps
  extends Omit<
    DataTableProps,
    | 'columns'
    | 'dataQuery'
    | 'hasServerDataTableContextProviderParent'
    | 'renderInServerContext'
    | 'title'
  > {
  columns?: TTableColumnDef[];
  dataQuery: DocumentNode;
  onTableChange: (pagination: any, filters: any, sorter: any, extra: any) => void;
}

// in order to turn our `refetch` into a async operation, we are keeping track
// of the queries loading and using an interval to resolve the promise.
// @see `refetchAwait` below
const loadingFlags: Record<string, boolean> = {};

/**
 * What is here was dangerous and repulsive to us. This message is a warning about danger
 * @param param0
 * @returns
 */
export const ServerDataTable: React.FunctionComponent<ServerDataTableProps> = ({
  columns,
  transform = undefined,
  dataQuery,
  onRow,
  onDataQueryChange,
  onTableChange,
  routeProps = {},
  rowKey,
  loading: externalLoading,
  setLoading,
  setTotalCount,
  ...props
}) => {
  const uniqueId = useUniqueId();
  const { context, setContext } = useServerDataTableContext();

  const {
    client,
    networkStatus,
    data = [],
    refetch,
    filterBy,
  } = useServerData({
    query: dataQuery,
    setLoading,
    setTotalCount,
    transform,
    columns,
    routeProps,
    onDataQueryChange,
    rowSelection: props.rowSelection,
  });

  const loading = [
    NetworkStatus.loading,
    NetworkStatus.setVariables,
    NetworkStatus.refetch,
  ].includes(networkStatus);

  // just in case there are multiple ServerDataTable nodes, we don't want to
  // "cross the streams" with our loading flags, so we use a unique id to
  // nicely separate them
  loadingFlags[uniqueId] = loading;

  const refetchAwait = useCallback(
    (variables?: Partial<any>) => {
      // limit our interval to 1000 iterations (at 30ms)
      let count = 1000;

      return new Promise<void>(resolve => {
        // by using an interval here and polling the state of the loading flag,
        // we can then resolve the promise once the loading is no longer `true`.
        // note that the context of the loading flag(s) are within this file, not
        // component, so there's only 1 instance of the loading flags object
        const int = setInterval(() => {
          if (count-- <= 0 || !loadingFlags[uniqueId]) {
            clearInterval(int);
            resolve();
          }
        }, 30);

        refetch(variables);
      });
    },
    [refetch, uniqueId]
  );

  useEffect(() => {
    if (context && filterBy) {
      if (filtersHaveChanged(context.tableFilters ?? [], filterBy as TQueryFilter[])) {
        setContext({ ...context, tableFilters: filterBy });
      }
    }
  }, [filterBy]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (setLoading) {
      setLoading(loading);
    }
  }, [loading, setLoading]);

  // set loading state to be dependent on dataQuery loading or externalLoading
  const tableLoading = loading || Boolean(externalLoading);

  useEffect(() => {
    setContext(context => ({ ...context, client, loading: tableLoading, refetch, refetchAwait }));
  }, [client, refetch, refetchAwait, setContext, tableLoading]);

  // for most implementations, we want to remove null values.
  // Implementations that do not want this can replace a null value with empty object.
  const dataSource = !tableLoading ? (data || []).filter(Boolean) : [];

  return (
    <StyledServerTable
      {...props}
      loading={tableLoading}
      locale={{ emptyText: <EmptyState {...props} loading={tableLoading} /> }}
      columns={columns}
      dataSource={dataSource}
      bordered={false}
      rowKey={rowKey}
      onRow={onRow}
      onChange={(...args) => onTableChange(...args)}
      rowClassName={archivedRowClassName}
    />
  );
};

interface ServerDataTableContextProviderProps {
  children: React.ReactNode;
  defaultState?: Partial<TServerDataTableContext>;
}

export function ServerDataTableContextProvider({
  children,
  defaultState,
}: ServerDataTableContextProviderProps) {
  const { context, setContext } = useServerDataTableContextState(defaultState);

  return (
    <ServerDataTableContext.Provider value={{ context, setContext }}>
      {children}
    </ServerDataTableContext.Provider>
  );
}

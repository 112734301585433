import { useState } from 'react';
import { UsergroupAddOutlined, PlusOutlined } from '@ant-design/icons';
import { Select, Divider, Modal, ConfigProvider } from 'antd';
import GET_COLLABORATOR_USERS from './graphql/queries/GetCollaboratorUsers.gql';
import { useApolloContext, useQuery } from 'utils/apollo';
import { AddCollabContainer, CollaboratorSelect } from './CollaboratorChooser.style';

export const CollaboratorChooser = ({ value, defaultValue, ...props }) => {
  const { papiClient } = useApolloContext();
  const { data, loading } = useQuery(GET_COLLABORATOR_USERS, {
    client: papiClient,
  });

  const collaborators = data?.users ?? [];

  const [addModalVisible, setAddModalVisible] = useState(false);
  const valueProps = value
    ? {
        value: value.map(user => (user.email ? user.email : user)),
      }
    : {
        defaultValue,
      };

  const handleAddClick = e => {
    // small wait to let the antd dropdown animations get outta the way
    setTimeout(() => setAddModalVisible(true), 100);
  };

  const handleAddClose = () => {
    setAddModalVisible(false);
  };

  const emptyRender = () => (
    <div>
      <UsergroupAddOutlined style={{ marginRight: '4px' }} />
      <span>No current collaborators</span>
    </div>
  );

  // have to use onMouseDown rather than onClick cause antd
  const dropdownRender = dropdown => (
    <div id="collaborator-chooser-dropdown">
      <div onMouseDown={handleAddClick}>
        <AddCollabContainer>
          <PlusOutlined style={{ marginRight: '4px' }} />
          <span>Add Collaborator</span>
        </AddCollabContainer>
      </div>
      <Divider style={{ margin: '4px 0' }} />
      {dropdown}
    </div>
  );

  return (
    <ConfigProvider renderEmpty={emptyRender}>
      <Modal
        title={'Add a Collaborator'}
        visible={addModalVisible}
        onCancel={handleAddClose}
        footer={null}
      >
        Please contact <a href="mailto:support@skyspecs.com">support@skyspecs.com</a> to link a new
        collaborator to your account. Once a collaborator relationship has been established, you
        will be able to grant that collaborator account access to whichever Work Orders you'd like.
      </Modal>
      <CollaboratorSelect
        mode="multiple"
        dropdownMatchSelectWidth={false}
        placeholder="Add Collaborators..."
        dropdownRender={dropdownRender}
        loading={loading}
        {...valueProps}
        {...props}
      >
        {collaborators.map(({ email: collab, customer: { name: companyName } }) => (
          <Select.Option key={collab} value={collab}>
            {`${collab} | ${companyName}`}
          </Select.Option>
        ))}
      </CollaboratorSelect>
    </ConfigProvider>
  );
};

import styled from 'styled-components';
import COLORS from 'utils/color/definitions';
import { None } from 'components/data/helpers/helpers';

// Filter Dropdown Components for range sliders and dates
export const CustomFilterPopover = styled.div<{ width?: string }>`
  min-width: 16rem;
  max-width: 32rem;
  ${({ width }) => (width ? `width: ${width}` : '')}
`;

export const CustomFilterContainer = styled.div`
  padding: 0.5rem;

  > .ant-radio-group {
    display: flex;
    width: 100%;

    > .ant-radio-button-wrapper {
      display: flex;
      flex-grow: 1;
      justify-content: center;
    }
  }
`;

export const NoneCheckboxContainer = styled.div`
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
`;

export const SecondaryFilterLabel = styled.p`
  color: ${COLORS.SECONDARY_TEXT_COLOR};
  margin-bottom: 0.25rem;
`;

export const InputNumberContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > div:nth-of-type(1) {
    margin-right: '1rem';
  }
  > div:nth-of-type(1) {
    margin-left: '1rem';
  }
`;

export const InputNumberLabel = styled.p`
  margin-bottom: 0.25rem;
`;

// SelectAll stylings
export const Container = styled.div`
  max-height: 264px;
  overflow-x: scroll;
`;

export const ListItem = styled.div<{ checked?: boolean }>`
  display: flex;
  padding: 5px 12px;
  background-color: ${({ checked }) => checked && '#f0fdff'};
  color: ${({ checked }) => checked && COLORS.TEAL};

  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  > button {
    padding-left: 0;
  }
`;

export const Text = styled.div`
  padding-left: 5px;
`;

export const BoldText = styled.div`
  padding-left: 5px;
  font-weight: bold;
`;

export const NoneText = styled(None)`
  padding-left: 5px;
`;

// Select styling
export const SelectContainer = styled.div`
  display: flex;
  > .ant-select {
    width: 100%;
  }
`;

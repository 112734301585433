import { useCallback } from 'react';
import { UndoOutlined } from '@ant-design/icons';
import { StyledButton } from '../ImageViewer.style';

type RotateProps = {
  onChange: (data: any) => void;
  value?: boolean;
  disabled?: boolean;
};

export const Rotate: React.FunctionComponent<RotateProps> = ({
  onChange,
  value = false,
  disabled = false,
}) => {
  const handleClick = useCallback(() => {
    onChange({ rotate: !value });
  }, [value, onChange]);

  return (
    <StyledButton
      data-testid="image-controls-rotate"
      title="Rotate"
      type={value ? 'primary' : 'default'}
      onClick={handleClick}
      disabled={disabled}
    >
      <UndoOutlined />
    </StyledButton>
  );
};

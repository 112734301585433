import { Fragment } from 'react';
import { Tag } from 'antd';
import { withAuxiliaryComponents } from './withAuxiliaryComponents';
import { None, moreTooltipper } from 'components/data/helpers';

export const AuxiliaryComponentList = ({ auxiliaryComponents = [], compact }) => {
  if (!auxiliaryComponents.length) {
    return <None />;
  }

  const pills = auxiliaryComponents.map(aux => <Tag key={aux.id}>{aux.name}</Tag>);

  if (compact) {
    return moreTooltipper(auxiliaryComponents.map(aux => aux.name));
  }
  return <Fragment>{pills}</Fragment>;
};

export default withAuxiliaryComponents()(AuxiliaryComponentList);

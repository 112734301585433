import styled from 'styled-components';
import { Carousel } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import COLORS from 'utils/color/definitions';

export const StyledDiv = styled.div`
  height: 100%;
`;

export const StyledCarouselWrapper = styled.div<{ innerHeight?: string }>`
  position: relative;
  height: 100%;

  .ant-carousel {
    height: ${({ innerHeight }) => innerHeight ?? 'unset'};
    background-color: ${COLORS.GRAY};
  }
`;

export const StyledCarousel = styled(Carousel)`
  height: 100%;

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div,
  .slick-slide > div > div {
    height: 100%;
  }

  .slick-dots {
    li {
      height: 5px;
      margin-top: 1em;
      margin-bottom: 1em;
    }
    li button {
      height: 5px;
      opacity: 0.4;
      background: ${COLORS.TEAL} !important;
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }

  .slick-dots-bottom {
    bottom: 0;
    margin-bottom: 0;
  }
`;

export const StyledQuestionCircleTwoTone = styled(QuestionCircleTwoTone)<{
  $isInCarousel: boolean;
}>`
  cursor: pointer;
  position: absolute;
  bottom: ${({ $isInCarousel }) => ($isInCarousel ? 'calc(24px + 1em)' : '1em')};
  right: 1em;
`;

export const EmptyImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

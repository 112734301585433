import { Progress } from 'antd';
import {
  ProgressWrapper,
  Summary,
} from 'horizon/components/GlobalProgressIndicator/GenericProgress/GenericProgressToast.style';
import { useUploadContext } from 'components/FileUploader';

export function FileUploadProgressSummary() {
  const { validFiles } = useUploadContext();
  const validFilesCount = validFiles.length;
  let completedFilesCount = 0;

  const totalCompletedPercentage = validFiles.reduce((sum, f) => {
    const filePercentComplete = f.status === 'completed' ? 100 : f.percent;
    if (filePercentComplete === 100) {
      completedFilesCount += 1;
    }
    return sum + filePercentComplete;
  }, 0);

  const totalPercentComplete = Math.round(
    (totalCompletedPercentage / (validFilesCount * 100)) * 100
  );

  return (
    <ProgressWrapper>
      <Summary>
        {completedFilesCount} / {validFilesCount}
      </Summary>
      <Progress percent={totalPercentComplete} showInfo={false} status="active" />
    </ProgressWrapper>
  );
}

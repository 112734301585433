import {
  AtlasGqlWorkContainerFieldType,
  AtlasGqlWorkContainerTypeOption,
} from './../../../../types/atlas-graphql';
export const forOrganizationField = {
  name: 'For Organization',
  type: AtlasGqlWorkContainerFieldType.Select,
  configured: [
    {
      //@ts-ignore - TS expects more fields here, but we really do only need the type
      associatedType: {
        type: AtlasGqlWorkContainerTypeOption.InspectionTask,
      },
      // We want this field to be last, essentially.
      displayOrder: 1000,
    },
  ],
};

import COLORS from 'utils/color/definitions';

export const DEFAULT_OPACITY = 0.6;
export const MORE_OPACITY = 0.8;
export const LESS_OPACITY = 0.2;

/**
 * Set of svg components for shapes that can be used as points when
 * plotting damages. Each takes x/y coordinates and a color as props
 */
export type PointProps = {
  x: number;
  y: number;
  color: string;
  opacity: number;
};
export const Circle: React.FunctionComponent<PointProps> = ({ x, y, color, opacity }) => (
  <circle cx={x} cy={y} r="2.5" stroke={color} strokeOpacity={opacity} />
);
export const Square: React.FunctionComponent<PointProps> = ({ x, y, color, opacity }) => (
  <rect x={x - 2.5} y={y - 2.5} height="5" width="5" stroke={color} strokeOpacity={opacity} />
);
export const Diamond: React.FunctionComponent<PointProps> = ({ x, y, color, opacity }) => (
  <rect
    x={x - 2.5}
    y={y - 2.5}
    height="5"
    width="5"
    stroke={color}
    strokeOpacity={opacity}
    transform={`rotate(45,${x},${y})`}
  />
);
export const Triangle: React.FunctionComponent<PointProps> = ({ x, y, color, opacity }) => (
  <path
    d={['M', x, y - 2, 'l', 2.5, 5, 'h', -5, 'Z'].join(' ')}
    stroke={color}
    strokeOpacity={opacity}
  />
);
export const Cross: React.FunctionComponent<PointProps> = ({ x, y, color, opacity }) => (
  <path
    d={['M', x, y - 3.5, 'v', 7, 'v', -3.5, 'h', 3.5, 'h', -7].join(' ')}
    stroke={color}
    strokeOpacity={opacity}
    strokeWidth="2"
  />
);
export const YPoint: React.FunctionComponent<PointProps> = ({ x, y, color, opacity }) => (
  <path
    d={['M', x - 3, y - 3, 'l', 3, , 3, , 'l', 3, , -3, , 'l', -3, , 3, 'v', 3].join(' ')}
    stroke={color}
    strokeOpacity={opacity}
    strokeWidth="2"
  />
);

/**
 * Lists of colors and shapes to use for damage points. These two arrays should always
 * have different length, so we can cycle through them and get unique pairs
 */
export const POINT_COLORS = [
  COLORS.RED,
  COLORS.ORANGE,
  COLORS.LIGHT_BLUE,
  COLORS.SEV_LESS_COLOR,
  COLORS.GREEN,
  COLORS.PURPLE,
  COLORS.MAGENTA,
];
export const POINT_SHAPES = [Circle, Square, Cross, Diamond, YPoint, Triangle];

import { Select } from 'antd';
import { useEffect, useState } from 'react';

import { useGetArchiveDescendantsCountLazyQuery } from 'types/atlas-graphql';
import { StyledAlert } from './WorkContainerArchivedChooser.style';
import { EWorkContainerArchiveOptions } from 'horizon/routes/WorkOrders/Tasks/TaskList/types';
import { getFullArchiveWarningText } from 'horizon/routes/WorkOrders/ArchiveTaskButton/helpers';

interface IWorkContainerArchivedChooserProps {
  idsToUpdate: string[];
  onChange?: any;
  disabled?: boolean;
  // Keep below to continue working with our js files
  props?: any;
}

export const WorkContainerArchivedChooser: React.FunctionComponent<
  IWorkContainerArchivedChooserProps
> = ({ idsToUpdate, onChange, disabled, props }) => {
  const [archivedValue, setArchivedValue] = useState<string>('');
  const [archiveDescendantsCount, setArchiveDescendentsCount] = useState<any>({});
  const [warningMessage, setWarningMessage] = useState<string>('');

  const [getArchiveCount] = useGetArchiveDescendantsCountLazyQuery({
    onCompleted: data => {
      setArchiveDescendentsCount(data?.archiveDescendantsCount);
    },
    // This is needed to avoid reusing stale results after we bulk archive work containers
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    // check if the value is defined before calling the query, to prevent returning misleading data
    if (archivedValue) {
      getArchiveCount({
        variables: {
          input: {
            ids: idsToUpdate,
            archiveValue: archivedValue === EWorkContainerArchiveOptions.archived,
          },
        },
      });
    }
  }, [archivedValue, getArchiveCount, idsToUpdate]);

  useEffect(() => {
    if (archiveDescendantsCount) {
      const warningText = getFullArchiveWarningText(archiveDescendantsCount);
      if (!archivedValue || warningText === undefined) return;
      if (warningText === '') {
        setWarningMessage(warningText);
      } else {
        const formattedAction =
          archivedValue === EWorkContainerArchiveOptions.archived ? 'archive' : 'unarchive';
        const fullMessage = `This will ${formattedAction} ${warningText}`;
        setWarningMessage(fullMessage);
      }
    }
  }, [archiveDescendantsCount, archivedValue]);

  const handleOnChange = (selectedValue: string) => {
    setArchivedValue(selectedValue);
    onChange(selectedValue);
  };

  return (
    <>
      <Select defaultValue={archivedValue} onChange={handleOnChange} disabled={disabled} {...props}>
        <Select.Option
          key={EWorkContainerArchiveOptions.archived}
          value={EWorkContainerArchiveOptions.archived}
        >
          {'Archived'}
        </Select.Option>
        <Select.Option
          key={EWorkContainerArchiveOptions.unarchived}
          value={EWorkContainerArchiveOptions.unarchived}
        >
          {'Unarchived'}
        </Select.Option>
      </Select>
      {warningMessage !== '' && !disabled ? (
        <StyledAlert message={warningMessage} type="warning" showIcon />
      ) : (
        ''
      )}
    </>
  );
};

import { useEffect } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Modal, Alert } from 'antd';
import { Button, Tooltip } from 'components/ui';
import {
  DisabledDeleteIcon,
  StyledDeleteButtonLink,
  StyledWarning,
} from './DeleteConfirmation.style';
import { ModalProps } from 'antd/lib/modal';
import { ButtonType } from 'antd/lib/button';
import { useSafeState } from 'utils/hooks';

export interface DeleteConfirmationProps extends ModalProps {
  cancelBtnText?: string;
  deleteBtnCss?: React.CSSProperties;
  deleteBtnText?: string;
  deleteBtnType?: ButtonType;
  disabled?: boolean;
  errorInfo?: React.ReactNode;
  hideDeleteIcon?: boolean;
  hideText?: boolean;
  id: string;
  onDelete?: () => Promise<{ callback?: () => void } | undefined | void>;
  title: string;
  toolTipMessage?: string;
  type: 'button' | 'link';
  useToolTip?: boolean;
  warningContent: React.ReactNode;
  onModalVisibleChange?: (visible: boolean) => void;
}

export function DeleteConfirmation({
  cancelBtnText = 'Cancel',
  deleteBtnCss,
  deleteBtnText = 'Delete',
  deleteBtnType = 'primary',
  disabled = false,
  errorInfo,
  hideDeleteIcon = false,
  hideText = false,
  id,
  onCancel: _onCancel,
  onDelete = () => Promise.resolve({}),
  title,
  toolTipMessage = '',
  type,
  useToolTip = false,
  warningContent,
  onModalVisibleChange,
  ...modalProps
}: DeleteConfirmationProps) {
  const [visible, setVisible] = useSafeState(false);
  const [isDeleting, setIsDeleting] = useSafeState(false);
  const [error, setError] = useSafeState<React.ReactNode>('');

  useEffect(() => {
    if (onModalVisibleChange) {
      onModalVisibleChange(visible);
    }
  }, [visible]);

  const handleOnCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (_onCancel) {
      _onCancel(e);
    }
    if (!isDeleting) {
      setError(false);
      setVisible(false);
    }
  };

  const handleOnDelete = async () => {
    setIsDeleting(true);
    try {
      const result = await onDelete();
      const { callback } = result || {};
      setVisible(false);

      if (callback) {
        callback();
      }
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const deleteIcon = !hideDeleteIcon && <DeleteOutlined />;
  const buttonText = !hideText && deleteBtnText;

  function renderIcon() {
    if (deleteIcon && disabled) {
      return <DisabledDeleteIcon />;
    }

    if (deleteIcon && !disabled) {
      return deleteIcon;
    }

    return null;
  }

  const buttonFragment =
    type === 'button' ? (
      <Button
        _version={4}
        disabled={disabled}
        icon={deleteIcon}
        id={id || 'delete-button'}
        onClick={() => setVisible(true)}
        role="button"
        style={deleteBtnCss}
        title={deleteBtnText}
        type={deleteBtnType}
        danger={true}
        data-testid="task-delete-button"
      >
        {buttonText}
      </Button>
    ) : (
      <StyledDeleteButtonLink
        _version={4}
        disabled={disabled}
        id={id || 'delete-link'}
        onClick={() => setVisible(true)}
        style={deleteBtnCss}
        type="link"
      >
        {renderIcon()}
        <span>{buttonText}</span>
      </StyledDeleteButtonLink>
    );

  return (
    <>
      {useToolTip ? (
        <Tooltip placement="top" title={disabled && toolTipMessage}>
          {buttonFragment}
        </Tooltip>
      ) : (
        buttonFragment
      )}

      <Modal
        cancelButtonProps={{ id: 'delete-confirmation-modal-cancel' }}
        cancelText={cancelBtnText}
        confirmLoading={isDeleting}
        destroyOnClose={true}
        okButtonProps={{
          // @ts-ignore
          'data-testid': 'execute-delete-button',
          id: 'delete-confirmation-modal-ok',
        }}
        okText={deleteBtnText}
        okType="danger"
        onCancel={handleOnCancel}
        onOk={handleOnDelete}
        title={title}
        visible={visible}
        {...modalProps}
      >
        {error ? (
          <Alert type="error" message={error} description={errorInfo} />
        ) : (
          <StyledWarning>{warningContent}</StyledWarning>
        )}
      </Modal>
    </>
  );
}

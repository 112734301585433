import { useMemo } from 'react';
import { flatten } from 'lodash';

/**
 * Component for drawing a blade side shape (i.e. pressure side, suction side) given a x/y d3 scales
 * and a set of coordinates.
 */

type SideShapeProps = {
  xScale: d3.ScaleLinear<number, number, never>;
  yScale: d3.ScaleLinear<number, number, never>;
  points: [number, number][];
};

export const SideShape: React.FunctionComponent<SideShapeProps> = ({ xScale, yScale, points }) => {
  const xRange = useMemo(() => xScale.range(), [xScale]);
  const yRange = useMemo(() => yScale.range(), [yScale]);

  return (
    <path
      d={[
        'M',
        xRange[0],
        yRange[0],
        ...flatten(points.map(point => ['L', xScale(point[0]), yScale(point[1])])),
        'L',
        xScale(points[points.length - 1][0]),
        yRange[0],
        'L',
        xRange[0],
        yRange[0],
      ].join(' ')}
      fill="#F7F7F7"
      stroke="#0000001F"
    />
  );
};

import React, { useCallback } from 'react';
import { DatePicker as AntdDatePicker } from 'antd5';
import { PickerProps } from 'antd5/es/date-picker/generatePicker';
import moment, { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { forceMomentToUTC } from 'utils/date/forceMomentToUTC';

type DatePickerProps = PickerProps<Moment> & {
  format: string;
};

// Antd5 uses Day.js as its default instead of Moment, so we need to generate a
// date picker that uses Moment... until we decide to move to Day.js
// @see https://ant.design/docs/react/use-custom-date-library
const MomentDatePicker = AntdDatePicker.generatePicker<Moment>(momentGenerateConfig);

/***
 * DatePicker component that wraps the antd DatePicker component and forces the onChange prop to use a UTC date
 * to avoid weird timezone issues.
 *
 * Explanation:
 *
 * If there is not an initial value, the antd component will return a date in the local timezone however if there is and the format
 * does not include a timezone, it will return a date in UTC. So depending on the situation and timing, you can end up with picking
 * one date in the picker and then submitting a different date.
 *
 * ```typescript
 * const thing1 = moment('2023-08-10T00:00:00.000+02:00');
 * const thing2 = moment('2023-08-10T00:00:00.000+02:00', 'YYYY-MM-DD');
 * console.log(thing1.format('YYYY-MM-DD')); // 2023-08-09
 * console.log(thing2.format('YYYY-MM-DD')); // 2023-08-10
 * ```
 *
 * This component fixes that by always forcing the date to be in UTC, so the user
 * will always get the same date back that they picked.
 *
 * @see https://app.shortcut.com/skyspecs/story/65045/odd-datepicker-behavior
 *
 * @constructor
 */
export const UTCDatePicker: React.FunctionComponent<any> = ({
  onChange,
  format = 'YYYY-MM-DD',
  ...props
}: DatePickerProps) => {
  const handleChange = useCallback(
    (date: moment.Moment | null, str: string) => {
      if (moment.isMoment(date)) {
        const newDate = forceMomentToUTC(date);
        onChange?.(newDate, newDate.format(format));
        return;
      }

      onChange?.(date, str);
    },
    [onChange, format]
  );

  return <MomentDatePicker format={format} onChange={handleChange} {...props} />;
};

import { StorageKey, storageManager, getItem } from 'utils/storage';

import { TSortableColumn, TMultiSortColumn } from '../types';
import { TDataTableContext } from 'components/DataTable/types';
import { TDisplayColumnData } from 'components/data/types';

export const multiSorterStorage = ({ storageKey }: { storageKey?: StorageKey }) => {
  const customerId = getItem('customerId');
  if (storageKey && customerId) {
    const key = `${storageKey}:${customerId}`;
    return storageManager({ storageKey: key });
  }

  return storageManager({ storageKey: undefined });
};

const reduceMultiSortSelection = (
  columnDefs: TDisplayColumnData[],
  sortedColumns: TMultiSortColumn[]
) => {
  return sortedColumns.reduce((acc: TSortableColumn[], col: TMultiSortColumn) => {
    const { key, sort } = col;
    const columnDefinition = key && columnDefs.find(columnDef => columnDef.key === key);
    if (columnDefinition) {
      return [...acc, { column: columnDefinition, sortDirection: sort }];
    }
    return acc;
  }, []);
};

export const updateSort = (
  columnDefs: TDisplayColumnData[],
  sortedColumns: TMultiSortColumn[],
  updateMultiSortDef: TDataTableContext['updateMultiSortDef'],
  multiSorterStorageManager: any
) => {
  const sortDef = reduceMultiSortSelection(columnDefs, sortedColumns ?? []);
  if (sortDef.length) {
    updateMultiSortDef(sortDef);
    multiSorterStorageManager.set(sortedColumns);
  } else {
    updateMultiSortDef(undefined);
    multiSorterStorageManager.remove();
  }
};

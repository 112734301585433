export const UndoAreaSelectionIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="currentColor"
    style={{ transform: 'translateY(4px)' }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 2V8.87868L11 10.8787V2C11 1.44772 11.4477 1 12 1C12.5523 1 13 1.44772 13 2Z" />
    <path d="M13.1213 13L15.1213 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H13.1213Z" />
    <path d="M11 22V15.1213L13 13.1213V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22Z" />
    <path d="M10.8787 11L8.87868 13H2C1.44772 13 1 12.5523 1 12C1 11.4477 1.44772 11 2 11H10.8787Z" />
    <path d="M15 15H22L20 19H17L15 15Z" />
    <path d="M17 20H20V22H17V20Z" />
    <path d="M22.3536 2.35355C22.5488 2.15829 22.5488 1.84171 22.3536 1.64645C22.1583 1.45118 21.8417 1.45118 21.6464 1.64645L1.64645 21.6464C1.45118 21.8417 1.45118 22.1583 1.64645 22.3536C1.84171 22.5488 2.15829 22.5488 2.35355 22.3536L22.3536 2.35355Z" />
  </svg>
);

import {
  EArchivedFilterStateOption,
  IS_NULL,
  IS_NOT_NULL,
  ACTIVE_FILTER,
  ARCHIVED_FILTER,
} from './index';

import { TQueryFilter } from 'components/DataTable/types';

// Helper function used to handle updating the toggle state of the archive filter
export function onArchiveChange(
  newFilterValue: EArchivedFilterStateOption,
  archiveFilter: any,
  setArchiveFilter: React.Dispatch<React.SetStateAction<TQueryFilter | null>>,
  onClearSelection?: () => void
) {
  switch (newFilterValue) {
    case EArchivedFilterStateOption.ACTIVE: {
      if (archiveFilter?.operator === IS_NULL) {
        break;
      }
      onClearSelection?.();
      setArchiveFilter(ACTIVE_FILTER);
      break;
    }
    case EArchivedFilterStateOption.ARCHIVED: {
      if (archiveFilter?.operator === IS_NOT_NULL) {
        break;
      }
      onClearSelection?.();
      setArchiveFilter(ARCHIVED_FILTER);
      break;
    }
    // Default should only be BOTH case, but just for safety, have the fallback be all
    default: {
      if (!archiveFilter) {
        break;
      }
      onClearSelection?.();
      setArchiveFilter(null);
      break;
    }
  }
}

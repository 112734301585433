import styled from 'styled-components';
import { Skeleton, Tooltip, Col, Row } from 'antd';

export const Attribute = styled.div`
  display: flex;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Label = styled.span`
  font-weight: 700;
  font-size: 1rem;
  color: ${({ theme }) => theme.labelColor};
`;

export const HelpTooltip = styled(Tooltip)`
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  cursor: pointer;
`;

export const LabelContainer = styled.span`
  margin-right: 0.5rem;
`;

export const Description = styled.span`
  min-width: 0;
  color: ${({ theme }) => theme.descriptionColor};
  width: 100%;
  font-size: 1rem;
  white-space: normal;
`;

export const StyledSkeleton = styled(Skeleton)`
  display: inline-block;
  vertical-align: middle;
  width: unset;

  .ant-skeleton-content .ant-skeleton-title {
    margin: 0;
  }
`;

export const StyledColumn = styled(Col)`
  max-width: 100%;
`;

interface IStyledRowProps {
  type: string;
  justify: string;
}

export const StyledRow = styled(Row)<IStyledRowProps>`
  width: 100%;
`;

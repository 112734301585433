import { memo } from 'react';
// Utils
import { ErrorBoundary as BugSnagErrorBoundary } from 'utils/bugsnag';
import { useFeatures } from 'utils/features';
import GlobalFooter from 'components/GlobalFooter';

// Styles
import {
  StyledError,
  StyledImage,
  StyledTitle,
  StyledText,
  ErrorContainer,
  StyledButton,
  StyledCollapse,
  StyledErrorMessage,
  FallbackContainer,
} from './styles';

// Resources
import { FULL_TURBINE_IMAGE } from 'utils/constants';

// Libraries
import { Collapse, Divider, Alert } from 'antd';

const { Panel } = Collapse;

interface IErrorBoundaryContentProps {
  // Main error that prompted the boundary. The stacktrace from the error may be displayed
  error: Error;
  // Not currently used, but a required field for the BugSnagErrorBoundary
  info: React.ErrorInfo;
  // Callback provided by BugsnagErrorBoundary that attempts to re-render child components
  clearError: () => void;
}

const ErrorBoundaryContent: React.FunctionComponent<IErrorBoundaryContentProps> = ({
  error,
  info,
  clearError,
}) => {
  const BaseError = memo(() => (
    <StyledError>
      <StyledImage
        alt="turbine icon"
        src={FULL_TURBINE_IMAGE}
        // data-private used to hide images from LogRocket: https://docs.logrocket.com/reference/dom
        data-private="logrocket-hide"
      />
      <StyledTitle level={3}>Sorry, we are having problems with our system.</StyledTitle>
      <StyledText>
        We have been notified of the error you are experiencing and will review it promptly. Please
        refresh the page or contact support.
      </StyledText>
    </StyledError>
  ));

  try {
    // Jo 4/19/23 - On the off chance that the useFeatures hook itself throws an error,
    // we need to be able to catch it and still display a basic error message.
    // Linter gets upset because you really shouldn't be calling features conditionally within a try/catch like this.
    // eslint-disable-next-line
    const { INTERNAL_TESTER } = useFeatures().features;
    return (
      <ErrorContainer>
        <BaseError />
        {INTERNAL_TESTER && error && (
          <>
            <StyledButton _version={4} type="primary" onClick={clearError}>
              Reload
            </StyledButton>
            <Divider />
            <StyledCollapse ghost>
              <Panel header="More information" key="1">
                <Alert
                  message={<StyledErrorMessage>{error?.stack}</StyledErrorMessage>}
                  type="warning"
                />
              </Panel>
            </StyledCollapse>
          </>
        )}
      </ErrorContainer>
    );
  } catch (e) {
    return (
      <FallbackContainer>
        <ErrorContainer>
          <BaseError />
        </ErrorContainer>
        <GlobalFooter />
      </FallbackContainer>
    );
  }
};

interface IProps {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

export const ErrorBoundary: React.FunctionComponent<IProps> = ({ children }) => {
  return <BugSnagErrorBoundary FallbackComponent={ErrorBoundaryContent} children={children} />;
};

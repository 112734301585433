import { useCallback, useState } from 'react';
import { message } from 'antd';
import { isNull, isEqual } from 'lodash';

export function usePriority({ data, onSavePriority }) {
  const [isPrioritizing, setIsPrioritizing] = useState(false);
  const [prioritizedItems, setPrioritizedItems] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOpenPriority = useCallback(() => {
    setPrioritizedItems(data);
    setIsPrioritizing(true);
  }, [data]);

  const handleCancelPriority = useCallback(() => {
    setPrioritizedItems(null);
    setIsPrioritizing(false);
  }, []);

  const handleDragUpdate = useCallback(orderedItems => {
    setPrioritizedItems(orderedItems);
  }, []);

  const handleSavePriority = useCallback(async () => {
    setLoading(true);

    try {
      await onSavePriority(prioritizedItems);
    } catch (err) {
      message.error(err.message);
    } finally {
      setLoading(false);
      setIsPrioritizing(false);
      setPrioritizedItems(null);
    }
  }, [onSavePriority, prioritizedItems]);

  const allItemsHavePriority =
    prioritizedItems?.length &&
    data?.length &&
    prioritizedItems.map(i => i.priority).filter(Boolean).length === data.length;
  /**
   * Disable saving priority if:
   * - priority is loading
   * OR
   *   - prioritizedItems are NOT null
   *   AND
   *   - prioritizedItems equals the value of data
   *   AND
   *   - all prioritizedItems have a priority value already set
   */
  const disableSavePriority =
    loading ||
    (!isNull(prioritizedItems) && isEqual(prioritizedItems, data) && allItemsHavePriority);

  return {
    handleOpenPriority,
    handleCancelPriority,
    handleDragUpdate,
    handleSavePriority,
    isPrioritizing,
    loading,
    disableSavePriority,
    prioritizedItems,
  };
}

import { useState } from 'react';

import { User } from 'utils/types';
import { storageManager, getItem } from 'utils/storage';
import { TQueryFilter, TServerDataTableContext } from 'components/DataTable/types';
import { GetRowKey } from 'rc-table/lib/interface';
import { EWorkContainerArchiveOptions } from 'horizon/routes/WorkOrders/Tasks/TaskList/types';
import { max } from 'lodash';

type DraggableRow = {
  id: string;
  priority: number;
};

// FIXME: Certain tables include different sub-records depending on the active parent record.
// We need more sophisticated storage management to persist pagination correctly for each parent.
// For now we can correctly persist pagination for generic tables in the list. - AN 8/20/24
export const genericClientTableStorageIds = [
  'alerts-table',
  'campaign-list-table',
  'unplanned-tasks-list-table',
  'upcoming-task-list',
];

export const rowKey = ({ key, id }: { key?: string; id?: string }): React.Key => {
  return key ?? id ?? '';
};

export function filtersHaveChanged(oldFilters: TQueryFilter[], newFilters: TQueryFilter[]) {
  return JSON.stringify(oldFilters) !== JSON.stringify(newFilters);
}

export function useServerDataTableContextState(defaultState?: Partial<TServerDataTableContext>) {
  const [context, setContext] = useState<TServerDataTableContext>({
    loading: false,
    refetch: undefined,
    refetchAwait: undefined,
    tableFilters: [],
    ...defaultState,
  });

  return { context, setContext };
}

// Helper function to reprioritize rows as part of a dragula 'on' event
export function rePrioritizeRowsForDrop(data: any[] = [], tbody: Element, rowKey: any) {
  const dataCopy = [...data];

  // Find row keys
  const rowKeys = Array.from(tbody.children)
    .map((row: any) => row.getAttribute('data-row-key'))
    .filter(Boolean);

  // Sort dataCopy by rowKeys before anything else.
  dataCopy.sort(
    (a, b) =>
      rowKeys.indexOf((rowKey as GetRowKey<any>)(a)) -
      rowKeys.indexOf((rowKey as GetRowKey<any>)(b))
  );

  // Priorities of rows that are currently visible in order.
  // Used to help reorder later
  const visibleRowsPriorities = dataCopy
    .filter((row: DraggableRow) => rowKeys.includes(row.id))
    .map((row: DraggableRow) => row.priority)
    .sort();

  // Ids of invisible rows that are currently invisible
  const invisibleRowsIds = dataCopy
    .filter((row: DraggableRow) => !rowKeys.includes(row.id))
    .map((row: DraggableRow) => row.id);

  // If we don't have invisible rows we can return early with dataCopy
  if (invisibleRowsIds.length === 0) return dataCopy;

  // Rework priority numbers and then resort based on those priority numbers
  const sortedByFilterDataCopy = dataCopy
    .map(row => {
      // If it's invisible, return it with its same priority
      if (invisibleRowsIds.includes(row.id)) return { ...row, newPriority: row.priority };

      // If it's visible set a newPriority of the next row in the visibleRows array to sort on
      const shiftedRowPriority = visibleRowsPriorities.shift();
      return { ...row, newPriority: shiftedRowPriority };
    })
    .sort((a, b) => a.newPriority - b.newPriority);

  return sortedByFilterDataCopy;
}

export function rePrioritzeRowsForFilteredSorting(currentData: any[], prioritizedItems: any[]) {
  // Find the list of updated items:
  const updatedRows = prioritizedItems.map(row => {
    return { id: row.id, priority: row.priority };
  });
  const updatedRowIds = updatedRows.map(row => row.id);
  const updatedRowPriorities = updatedRows.map(row => row.priority);

  const sorted = currentData
    .map((row: any) => {
      // If current row is part of updated rows use the next priority
      if (updatedRowIds.includes(row.id)) {
        const shiftedRowPriority = updatedRowPriorities.shift();
        return { ...row, newPriority: shiftedRowPriority };
      }

      return { ...row, newPriority: row.priority };
    })
    .sort((a, b) => a.newPriority - b.newPriority);

  return sorted;
}

/**
 * Helper that is meant to handle reprioritizing items for data collections that include archived items that have been filtered out from the current table contents.
 * To use this helper correctly, pass in both the entire collection of archived and unarchived rows, as well as the prioritized collection of unarchived rows.
 * @param allData All data rows to be sorted, including both archived and unarchived rows
 * @param prioritizedData Data to be sorted that only included unarchived rows
 * @returns A sorted array containing all archived and unarchived rows, with the archived rows sorted at the end of the array
 */
export const reprioritzeRowsThatIncludeArchived = (allData: any[], prioritizedData: any[]) => {
  // Grab all of the archived rows from the entire collection of data
  const archived = allData.filter(d => d.archived === true);
  // Assumming the prioritized data contains no archived rows, map through it and set the newPriority value according to index
  let unarchivedPriority = 1;
  const updatedPriorityUnarchived = prioritizedData.map(row => {
    const updated = { ...row, newPriority: unarchivedPriority };
    unarchivedPriority++;
    return updated;
  });
  // Find the maximum newPriority value to use as a starting place for the archived priority count
  const maxUpdatedPriority = max(updatedPriorityUnarchived.map(row => row.newPriority));
  let archivedPriority = maxUpdatedPriority + 1;
  // Map through the archived rows, set newPriority
  const updatedPriorityArchived = archived.map((archivedRow: any) => {
    const newRow = { ...archivedRow, newPriority: archivedPriority };
    archivedPriority++;
    return newRow;
  });
  // Sort by newPriority
  const sorted = [...updatedPriorityUnarchived, ...updatedPriorityArchived].sort(
    (a, b) => a.newPriority - b.newPriority
  );
  return sorted;
};

export const archivedRowClassName = (record: any, index: number) => {
  const { archived, __typename } = record;
  if (!archived) return '';

  // Keep our styling to only Work Container/Tasks related tables:
  if (
    ['WorkCampaign', 'WorkOrder'].includes(__typename) ||
    record?.type?.__typename === 'WorkContainerType'
  ) {
    return archived ? EWorkContainerArchiveOptions.archived : '';
  }
  return '';
};

export const tableStorage = ({ storageKey, user }: { storageKey?: string; user?: User }) => {
  const customerId = getItem('customerId');
  if (storageKey && customerId) {
    const key = [storageKey, customerId, user?.id].filter(Boolean).join('::');
    return storageManager({ storageKey: key });
  }

  return storageManager({ storageKey: undefined });
};

export const getMultiFilterValueForKey = (
  filters: { key: string; value: any[] }[],
  key: string
) => {
  return filters.find(f => f.key === key)?.value;
};

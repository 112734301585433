import styled from 'styled-components';
import { Checkbox } from 'antd';

export const ColumnContainer = styled.div`
  max-height: 460px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormActionsRight = styled.div`
  button {
    margin-left: 0.5rem;
  }
`;

export const ToggleWrapper = styled.div`
  padding: 1rem;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-right: 0.5rem !important;
`;

import {
  AtlasGqlGlobalWorkContainerField,
  AtlasGqlTask,
  AtlasGqlWorkContainerFieldType,
  AtlasGqlWorkContainerTypeOption,
} from 'types/atlas-graphql';
import { sortBy, getFilterDropdown, None, getOnFilter } from '../../helpers';
import { camelCase, uniq } from 'lodash';
import { TASK_EDIT_DESCRIPTION } from 'utils/access-control/rules';
import { TDisplayColumnData, TEditColumnData } from 'components/data/types';
import { getEditInput, getDefaultValue } from 'utils/useHeaderFields/utils';
import { TASK_LEVEL_TYPES } from 'utils/constants';
import { standardFieldsToIgnore } from './constants';

import { TAtlasGqlTaskWithFieldValues } from '../types';

/**
 * Helper function intended to provide a string that can be used to index into `additionalFields` on a WorkContainer in order to resolve the value of that field for that Container.
 * Used for rendering, sorting and filtering client-side Work Container Tables
 * @param field The WorkContainerField whose index string will be generated
 * @param types An array of the Type of Work Container for which the index string will be generated. If passing in multiple types, the index string will only be properly generated for fields that are configured for all provided types.
 * @returns a String that can be used with lodash `get` to retrieve the value of this custom field from the additionalFields of a Work Container of Type type
 */
const getClientPropertyPathByName = (
  field: AtlasGqlGlobalWorkContainerField,
  types: AtlasGqlWorkContainerTypeOption[]
): string => {
  // Rather than indexing into an array with a number, we index into the fieldValues object using the field's id, if it is configured for this type.
  // If the field is configured for every type in types, then use the field id as an index
  const configuredTypes = field?.configured?.map(c => c?.associatedType.type) ?? [];
  const index: string = types.every(c => c && configuredTypes.includes(c)) ? field.id : '';
  const basePath = `fieldValues[${index}].value`;
  switch (field?.type) {
    case AtlasGqlWorkContainerFieldType.Date:
      return `${basePath}.dateValue`;
    case AtlasGqlWorkContainerFieldType.MultiSelect:
      return `${basePath}.multiSelectValue`;
    case AtlasGqlWorkContainerFieldType.Number:
      return `${basePath}.numberValue`;
    case AtlasGqlWorkContainerFieldType.Select:
      return `${basePath}.selectValue`;
    case AtlasGqlWorkContainerFieldType.TimeRange:
      return `${basePath}.timeRangeEndValue`;
    case AtlasGqlWorkContainerFieldType.Text:
      return `${basePath}.textValue`;
    default:
      return '';
  }
};

/**
 * Maps a custom field to a column definition
 * NOTE: Sorting and filtering is currently disabled on client-side columns
 * @param fields Array of Custom fields to map into columns
 * @param isClientSide Boolean indicating whether or not these columns will be used in a client-side DataTable
 * @param clientSideType The Work Container type to be displayed, if these columns will be used in a client-side DataTable
 * @returns An array of column definitions
 */
export const mapFieldsToColumns = (
  fields: AtlasGqlGlobalWorkContainerField[],
  isClientSide: boolean = false,
  clientSideTypes: AtlasGqlWorkContainerTypeOption[] = [],
  disableCheckbox: boolean = false
): TDisplayColumnData[] => {
  const formattedColumns: (TDisplayColumnData | undefined)[] = fields
    .filter(f => !(f.standard && standardFieldsToIgnore.includes(f.name)))
    .map(f => {
      // If no field is found, return now
      if (!f) return undefined;

      // Get the type of the field.
      // If it's one of the SELECT types, override it to be Text for the purposes of sorting and filtering
      const type = f.type;
      let filterType = type;
      if (
        type === AtlasGqlWorkContainerFieldType.Select ||
        type === AtlasGqlWorkContainerFieldType.MultiSelect
      ) {
        filterType = AtlasGqlWorkContainerFieldType.Text;
      }
      // FIXME: We currently don't store anything like min/max limits for 'number' fields, so default it to this range.
      // But we can support that in the future if we want to.
      const min = 0;
      const max = 1000;
      const range = [
        { id: min, label: min },
        { id: max, label: max },
      ];
      // Populate an array of options that is empty unless the field defines options
      let options: any[] = [];
      if (f.options) {
        // Format the options to the correct shape
        options = f.options.map(val => {
          return { text: val || <None />, value: val };
        });
      }
      // Get the defaultValue defined by the field. This is used in editing
      //@ts-ignore Jo - TS is mad about this type not exactly matching, but it does work
      const defaultValue = getDefaultValue({ value: f.value, type: f.type });
      const column: TEditColumnData = {
        key: camelCase(f.name),
        title: f.name,
        //
        onFilter: getOnFilter({
          // Key depends on whether it's client side or server side
          key:
            isClientSide && clientSideTypes
              ? getClientPropertyPathByName(f, clientSideTypes)
              : camelCase(f.name),
          // Type determines which filter option is rendered
          type: filterType.toLowerCase(),
          // Required field but not needed for this purpose
          filterAttr: undefined,
          // Allow fuzzy matching
          exactMatch: false,
          // Required field but not needed for this purpose
          values: undefined,
          // Don't include filtering for none, just for simplicity
          includeNoneOption: false,
        }),
        // This is the default filterDropdown, that will only be applied to text fields
        // All other types will be overriden down below
        filterDropdown: getFilterDropdown({
          type: 'default',
        }),
        sorter:
          isClientSide && clientSideTypes
            ? sortBy(getClientPropertyPathByName(f, clientSideTypes))
            : sortBy(camelCase(f.name)),
        get: (record: any) => {
          const field = record.additionalFields?.find((aField: any) => aField?.id === f?.id);
          getFieldValue(field);
        },
        disableCheckbox: disableCheckbox,
        // We can assume that if the user can edit the description, they can edit any custom field
        editRule: TASK_EDIT_DESCRIPTION,
        edit: getEditInput(f, { defaultValue, options: f?.options }),
        render: (record: any) => {
          const field = record.additionalFields?.find((aField: any) => aField?.id === f?.id);
          return getFieldValue(field);
        },
        // We should only add the filters and filterDropdown fields if the field type is NOT Text
        ...(type !== AtlasGqlWorkContainerFieldType.Text && {
          // Get the type and pass along the defaults that would be needed for any type option
          filterDropdown: getFilterDropdown({
            type: filterType.toLowerCase(),
            values: range,
          }),
          filters: options,
        }),
      };
      return column;
    });
  // @ts-ignore - TS really doesn't like this typing, but filtering on Boolean filters out the undefined objects
  // Which means the only objects left will be of type TDisplayColumnData
  const filteredFormatted: TDisplayColumnData[] = formattedColumns.filter(Boolean);
  return filteredFormatted;
};

/**
 * Returns the appropriate data value for a field of a given type
 * @param field The field to extract the data value from
 * @returns the data value of the field
 */
const getFieldValue = (field: any) => {
  if (field) {
    switch (field?.type) {
      case AtlasGqlWorkContainerFieldType.Date:
        return field.value.dateValue;
      case AtlasGqlWorkContainerFieldType.MultiSelect:
        return field.value.multiSelectValue;
      case AtlasGqlWorkContainerFieldType.Number:
        return field.value.numberValue;
      case AtlasGqlWorkContainerFieldType.Select:
        return field.value.selectValue;
      case AtlasGqlWorkContainerFieldType.TimeRange:
        return field.value.timeRangeEndValue;
      case AtlasGqlWorkContainerFieldType.Text:
        return field.value.textValue;
      default:
        return <None />;
    }
  }
  return <None />;
};

/**
 * Helper function that updates the contents of the `additionalFields` field on an array of Work Containers
 * The API returns `additionalFields` as a variable-length array containing just the fields for which the Container has a value defined
 * This function adds a new field on the workContainers called fieldValues of shape:
 * {
 *   field_uuid: field_value
 * }
 * This allows other client-side-table logic to correctly operate based on assumptions that a field's value exists and is mapped in the fieldValues object to the field's id
 * @param workContainers The array of Work Container items to reformat the `additionalFields` of
 * @param workContainerFields The array of Field defintions and their configurations that will be used to correctly map the data.
 * @param type The type of the Work Containers being updated
 * @returns Array of the provided Work Containers, each with the `additionalFields` field updated
 */
export const reformatClientSideWorkContainersByName = (
  workContainers: AtlasGqlTask[] = [],
  workContainerFields: AtlasGqlGlobalWorkContainerField[] = [],
  type: AtlasGqlWorkContainerTypeOption | undefined = undefined
): TAtlasGqlTaskWithFieldValues[] => {
  return workContainers.map(container => {
    // Create an empty object into which we will store fields mapped to their ids
    const newFields: any = {};
    const finalType = type ?? container.type.type;
    container?.additionalFields?.forEach(field => {
      // Try to pull the configuration from the passed-in work container field defintions. If no def found, fall back to the on-task field def.
      const fieldWithConfiguration = workContainerFields.find(wcf => wcf.id === field.id) ?? field;
      const displayOrder = fieldWithConfiguration.configured?.find(
        x => x?.associatedType.type === finalType
      )
        ? field.id
        : undefined;
      if (displayOrder) {
        // Insert found fields mapped to their proper id
        newFields[displayOrder] = field;
      }
    });
    return {
      ...container,
      fieldValues: newFields,
    };
  });
};

export const getTaskTypeFiltersFromTasks = (tasks: AtlasGqlTask[]): string[] => {
  if (tasks?.length > 0) {
    return uniq(tasks.map(t => t.type.type));
  }
  return TASK_LEVEL_TYPES;
};

import styled from 'styled-components';
import COLORS from 'utils/color/definitions';

export const StyledMessageDiv = styled.div`
  display: flex;

  > .anticon {
    margin: 0 !important;
    top: 3px !important;
  }

  > .anticon-close {
    color: ${COLORS.SECONDARY_TEXT_COLOR} !important;
    &:hover {
      color: ${COLORS.DESCRIPTION_COLOR} !important;
    }
  }
`;

export const StyledMessageContent = styled.div`
  text-align: left;
  margin: 0 8px;
`;

import {
  IMAGE_ADD,
  IMAGE_REMOVE,
  IMAGE_UPDATE,
  IMAGES_CLEAR,
  DamageViewerAction,
} from '../actions';

interface DamageViewerState<I extends any> {
  images: I[];
}

const IMAGE_MAX = 2;

export const getImages = ({ state }: any) => state.damageViewer.images || [];
export const getImage = ({ state, index }: any) => state.damageViewer.images[index] || {};

export default function damageViewerReducer<I extends any>(
  state: DamageViewerState<I> = { images: [] },
  action: DamageViewerAction
) {
  switch (action.type) {
    case IMAGE_ADD: {
      const { image } = action;
      // TODO ARS - right now we only are going to allow 2 images to be open at a time
      // mainly due to UX considerations, but it would be trivial to open the gates and allow any number
      // behavior now will be if the array is maxed out then we just swap the last element on add
      if (state.images.length === IMAGE_MAX) {
        const newImages = state.images.filter((i, index) => index !== IMAGE_MAX - 1);
        return {
          ...state,
          images: [...newImages, image],
        };
      }
      return {
        ...state,
        images: [...state.images, image],
      };
    }

    case IMAGE_REMOVE: {
      const { index: updateIndex } = action;

      const newImages = state.images.filter((i, index) => index !== updateIndex);
      return {
        ...state,
        images: newImages,
      };
    }

    case IMAGE_UPDATE: {
      const { updates, index: updateIndex } = action;

      const newImages = state.images.map((i: any, index) => {
        if (index === updateIndex) {
          return { ...i, ...updates };
        }
        return i;
      });

      return {
        ...state,
        images: newImages,
      };
    }

    case IMAGES_CLEAR: {
      return {
        ...state,
        images: [],
      };
    }
    default:
      return state;
  }
}

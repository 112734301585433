import { TQueryFilter } from 'components/DataTable/types';

export const IS_NULL = 'IS_NULL';
export const IS_NOT_NULL = 'IS_NOT_NULL';

export const ACTIVE_FILTER: TQueryFilter = {
  key: 'archived',
  values: [],
  operator: IS_NULL,
};

export const ARCHIVED_FILTER: TQueryFilter = {
  key: 'archived',
  values: [],
  operator: IS_NOT_NULL,
};

export enum EArchivedFilterStateOption {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  BOTH = 'BOTH',
}

import { Dropdown } from 'antd5';
import { ItemType } from 'antd5/es/menu/hooks/useItems';
import { DownOutlined } from '@ant-design/icons';
import { TDropdownAction } from 'components/DataTable/types';
import { Button } from 'components/ui';

type AdditionalActionsProps = {
  additionalItems: TDropdownAction[];
};

export const AdditionalActions: React.FunctionComponent<AdditionalActionsProps> = ({
  additionalItems = [],
}) => {
  // menu items for 'Actions' dropdown
  const menuItems: ItemType[] = [
    ...additionalItems.map(item => {
      const { content, onClick, disabled, hoverable = true } = item;
      return {
        label: content,
        onClick,
        disabled,
        ...(hoverable ? {} : { className: 'not-hoverable' }),
      };
    }),
  ]
    .filter(Boolean)
    .map((item, index) => {
      return { ...item, key: index };
    });

  return (
    <Dropdown menu={{ items: menuItems }} trigger={['click']} placement="bottomLeft">
      <Button data-testid="datatable-actions-button">
        Actions <DownOutlined />
      </Button>
    </Dropdown>
  );
};

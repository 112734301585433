import { useState, useEffect, useCallback } from 'react';
import {
  useGetReportQuery,
  AtlasGqlReportStatus,
  AtlasGqlGetReportQuery,
  AtlasGqlReportDeliveryStatus,
} from 'types/atlas-graphql';

const POLL_INTERVAL = 5000;

export enum PollType {
  REPORT = 'report',
  DELIVERY = 'delivery',
}

export const usePollingReportQuery = () => {
  const [id, setId] = useState<string>();
  const [pollType, setPollType] = useState<PollType>(PollType.REPORT);
  const [pollInterval, setPollInterval] = useState(POLL_INTERVAL);
  const { data, loading } = useGetReportQuery({
    // Always want to fetch a new signed URL for the download link.
    fetchPolicy: 'network-only',
    pollInterval,
    skip: !id,
    variables: { id: id || '' },
  });

  const statusIsFailedOrCompleted = useCallback(
    (data: AtlasGqlGetReportQuery): boolean => {
      switch (pollType) {
        case PollType.REPORT:
          return (
            data?.report?.status === AtlasGqlReportStatus.Failed ||
            data?.report?.status === AtlasGqlReportStatus.Succeeded
          );
        case PollType.DELIVERY:
          return (
            data?.report?.deliveries?.[0].status === AtlasGqlReportDeliveryStatus.Failed ||
            data?.report?.deliveries?.[0].status === AtlasGqlReportDeliveryStatus.Succeeded
          );
      }
    },
    [pollType]
  );

  useEffect(() => {
    if (data && statusIsFailedOrCompleted(data)) {
      setPollInterval(0);
    } else {
      setPollInterval(POLL_INTERVAL);
    }
  }, [data, statusIsFailedOrCompleted]);

  return {
    data,
    loading,
    pollInterval,
    pollType,
    setPollType,
    setIdForPolling: setId,
  };
};

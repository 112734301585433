import { useCallback, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { isEmpty, isEqual, memoize } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';
import queryString from 'query-string';
import { getXYBounds } from 'utils/geo';
import { notifyBugsnag } from 'utils/bugsnag';
import { DamageTooltip } from 'components/damages/DamageTooltip';
import Controls from './Controls';
import OpenSeadragon, {
  CLICK_ZOOM_FACTOR,
  getOpenSeadragonViewer,
  OSDViewerWithSVG,
} from 'utils/openseadragon';
import {
  AddItemFailedEvent,
  OpenFailedEvent,
  TileDrawingEvent,
  TileLoadFailedEvent,
} from 'openseadragon';
import { usePrevious } from 'utils/hooks';
import { DamagePolygon, OSDContainer } from './ImageViewer.styles';
import { PictureTarget } from 'horizon/routes/Inspections/types';
import { AtlasGqlTargetBlade_External } from 'types/atlas-graphql';
import { useDamages2Banner } from 'horizon/components/Damages/Damages2Banner';
import { useSignedUrlsExpiredMessaging } from 'utils/signed-urls';

/**
 * This component is deprecated and will be removed during cleanup of
 * rtv1:new_image_viewer
 *
 * Further maintenance should not be needed, check with Riley with any questions.
 */

function getCopyLink({
  id,
  isHorizonDamage,
  defaultDamageId,
  defaultObservationId,
  defaultFilter,
}: {
  id: string;
  isHorizonDamage?: boolean;
  defaultDamageId?: string;
  defaultObservationId?: string;
  defaultFilter?: string;
}): string {
  if (id) {
    const searchParams = {
      damages: defaultDamageId ?? undefined,
      observation: defaultObservationId ?? undefined,
      filter: defaultFilter ?? undefined,
      ...(isHorizonDamage && { version: 2 }),
    };

    return `${window.location.origin}/pictures/${id}${
      !isEmpty(searchParams) ? `?${queryString.stringify(searchParams)}` : ''
    }`;
  }

  return '';
}

export const getRotation = (target: AtlasGqlTargetBlade_External) => {
  const bladeAngle = target?.bladeAngle ?? 0;
  const radians = Number((bladeAngle || '').replace('rad', ''));
  const degrees = radians ? radians * (180 / Math.PI) : 0;

  return degrees;
};

interface ImageViewerProps {
  id: string;
  isHorizonDamage?: boolean;
  defaultDamageId?: string;
  selectDamage?: (id: string) => void;
  defaultObservationId?: string;
  selectObservation?: (id: string) => void;
  defaultFilter?: string;
  image: { [key: string]: any };
  copyText?: any;
  defaultValue?: any;
  onEdit?: () => void;
  homography?: any;
  doAjaxLoad?: boolean;
  observations: {
    id: string;
    damageId?: string;
    selected: boolean;
    coordinates?: any[] | null;
    [key: string]: any;
  }[];
  target?: PictureTarget;
  render?: (arg?: OSDViewerWithSVG) => JSX.Element | null;
  controls?: any;
}

export const ImageViewer = ({
  id,
  isHorizonDamage,
  defaultDamageId,
  selectDamage,
  defaultObservationId,
  selectObservation,
  defaultFilter,
  image,
  copyText,
  defaultValue = {},
  homography,
  doAjaxLoad = false,
  observations = [],
  target,
  render = () => null,
  controls,
}: ImageViewerProps) => {
  useDamages2Banner(!!isHorizonDamage);
  const [viewer, setViewer] = useState<OSDViewerWithSVG>();
  const [svg, setSvg] = useState<SVGElement>();
  const [useRotate, setUseRotate] = useState<boolean>(true);
  const [levels, setLevels] = useState<{ url: string; width: number; height: number }[]>();
  const [imageContainer, setImageContainer] = useState<HTMLDivElement | null>(null);
  const [annotationHover, setAnnotationHover] = useState<string | undefined>(undefined);

  const zoomViewIn = () => {
    const viewport = viewer?.viewport;
    if (viewport) {
      const zoom = viewport.getZoom() * CLICK_ZOOM_FACTOR;
      viewport.zoomTo(zoom);
    }
  };

  const zoomViewOut = () => {
    const viewport = viewer?.viewport;
    if (viewport) {
      const zoom = viewport.getZoom() * (1 / CLICK_ZOOM_FACTOR);
      viewport.zoomTo(zoom);
    }
  };

  const resetZoom = () => {
    viewer?.viewport?.goHome();
  };

  const toggleRotate = useCallback(
    (useRotate: boolean) => {
      const viewport = viewer?.viewport;
      if (target && target.__typename === 'TargetBLADE_EXTERNAL') {
        const degrees = useRotate ? getRotation(target as AtlasGqlTargetBlade_External) : 0;
        viewport?.setRotation(degrees);
      }

      setUseRotate(useRotate);
    },
    [target, viewer]
  );

  const handleControls = ({ zoomIn, zoomOut, rotate, reset }: any) => {
    zoomIn && zoomViewIn();
    zoomOut && zoomViewOut();
    rotate !== undefined && toggleRotate(rotate);
    reset && resetZoom();
  };

  const handleDamageClick = (damageId?: string) => {
    if (damageId && selectDamage) {
      selectDamage(damageId);
    }
  };

  const handleObservationClick = (observationId?: string) => {
    if (observationId && selectObservation) {
      selectObservation(observationId);
    }
  };

  const handleAnnotationHover = (id?: string) => {
    if (id) {
      setAnnotationHover(id);
    } else {
      setAnnotationHover(undefined);
    }
  };

  // This will apply the linear transformation declared by a "Level" control
  const transformImage = memoize((context: CanvasRenderingContext2D) => {
    const image = context.getImageData(0, 0, context.canvas.width, context.canvas.height);
    const originalData = new Uint8ClampedArray(image.data);

    return ({ a = 1, b = 0 } = {}) => {
      // Prevent repaints using a key
      const key = [a, b].toString();

      if (key === context.canvas.id) return image;

      if (a === 1 && b === 0) {
        image.data.set(originalData);
      } else {
        const data = new Uint8ClampedArray(originalData);

        for (let i = 0; i < data.length; i += 4) {
          for (let j = 0; j < 3; ++j) {
            data[i + j] = a * data[i + j] + b;
          }
        }

        image.data.set(data);
      }

      context.canvas.id = key;

      return image;
    };
  });

  // debounced to allow viewer to initialize
  const focusLayer = useDebouncedCallback(() => {
    const { coordinates } = observations.find(o => o.selected) || {};

    if (!coordinates) return;

    const [[xMin, yMax], [xMax, yMin]] = getXYBounds(coordinates);
    const location = new OpenSeadragon.Rect(xMin, yMin, xMax - xMin, yMax - yMin);

    // ensures tab is in focus when moving the viewport
    window.requestAnimationFrame(() => {
      viewer?.viewport?.fitBoundsWithConstraints(location);
    });
  }, 300);

  useSignedUrlsExpiredMessaging(image?.signedDziSchema?.dzi?.Image.expiresAt);

  useEffect(() => {
    const handleZoomKeys = ({ target, code }: { target: any; code: string }) => {
      if (target.form === undefined) {
        switch (code) {
          case 'Minus':
            return zoomViewOut;
          case 'Equal':
            return zoomViewIn;
          default:
            return;
        }
      }
    };

    if (imageContainer !== null) {
      const degrees =
        useRotate && target && target.__typename === 'TargetBLADE_EXTERNAL'
          ? getRotation(target as AtlasGqlTargetBlade_External)
          : 0;

      const osdViewer = getOpenSeadragonViewer({
        degrees,
        doAjaxLoad,
        dziImage: image?.signedDziSchema?.dzi?.Image,
        element: imageContainer,
      });

      setViewer(osdViewer);
      window.addEventListener('keydown', handleZoomKeys);
    }
    return () => {
      if (viewer) {
        viewer.destroy();
        setViewer(undefined);
      }

      window.removeEventListener('keydown', handleZoomKeys);
    };
    // TODO: figure out why adding to the dependency array is causing an infinite loop
    // eslint-disable-next-line
  }, [imageContainer]);

  const previousViewer = usePrevious(viewer);

  useEffect(() => {
    if (viewer && viewer !== previousViewer) {
      const svg = viewer.svgOverlay().node();
      viewer.addHandler('open-failed', (event: OpenFailedEvent) => {
        notifyBugsnag(new Error(`open-failed.  ${event.source} : ${event.message}`));
      });
      viewer.addHandler('tile-load-failed', (event: TileLoadFailedEvent) => {
        notifyBugsnag(new Error(`tile-load-failed.  ${event.tile} : ${event.message}`));
      });
      viewer.addHandler('add-item-failed', (event: AddItemFailedEvent) => {
        notifyBugsnag(new Error(`add-item-failed. ${event.message}`));
      });
      focusLayer();
      setSvg(svg);
    }

    if (levels && viewer) {
      viewer.addHandler('tile-drawing', (event: TileDrawingEvent) => {
        const rendered = event?.rendered;
        const context = rendered?.context2D;
        const image = transformImage(context)(levels as any);
        context.putImageData(image, 0, 0);
      });
    }
  }, [viewer, previousViewer, levels, focusLayer, transformImage]);

  const prevImage = usePrevious(image);
  const prevObservations = usePrevious({ observations });
  useEffect(() => {
    const prevUrl = prevImage?.signedUrl?.url ? new URL(prevImage.signedUrl.url) : undefined;
    const newUrl = image?.signedUrl?.url ? new URL(image?.signedUrl?.url) : undefined;
    if (
      (prevUrl?.pathname !== newUrl?.pathname ||
        prevUrl?.searchParams.get('filter') !== newUrl?.searchParams.get('filter')) &&
      viewer
    ) {
      if (transformImage?.cache?.clear) {
        transformImage?.cache?.clear();
      }
      setLevels(undefined);
      toggleRotate(useRotate);
    }

    if (!isEqual(prevObservations?.observations, observations)) {
      focusLayer();
    }
  }, [
    focusLayer,
    image,
    observations,
    prevImage,
    prevObservations,
    toggleRotate,
    transformImage.cache,
    useRotate,
    viewer,
  ]);

  return (
    <>
      {/* data-private used to hide images from LogRocket: https://docs.logrocket.com/reference/dom */}
      <OSDContainer ref={setImageContainer} data-private="logrocket-hide">
        <Controls
          onChange={handleControls}
          downloadHref={image?.signedUrl.url}
          copyText={
            copyText ||
            getCopyLink({
              id,
              isHorizonDamage,
              defaultDamageId,
              defaultObservationId,
              defaultFilter,
            })
          }
          homography={homography}
          value={{
            rotate: useRotate,
            levels: defaultValue.levels,
          }}
        >
          {controls}
        </Controls>
        {svg &&
          createPortal(
            <>
              {render(viewer)}
              {observations.map((o, index) => {
                // Must use prop spreading for some reason. Setting "undefined" not working.
                const visible = o.selected ? { visible: false } : {};
                const key = `${o.id || o.annotationId || o.damageId}_tip` + index;
                return isHorizonDamage ? (
                  <DamageTooltip
                    id={o.id}
                    key={key}
                    onClick={() => handleObservationClick(o.id)}
                    isHorizonDamage={isHorizonDamage}
                    {...visible}
                  >
                    <DamagePolygon
                      data-testid="damage-polygon"
                      key={o.id}
                      selected={o.selected || !defaultObservationId}
                      coordinates={o.coordinates}
                      onMouseEnter={() => handleAnnotationHover(o.id)}
                      onMouseLeave={() => handleAnnotationHover(undefined)}
                      isHovering={annotationHover === o.id}
                    />
                  </DamageTooltip>
                ) : (
                  <DamageTooltip
                    id={o.damageId!}
                    key={key}
                    onClick={() => handleDamageClick(o?.damageId)}
                    {...visible}
                  >
                    <DamagePolygon
                      data-testid="damage-polygon"
                      key={o.id}
                      selected={o.selected || !defaultDamageId}
                      coordinates={o.coordinates}
                      onMouseEnter={() =>
                        handleAnnotationHover(o?.annotationId || o?.damageId || o?.id)
                      }
                      onMouseLeave={() => handleAnnotationHover(undefined)}
                      isHovering={annotationHover === o?.damageId}
                    />
                  </DamageTooltip>
                );
              })}
            </>,
            svg
          )}
      </OSDContainer>
    </>
  );
};

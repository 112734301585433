import styled from 'styled-components';
import { first } from 'lodash';
import { Avatar } from 'antd';
import { User } from '@auth0/auth0-react';

const StyledAvatar = styled(Avatar)`
  cursor: auto !important;
  color: ${({ theme }) => theme.white} !important;
  background-color: ${({ theme }) => theme.horizonGreen} !important;
`;

interface ExtendedUser extends User {
  firstName?: string;
  lastName?: string;
}

const getInitials = ({
  email,
  family_name,
  firstName,
  given_name,
  lastName,
  name,
}: ExtendedUser) => {
  const names =
    given_name && family_name // first attempt to use values from auth0
      ? [given_name, family_name]
      : firstName && lastName // second attempt to use values manually provided
        ? [firstName, lastName]
        : (name || email || '').split(' ');

  const initials = names.map(first).join('').toUpperCase();

  return <span title={names.join(' ')}>{initials}</span>;
};

interface UserAvatarProps {
  user: ExtendedUser;
}

export const UserAvatar = ({ user }: UserAvatarProps) => (
  <StyledAvatar>{getInitials(user)}</StyledAvatar>
);

export default UserAvatar;

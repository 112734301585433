import { StorageKey, storageManager, getItem } from 'utils/storage';

import { TExtensionColumn } from '../types';
import { TDataTableContext } from 'components/DataTable/types';
import { TDisplayColumnData } from 'components/data/types';

export const columnPickerStorage = ({ storageKey }: { storageKey?: StorageKey }) => {
  const customerId = getItem('customerId');
  if (storageKey && customerId) {
    const key = `${storageKey}:${customerId}`;
    return storageManager({ storageKey: key });
  }

  return storageManager({ storageKey: undefined });
};

const mapExtensionColumnsToDefinition = (
  columnDefs: TDisplayColumnData[],
  savedColumns: TExtensionColumn[]
): (TDisplayColumnData & { visible: boolean })[] => {
  return savedColumns
    .map((column: TExtensionColumn) => {
      const columnDefinition = columnDefs.find(implementation => {
        const key = implementation.key ? 'key' : 'dataIndex';
        return implementation[key] === column.key;
      });
      if (columnDefinition) {
        return {
          ...columnDefinition,
          visible: column.visible,
        };
      }
      return null;
    })
    .filter((c): c is TDisplayColumnData & { visible: boolean } => !!c);
};

export const updateCustomCols = (
  columnDefs: TDisplayColumnData[],
  customColumns: TExtensionColumn[],
  updateCustomizedColumnSet: TDataTableContext['updateCustomizedColumnSet'],
  columnPickerStorageManager: any,
  isInitialRender?: boolean
) => {
  const columnsToSave: Pick<TExtensionColumn, 'key' | 'visible'>[] = (customColumns ?? []).map(
    ({ key, visible }) => ({ key, visible })
  );
  if (customColumns?.length || isInitialRender) {
    const columnDefinitionsWithVisibility = mapExtensionColumnsToDefinition(
      columnDefs,
      customColumns
    );
    updateCustomizedColumnSet(columnDefinitionsWithVisibility);
    columnPickerStorageManager.set(columnsToSave);
  } else {
    updateCustomizedColumnSet(undefined);
    columnPickerStorageManager.remove();
  }
};

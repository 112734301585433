import { StyledQuestionCircleOutlined } from './Tooltip.style';
import { TooltipProps } from 'antd5';
import COLORS from 'utils/color/definitions';
import { NEW_TOOLTIP } from 'utils/release-toggles';
import { useAccountContext } from 'utils/account/AccountContext';
// TODO: Remove this V4 import when removing `new-tooltip` release toggle
import { Tooltip as TooltipV4 } from 'antd';
import { Tooltip as TooltipV5 } from 'antd5';

export const Tooltip = (props: TooltipProps) => {
  const useV5Tooltip = useAccountContext().hasReleaseToggle(NEW_TOOLTIP);

  if (useV5Tooltip) {
    return (
      <TooltipV5
        // Because AntD renders the tooltip contents at body level, the style
        // overrides for this component are defined in App.css The v5 overrides
        // only apply to the styled-ant5-tooltip class, and will not go into
        // effect if the toggle is disabled
        overlayClassName="antd5-tooltip"
        // This color is the same as the initial AntD Tooltip's background:
        // rgba(0, 0, 0, 0.75)
        color={COLORS.DESCRIPTION_COLOR}
        {...props}
      />
    );
  }

  return (
    // @ts-ignore - The Prop types don't exactly line up.
    <TooltipV4 {...props} />
  );
};

export const TooltipWithQuestionIcon = ({ message }: { message: JSX.Element | string }) => {
  return (
    <Tooltip style={{ margin: '0 0.5rem' }} title={message}>
      <StyledQuestionCircleOutlined />
    </Tooltip>
  );
};

import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { isFinite } from 'lodash';

import {
  AtlasGqlHorizonDamageObservation,
  AtlasGqlHorizonDamagePropagation,
} from 'types/atlas-graphql';
import { propagationTypeMapping } from 'horizon/components/Damages/utils';
import { ListItem } from '../ComparerPane/ComparerPane.style';
import { None } from 'components/data/helpers';

type DamageDetailObservationListItemProps = {
  observation: AtlasGqlHorizonDamageObservation;
  propagation?: AtlasGqlHorizonDamagePropagation | null | undefined;
  selected?: boolean;
  onClick: () => void;
};

export const DamageDetailObservationListItem: React.FunctionComponent<
  DamageDetailObservationListItemProps
> = ({ observation, propagation, selected = false, onClick }) => {
  const {
    Material: material,
    Type: type,
    Length: length,
    Width: width,
  }: {
    Material?: number;
    Type?: string;
    Length?: string;
    Width?: string;
  } = observation.attrs ?? {};

  const hasNoMetadata: boolean = !material && !type && !length && !width;
  return (
    <ListItem key={observation.id} selected={selected} onClick={onClick}>
      <div>
        {!hasNoMetadata ? (
          <>
            <p>{[type, material].filter(Boolean).join(', ')}</p>
            <p className="inspection-detail">
              {isFinite(length) && isFinite(width) && `${length}m × ${width}m`}
            </p>
          </>
        ) : (
          <None message={'No Metadata'} />
        )}
      </div>
      {propagation && (
        <Popover
          title={propagation.type ? propagationTypeMapping[propagation.type] : ''}
          content={propagation.notes}
          placement="left"
          trigger="hover"
        >
          <InfoCircleOutlined
            onClick={e => {
              e.stopPropagation();
            }}
          />
        </Popover>
      )}
    </ListItem>
  );
};

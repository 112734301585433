import { Modal } from 'antd';
import { AtlasGqlDamage, AtlasGqlHorizonDamage } from 'types/atlas-graphql';
import { CreateTasksFromDamagesForm } from '../CreateTasksFromDamagesForm';

interface ICreateTasksFromDamagesModal {
  damages: (AtlasGqlHorizonDamage | AtlasGqlDamage)[];
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const CreateTasksFromDamagesModal: React.FC<ICreateTasksFromDamagesModal> = ({
  damages,
  open,
  setOpen,
}) => {
  return (
    <Modal title="Create Tasks" visible={open} onCancel={() => setOpen(false)} footer={null}>
      <CreateTasksFromDamagesForm damages={damages} open={open} setOpen={setOpen} />
    </Modal>
  );
};

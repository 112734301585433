// This function is a JSON.stringify replacer. It finds circular references and removes
// them. It also looks to see if we have a key for sortedInfo, and if so, remove the
// column and field properties. Since they are not necessary for sorting.
// Got this solution from:
// https://codedamn.com/news/javascript/how-to-fix-typeerror-converting-circular-structure-to-json-in-js
export const stringifyWithReplacer = (obj: any) => {
  let cache: any[] = [];
  const str = JSON.stringify(obj, function (key, value) {
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return;
      }
      // Trevor: I think we get these values from clicking a sorter, but im not entirely
      // sure how to prevent those items from getting added. I found a way to remove them
      // and this gets rid of those warnings in the table header sorts.
      if (key === 'sortedInfo') {
        delete value.column;
        delete value.field;
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = []; // reset the cache
  return str;
};

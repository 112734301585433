import { Component } from 'react';
import EditActions from '../EditActions';
import { CanRender } from 'utils/usePermissions';
import { renderColumn } from 'components/data/helpers';
import { ActionsContainer, InlineEditableContainer, StyledSpinner } from './InlineEditable.style';

export function inlineEditableHeaderField(column, options = {}, isEditButtonVisible = true) {
  options = {
    ...options,
    isRequired: false,
    canEdit: () => isEditButtonVisible,
    canRemove: () => true,
  };

  return {
    ...column,
    render: (value, record, index) => {
      return (
        <InlineEditable column={column} value={value} record={record} index={index} {...options} />
      );
    },
  };
}

// This component uses a combination of both rules and the isEditButtonVisible prop
// to determine whether to show the edit button.
// This is to continue to deprecate the use of rules going forward but need a way
// to dynamically show the button for ongoing work. - AN 9/27/23
export function inlineEditable(column, options = {}, isEditButtonVisible = true) {
  const { editRule } = column;
  if (!editRule) {
    return column;
  }

  options = {
    ...options,
    isRequired: options.isRequired,
    canEdit: () => isEditButtonVisible,
    canRemove: () => true,
  };

  return {
    ...column,
    render: (value, record, index) => (
      <CanRender rules={[editRule]} fallbackRender={() => renderColumn(column, record, index)}>
        {options.loading ? (
          <StyledSpinner />
        ) : (
          <InlineEditable
            column={column}
            value={value}
            record={record}
            index={index}
            {...options}
          />
        )}
      </CanRender>
    ),
  };
}

class InlineEditable extends Component {
  state = {
    editing: false,
    editedValue: null,
    isHovered: false,
    isInputValid: true,
  };

  isInputValid = input => {
    if (typeof input === 'string') input = input.trim();
    const isValid = input !== undefined && input !== null && input !== '';
    this.setState({
      isInputValid: isValid,
    });
  };

  handleMouseEnter = () => this.setState({ isHovered: true });
  handleMouseLeave = () => this.setState({ isHovered: false });
  handleEdit = () => {
    if (this.props.overrideOnEdit) {
      this.props.overrideOnEdit(() => {
        // let the caller decide if they want to call the default action
        this.setState({ editing: true });
      });
    } else {
      this.setState({ editing: true });
      if (this.props.onEdit) this.props.onEdit();
    }
  };
  handleCancel = () => this.setState({ editing: false, editedValue: null });
  handleChange = editedValueContainer => {
    this.setState({ editedValue: editedValueContainer });
    const editedValue = Object.values(editedValueContainer)[0];
    if (this.props.isRequired) this.isInputValid(editedValue);
  };

  handleSave = () => {
    this.props.onSave(this.state.editedValue, this.props.column);
    this.setState({ editing: false, editedValue: null, isHovered: false });
  };

  render() {
    const { column, value, record, index, id, style, ...restProps } = this.props;
    const { editing, isHovered, isInputValid } = this.state;

    const actionsProps = {
      isInputValid,
      record,
      editing,
      ...restProps,
      onSave: this.handleSave,
      onEdit: this.handleEdit,
      onCancel: this.handleCancel,
    };

    return (
      <InlineEditableContainer
        data-testid={`inline-editable-${record.title}`}
        className="inline-editable"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={style}
      >
        {editing
          ? column.edit(record, { onChange: this.handleChange })
          : column.render(value, record, index)}
        <ActionsContainer id={id} visible={isHovered || editing}>
          <EditActions {...actionsProps} />
        </ActionsContainer>
      </InlineEditableContainer>
    );
  }
}

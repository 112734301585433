import { useEffect, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useAccountContext } from 'utils/account/AccountContext';
import { useChangeCustomer } from 'utils/hooks';

const ignoreOrgRoutes = ['/reset', '/api'];

export function useOrgRedirect() {
  const { customer } = useAccountContext();
  const { pathname } = useLocation();
  const changeCustomer = useChangeCustomer({ showNotificationOnRedirect: true });

  const orgIdInUrl = useMemo(() => {
    const match = matchPath<{ orgId: string }>(pathname, {
      path: '/o/:orgId',
      strict: false,
    });
    return match?.params.orgId;
  }, [pathname]);

  useEffect(() => {
    if (orgIdInUrl && customer?.id && !ignoreOrgRoutes.includes(pathname)) {
      changeCustomer(orgIdInUrl);
    }
  }, [changeCustomer, customer?.id, orgIdInUrl, pathname]);
}

import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Button } from 'components/ui';

export const StyledDeleteButtonLink = styled(Button)`
  color: ${({ theme }) => theme.lightRed} !important;
  &:hover {
    color: ${({ theme }) => theme.red} !important;
  }
`;

export const StyledWarning = styled.div`
  font-size: 1.1rem;
`;

export const DisabledDeleteIcon = styled(DeleteOutlined)`
  color: #bcbcbc;
`;

import styled from 'styled-components';

export const ListItemContainer = styled.div`
  display: ${props => (props.isHidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 16px;
  padding: 0px 16px;
  box-shadow: ${props => props.isDragging && '0px 0px 10px rgba(0, 0, 0, 0.4)'};
  border: 1px solid grey;
`;

export const ToggleContainer = styled.div`
  display: flex;
`;

export const ColumnName = styled.div`
  margin-left: 8px;
  font-weight: bold;
`;

import { TablePaginationConfig } from 'antd';
import { isEmpty } from 'lodash';
import { getUrlParams } from './paging';

interface UseDefaultStateParams {
  initialPresetFilter?: any;
  pagination?: false | TablePaginationConfig;
  routeProps?: any;
}

export function useDefaultState({
  pagination: paginationOverride = undefined,
  initialPresetFilter = {},
  routeProps = {},
}: UseDefaultStateParams) {
  const { location } = routeProps;
  const urlParams = location ? getUrlParams(location) : {};

  const {
    pagination: urlPagination = {},
    filters: urlFilters = {},
    sortOrder: urlSortOrder = {},
    ...urlRouteVariables
  } = urlParams as any;

  const { value: initialPresetFilterValue } = initialPresetFilter;

  const { filters: initialPresetFilters = {}, sortOrder: initialPresetSortOrder = {} } =
    initialPresetFilterValue || {};

  const defaultPagination =
    paginationOverride === false
      ? {}
      : {
          showSizeChanger: true,
          hideOnSinglePage: false,
          style: { padding: '0 1em' },
          showTotal: (total: number | string, range: (number | string)[]) =>
            `${range[0]}-${range[1]} of ${total}`,
          pageSize: 10,
          current: 1,
          ...paginationOverride,
          ...urlPagination,
        };

  // if either filters, sortOrder or routeVariables are present on the url, use that to calculate both filter and sort order default state

  const defaultFilteredInfo =
    !isEmpty(urlFilters) || !isEmpty(urlSortOrder) || !isEmpty(urlRouteVariables)
      ? urlFilters
      : initialPresetFilters;

  const defaultSortOrder =
    !isEmpty(urlFilters) || !isEmpty(urlSortOrder) || !isEmpty(urlRouteVariables)
      ? urlSortOrder
      : initialPresetSortOrder;

  return {
    defaultPagination,
    defaultFilteredInfo,
    defaultSortOrder,
    defaultRouteVariables: urlRouteVariables,
  };
}

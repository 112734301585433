import { Input, Select, InputNumber, TimePicker } from 'antd';
import TagChooser from 'components/choosers/TagChooser';
import { WorkContainerChooser } from 'components/choosers/WorkContainerChooser';
import { TrustedOrganizationChooser } from 'components/choosers/TrustedOrganizationChooser';
import { startCase } from 'utils/format';
import { TASK_STATUSES, WORK_ORDER } from 'utils/constants';
import { UTCDatePicker } from 'components/UTCDatePicker';

const inputDict = {
  TEXT: ({ disabled }) => <Input disabled={disabled} />,
  NUMBER: () => <InputNumber />,
  DATE: ({ disabled }) => <UTCDatePicker disabled={disabled} />,
  SELECT: ({ options = [] }) => (
    <Select allowClear>
      {options?.map(option => {
        return (
          <Select.Option value={option} key={option}>
            {option}
          </Select.Option>
        );
      })}
    </Select>
  ),
  TIME_RANGE: () => <TimePicker.RangePicker format={'HH:mm'} />,
};

export function getInput({ field, uneditableFields, form }) {
  // Tags requires a specific Chooser.
  // Handle it separately
  if (field.name === 'Tags') {
    return (
      <TagChooser
        onChange={tags =>
          form.setFieldsValue({
            Tags: tags,
          })
        }
      />
    );
  }

  // Status requires some extra logic.
  // Handle it separately
  if (field.name === 'Status') {
    return (
      <Select
        onChange={status =>
          form.setFieldsValue({
            Status: status,
          })
        }
      >
        {Object.values(TASK_STATUSES).map(status => (
          <Select.Option key={status} value={status}>
            {startCase(status)}
          </Select.Option>
        ))}
      </Select>
    );
  }

  // Parent requires a specific chooser.
  // Handle it separately
  if (field.name === 'Parent') {
    return (
      <WorkContainerChooser
        type={WORK_ORDER}
        labelInValue={false}
        onChange={value => {
          form.setFieldsValue({ Parent: value });
        }}
      />
    );
  }

  // For Org is more complicated than the other SELECT fields.
  // Handle it separately
  if (field.name === 'For Organization') {
    return (
      <TrustedOrganizationChooser
        onChange={organization =>
          form.setFieldsValue({
            ForOrganization: organization,
          })
        }
      />
    );
  }

  return inputDict[field.type]({
    options: field?.options,
    disabled: Boolean(uneditableFields[field.name]),
  });
}

import { Form } from 'antd';

import { ExternalServiceRequestChooser } from '.';
import { FeatureFlag, flags } from 'utils/features';

interface IMaximoServiceRequestProps {
  assetId: string;
  onChange?: any;
  turbineList: any[];
  formItemLayout?: any;
  label: string;
}

export const MaximoServiceRequest: React.FunctionComponent<IMaximoServiceRequestProps> = ({
  assetId,
  onChange,
  turbineList,
  formItemLayout,
  label,
}) => {
  return (
    <FeatureFlag flag={flags.MAXIMO_SRS}>
      <Form.Item label={label} {...formItemLayout}>
        <ExternalServiceRequestChooser
          assetId={assetId}
          disableForMultipleTurbines={turbineList.length > 1 ? true : false}
          onChange={onChange}
        />
      </Form.Item>
    </FeatureFlag>
  );
};

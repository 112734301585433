import { message } from 'antd';
import {
  InfoCircleFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  CloseOutlined,
} from '@ant-design/icons';
import { StyledMessageDiv, StyledMessageContent } from './Messages.style';

export enum MessageTypes {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

type MessageWithCloseProps = {
  key?: string;
  type?: MessageTypes;
  content: JSX.Element;
};

export const MessageWithClose = ({
  key = '',
  type = MessageTypes.INFO,
  content,
}: MessageWithCloseProps) => {
  const config = {
    key,
    content: (
      <StyledMessageDiv>
        {type === MessageTypes.SUCCESS ? (
          <CheckCircleFilled />
        ) : type === MessageTypes.ERROR ? (
          <CloseCircleFilled />
        ) : type === MessageTypes.WARNING ? (
          <ExclamationCircleFilled />
        ) : (
          <InfoCircleFilled />
        )}
        <StyledMessageContent>{content}</StyledMessageContent>
        <CloseOutlined onClick={() => message.destroy(key)} />
      </StyledMessageDiv>
    ),
    icon: <></>,
    duration: 0,
  };

  // switch on type with 'info' being the default
  switch (type) {
    case MessageTypes.SUCCESS:
      message.success(config);
      break;

    case MessageTypes.ERROR:
      message.error(config);
      break;

    case MessageTypes.WARNING:
      message.warning(config);
      break;

    default:
      message.info(config);
      break;
  }
};

import { DragOutlined } from '@ant-design/icons';

import { Checkbox } from 'antd';
import { Draggable } from 'react-beautiful-dnd';
import { ListItemContainer, ToggleContainer, ColumnName } from './ColumnListItem.style';

export function ColumnListItem({ index, column, toggleVisibility }) {
  if (column.fixed) {
    return (
      <ListItemContainer>
        <ToggleContainer>
          <Checkbox checked={column.visible} disabled={true} />
          <ColumnName>{column.title}</ColumnName>
        </ToggleContainer>
      </ListItemContainer>
    );
  }

  return (
    <Draggable draggableId={column.key} index={index}>
      {(provided, snapshot) => (
        <ListItemContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
          isHidden={column.hidden}
        >
          <ToggleContainer>
            <Checkbox checked={column.visible} onChange={() => toggleVisibility(column)} />
            <ColumnName>{column.title}</ColumnName>
          </ToggleContainer>
          <DragOutlined />
        </ListItemContainer>
      )}
    </Draggable>
  );
}

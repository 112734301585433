import { AtlasGqlWorkContainerTypeOption } from 'types/atlas-graphql';
import { TAtlasTaskDetail } from './types';
import { OTHER_TASK_TYPE_NAME } from 'utils/constants';
import { camelCase, get } from 'lodash';

// Helper function to wrap all of the logic to pull workOrder, campaign, damage, and logSchema out of an Atlas Task.
export function deconstructAtlasTask(task: TAtlasTaskDetail) {
  const workOrder = task && 'parent' in task ? task.parent : undefined;
  const campaign = workOrder && 'parent' in workOrder ? workOrder.parent : undefined;
  const damage = task && 'damage' in task ? task.damage : undefined;
  const horizonDamage = task && 'horizonDamage' in task ? task.horizonDamage : undefined;
  const targets = task && 'targets' in task ? task.targets : undefined;
  const taskAsset = task && 'asset' in task ? task.asset : undefined;
  const damageAsset = damage && 'asset' in damage ? damage.asset : undefined;
  const horizonDamageAsset =
    horizonDamage && 'asset' in horizonDamage ? horizonDamage.asset : undefined;
  const forOrganization = task && 'forOrganization' in task ? task.forOrganization : undefined;
  const logSchema = task && 'logSchema' in task ? task.logSchema : [];
  const typeName = task?.type?.type ?? '';
  const picture = damage ? get(damage?.observations, '[0].picture', {}) : undefined;
  const inspectionType = getInspectionTypeFromDamageInfo(horizonDamage, picture);
  const isArchived = Boolean(task?.archived);
  const vendorOrganization =
    workOrder && 'vendorOrganization' in workOrder ? workOrder.vendorOrganization : undefined;

  return {
    workOrder,
    campaign,
    damage,
    horizonDamage,
    targets,
    // The inferred types from these two different assets are not mapping well.
    asset: (taskAsset ?? damageAsset ?? horizonDamageAsset) as any,
    forOrganization,
    logSchema,
    typeName,
    inspectionType,
    picture,
    isArchived,
    vendorOrganization,
  };
}

export function mapUpcaseTaskTypeOptionToTypeName(
  type: AtlasGqlWorkContainerTypeOption | undefined
): string {
  if (!type) return '';
  switch (type) {
    case AtlasGqlWorkContainerTypeOption.OtherTask: {
      // This one type is why we need this function.
      // Other task is the only type that's Type Option does not match the type name.
      return OTHER_TASK_TYPE_NAME;
    }
    default: {
      return camelCase(type);
    }
  }
}

/**
 * Helper function that attempts to determine the type of an Inspection as related to a HorizonDamge or Picture
 * @param horizonDamage the HorizonDamage from which the inspection type should be extracted
 * @param legacyPicture the Picture object (likely extracted from a Legacy Damage) from which the inspection type should be extracted
 * @returns a string of the inspection type, or undefined if no inspection type found
 */
function getInspectionTypeFromDamageInfo(
  horizonDamage: any | undefined,
  legacyPicture: any | undefined
): string | undefined {
  // Attempt to resolve from HorizonDamage if we can
  if (horizonDamage && horizonDamage.observationGroups?.length) {
    const obsGroups = horizonDamage?.observationGroups[0];
    const observation = obsGroups?.observations ? obsGroups.observations[0] : undefined;
    return observation?.inspection?.type?.name ?? undefined;
  }
  // Fall back to legacy damage if HorizonDamage doesn't work
  if (legacyPicture) {
    return legacyPicture?.inspection?.type?.name;
  }
  // If no observation information can be resolved from damage, we cannot determine inspectionType
  return undefined;
}

import { useState, useMemo, useEffect } from 'react';
import { flatten, startCase, lowerCase, intersection, omitBy, isUndefined } from 'lodash';
import { Form, Select, Input } from 'antd';
import { Button, Tooltip } from 'components/ui';
import styled from 'styled-components';
import { ReportContext, ReportTemplatesForContext } from './types';

import {
  useGetReportDeliveryModesQuery,
  AtlasGqlGetReportDeliveryModesQuery,
} from 'types/atlas-graphql';
import { SelectValue } from 'antd/lib/select';

import { storage } from 'utils/storage';

export interface SubmitReportTemplateProp {
  id: string;
  variantId?: string;
}

export interface SubmitAdvancedReportProps {
  deliveryModeId: string;
  reportTemplates: Array<SubmitReportTemplateProp>;
  hermesId: string;
}

interface Props {
  templates: ReportTemplatesForContext[];
  onSubmit: (props: SubmitAdvancedReportProps) => Promise<void>;
  onCancel: () => void;
  cacheKey?: string | null;
  reportContexts: ReportContext[];
}

const FormActions = styled.div`
  display: flex;
  button:first-child {
    margin: 0 0.5rem 0 auto;
  }
`;

const { Option } = Select;

function getFormattedName({ context, name }: { context: string; name: string }) {
  return `${startCase(lowerCase(context))}: ${name}`;
}

function isHermesAPIDeliveryModeSelected({
  selectedId,
  options,
}: {
  selectedId: string;
  options?: AtlasGqlGetReportDeliveryModesQuery['reportDeliveryModes'];
}) {
  return options?.find(mode => mode.id === selectedId)?.name === 'Hermes API';
}

interface VariantIdLookup {
  [key: string]: string;
}

export function AdvancedReportForm(props: Props) {
  const { templates, onSubmit, onCancel, cacheKey, reportContexts } = props;
  const [form] = Form.useForm();

  const { data, loading: deliveryModesLoading } = useGetReportDeliveryModesQuery();
  const customerId = storage.getItem('customerId');
  const cachedReportConfig = cacheKey
    ? storage.getItem(`reports-${customerId}-${cacheKey}`, true)
    : null;

  let cachedTemplateIds = [],
    cachedDeliveryModeId = null,
    cachedVariantIds = {};

  cachedTemplateIds = cachedReportConfig?.reportTemplates?.map(({ id }) => id) || [];
  cachedDeliveryModeId = cachedReportConfig?.deliveryModeId;
  cachedVariantIds =
    cachedReportConfig?.reportTemplates?.reduce((acc, { id, variantId }) => {
      acc[id] = variantId;
      return acc;
    }, {}) || {};

  const [templateIds, setTemplateIds] = useState<SelectValue[]>(cachedTemplateIds);
  const [variantIdLookup, setVariantIdLookup] = useState<VariantIdLookup>(cachedVariantIds);
  const [showHermesIdInputField, setShowHermesIdInputField] = useState<Boolean>(
    isHermesAPIDeliveryModeSelected({
      selectedId: cachedDeliveryModeId,
      options: data?.reportDeliveryModes,
    })
  );
  const [isValidHermesId, setIsValidHermesId] = useState(false);

  const values = form.getFieldsValue(true);
  useEffect(() => {
    const { deliveryModeId } = values;
    if (!deliveryModeId) return;

    const allTemplates = flatten((templates || []).map(({ templates }) => templates)).map(
      ({ template }) => template
    );

    let clearField = false;
    (templateIds || []).forEach(id => {
      const template = allTemplates.find(t => t.id === id);
      if (!template?.deliveryCompatibility?.some(d => d.id === deliveryModeId)) {
        clearField = true;
      }
    });

    if (clearField) {
      form.setFieldsValue({ deliveryModeId: null });
    }
  }, [form, templates, templateIds]);

  // Validate delivery mode when other fields are updated
  useEffect(() => {
    const { deliveryModeId } = values;
    setShowHermesIdInputField(
      isHermesAPIDeliveryModeSelected({
        selectedId: deliveryModeId,
        options: data?.reportDeliveryModes,
      })
    );
  }, [values]);

  const handleValuesChange = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];

    if (fieldName === 'templateIds') {
      const value = changedValues[fieldName];
      setTemplateIds(value);
      form.setFieldsValue({ deliveryModeId: null }); // Clear delivery mode when templates are cleared
    } else if (fieldName === 'deliveryModeId') {
      // Validate delivery mode to toggle visibility of Hermes Inspection ID
      setShowHermesIdInputField(
        isHermesAPIDeliveryModeSelected({
          selectedId: changedValues[fieldName],
          options: data?.reportDeliveryModes,
        })
      );
    } else if (fieldName === 'hermesId') {
      setIsValidHermesId(Boolean(changedValues[fieldName]));
    }
  };
  const handleChangeVariant = (templateId: any, variantId: any) => {
    setVariantIdLookup({
      ...variantIdLookup,
      [templateId]: variantId,
    });
  };

  const handleFinish = ({
    templateIds,
    deliveryModeId,
    hermesId,
  }: {
    templateIds: string[];
    deliveryModeId: string;
    hermesId: string;
  }) => {
    const reportTemplates = templateIds.map((id: string) => ({
      id,
      variantId: variantIdLookup[id],
    }));
    if (cacheKey) {
      storage.setItem(
        `reports-${customerId}-${cacheKey}`,
        { reportTemplates, deliveryModeId },
        true
      );
    }
    return onSubmit({ deliveryModeId, reportTemplates, hermesId });
  };

  const reportDeliveryModes = data?.reportDeliveryModes;

  const variantDropdowns = templateIds
    ?.map(tId => {
      const allTemplates = flatten((templates || []).map(({ templates }) => templates));
      const thisTemplate = allTemplates.find(t => t.template.id === tId);

      const template = thisTemplate?.template;
      const variants = template?.variants;

      if (!template || !variants || !variants.length) {
        return null;
      }

      return (
        <Form.Item
          label={`${getFormattedName({ context: template.context, name: template.name })} variant`}
          name={template.id}
          key={template.id}
        >
          <Select
            allowClear
            placeholder="Leave blank for default"
            onChange={variantId => handleChangeVariant(tId, variantId)}
          >
            {variants.map(({ id, name }: { id: string; name: string }) => {
              return (
                <Option key={id} value={id}>
                  {name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      );
    })
    .filter(Boolean);

  const deliveryModeOptions = useMemo(() => {
    const allTemplates = flatten((templates || []).map(({ templates }) => templates));
    const activeTemplates = allTemplates.filter(t => (templateIds || []).includes(t.template.id));

    // Only allow use of delivery modes that are compatible with all the selected
    // report templates. (ie. the intersection)
    const allowedDeliveryModes = intersection(
      ...activeTemplates.map(t => (t.template.deliveryCompatibility || []).map(({ id }) => id))
    );

    return (reportDeliveryModes || []).map(({ id, name }) => {
      const disabled = !allowedDeliveryModes.includes(id);
      return (
        <Option key={id} value={id} disabled={disabled}>
          <Tooltip
            title={disabled ? `Some templates are not compatible with delivery by ${name}` : null}
          >
            {name}
          </Tooltip>
        </Option>
      );
    });
  }, [templateIds, templates, reportDeliveryModes]);

  const isRBTAugmentedVariantSelected = () => {
    const allTemplates = flatten((templates || []).map(({ templates }) => templates));

    // Remove undefined values and create a set of unique variant IDs
    const variantsIdSet = new Set(Object.values(omitBy(variantIdLookup, isUndefined)));
    const filteredVariants = allTemplates.reduce((accumulator, current) => {
      // Extract the variants from the current template
      const variants = current.template.variants;

      // Filter variants and add to the accumulator
      const filtered = variants?.filter(variant => variantsIdSet.has(variant.id));
      return accumulator.concat(filtered);
    }, []);

    return filteredVariants.some(variant => variant.name === 'RBT Augmented');
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      onValuesChange={handleValuesChange}
      initialValues={{
        templateIds: cachedTemplateIds,
        deliveryModeId: cachedDeliveryModeId,
        ...cachedVariantIds,
      }}
    >
      <Form.Item
        key="templateIds"
        label="Templates"
        name="templateIds"
        rules={[{ required: true, message: 'Please select one or more templates.' }]}
      >
        <Select allowClear={true} mode="multiple" placeholder="Select report templates">
          {flatten((templates || []).map(({ templates }) => templates)).map(
            ({ template, disabled }) => {
              const { id, name, itemLimit, context } = template;
              const matchingContext = reportContexts.find(
                reportContext => reportContext?.context === context
              );
              return (
                <Option key={id} value={id} disabled={disabled}>
                  <Tooltip
                    title={
                      disabled
                        ? matchingContext?.toolTip
                          ? matchingContext?.toolTip
                          : `${getFormattedName({
                              context,
                              name,
                            })} reports are limited to ${itemLimit} items`
                        : null
                    }
                  >
                    {getFormattedName({ context, name })}
                  </Tooltip>
                </Option>
              );
            }
          )}
        </Select>
      </Form.Item>
      {variantDropdowns}
      {templateIds?.length ? (
        <Form.Item key="deliveryModeId" label="Delivery Mode" name="deliveryModeId">
          <Select allowClear placeholder="Select delivery mode" loading={deliveryModesLoading}>
            {deliveryModeOptions}
          </Select>
        </Form.Item>
      ) : undefined}
      {showHermesIdInputField && isRBTAugmentedVariantSelected() ? (
        <Form.Item
          name="hermesId"
          label="Hermes Inspection ID"
          rules={[{ required: true, message: 'Please enter hermes inspection id.' }]}
        >
          <Input placeholder="Enter inspection ID" />
        </Form.Item>
      ) : undefined}
      <FormActions>
        <Button
          _version={4}
          type="primary"
          htmlType="submit"
          disabled={
            showHermesIdInputField && isRBTAugmentedVariantSelected()
              ? !templateIds?.length || !isValidHermesId
              : !templateIds?.length
          }
        >
          Create
        </Button>
        <Button _version={4} onClick={onCancel}>
          Cancel
        </Button>
      </FormActions>
    </Form>
  );
}

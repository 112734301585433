import { useState, useEffect } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { PageHeaderLayout } from 'components/PageHeaderLayout';
import { useApolloContext } from 'utils/apollo';
import queryString from 'query-string';

// If you reset apollo cache and there are queries in flight, then the queries
// return the results for the old customer and that gets shown in horizon. This
// is smelly but it is Apollo's fault.
function Reset() {
  const { resetStore } = useApolloContext();
  const { search, state } = useLocation<{ redirectUrl?: string }>();

  let redirectUrl = state?.redirectUrl;

  if (!redirectUrl && search) {
    const parsed = queryString.parse(search);
    redirectUrl = parsed.redirectUrl as string | undefined;
  }

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    (async () => {
      await resetStore();
      setInitialized(true);
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return initialized ? <Redirect to={redirectUrl || '/'} /> : <PageHeaderLayout />;
}

export default Reset;

import { RefObject, useEffect, useRef } from 'react';
import { InputRef } from 'antd5';
import { BaseSelectRef } from 'rc-select';
import { useIsInView } from 'utils/hooks';

export const AutoFocus: React.FunctionComponent<{
  elementToFocus: RefObject<InputRef | BaseSelectRef | HTMLElement>;
}> = ({ children, elementToFocus }) => {
  const ref = useRef(null);
  const isInView = useIsInView(ref);

  useEffect(() => {
    if (isInView) {
      elementToFocus.current?.focus();
    }
  }, [isInView]);

  return <span ref={ref}>{children}</span>;
};

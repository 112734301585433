import { ForwardRefExoticComponent, RefAttributes } from 'react';
import styled from 'styled-components';
import { FileExclamationOutlined } from '@ant-design/icons';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';

const Container = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.secondaryTextColor};
  padding: 1rem;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface EmptyStateProps {
  icon?: ForwardRefExoticComponent<AntdIconProps & RefAttributes<HTMLSpanElement>>;
  text?: string;
  action?: any;
}

const EmptyState = ({
  icon: Icon = FileExclamationOutlined,
  text = `There's nothing here`,
  action = null,
}: EmptyStateProps) => (
  <Container data-testid="inspection-empty-state-container">
    <Inner>
      <Icon
        style={{
          fontSize: '3rem',
          margin: '1rem',
        }}
      />
      {text}
      {action}
    </Inner>
  </Container>
);

export default EmptyState;

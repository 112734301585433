import { useEffect, useState } from 'react';

import { AtlasGqlHorizonDamage, AtlasGqlHorizonDamageObservation } from 'types/atlas-graphql';
import { getPrimaryObservationForDamage } from 'horizon/components/Damages/utils';
import { ComparerPane } from '../ComparerPane';
import { DamageComparerDetail } from './DamageComparerDetail';

type DamageComparerPaneProps = {
  paneSide: 'left' | 'right';
  damages: AtlasGqlHorizonDamage[];
  selectedDamageId: string;
  setSelectedDamageId: (id: string) => void;
  disabledDamageIds: string[];
};

export const DamageComparerPane: React.FunctionComponent<DamageComparerPaneProps> = ({
  paneSide,
  damages,
  selectedDamageId,
  setSelectedDamageId,
  disabledDamageIds,
}) => {
  const selectedDamage: AtlasGqlHorizonDamage | undefined = damages.find(
    ({ id }) => id === selectedDamageId
  );
  const [selectedObservation, setSelectedObservation] = useState<
    AtlasGqlHorizonDamageObservation | undefined
  >();

  useEffect(() => {
    const observation: AtlasGqlHorizonDamageObservation | null | undefined =
      selectedDamage && getPrimaryObservationForDamage(selectedDamage);

    if (observation) {
      setSelectedObservation(observation);
    }
  }, [selectedDamage]);

  return (
    <>
      {selectedDamage && selectedObservation && (
        <ComparerPane observation={selectedObservation}>
          <DamageComparerDetail
            paneSide={paneSide}
            damages={damages}
            disabledDamageIds={disabledDamageIds}
            selectedDamage={selectedDamage}
            selectedObservation={selectedObservation}
            setSelectedDamageId={setSelectedDamageId}
            setSelectedObservation={setSelectedObservation}
          />
        </ComparerPane>
      )}
    </>
  );
};

import {
  AtlasGqlAttachmentRecordType,
  useGetAttachmentCategoriesForTypeQuery,
} from 'types/atlas-graphql';
import { Spinner } from 'components/ui';
import { Select } from 'antd5';

export const CATEGORY_NONE_VALUE_STRING = 'none';

interface AttachmentCategoryChooserProps {
  type: AtlasGqlAttachmentRecordType;
  defaultCategoryId?: string | null;
  onChange: (attachmentCategory: any) => void;
  disabled?: boolean;
}

export const AttachmentCategoryChooser: React.FunctionComponent<AttachmentCategoryChooserProps> = ({
  type,
  defaultCategoryId,
  onChange,
  disabled = false,
}) => {
  const { data, loading } = useGetAttachmentCategoriesForTypeQuery({
    variables: { input: { type } },
  });

  const categoryOptions =
    data?.categoriesForType.map(cat => {
      return { value: cat.id, label: cat.name };
    }) ?? [];

  // Set None value to a default string as AntD gets mad if it's null. This must be handled and remapped in onChange from the parent.
  const allOptions = [{ value: CATEGORY_NONE_VALUE_STRING, label: 'None' }, ...categoryOptions];

  if (loading) {
    return <Spinner />;
  }

  return (
    <Select
      data-testid="attachment-category-chooser"
      placeholder="None"
      onChange={onChange}
      options={allOptions}
      defaultValue={defaultCategoryId}
      disabled={disabled}
    />
  );
};

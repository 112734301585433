import { useRef, useEffect, useState, FC, useCallback } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useAccountContext } from 'utils/account/AccountContext';
import { usePermissions } from 'utils/usePermissions';
import { COMMENT_CREATE } from 'utils/access-control/rules';
import { Spinner } from 'components/ui';
import Comment from './Comment';
import AddComment from './AddComment';
import EditComment from './EditComment';
import DeleteComment from './DeleteComment';
import { UserAvatar } from 'components/UserAvatar';
import { AtlasGqlGetCommentsQuery, AtlasGqlUpdateCommentInput } from 'types/atlas-graphql';
import { SingularElement } from 'utils/types';
import { RefetchConfig } from './refetchConfig';

interface StyledCommentsProps {
  columnLayout?: boolean;
  isAtTop?: boolean;
}

const StyledCommentsWrapper = styled.div<StyledCommentsProps>`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  display: ${({ columnLayout }) => columnLayout && 'flex'};

  .ant-comment-content-author-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-comment-actions {
    margin: 0.5rem 0 0 0;
  }

  .ant-comment-inner {
    padding: 0.5rem 0;
  }

  .ant-comment-content-detail {
    white-space: break-spaces;
  }

  // to fix the styling in Firefox as a result of the white-space property
  .ant-row::before,
  .ant-row::after,
  .ant-legacy-form-item-control::before,
  .ant-legacy-form-item-control::after {
    display: unset;
    content: unset;
  }

  ${({ isAtTop }) =>
    !isAtTop &&
    `
    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
      height: 10px;
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }
  `}
`;

const StyledSpinner = styled(Spinner)`
  min-height: unset;
`;

const FromNow: FC<{
  timestamp: number;
}> = ({ timestamp }) => {
  const time = moment.unix(timestamp);
  return <span title={time.format('lll')}>{time.fromNow()}</span>;
};

export type CommentThread = AtlasGqlGetCommentsQuery['comments'];
export type SingleComment = SingularElement<CommentThread>;

export interface CommentListProps {
  comments: CommentThread;
  className?: string;
  onSave?: (text: string) => void;
  onEdit?: (input: AtlasGqlUpdateCommentInput) => void;
  editId?: string | null;
  saveLoading?: boolean;
  editLoading?: boolean;
  loading?: boolean;
  columnLayout?: boolean;
  createRule?: string[];
  areWriteActionsVisible?: boolean;
  refetchConfig?: RefetchConfig;
}

const CommentList: FC<CommentListProps> = ({
  comments = [],
  className,
  onSave,
  onEdit,
  editId,
  saveLoading,
  editLoading,
  loading,
  columnLayout,
  createRule = [COMMENT_CREATE],
  areWriteActionsVisible = true,
  refetchConfig = {},
}) => {
  const { user } = useAccountContext();
  const { CanRender } = usePermissions();
  const commentsRef = useRef<HTMLDivElement | null>(null);
  const [isAtTop, setIsAtTop] = useState(true);
  const [commentsLength, setCommentsLength] = useState(0);

  useEffect(() => {
    const commentsNode = commentsRef.current;
    if (!commentsNode) return;
    const commentCount = comments?.length ?? 0;
    // Check if the comments length has increased
    if (commentCount > commentsLength) {
      commentsNode.scrollTop = commentsNode.scrollHeight;
    }
    setCommentsLength(commentCount);
    const scrollListener = () => {
      if (commentsNode.scrollTop === 0) {
        setIsAtTop(true);
      } else {
        setIsAtTop(false);
      }
    };
    commentsNode.addEventListener('scroll', scrollListener);
    return () => {
      commentsNode.removeEventListener('scroll', scrollListener);
    };
  }, [comments, commentsLength]);

  const getActions = useCallback(
    (comment: SingleComment): JSX.Element[] => {
      const actions: JSX.Element[] = [];
      if (!comment) return actions;
      const isAuthor = comment.author?.id === user.id;
      if (isAuthor)
        actions.push(
          <DeleteComment
            id={comment.id}
            hideDeleteIcon
            type="link"
            deleteBtnCss={{
              fontSize: '0.75rem !important',
              paddingLeft: '0 !important',
              height: 'unset !important',
            }}
            refetchConfig={refetchConfig}
          />
        );
      if (isAuthor && onEdit) {
        actions.push(<EditComment comment={comment} onEdit={onEdit} loading={editLoading} />);
      }
      return actions;
    },
    [user, onEdit, editLoading, refetchConfig]
  );

  return (
    <StyledCommentsWrapper className={className} columnLayout={columnLayout} isAtTop={isAtTop}>
      <div data-testid="comments" ref={commentsRef}>
        {loading ? (
          <StyledSpinner />
        ) : comments?.length ? (
          comments.map(comment => {
            const { id, author, text, timestamp } = comment;
            const name =
              author?.firstName && author?.lastName
                ? `${author.firstName} ${author.lastName}`
                : null;
            // we want to fallback to authorEmail in case author (User) resolves to null
            const email = author?.email ?? comment.authorEmail;
            const title = name || email || '(Invalid User)';
            return (
              <Comment
                className={`testid-comment-${id}`}
                key={id}
                avatar={author && <UserAvatar user={author} />}
                author={<span title={title}>{title}</span>}
                datetime={<FromNow timestamp={Number(timestamp) / 1000} />}
                actions={areWriteActionsVisible ? getActions(comment) : []}
                content={editLoading && id === editId ? <StyledSpinner /> : text}
              />
            );
          })
        ) : (
          <em>no comments yet...</em>
        )}
        {saveLoading && <StyledSpinner />}
      </div>
      <CanRender
        rules={createRule}
        render={() =>
          areWriteActionsVisible && onSave ? (
            <AddComment onSave={onSave} columnLayout={columnLayout} />
          ) : null
        }
      />
    </StyledCommentsWrapper>
  );
};

export default CommentList;

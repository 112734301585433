import styled from 'styled-components';
import { Tag } from 'antd';
import { TeamOutlined } from '@ant-design/icons';

export const CollaboratorsCompactIcon = styled(TeamOutlined)`
  font-size: 1.25rem;
`;

export const CollaboratorsTag = styled(Tag)`
  max-width: 100%;
`;

export const WorkOrderNumberAndCollabIcon = styled(TeamOutlined)`
  margin-right: 5px;
  font-size: 1.15em;
`;

export const WorkNumberContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

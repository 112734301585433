import { useEffect, useState } from 'react';

import { Select } from 'antd';
import { sortBy, uniqBy } from 'lodash';

import { getStrippedUniqueIdLabel } from 'horizon/components/Assets/AssetDetails/helpers';
import { Spinner } from 'components/ui';
import { AtlasGqlAsset, useGetAssetsForAssetPickerQuery } from 'types/atlas-graphql';

import { AssetHierarchyItem, AssetTypeOption } from './types';
import { FormFieldContainer, StyledSelect } from './AssetPicker.style';

type AssetPickerItemProps = {
  assetType?: AssetTypeOption;
  defaultValue?: string;
  disabled?: boolean;
  disableSubAssetUntilAssetTypeIsSelected?: boolean;
  filterAssetTypeOption?: (asset: AtlasGqlAsset, parentAsset?: AssetHierarchyItem) => boolean;
  onChange: (asset: AssetHierarchyItem) => void;
  onChangeAssetType?: () => void;
  parentId?: string;
  parentHierarchyAsset?: AssetHierarchyItem;
  testId?: string;
};

export const AssetPickerItem: React.FunctionComponent<AssetPickerItemProps> = ({
  assetType,
  defaultValue,
  disabled = false,
  disableSubAssetUntilAssetTypeIsSelected = false,
  filterAssetTypeOption,
  onChange,
  onChangeAssetType,
  parentId,
  parentHierarchyAsset,
  testId,
}) => {
  const [workingAssetType, setWorkingAssetType] = useState<AssetTypeOption>();
  const { data, loading } = useGetAssetsForAssetPickerQuery({
    variables: {
      // @ts-ignore
      filterBy: [
        assetType?.id && { key: 'assetTypeIds', values: [assetType.id] },
        parentId && { key: 'childrenOfIds', values: [parentId] },
      ].filter(Boolean),
    },
  });

  const options = sortBy(
    data?.assetsWithMetadata?.items
      ?.filter(
        (asset: AtlasGqlAsset) => !workingAssetType || asset?.assetType?.id === workingAssetType.id
      )
      ?.map((asset: AtlasGqlAsset) => ({
        assetId: asset.id,
        name: getStrippedUniqueIdLabel(asset),
        assetTypeId: asset.assetType?.id,
        assetTypeName: asset.assetType?.name,
      })) ?? [],
    'name'
  );

  const assetTypeOptions = uniqBy(
    data?.assetsWithMetadata?.items
      ?.filter((asset: AtlasGqlAsset) =>
        filterAssetTypeOption ? filterAssetTypeOption(asset, parentHierarchyAsset) : true
      )
      .map((asset: AtlasGqlAsset) => asset.assetType) ?? [],
    id => id
  ).filter(Boolean);

  useEffect(() => {
    if (defaultValue && !assetType) {
      const defaultValueAssetTypeId = options.find(
        ({ assetId }) => assetId === defaultValue
      )?.assetTypeId;

      if (defaultValueAssetTypeId) {
        handleChangeAssetType(defaultValueAssetTypeId, true);
      }
    }
  }, [defaultValue, options]);

  const handleChange = (assetId: string): void => {
    const selectedAsset = options.find(option => option.assetId === assetId);

    if (selectedAsset) {
      onChange(selectedAsset);

      handleChangeAssetType(selectedAsset.assetTypeId);
    }
  };

  const handleChangeAssetType = (typeId: string, isSettingDefault?: boolean) => {
    const selectedType = assetTypeOptions.find(assetType => assetType?.id === typeId);

    if (selectedType) setWorkingAssetType(selectedType);

    /**
     * Parent change handler should not be called when the default (i.e.
     * existing value) is being selected
     */
    if (onChangeAssetType && workingAssetType?.id !== selectedType?.id && !isSettingDefault) {
      onChangeAssetType();
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (!options?.length) {
    return null;
  }

  return (
    <FormFieldContainer>
      {!assetType && (
        <StyledSelect
          data-testid={`asset-picker-asset-type${testId ? `-${testId}` : ''}`}
          placeholder="Select Asset Type"
          onChange={(typeId: string) => handleChangeAssetType(typeId)}
          value={workingAssetType?.id}
          disabled={disabled}
          virtual={false}
        >
          {assetTypeOptions.map(assetType => (
            <Select.Option key={assetType?.id} value={assetType?.id}>
              {assetType?.name}
            </Select.Option>
          ))}
        </StyledSelect>
      )}
      <StyledSelect
        data-testid={`asset-picker-asset${testId ? `-${testId}` : ''}`}
        showSearch
        placeholder={`Select ${assetType?.name ?? workingAssetType?.name ?? 'sub-asset'}`}
        optionFilterProp="name"
        onChange={handleChange}
        filterOption={(input, option) =>
          option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={defaultValue}
        disabled={
          disabled ||
          (disableSubAssetUntilAssetTypeIsSelected && !workingAssetType?.id && !assetType)
        }
        virtual={false}
      >
        {options.map(({ assetId, name }) => (
          <Select.Option key={assetId} value={assetId} name={name}>
            {name}
          </Select.Option>
        ))}
      </StyledSelect>
    </FormFieldContainer>
  );
};

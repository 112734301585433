import { useEffect, useCallback, Fragment } from 'react';
import { useUploadContext } from 'components/FileUploader';
import { FileUploadModal } from 'components/FileUploader/Modal/FileUploadModal';
import { ConfirmationModal } from 'components/ConfirmationModal';

export function Portals() {
  const { modalProps, setModalProps, uploading, confirmProps, setConfirmProps } =
    useUploadContext();

  const handleConfirm = useCallback(
    event => {
      if (uploading) {
        event.preventDefault();
        event.returnValue = true;
      }
    },
    [uploading]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleConfirm);

    return () => {
      window.removeEventListener('beforeunload', handleConfirm);
    };
  }, [uploading, handleConfirm]);

  function handleOk() {
    setModalProps(prevState => ({ ...prevState, canceledUpload: true }));

    window.removeEventListener('beforeunload', handleConfirm);

    confirmProps.onOk && confirmProps.onOk();
    setConfirmProps(null);
  }

  return (
    <Fragment>
      {modalProps ? <FileUploadModal {...modalProps} /> : null}
      {confirmProps ? (
        <ConfirmationModal
          {...confirmProps}
          visible
          onCancel={() => setConfirmProps(null)}
          onOk={handleOk}
        />
      ) : null}
    </Fragment>
  );
}

import { useCallback } from 'react';
import {
  ManagedComponentChooserInput,
  ManagedComponentChooserResult,
  ManagedChangeInput,
} from './types';
import { useActiveCustomerId, useCurrentCustomerId } from 'utils/account/hooks';
import { storage } from 'utils/storage';
import { useImmer } from 'use-immer';

export const useManagedComponentChooser = ({
  componentChooserOnChangeCallback,
  componentId: _componentId,
}: ManagedComponentChooserInput = {}): ManagedComponentChooserResult => {
  const [state, setState] = useImmer<{
    siteIds?: string[];
    turbineIds?: string[];
    componentTypes?: string[];
    componentIds?: string[];
    assetIds?: string[];
    initialized: boolean;
  }>({
    siteIds: undefined,
    turbineIds: undefined,
    componentTypes: undefined,
    componentIds: undefined,
    assetIds: undefined,
    initialized: false,
  });

  const [customerId] = useCurrentCustomerId();
  const [activeCustomerId] = useActiveCustomerId();
  const componentId = _componentId && `${_componentId}::${customerId}`;

  const loadState = useCallback(() => {
    if (componentId) {
      const state = storage.getItem(componentId, true);
      return state || {};
    }
    return {};
  }, [componentId]);

  const doSaveState = useCallback(
    (data: any) => {
      // Kinda hacky, but this prevents a save from happening with the wrong customerId
      // if the component is rerendered while the user is switching customers in another tab.
      if (activeCustomerId !== customerId) {
        return;
      }

      if (state.initialized && componentId) {
        storage.setItem(componentId, data, true);
      }
    },
    [state.initialized, componentId, customerId, activeCustomerId]
  );

  const handleAssetChooserChange = useCallback(
    ({ siteIds, turbineIds, componentTypes, componentIds, assetIds }: ManagedChangeInput) => {
      doSaveState({ siteIds, turbineIds, componentTypes, componentIds, assetIds });
      setState(draft => {
        draft.initialized = true;
        draft.siteIds = siteIds;
        draft.turbineIds = turbineIds;
        draft.componentTypes = componentTypes;
        draft.componentIds = componentIds;
        draft.assetIds = assetIds;
      });

      if (componentChooserOnChangeCallback) {
        componentChooserOnChangeCallback({
          siteIds,
          turbineIds,
          componentTypes,
          componentIds,
          assetIds,
        });
      }
    },
    [doSaveState, componentChooserOnChangeCallback, setState]
  );

  const handleClearAssetChooserSelection = useCallback(() => {
    doSaveState({});
    setState(draft => {
      draft.initialized = true;
      draft.siteIds = undefined;
      draft.turbineIds = undefined;
      draft.componentTypes = undefined;
      draft.componentIds = undefined;
      draft.assetIds = undefined;
    });
    if (componentChooserOnChangeCallback) {
      componentChooserOnChangeCallback({ assetIds: null });
    }
  }, [doSaveState, componentChooserOnChangeCallback, setState]);

  return {
    onChange: handleAssetChooserChange,
    onClearSelected: handleClearAssetChooserSelection,
    loadState,
    componentChooserState: state,
    setComponentChooserState: setState,
  };
};

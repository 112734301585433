import { Popover } from 'antd'; // Intentionally left on antd v4 for style consistency
import { StyledPopoverTable } from './Popovers.style';

import { getFilterDropdown, getOnFilter, sortBy, None } from 'components/data/helpers';
import { STANDARD_COLUMN_SCROLL_Y, TABLE_SCROLL_WIDTH_SMALL } from 'utils/constants';
import { TTableColumnDef } from 'horizon/types/TableColumnDef';
import { AtlasGqlHorizonDamageObservation } from 'types/atlas-graphql';
import { ObservationLink } from 'horizon/components/Damages/utils';

const observationName: TTableColumnDef<AtlasGqlHorizonDamageObservation> = {
  key: 'observation',
  title: 'Observation',
  dataIndex: 'observation',
  render: (_, observation) => {
    const observationId = observation.id;
    const pictureId = observation.picture?.id;

    return observationId ? (
      <ObservationLink observationId={observationId} pictureId={pictureId} />
    ) : (
      <None />
    );
  },
};

const dateFilterConfig = {
  key: 'inspection.inspectionDate',
  type: 'date',
  filterAttr: '',
  exactMatch: false,
  values: '',
  includeNoneOption: false,
};

const observationDate: TTableColumnDef<AtlasGqlHorizonDamageObservation> = {
  key: 'date',
  title: 'Date',
  dataIndex: 'date',
  sorter: sortBy('inspection.inspectionDate'),
  defaultSortOrder: 'descend',
  filterDropdown: getFilterDropdown(dateFilterConfig),
  onFilter: getOnFilter(dateFilterConfig),
  filterProperty: 'inspection.inspectionDate',
  render: (_, observation) => {
    const date = observation.inspection?.inspectionDate;
    return date ? date : <None />;
  },
};

type ObservationsPopoverTableProps = {
  observations: AtlasGqlHorizonDamageObservation[];
};

export const ObservationsPopoverTable: React.FunctionComponent<ObservationsPopoverTableProps> = ({
  observations,
}) => {
  return observations.length ? (
    <Popover
      content={
        <StyledPopoverTable
          data-testid="observations-popover-table"
          columns={[observationDate, observationName]}
          dataSource={observations}
          size="small"
          rowKey="id"
          pagination={false}
          scroll={STANDARD_COLUMN_SCROLL_Y}
          style={{ width: TABLE_SCROLL_WIDTH_SMALL }}
        />
      }
    >
      {observations.length}
    </Popover>
  ) : (
    <None />
  );
};

import { Link } from 'react-router-dom';

import {
  status as commonStatus,
  shortDescription as commonShortDescription,
  description as commonDescription,
  name as commonName,
  dueDate as commonDueDate,
  createdAndCreatedBy as commonCreatedAndCreatedBy,
} from './common';
import { None } from '../helpers';
import {
  WORK_CAMPAIGN_EDIT_NAME,
  WORK_CAMPAIGN_EDIT_DESCRIPTION,
  WORK_CAMPAIGN_EDIT_STATUS,
  WORK_CAMPAIGN_EDIT_DUE_DATE,
} from 'utils/access-control/rules';

export { created, createdBy } from './common';

export const name = {
  ...commonName,
  editRule: WORK_CAMPAIGN_EDIT_NAME,
  onHeaderCell: () =>
    ({
      'data-testid': 'campaign-name-header',
    }) as any,
};

export const createdAndCreatedBy = {
  ...commonCreatedAndCreatedBy,
  onHeaderCell: () =>
    ({
      'data-testid': 'campaign-created-and-by-header',
    }) as any,
};

export const shortDescription = {
  ...commonShortDescription,
  editRule: WORK_CAMPAIGN_EDIT_DESCRIPTION,
  onHeaderCell: () =>
    ({
      'data-testid': 'campaign-short-description-header',
    }) as any,
};

export const description = {
  ...commonDescription,
  editRule: WORK_CAMPAIGN_EDIT_DESCRIPTION,
  onHeaderCell: () =>
    ({
      'data-testid': 'campaign-description-header',
    }) as any,
};

export const dueDate = {
  ...commonDueDate,
  editRule: WORK_CAMPAIGN_EDIT_DUE_DATE,
  onHeaderCell: () =>
    ({
      'data-testid': 'campaign-due-date-header',
    }) as any,
};

export const status = {
  ...commonStatus,
  editRule: WORK_CAMPAIGN_EDIT_STATUS,
  onHeaderCell: () =>
    ({
      'data-testid': 'campaign-status-header',
    }) as any,
};

export const workCampaignNumber = {
  key: 'workCampaignNumber',
  title: 'Campaign',
  render: (record: any) => {
    const { id, number } = record || {};
    return [id, number].every(Boolean) ? <Link to={`/campaigns/${id}`}> {number} </Link> : <None />;
  },
  onHeaderCell: () =>
    ({
      'data-testid': 'campaign-number-header',
    }) as any,
};

import { createContext, useState } from 'react';

export const ImageNavButtonDisabledContext = createContext({
  disableShortcutKeys: false,
  setDisableShortcutKeys: value => {},
});

export const ImageNavButtonDisabledProvider = ({ children }) => {
  const [disableShortcutKeys, setDisableShortcutKeys] = useState(false);

  const contextValue = { disableShortcutKeys, setDisableShortcutKeys };

  return (
    <ImageNavButtonDisabledContext.Provider value={contextValue}>
      {children}
    </ImageNavButtonDisabledContext.Provider>
  );
};

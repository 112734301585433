import { useState, useCallback } from 'react';
import { flatten } from 'lodash';
import { message } from 'antd'; // Intentionally left on antd v4 for style consistency

import { ApolloError } from '@apollo/client';
import {
  AtlasGqlHorizonDamage,
  useGetHorizonDamagesForMergingQuery,
  useMergeHorizonDamagesMutation,
} from 'types/atlas-graphql';
import { formatDamageId } from 'horizon/components/Damages/utils';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { Spinner } from 'components/ui';

export type MergeConfirmationState = {
  isOpen: boolean;
  targetDamageId?: string;
  sourceDamageIds?: string[];
};

type MergeDamagesModalProps = {
  mergeConfirmationState: MergeConfirmationState;
  onClose: () => void;
  onSuccess?: (damage: AtlasGqlHorizonDamage) => void;
  onError?: (error: ApolloError) => void;
};

export const MergeDamagesModal: React.FunctionComponent<MergeDamagesModalProps> = ({
  mergeConfirmationState,
  onClose,
  onSuccess,
  onError,
}) => {
  const [observationCount, setObservationCount] = useState<number>();
  const [taskCount, setTaskCount] = useState<number>();
  const [commentCount, setCommentCount] = useState<number>();
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [pollingCount, setPollingCount] = useState<number>(0);

  const { isOpen, targetDamageId, sourceDamageIds = [] } = mergeConfirmationState;

  const damageIdsToMerge = [targetDamageId, ...sourceDamageIds];

  // query for damages to be merged together (for validation)
  const { loading: damagesLoading } = useGetHorizonDamagesForMergingQuery({
    variables: { input: { ids: damageIdsToMerge }, limit: damageIdsToMerge.length },
    fetchPolicy: 'network-only',
    skip: !isOpen || damageIdsToMerge.length === 0,
    onCompleted: data => {
      const damages = data?.getHorizonDamages?.items;

      if (damages) {
        const observations = flatten(
          flatten(damages.map(d => d?.observationGroups?.map(g => g?.observations ?? [])))
        );
        const tasks = flatten(damages.map(d => d?.tasks));
        const comments = flatten(
          flatten(damages.map(d => d?.observationGroups?.map(g => g?.comments ?? [])))
        );

        setObservationCount(observations.length);
        setTaskCount(tasks.length);
        setCommentCount(comments.length);
      }
    },
  });

  // query for final merged damage (for validation)
  const { startPolling, stopPolling } = useGetHorizonDamagesForMergingQuery({
    variables: { input: { ids: [targetDamageId] }, limit: 1 },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !isPolling || !targetDamageId,
    onCompleted: data => {
      const damage = data?.getHorizonDamages?.items?.[0];

      // check that the tasks & comments have been properly updated
      validateMerge(damage as AtlasGqlHorizonDamage | undefined);

      // increment polling count
      setPollingCount(pollingCount + 1);
    },
  });

  // merge damage mutation
  const [mergeDamages, { loading: mergeLoading }] = useMergeHorizonDamagesMutation({
    onCompleted: data => {
      const damage = data.mergeHorizonDamages;

      // check that the tasks & comments have been properly updated
      validateMerge(damage as AtlasGqlHorizonDamage | undefined);
    },
    onError: error => {
      handleError(error);
    },
    refetchQueries: ['GetComments'],
  });

  // success handler
  const handleSuccess = useCallback(
    (damage: AtlasGqlHorizonDamage) => {
      message.success(
        <>
          Damage successfully linked to Damage{' '}
          <a href={`/damages2/${damage.id}`} target="_blank" rel="noreferrer">
            {formatDamageId({ id: damage.id })}
          </a>
        </>
      );

      onSuccess && onSuccess(damage);
      onClose();
    },
    [onSuccess, onClose]
  );

  // error handler
  const handleError = useCallback(
    (error: ApolloError) => {
      message.error('There was an error linking the damages. Please try again.');

      onError && onError(error);
      onClose();
    },
    [onError, onClose]
  );

  // checks that the tasks & comments have been properly updated
  const validateMerge = useCallback(
    (damage?: AtlasGqlHorizonDamage) => {
      if (!!damage) {
        const tasks = damage.tasks ?? [];
        const comments = flatten([...(damage.observationGroups ?? []).map(g => g?.comments ?? [])]);

        if ((tasks.length === taskCount && comments.length === commentCount) || pollingCount > 3) {
          stopPolling();
          setIsPolling(false);
          handleSuccess(damage);
        } else {
          startPolling(2000);
          setIsPolling(true);
        }
      }
    },
    [startPolling, stopPolling, pollingCount, taskCount, commentCount, handleSuccess]
  );

  return (
    <ConfirmationModal
      open={isOpen}
      loading={damagesLoading || mergeLoading || isPolling}
      title="Confirm Damage Link"
      okText="Link"
      onOk={() => {
        if (!!targetDamageId && !!sourceDamageIds) {
          mergeDamages({
            variables: {
              input: {
                targetDamageId,
                sourceDamageIds,
              },
            },
          });
        }
      }}
      cancelText="Cancel"
      onCancel={onClose}
      message={
        damagesLoading ? (
          <Spinner />
        ) : (
          `This action will link ${damageIdsToMerge.length} Damages, with ${observationCount} Observations` +
          ` and all associated Tasks and Comments, together under Damage ID ${formatDamageId({ id: targetDamageId })}.` +
          ` This action cannot be undone.`
        )
      }
      destroyOnClose
    />
  );
};

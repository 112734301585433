import { useContext, useRef, useEffect, useState, useCallback, useMemo } from 'react';

import { Table } from 'antd';
import styled from 'styled-components';
import { flatten, groupBy, orderBy, isEqual } from 'lodash';
import dragula from 'react-dragula';

import { EmptyState } from './EmptyState';
import { DataTableProps, DataTableContext } from './index';

import { archivedRowClassName, rePrioritizeRowsForDrop } from './utils';
import { ArchivedRowStyle, SelectedRowTransitionStyle } from './DataTable.style';

interface ClientDataTableProps extends Omit<DataTableProps, 'title'> {
  draggable?: boolean;
  onDragUpdate?: (orderedItems: any[]) => void;
  onTableChange?: (pagination: any, filters: any, sorter: any, extra: any) => void;
  isPrioritizing?: boolean;
  handleOrderUpdate?: (orderedItems: any[]) => void;
}

/**
 * "The danger is still present, in your time, as it was in ours.
 * @param param0
 * @returns
 */
export const ClientDataTable: React.FunctionComponent<ClientDataTableProps> = ({
  data,
  draggable,
  handleOrderUpdate,
  isPrioritizing,
  loading,
  onDragUpdate,
  onTableChange,
  rowKey,
  columns,
  ...props
}) => {
  const { filteredInfo, multiSortDef, updatePagination, initialPagination } =
    useContext(DataTableContext) || {};

  // We need to be able to keep track of when the drag and drop is being used
  // So that the table doesn't accidentally try to apply a multi-column sort and a drag and drop at the same time
  const [isUpdatingDrag, setIsUpdatingDrag] = useState(false);

  /**
   * Some columns control filters for multiple properties, e.g. damage severity and critical
   * status. See ./README for detail.
   *
   * In these cases, the column definition's `onFilter` cannot be applied directly. The filters
   * are grouped by property here, and their filter functions are pulled out of the column
   * definition's `onFilter` or `additionalFilterFunctions`, and applied to the data before it is
   * passed to the table component.
   */
  const applyMultiFilters = useCallback(
    (data: any[]) => {
      const columnMultiFilters = flatten(
        Object.values(filteredInfo ?? {}).filter((values: any[]) =>
          values?.[0]?.hasOwnProperty('key')
        )
      );
      const groupedMultiFilters = Object.entries(groupBy(columnMultiFilters, 'key')).map(
        ([k, v]) => [k, v.map(({ value }) => value)]
      );

      if (groupedMultiFilters.length) {
        return data.reduce((filteredRows, row) => {
          const filterResults: boolean[] = groupedMultiFilters.map(
            ([k, values]: [string, any[]]) => {
              const basePropertyFilterFunction = columns?.find(({ key }) => key === k)?.onFilter;
              const secondaryPropertyFilterFunction = columns?.find(
                ({ additionalFilterFunctions }) => !!additionalFilterFunctions?.[k]
              )?.additionalFilterFunctions[k];

              if (!!basePropertyFilterFunction) {
                return values.map(v => basePropertyFilterFunction(v, row)).every(r => !!r);
              } else if (!!secondaryPropertyFilterFunction) {
                return values.map(v => secondaryPropertyFilterFunction(v, row)).every(r => !!r);
              } else {
                return true;
              }
            }
          );

          if (filterResults.every(r => !!r)) {
            return [...filteredRows, row];
          }
          return filteredRows;
        }, []);
      }

      return data;
    },
    [columns, filteredInfo]
  );
  const applyMultiSort = useCallback(
    (data: any[]) => {
      // If there is a multiSortDef, we need to apply that mutli-column sort manually.
      const sortedData = orderBy(
        data,
        multiSortDef?.map(({ column: { get } }) => get),
        // sortDirection is by default in all caps for server-side functionality.
        // but lodash expects lowercase here.
        multiSortDef?.map(({ sortDirection }) => sortDirection.toLowerCase())
      );
      // Check to see if the contents of dataSource changed after sorting.
      const sourcesEqual = isEqual(data, sortedData);

      // If prioritizing, we need to trigger a specific callback to update the priority state
      // It's only safe to call the prioritization handleUpdate callback if we are prioritizing
      // and we have the callback
      // and the and the sort has changed the order of the contents of the list
      // and we're not currently updating using drag and drop
      const shouldCallHandler =
        isPrioritizing && handleOrderUpdate && !sourcesEqual && !isUpdatingDrag;

      if (shouldCallHandler) {
        handleOrderUpdate(sortedData);
      }
      return sortedData;
    },
    [handleOrderUpdate, isPrioritizing, isUpdatingDrag, multiSortDef]
  );

  const dataSource = useMemo(() => {
    // for most implementations, we want to remove null values.
    // Implementations that do not want this can replace a null value with empty object.
    const filteredData = applyMultiFilters((data || []).filter(Boolean));

    if (multiSortDef) {
      return applyMultiSort(filteredData);
    }

    return filteredData;
  }, [data, multiSortDef, applyMultiFilters, applyMultiSort]);

  useEffect(
    () => {
      if (draggable) {
        updatePagination(false);
      } else {
        updatePagination(initialPagination);
      }
    },
    [draggable] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const dragulaDecorator = useRef(null);

  // useEffect watches for dragula events
  useEffect(() => {
    const wrapper = dragulaDecorator.current;
    if (wrapper) {
      // @ts-ignore
      const tbody = wrapper.querySelector('.ant-table-tbody');

      const drake = dragula([tbody], {
        moves: () => !!draggable,
        mirrorContainer: tbody,
      });

      drake.on('drop', () => {
        setIsUpdatingDrag(true);
        const sortedPrioritizedRows = rePrioritizeRowsForDrop(
          dataSource.sort((a, b) => a.priority - b.priority),
          tbody,
          rowKey
        );

        // This is updating a state value in WorkOrderTasksTable
        onDragUpdate?.(sortedPrioritizedRows);
      });

      return () => {
        // We're done updating the drag and drop when the drake is destroyed.
        setIsUpdatingDrag(false);
        drake.destroy();
      };
    }
    // Disabling exhaustive deps. We should re-run this every time data changes, but NOT dataSource
    // if dataSource is in the dependency array, this can cause drag-and-drop behavior to fail and crash the whole page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDragUpdate, data, draggable, rowKey]);

  const handlePrioritizingTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    onTableChange?.(pagination, filters, sorter, extra);
    // If the table is prioritizing and the table changes, we need to pass the updated ordering of the table to the priority-handling
    // handleOrderUpdate function, so that the save button can be correctly enabled/disabled.

    if (isPrioritizing) {
      const orderedItems = extra.currentDataSource;
      handleOrderUpdate && handleOrderUpdate(orderedItems);
    }
  };

  return (
    <div ref={draggable ? dragulaDecorator : null}>
      <StyledTable
        {...props}
        columns={columns}
        loading={loading}
        locale={{ emptyText: <EmptyState {...props} loading={!!loading} /> }}
        draggable={!!draggable}
        dataSource={dataSource}
        bordered={false}
        rowKey={rowKey}
        onChange={(...args) =>
          isPrioritizing ? handlePrioritizingTableChange(...args) : onTableChange?.(...args)
        }
        rowClassName={archivedRowClassName}
      />
    </div>
  );
};

const StyledTable = styled(Table)<{ draggable: boolean }>`
  .ant-table-row {
    /* fallback to 'move' if 'grab' isn't supported */
    cursor: ${({ draggable }) => (draggable ? 'move' : undefined)};
    cursor: ${({ draggable }) => (draggable ? 'grab' : undefined)};
  }

  ${ArchivedRowStyle}
  ${SelectedRowTransitionStyle}

  .gu-mirror {
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.5);
  }
`;

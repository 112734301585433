import { formatDate, startCase } from 'utils/format';
import { Link } from 'react-router-dom';
import { Tag, Divider, Input, TagProps } from 'antd';
import { None, sortBy } from './helpers';
import { getFilterDropdown, getOnFilter } from './helpers/index';
import { get } from 'lodash';
import { TTableColumnDef } from 'horizon/types/TableColumnDef';
import { PaginatedInspection } from 'horizon/routes/Inspections/types';
import { renderAsset, renderAssetSite } from 'horizon/components/Assets/AssetDetails/helpers';
import { AtlasGqlFilterOperator, AtlasGqlInspection } from 'types/atlas-graphql';
import { TurbineInspection } from 'horizon/components/Assets/AssetDetails/types';
import { getStatusColor } from 'utils/color/helpers';

export const removeInspectionLink = (column: TTableColumnDef<PaginatedInspection>) => {
  return {
    ...column,
    render: (_: any, record: any) => {
      const { inspectionDate } = record || {};
      return inspectionDate ? inspectionDate : <None message="No Date" />;
    },
  };
};

const dateFilterConfig = { key: 'inspectionDate', type: 'date' };

export const date: TTableColumnDef<PaginatedInspection> = {
  title: 'Inspected',
  key: 'inspectionDate',
  dataIndex: 'inspectionDate',
  sorter: sortBy('inspectionDate'),
  filterDropdown: getFilterDropdown(dateFilterConfig),
  onFilter: getOnFilter(dateFilterConfig as any),
  get: record => get(record, 'inspectionDate'),
  filterProperty: 'inspectionDate',
  onHeaderCell: () =>
    ({
      'data-testid': 'inspection-date-header',
    }) as any,
  render(_, record) {
    const { id, inspectionDate, type } = record || {};
    return (
      <span data-testid="inspection-date">
        {[id, inspectionDate].every(Boolean) ? (
          <Link
            to={`/${
              type?.name === 'Internal Inspection' ? 'internal-inspections' : 'inspections'
            }/${id}`}
          >
            {inspectionDate}
          </Link>
        ) : (
          <None message="No Date" />
        )}
      </span>
    );
  },
};

export const dateTurbineLink: any = {
  ...date,
  render(_: any, record: any) {
    const { id, inspectionDate, asset } = record || {};
    return (
      <span data-testid="inspection-turbine-date">
        {[id, inspectionDate].every(Boolean) ? (
          <Link to={`/assets/${asset.id}/inspections/${id}`}>{formatDate(inspectionDate)}</Link>
        ) : (
          <None message="No Date" />
        )}
      </span>
    );
  },
};

const createdDateFilterConfig = { key: 'created', type: 'date' };

export const createdDate: TTableColumnDef<AtlasGqlInspection> = {
  title: 'Created',
  key: 'created',
  dataIndex: 'created',
  sorter: sortBy('created'),
  filterDropdown: getFilterDropdown(createdDateFilterConfig),
  onFilter: getOnFilter(createdDateFilterConfig as any),
  get: record => get(record, 'created'),
  filterProperty: 'created',
  onHeaderCell: () =>
    ({
      'data-testid': 'inspection-created-date-header',
    }) as any,
  render(_, record) {
    const { created } = record || {};
    return <span data-testid="inspection-created-date">{formatDate(created)}</span>;
  },
};

export const createdBy: TTableColumnDef<AtlasGqlInspection> = {
  title: 'Created By',
  key: 'createdBy',
  dataIndex: 'createdBy',
  sorter: sortBy('createdBy.email'),
  get: record => get(record, ['createdBy', 'email']),
  filterProperty: 'createdBy.email',
  onHeaderCell: () =>
    ({
      'data-testid': 'inspection-created-by-header',
    }) as any,
  render(_, record) {
    const { createdBy } = record || {};
    if (!createdBy?.email) {
      return (
        <span data-testid="inspection-createdBy-email-none">
          <None />
        </span>
      );
    }
    return <span data-testid="inspection-createdBy-email">{createdBy?.email}</span>;
  },
};

const statuses = ['DRAFT', 'RELEASED'];

const statusFilterConfig = {
  key: 'status',
  type: 'string',
  values: statuses,
};

export const status: TTableColumnDef<PaginatedInspection> = {
  key: 'status',
  title: 'Status',
  dataIndex: 'status',
  sorter: sortBy('status'),
  filters: statuses.map(s => {
    return { text: startCase(s), value: s };
  }),
  filterProperty: 'status',
  onFilter: getOnFilter(statusFilterConfig as any),
  filterDropdown: getFilterDropdown(statusFilterConfig),
  render(_: any, record: any) {
    const status = record?.status;

    if (!status) {
      return <None />;
    }
    return (
      <Tag color={getStatusColor(status)} data-testid="inspection-status">
        {status}
      </Tag>
    );
  },
};

export function getType(inspections: TurbineInspection[]): TTableColumnDef {
  const filters = inspections.reduce<{ text: string; value: string }[]>((acc, curr) => {
    const name = curr?.type?.name;
    if (name && !acc.find(v => v.text === name)) {
      acc.push({
        text: name,
        value: name,
      });
      return acc;
    }

    // value already within array
    return acc;
  }, []);

  return {
    ...type,
    filters,
    onFilter: (value, record) => record?.type?.name.indexOf(value) === 0,
  };
}

export const type: TTableColumnDef<PaginatedInspection> = {
  title: 'Inspection Type',
  sorter: sortBy('type.name'),
  get: record => get(record, ['type', 'name']),
  key: 'type',
  dataIndex: 'type',
  filterProperty: 'type.name',
  onHeaderCell: () =>
    ({
      'data-testid': 'inspection-type-header',
    }) as any,
  render(type) {
    return <span data-testid="inspection-type">{type?.name || <None />}</span>;
  },
};

const locationSorters = ['location.name', 'turbine.name'].map(sortBy);

export const location = {
  title: 'Site',
  key: 'location',
  dataIndex: 'location',
  defaultFilterOperator: 'CONTAINS',
  filterProperty: ['location.name', 'turbine.name'] as any,
  sorter(...args: any) {
    return locationSorters.map(fn => fn(...args)).find(Boolean);
  },
  get: (record: any) => get(record, ['location', 'name']),
  render(_: any, { turbine, location }: any = {}) {
    if (!turbine && !location) {
      return (
        <span data-testid="inspection-site">
          <None />
        </span>
      );
    }

    return (
      <span data-testid="inspection-site">
        {location && (
          <>
            <Link to={`/sites/${location.id}`}>{location.name}</Link>
            <Divider type="vertical" />
          </>
        )}
        {turbine && <Link to={`/turbines/${turbine.id}`}>Turbine {turbine.name}</Link>}
      </span>
    );
  },
};

export const site: TTableColumnDef<PaginatedInspection> = {
  title: 'Site',
  key: 'siteId',
  dataIndex: 'site',
  defaultFilterOperator: AtlasGqlFilterOperator.Equals,
  onHeaderCell: () =>
    ({
      'data-testid': 'inspection-site-header',
    }) as any,
  render(_, { asset }) {
    return <span data-testid="inspection-site">{renderAssetSite(asset)}</span>;
  },
};

export const asset: TTableColumnDef<PaginatedInspection> = {
  title: 'Asset',
  key: 'assetId',
  dataIndex: 'asset',
  onHeaderCell: () =>
    ({
      'data-testid': 'inspection-asset-header',
    }) as any,
  render(_, { asset }) {
    return <span data-testid="inspection-asset">{renderAsset(asset)}</span>;
  },
};

export const assetWithSite: TTableColumnDef<PaginatedInspection> = {
  title: 'Asset',
  key: 'assetId',
  dataIndex: 'asset',
  onHeaderCell: () =>
    ({
      'data-testid': 'inspection-asset-header',
    }) as any,
  render(_, { asset }) {
    return <span data-testid="inspection-asset">{renderAsset(asset, { includeSite: true })}</span>;
  },
};

export const assetType: TTableColumnDef<PaginatedInspection> = {
  title: 'Asset Type',
  key: 'assetTypeName',
  dataIndex: 'assetType',
  onHeaderCell: () =>
    ({
      'data-testid': 'inspection-asset-type-header',
    }) as any,
  render(_, { asset }) {
    return (
      <span data-testid="inspection-asset-type">
        {asset?.assetType ? asset.assetType.name : <None />}
      </span>
    );
  },
};

export const description = {
  title: 'Description',
  key: 'description',
  filterProperty: 'description',
  render(record: any) {
    const { description } = record || {};
    return <span data-testid="inspection-description">{description || <None />}</span>;
  },
  edit(record: any, { onChange, ...props }: any) {
    const { description } = record || {};
    return (
      <Input
        style={{ maxWidth: '300px' }}
        defaultValue={description}
        onChange={onChange && (e => onChange({ description: e.target.value }))}
        {...props}
      />
    );
  },
};

export const campaign: TTableColumnDef<PaginatedInspection> = {
  title: 'Campaign',
  key: 'campaign',
  dataIndex: 'campaign',
  defaultFilterOperator: 'CONTAINS',
  filterProperty: 'campaign.name',
  sorter: sortBy('campaign.name'),
  get: record => get(record, ['campaign', 'name']),
  onHeaderCell: () =>
    ({
      'data-testid': 'inspection-campaign-header',
    }) as any,
  render(campaign) {
    const { id, name } = campaign || {};
    return name ? <Link to={`/inspection-campaigns/${id}`}>{name}</Link> : <None />;
  },
};

export const task = {
  title: 'Task',
  key: 'task',
  dataIndex: 'task',
  filterProperty: 'task.number',
  get: (record: any) => get(record, ['task', 'number']),
  render: (task: any) => {
    const { id, number } = task || {};
    return [id, number].every(Boolean) ? <Link to={`/tasks/${id}`}>{number}</Link> : <None />;
  },
};

export const vendor: TTableColumnDef<PaginatedInspection> = {
  title: 'Vendor',
  sorter: sortBy('vendor.name'),
  get: record => get(record, ['vendor', 'name']),
  key: 'vendor',
  dataIndex: 'vendor',
  defaultFilterOperator: 'CONTAINS',
  filterProperty: 'vendor.name',
  onHeaderCell: () =>
    ({
      'data-testid': 'inspection-vendor-header',
    }) as any,
  render(vendor) {
    return <span data-testid="inspection-vendor">{vendor?.name || <None />}</span>;
  },
};

export const reviewed = {
  title: 'Reviewed Images',
  key: 'reviewedTotals',
  dataIndex: 'reviewedTotals',
  render(totals: any) {
    const { reviewed, total } = totals || {};
    return (
      <span data-testid="inspection-reviewed">{total ? `${reviewed}/${total}` : <None />}</span>
    );
  },
};

export default () => [date, location];

import { startCase as _startCase } from 'lodash';
import moment from 'moment';

import { DATE_FORMAT, TIME_FORMAT } from './constants';

// extended from lodash so FOO BAR can be Foo Bar (lodash would keep it all uppercase), and roman numerals will be capitalized
export function startCase(value: string | null | undefined) {
  return value
    ? _startCase(
        value.toLowerCase().replace(/(\s+|^)([mdclxvi]+)(\s+|\n|$)/, match => match.toUpperCase())
      ) // keeps roman numerals capitalized
    : null;
}

export function formatDate(value: moment.MomentInput) {
  return value ? moment(value).format(DATE_FORMAT) : null;
}

export function formatUnixTimestamp(value: number) {
  return value ? moment.unix(value).format(DATE_FORMAT) : null;
}

export function formatTime(value: moment.MomentInput) {
  return value ? moment(value).format(TIME_FORMAT) : null;
}

import React, { useState } from 'react';
import LinkButton from 'components/LinkButton';
import { Divider, Modal } from 'antd';
import {
  AtlasGqlMaintenanceActivity,
  AtlasGqlMaintenanceActivityStatus,
  useUpdateMaintenanceActivityMutation,
} from 'types/atlas-graphql';
import { HeaderWrapper, TextSpan } from './maintenanceActivities.style';

interface IMaintenanceActivityStatusHeader {
  maintenanceActivity: AtlasGqlMaintenanceActivity;
}

export const MaintenanceActivityStatusHeader: React.FunctionComponent<
  IMaintenanceActivityStatusHeader
> = ({ maintenanceActivity }) => {
  const { id, name, status } = maintenanceActivity;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const isEnded = status === AtlasGqlMaintenanceActivityStatus.Ended;

  const [updateMaintenanceActivityMutation] = useUpdateMaintenanceActivityMutation();

  const handleEnd = () => {
    updateMaintenanceActivityMutation({
      variables: {
        id: id,
        input: {
          status: AtlasGqlMaintenanceActivityStatus.Ended,
        },
      },
    });
    setIsEditing(false);
  };

  const modalHeaderText = `End Recurring Task.`;
  const modalWarningText = (name: string) =>
    `End the recurrence of "${name}"? New Tasks will no longer be created from this Recurring Task Group once the current Active Task is completed.`;

  if (!isEditing) {
    return (
      <HeaderWrapper>
        <TextSpan>{isEnded ? 'Ended' : 'Active'}</TextSpan>
        {!isEnded && (
          <LinkButton data-testid="edit-button" onClick={() => setIsEditing(true)}>
            Edit
          </LinkButton>
        )}
      </HeaderWrapper>
    );
  }

  return (
    <>
      <LinkButton data-testid="edit-button" onClick={() => setIsEditing(false)}>
        Cancel
      </LinkButton>
      <Divider type="vertical" />
      <LinkButton
        data-testid="edit-button"
        onClick={() => {
          Modal.confirm({
            title: modalHeaderText,
            content: modalWarningText(name),
            onOk: handleEnd,
            okText: 'Okay',
            onCancel: () => setIsEditing(false),
          });
        }}
        color={'red'}
      >
        End Recurrence
      </LinkButton>
    </>
  );
};

import { TAssetChooserInput } from 'components/choosers/types';
import { ASSET_TYPENAMES } from 'components/data/helpers';
import { AssetHierarchyItem } from 'horizon/components/Assets/AssetPicker/types';
import {
  AtlasGqlOtherAssetTask,
  AtlasGqlInspectionTask,
  AtlasGqlInternalBladeInspectionTask,
  AtlasGqlWorkOrder,
  AtlasGqlWorkCampaign,
  AtlasGqlAsset,
} from 'types/atlas-graphql';

// Default select search is to search on value, where most of the time we want to search on the title (represented in children)
export const filterOption = (input: string, option: any) =>
  option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

export function getAssetChooserDefaults(
  asset: any,
  settings?: TAssetChooserInput
): TAssetChooserInput {
  const emptyDefaults: TAssetChooserInput = {
    ...{
      locationIds: [],
      turbineIds: [],
      bladeIds: [],
      onChange: () => {},
      block: false,
      small: false,
      width: '',
    },
    ...settings,
  };

  if (!asset) {
    return emptyDefaults;
  }

  if (
    asset.__typename === ASSET_TYPENAMES.site ||
    asset.__typename === ASSET_TYPENAMES.siteLocation
  ) {
    const { id: locationId } = asset;
    return { ...emptyDefaults, ...{ locationIds: [locationId] } };
  }

  if (asset.__typename === ASSET_TYPENAMES.turbine) {
    const { id: turbineId, parent: site, location: legacyLocation } = asset;
    const locationId = site?.id ?? legacyLocation?.id;
    return { ...emptyDefaults, ...{ locationIds: [locationId], turbineIds: [turbineId] } };
  }

  if (asset.__typename === ASSET_TYPENAMES.blade) {
    const { id: bladeId, parent: turbine, turbine: legacyTurbine } = asset;
    const turbineId = turbine?.id ?? legacyTurbine?.id;
    const locationId = turbine?.parent?.id ?? legacyTurbine?.location?.id;
    return {
      ...emptyDefaults,
      ...{ locationIds: [locationId], turbineIds: [turbineId], bladeIds: [bladeId] },
    };
  }

  return emptyDefaults;
}

export function getTaskAssetInfo(
  input:
    | AtlasGqlOtherAssetTask
    | AtlasGqlInspectionTask
    | AtlasGqlInternalBladeInspectionTask
    | AtlasGqlWorkOrder
    | AtlasGqlWorkCampaign
    | undefined
): any {
  if (!input) return undefined;

  switch (input.__typename) {
    case 'OtherAssetTask':
    case 'InspectionTask':
    case 'InternalBladeInspectionTask': {
      return input.asset;
    }
    case 'WorkOrder':
    case 'WorkCampaign': {
      return input.commonAsset ?? undefined;
    }
    default: {
      return undefined;
    }
  }
}

export function convertToAssetHierarchyItem(asset: any): AssetHierarchyItem | undefined {
  if (!asset) return undefined;
  return {
    assetId: asset?.id,
    name: asset?.name,
    assetTypeId: asset?.type?.id ?? asset?.assetType?.id,
    assetTypeName: asset?.type?.name ?? asset?.assetType?.name,
  };
}

/*
This component is an evolution of the AssetChooser component.
It allows for selecting, in order, sites, turbines, component types, and sub-components
Selecting any option from any chooser fires off a new onChange.

** Note: The ComponentTypeChooser returns the *name* of the selected type, not a UUID like the other choosers.
*/

import { useEffect, useMemo, Fragment } from 'react';

import { isEqual } from 'lodash';
import {
  TurbineChooser,
  LocationChooser,
  ComponentTypeChooser,
  SubComponentChooser,
} from '../index';
import { NOOP } from 'utils/helpers';

import { ManagedComponentChooserState, TComponentChooserSetting } from './types';
import { StyledControls, SelectControl } from './styles';
import { getIdsAsArray, getLowestLevelAssetIds } from './helpers';
import { useServerDataTableContext } from 'components/DataTable/ServerDataTable';
import { Updater, useImmer } from 'use-immer';

export interface IComponentChooserProps {
  onChange: ({
    siteIds,
    turbineIds,
    componentIds,
    componentTypes,
    assetIds,
  }: {
    siteIds?: string[];
    turbineIds?: string[];
    componentIds?: string[];
    componentTypes?: string[];
    assetIds?: string[];
  }) => void;
  assetIds?: string[]; // Array of asset ids that have been selected.
  siteIds?: string[]; // Array of site ids that have been selected.
  turbineIds?: string[]; // Array of turbine ids that have been selected.
  componentIds?: string[]; // Array of component ids that have been selected.
  componentTypes?: string[]; // Array of string names of selected component types that have been selected.
  allowedComponentTypeOptions?: string[];
  block?: boolean;
  small?: boolean;
  width?: string;
  hasParentContainer?: boolean;
  disabled?: boolean;
  loadState?: any;
  componentChooserState?: ManagedComponentChooserState;
  setComponentChooserState?: Updater<ManagedComponentChooserState>;
  settings?: {
    site?: TComponentChooserSetting;
    turbine?: TComponentChooserSetting;
    componentType?: TComponentChooserSetting & { allowComponentTypeWithMultipleTurbines?: boolean };
    component?: TComponentChooserSetting & { disableComponentWithMultipleTurbines?: boolean };
  };
}

export const ComponentChooser: React.FunctionComponent<IComponentChooserProps> = ({
  onChange,
  siteIds: selectedSiteIds,
  turbineIds: selectedTurbineIds,
  componentIds: selectedComponentIds,
  componentTypes: selectedComponentTypes,
  allowedComponentTypeOptions,
  block = false,
  small = false,
  width = '100%',
  hasParentContainer = false,
  disabled = false,
  loadState = NOOP,
  componentChooserState,
  setComponentChooserState,
  settings: {
    site: { multiple: multipleSites = true, visible: visibleSites = true } = {},
    turbine: { multiple: multipleTurbines = true, visible: visibleTurbines = true } = {},
    componentType: { allowComponentTypeWithMultipleTurbines = false } = {},
    component: {
      visible: visibleComponents = false,
      disableComponentWithMultipleTurbines = false,
    } = {},
  } = {},
}) => {
  const { loading } = useServerDataTableContext().context;

  const [_state, _setState] = useImmer<ManagedComponentChooserState>({
    siteIds: selectedSiteIds,
    turbineIds: getIdsAsArray(selectedTurbineIds),
    componentTypes: getIdsAsArray(selectedComponentTypes),
    componentIds: getIdsAsArray(selectedComponentIds),
    initialized: false,
  });

  const siteIds = componentChooserState?.siteIds ?? _state.siteIds;
  const turbineIds = componentChooserState?.turbineIds ?? _state.turbineIds;
  const componentTypes = componentChooserState?.componentTypes ?? _state.componentTypes;
  const componentIds = componentChooserState?.componentIds ?? _state.componentIds;
  const initialized = componentChooserState?.initialized ?? _state.initialized;

  const setState = setComponentChooserState ?? _setState;

  const Container = useMemo(
    () => (hasParentContainer ? Fragment : StyledControls),
    [hasParentContainer]
  );
  const handleSiteChange = (newSiteIds: string[]) => {
    if (!isEqual(newSiteIds, siteIds)) {
      setState(draft => {
        draft.siteIds = newSiteIds;
        draft.turbineIds = undefined;
        draft.componentTypes = undefined;
        draft.componentIds = undefined;
      });
    }
  };

  const handleTurbineChange = (newTurbineIds: string[]) => {
    if (!isEqual(newTurbineIds, turbineIds)) {
      setState(draft => {
        draft.turbineIds = newTurbineIds;
        draft.componentTypes = undefined;
        draft.componentIds = undefined;
      });
    }
  };

  const handleComponentTypeChange = (newTypes: string[]) => {
    if (!isEqual(newTypes, componentTypes)) {
      setState(draft => {
        draft.componentTypes = newTypes;
        draft.componentIds = undefined;
      });
    }
  };

  const handleComponentChange = (newComponentIds: string[]) => {
    if (!isEqual(newComponentIds, componentIds)) {
      setState(draft => {
        draft.componentIds = newComponentIds;
      });
    }
  };

  // This useEffect and the following one can be leveraged to
  // store persistent state if a storage object is passed to useManagedComponentChooser
  useEffect(() => {
    const state = loadState();
    if (state) {
      setState(draft => {
        draft.siteIds = state?.siteIds;
        draft.turbineIds = state?.turbineIds;
        draft.componentTypes = state?.componentTypes;
        draft.componentIds = state?.componentIds;
        draft.initialized = true;
      });
      return;
    }
    setState(draft => {
      draft.initialized = true;
    });
  }, [loadState]);

  // pump out an onChange when an asset selection has changed
  useEffect(() => {
    if (!initialized) return;
    onChange({
      siteIds,
      turbineIds,
      componentIds,
      componentTypes,
      assetIds: getLowestLevelAssetIds(siteIds, turbineIds, componentIds),
    });
  }, [siteIds, turbineIds, componentTypes, componentIds, onChange, initialized]);

  const [locationId, hasMultipleSiteIds] = siteIds || [];
  const [turbineId, hasMultipleTurbineIds] = turbineIds || [];
  const [componentType] = componentTypes || [];

  return (
    <Container {...(!hasParentContainer ? { block, width } : {})}>
      {visibleSites && (
        <SelectControl block={block} small={small}>
          <LocationChooser
            disabled={Boolean(disabled || loading)}
            value={siteIds}
            onChange={handleSiteChange}
            mode={multipleSites ? 'multiple' : undefined}
          />
        </SelectControl>
      )}

      {visibleTurbines && (
        <SelectControl block={block} small={small}>
          <TurbineChooser
            disabled={Boolean(!locationId || hasMultipleSiteIds || loading)}
            disableInput={Boolean(
              disabled || hasMultipleSiteIds || locationId === undefined || loading
            )}
            locationId={locationId}
            value={turbineIds}
            onChange={handleTurbineChange}
            mode={multipleTurbines ? 'multiple' : undefined}
          />
        </SelectControl>
      )}

      {visibleComponents && (
        <SelectControl block={block} small={small}>
          <ComponentTypeChooser
            disabled={Boolean(
              !turbineId ||
                loading ||
                (hasMultipleTurbineIds && !allowComponentTypeWithMultipleTurbines) ||
                turbineId === undefined
            )}
            value={componentTypes}
            onChange={handleComponentTypeChange}
            mode={'multiple'}
            allowedOptions={allowedComponentTypeOptions}
          />
        </SelectControl>
      )}

      {visibleComponents && (
        <SelectControl block={block} small={small}>
          <SubComponentChooser
            disabled={Boolean(
              !componentType ||
                turbineId === undefined ||
                loading ||
                (hasMultipleTurbineIds && disableComponentWithMultipleTurbines)
            )}
            value={componentIds}
            assetParentIds={turbineIds}
            assetTypeNames={componentTypes}
            onChange={handleComponentChange}
            mode={'multiple'}
          />
        </SelectControl>
      )}
    </Container>
  );
};

import { useFeatures } from 'utils/features';
import { useBanner } from '../../GlobalBanner';

export const useDamages2Banner = (shouldShowBanner: boolean) => {
  const { HORIZON_DAMAGES, LEGACY_DAMAGES } = useFeatures().features;
  useBanner(
    HORIZON_DAMAGES && LEGACY_DAMAGES && shouldShowBanner
      ? 'You are working with Damages 2.0. Changed data will not be visible within legacy damages.'
      : null
  );
};

import { useEffect } from 'react';
import App from './App';
import { storage } from 'utils/storage';
import { reinitializeApp, useAccountDataChannel } from '../utils/account/channels';

// perform various bootstrapping functionality here.
//
// @see https://app.shortcut.com/skyspecs/story/46065
function Bootstrap() {
  useAccountDataChannel();

  // on initial load only, determine if the browser tab/window was manually
  // refreshed or navigated to and if the customer IDs don't match, then force
  // refresh the app after clearing session storage
  useEffect(() => {
    const customerId = storage.getItem('customerId');
    const activeCustomerId = storage.getItem('customerId:active');

    if (!customerId || !activeCustomerId || customerId === activeCustomerId) {
      return;
    }

    // @see https://stackoverflow.com/a/53307588/2121598
    const browserWasManuallyReloaded = window.performance
      .getEntriesByType('navigation')
      .some(nav => ['navigate', 'reload'].includes((nav as any).type));

    if (browserWasManuallyReloaded) {
      reinitializeApp();
    }
  }, []);

  return <App />;
}

export default Bootstrap;

export const REALTIME_POTENTIAL_ATTRIBUTE_KEYS = [
  'Length',
  'Width',
  'Distance',
  'Chord',
  'Chord Points',
  'Distance from Nacelle',
  'Tower Side',
  'Nacelle Side',
  'Transition Piece Side',
];

export const CONFIRM_PRIMARY_COPY =
  'Mark this as the Primary Observation. All linked Observations from this Inspection will be shown on the Damages table.';

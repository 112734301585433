import { Tooltip } from 'components/ui';

import SeverityTag from 'components/SeverityTag';
import { getUniqueIdentifier } from 'horizon/components/Assets/AssetDetails/helpers';
import { getOrderedAncestors } from 'components/data/assets/assetColumnRenderers';
import { AtlasGqlHorizonDamage } from 'types/atlas-graphql';

import { formatDamageId } from 'horizon/components/Damages/utils';
import { ListItem } from '../ComparerPane/ComparerPane.style';

type DamageListItemProps = {
  damage: AtlasGqlHorizonDamage;
  selected?: boolean;
  disabled?: boolean;
  onClick: () => void;
};

export const DamageListItem: React.FunctionComponent<DamageListItemProps> = ({
  damage,
  selected = false,
  disabled = false,
  onClick,
}) => {
  const { asset } = damage;
  const assetHierarchyNames: string[] = [...getOrderedAncestors(asset), asset].map(a =>
    getUniqueIdentifier(a)
  );

  const {
    Material: material,
    Severity: severity,
    Type: type,
  }: {
    Material?: number;
    Severity?: number;
    Type?: string;
  } = damage?.primaryObservationGroupAttrs ?? {};

  return (
    <ListItem key={damage.id} selected={selected} disabled={disabled} onClick={onClick}>
      {/* Deprecated use of SeverityTag - does not need new critical treatment */}
      <SeverityTag style={{ marginRight: '8px' }} value={severity} />
      <div>
        <p>
          <strong>{formatDamageId(damage)}</strong>
          {[type, material].filter(Boolean).length ? ': ' : ''}
          {[type, material].filter(Boolean).join(', ')}
        </p>
        <p className="inspection-detail">
          <Tooltip title={assetHierarchyNames.join('/')}>
            {assetHierarchyNames[0]}
            {assetHierarchyNames.length > 2 ? '/.../' : '/'}
            {assetHierarchyNames[assetHierarchyNames.length - 1]}
          </Tooltip>
        </p>
      </div>
    </ListItem>
  );
};

import { RadioChangeEvent } from 'antd5';
import React, { useEffect, useState } from 'react';
import { SectionTitle, StyledDivider, StyledRadioGroup } from './ArchivedFilterToggle.style';
import { EArchivedFilterStateOption, onArchiveChange, IS_NULL, IS_NOT_NULL } from '../index';
import { TDropdownAction, TQueryFilter } from 'components/DataTable/types';

interface IArchivedFilterToggleProps {
  onChange: (value: EArchivedFilterStateOption) => void;
  currentValue: TQueryFilter | null;
  setCurrentValue: React.Dispatch<React.SetStateAction<TQueryFilter | null>>;
  serverSide?: boolean;
  defaultValue?: EArchivedFilterStateOption;
}

const ArchivedFilterToggle: React.FunctionComponent<IArchivedFilterToggleProps> = ({
  onChange,
  currentValue,
  setCurrentValue,
  serverSide = true,
  defaultValue,
}) => {
  const options = [
    { label: 'Active', value: EArchivedFilterStateOption.ACTIVE },
    { label: 'Archived', value: EArchivedFilterStateOption.ARCHIVED },
    { label: 'Both', value: EArchivedFilterStateOption.BOTH },
  ];

  // If the default value ever changes, we need to hard reset to that, overriding whatever manual value may have been set.
  useEffect(() => {
    // If defaultValue is undefined don't bother attemping to set it.
    if (defaultValue !== undefined) {
      onChange(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = ({ target: { value } }: RadioChangeEvent) => {
    setCurrentValue(value);
    onChange(value);
  };

  // Determine value for Radio Group.
  // Complicated because we don't have a specific operator we use for "BOTH"
  const determineSelectedValue = (operator: string | undefined): string => {
    if (operator === IS_NULL) return EArchivedFilterStateOption.ACTIVE;
    if (operator === IS_NOT_NULL) return EArchivedFilterStateOption.ARCHIVED;
    return EArchivedFilterStateOption.BOTH;
  };

  return (
    <>
      <StyledDivider />
      <SectionTitle>View Option</SectionTitle>
      <StyledRadioGroup
        options={options}
        onChange={handleChange}
        value={serverSide ? determineSelectedValue(currentValue?.operator) : currentValue}
        optionType="button"
      />
    </>
  );
};

/**
 * A menu action item that displays a toggle for three options for server-side tables, used to filter Work Containers by their archived status
 * @param currentValue useState value of the currently selected tab.
 * @param setCurrentValue useState setter used to track the currently selected tab.
 * @param onClearSelection callback that will get called when the filter changes. Intended to be used to clear the selection in the table.
 * @param defaultValue optional value the dropdown should start with. Will almost always be the active work containers.
 * @returns A TDropdownAction that can be passed into the Actions or additionalDropdownActions of a DataTable
 */
export const ArchivedFilterToggleAction = (
  currentValue: TQueryFilter | null,
  setCurrentValue: React.Dispatch<React.SetStateAction<TQueryFilter | null>>,
  onClearSelection?: () => void,
  defaultValue?: EArchivedFilterStateOption
) => {
  const action: TDropdownAction = {
    content: (
      <ArchivedFilterToggle
        onChange={e => onArchiveChange(e, currentValue, setCurrentValue, onClearSelection)}
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
        defaultValue={defaultValue}
      />
    ),
    // Flag the action as non-hoverable. The renderer handles overriding the hover state
    hoverable: false,
  };
  return action;
};

/**
 * A menu action item that displays a toggle for three options for client-side tables, used to filter Work Containers by their archived status
 * @param onArchiveChange A callback function. Intended to be used from the calling component to react to this changing state.
 * @param defaultValue Value the dropdown should start with. Will almost always be the active work containers.
 * @returns A TDropdownAction that can be passed into the Actions or additionalDropdownActions of a DataTable
 */
export const ArchivedFilterToggleActionClientSide = (
  onArchiveChange: (value: any) => void,
  defaultValue?: EArchivedFilterStateOption
) => {
  const [currentValue, setCurrentValue] = useState<any>(
    defaultValue ? defaultValue : EArchivedFilterStateOption.ACTIVE
  );

  useEffect(() => {
    if (defaultValue) {
      setCurrentValue(defaultValue);
    }
  }, [defaultValue]);

  const action: TDropdownAction = {
    content: (
      <ArchivedFilterToggle
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
        onChange={onArchiveChange}
        serverSide={false}
        defaultValue={defaultValue}
      />
    ),
    // Flag the action as non-hoverable. The renderer handles overriding the hover state
    hoverable: false,
  };
  return action;
};

import { useContext } from 'react';
import { get } from 'lodash';
import { DragOutlined } from '@ant-design/icons';

import { DataTableContext } from './index';
import { strong, getFilterDropdown, getOnFilter, removeFilter } from 'components/data/helpers';

export const drag = title => ({
  key: 'drag',
  title,
  render: () => <DragOutlined />,
});

export function useFormattedColumns({ columns = [], isPrioritizing }) {
  const {
    filteredInfo: currentTableFilters,
    sortedInfo: currentTableSortOrder,
    multiSortDef,
  } = useContext(DataTableContext);

  function getSorterAndFilter({ serverSortAndFilter, onFilter, sorter }) {
    if (serverSortAndFilter) {
      return {
        onFilter: undefined,
        sorter: !!sorter,
      };
    }

    return {
      onFilter,
      sorter: multiSortDef ? undefined : sorter,
    };
  }

  const formattedCols = columns.map(column => {
    const { key: columnKey, filterProperty, sortKey, title } = column;

    const currentColumnFilter = get(currentTableFilters, columnKey, null);
    const currentColumnSortOrder =
      currentTableSortOrder &&
      (currentTableSortOrder.columnKey === columnKey ||
        (sortKey && currentTableSortOrder.columnKey === sortKey))
        ? currentTableSortOrder.order
        : null;

    // FIXME ARS: filterProperty is not an antd thing, it is a shorthand for the column def to flip on some additional defaulted antd props [filterDropdown and onFilter]
    // I think we will remove the shorthand eventually but don't want to change all the files yet
    // AND the way this is used isn't consistent so handling each of the attributes independently
    if (filterProperty) {
      column = {
        ...column,
        filterDropdown: column.filterDropdown
          ? column.filterDropdown
          : !column.filters && getFilterDropdown(),
        onFilter: column.onFilter || getOnFilter({ key: filterProperty }),
      };
    }

    const formattedCol = {
      ...column,
      filteredValue: currentColumnFilter,
      sortOrder: currentColumnSortOrder,
      title: strong(title),
      // FIXME ARS: still hate this, but I still need to put some thought in how to reason about this a level up
      ...getSorterAndFilter(column),
    };

    return isPrioritizing ? removeFilter(formattedCol) : formattedCol;
  });

  return isPrioritizing ? [drag('Drag'), ...formattedCols] : formattedCols;
}

import { Layout as LayoutV5, LayoutProps as LayoutPropsV5 } from 'antd5';
import { Layout as LayoutV4, LayoutProps as LayoutPropsV4 } from 'antd';
import { Version4Props, Version5Props } from '../types';

interface PropsV5 extends Version5Props, LayoutPropsV5 {}
interface PropsV4 extends Version4Props, LayoutPropsV4 {}
type Props = PropsV5 | PropsV4;

export function Layout({ _version = 5, ...props }: Props) {
  if (_version === 4) {
    return <LayoutV4 {...props} />;
  }
  return <LayoutV5 {...props} />;
}

export function Header({ _version = 5, ...props }: Props) {
  if (_version === 4) {
    return <LayoutV4.Header {...props} />;
  }
  return <LayoutV5.Header {...props} />;
}

export function Footer({ _version = 5, ...props }: Props) {
  if (_version === 4) {
    return <LayoutV4.Footer {...props} />;
  }
  return <LayoutV5.Footer {...props} />;
}

export function Content({ _version = 5, ...props }: Props) {
  if (_version === 4) {
    return <LayoutV4.Content {...props} />;
  }
  return <LayoutV5.Content {...props} />;
}

export function Sider({ _version = 5, ...props }: Props) {
  if (_version === 4) {
    return <LayoutV4.Sider {...props} />;
  }
  return <LayoutV5.Sider {...props} />;
}

// components added to Layout to reflect how antd behaves
Layout.Header = Header;
Layout.Footer = Footer;
Layout.Content = Content;
Layout.Sider = Sider;

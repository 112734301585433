import COLORS from './definitions';

export function getSeverityColor(severity?: number, maxValue: number = 5) {
  if (!severity || severity > maxValue) {
    return null;
  }
  if (severity === maxValue) {
    return COLORS.SEV_MAX_COLOR;
  }

  if (severity >= maxValue - 1) {
    return COLORS.SEV_LESS_COLOR;
  }
}

export function getStatusColor(
  status: 'COMPLETE' | 'COMPLETED' | 'DRAFT' | 'FAILED' | 'IN_PROGRESS' | 'PENDING' | 'RELEASED'
) {
  switch (status) {
    case 'IN_PROGRESS':
      return COLORS.TEAL;
    case 'PENDING':
    case 'DRAFT':
      return COLORS.DARK_GRAY;
    case 'COMPLETE':
    case 'COMPLETED':
    case 'RELEASED':
      return COLORS.GREEN;
    case 'FAILED':
      return COLORS.RED;
    default:
      return undefined;
  }
}
export type AssetColorStatus =
  | 'Active'
  | 'Curtailed'
  | 'Decommissioned'
  | 'Offline'
  | 'Online'
  | 'Recycled'
  | 'Storage';

export function getAssetColorStatus(status: AssetColorStatus) {
  switch (status) {
    case 'Offline':
    case 'Recycled':
      return COLORS.RED;
    case 'Online':
    case 'Active':
      return COLORS.TEAL;
    case 'Curtailed':
    case 'Storage':
      return COLORS.SEV_LESS_COLOR;
    case 'Decommissioned':
      return COLORS.WHITE;
    default:
      return COLORS.WHITE;
  }
}

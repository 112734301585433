import React from 'react';
import { TDisplayColumnData, TEditColumnData } from '../../types';
import { None, sortBy } from 'components/data/helpers';
import { AtlasGqlMaintenanceActivity } from 'types/atlas-graphql';
import { get } from 'lodash';
import { MaintenanceActivityStatusHeader, MaintenanceActivityNameHeader } from './';
import { inlineEditable } from 'utils/editable/InlineEditable/InlineEditable';

export const maintenanceActivityNameColumn = (): TDisplayColumnData => {
  return {
    key: 'name',
    title: 'Name',
    dataIndex: 'maintenanceActivity',
    get: (record: any) => get(record, 'maintenanceActivity'),
    render: (maintenanceActivity: AtlasGqlMaintenanceActivity) => {
      const { name } = maintenanceActivity || {};

      return name?.length ? name : <None />;
    },
  };
};

export const maintenanceActivityIntervalColumn = (): TDisplayColumnData => {
  return {
    key: 'dayCount',
    title: 'Interval',
    dataIndex: 'maintenanceActivity',
    get: (record: any) => get(record, 'maintenanceActivity'),
    render: (maintenanceActivity: AtlasGqlMaintenanceActivity) => {
      const { dayCount } = maintenanceActivity || {};

      return dayCount ? `${dayCount} Days` : <None />;
    },
  };
};

export const maintenanceActivityNameHeader = (): TEditColumnData => {
  const columnDef = inlineEditable({
    key: 'name',
    title: 'Recurring Task',
    dataIndex: 'maintenanceActivity',
    get: (record: any) => get(record, 'maintenanceActivity'),
    render: (maintenanceActivity: AtlasGqlMaintenanceActivity) => {
      const { id } = maintenanceActivity || {};

      // If we don't have an id we probably have an error...
      if (!id) {
        return <None />;
      }

      return <MaintenanceActivityNameHeader maintenanceActivity={maintenanceActivity} />;
    },
  });

  return columnDef;
};

export const maintenanceActivityStatusHeader = () => {
  const columnDef = {
    title: 'Recurrence Status',
    key: 'maintenanceActivity',
    dataIndex: 'maintenanceActivity',
    sorter: sortBy('maintenanceActivity'),
    filterProperty: 'maintenanceActivity',
    get: (record: any) => get(record, 'maintenanceActivity'),
    render: (maintenanceActivity: AtlasGqlMaintenanceActivity) => {
      const { id } = maintenanceActivity || {};

      // If we don't have an id we probably have an error...
      if (!id) {
        return <None />;
      }

      return <MaintenanceActivityStatusHeader maintenanceActivity={maintenanceActivity} />;
    },
  };

  return columnDef;
};

import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { clear, getItem } from '../storage';
import { APP_NAME } from '../env';
import { Spinner } from 'components/ui';
import { setBugsnagUser } from 'utils/bugsnag';
import { setLogRocketUser } from 'utils/logrocket';
import { setHeapUser } from 'utils/heap';
import { useAuthContext } from 'utils/auth';
import { useCurrentCustomerId } from 'utils/account/hooks';

interface SessionProps {
  children: React.ReactElement;
}

function Session({ children }: SessionProps) {
  const { isAuthenticated, isLoading, logout, user } = useAuthContext();
  const [currentCustomerId, setCurrentCustomerId] = useCurrentCustomerId();
  const [initialized, setInitialized] = useState<boolean>(false);
  const redirectUrl = useRedirectUrl();

  const hasAppAccess = useMemo(() => {
    const groups: string[] = user?.['https://skyspecs.com/jwt/ohi']?.groups ?? [];
    return isLoading ? false : groups.includes(APP_NAME);
  }, [isLoading, user]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated || !user) {
      clear();
      return window.location.replace(redirectUrl);
    }

    if (!hasAppAccess) {
      // to avoid infinite redirects, we want to explicitly log the user out
      // when they don't have Horizon access
      console.info('User does not have Horizon access');
      logout({ broadcast: true });
      return;
    }

    // Attempt to preserve the customer that the user had switched into
    if (!currentCustomerId) {
      setCurrentCustomerId(
        getItem('customerId') || user['https://skyspecs.com/jwt/flt']?.defaultOrg
      );
    }

    setBugsnagUser(user);
    setLogRocketUser(user);
    setHeapUser(user);

    setInitialized(true);
  }, [
    currentCustomerId,
    hasAppAccess,
    isAuthenticated,
    isLoading,
    logout,
    redirectUrl,
    setCurrentCustomerId,
    setInitialized,
    user,
  ]);

  if (isAuthenticated && initialized) {
    return children;
  }

  return <Spinner />;
}

export default Session;

function useRedirectUrl() {
  const { createHref, location } = useHistory();
  const { pathname, search: lsearch, state: lstate } = location;

  // the `returnTo` and `state` values are read in AuthRedirect
  const search =
    queryString.stringify({
      returnTo: pathname === '/' && !lsearch ? undefined : `${pathname}${lsearch}`,
      state:
        // ignore state when all values are falsy
        !lstate || (typeof lstate === 'object' && !Object.values(lstate).some(Boolean))
          ? undefined
          : JSON.stringify(lstate),
    }) || undefined;

  return createHref({ pathname: '/auth_redirect', search });
}

import { Input, Select } from 'antd';
import moment from 'moment';
import { DebouncedInputNumber } from 'components/inputs';
import { UTCDatePicker } from 'components/UTCDatePicker';

const { Option } = Select;

export function ValueInput({ data, warn = false, onChange }) {
  const {
    label,
    type = '',
    options,
    value,
    defaultValue,
    min,
    max,
    delta = 1,
    required = true,
    disabled = false,
    allowClear = true,
    showSearch = true,
    width = '9em',
    dropdownMatchSelectWidth = true,
  } = data;
  const sharedStyle = {
    marginRight: '1em',
    border:
      warn || (required && (value === undefined || value === null)) ? '2px solid red' : undefined,
    width,
  };

  if (type.match('string') && options) {
    const multipleType = type === '[string]';
    const mode = multipleType ? 'tags' : undefined;
    const placeholder = multipleType ? 'Select options' : 'Select a value';

    return (
      <Select
        mode={mode}
        aria-label={label}
        style={sharedStyle}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...(value !== undefined ? { value } : {})}
        onChange={onChange}
        allowClear={allowClear}
        showSearch={showSearch}
        disabled={disabled}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      >
        {options.map(({ id, label }) => (
          <Option key={id} value={id}>
            {label || <span style={{ color: '#aaa' }}>None</span>}
          </Option>
        ))}
      </Select>
    );
  }

  if (type === 'number')
    return (
      <DebouncedInputNumber
        aria-label={label}
        min={min}
        max={max}
        step={Number(delta)}
        value={value !== undefined ? value : defaultValue}
        defaultValue={value !== undefined ? value : defaultValue}
        onChange={value => {
          onChange(value);
        }}
        style={{
          ...sharedStyle,
          width: undefined,
        }}
        disabled={disabled}
      />
    );

  if (type === 'string')
    return (
      <Input
        placeholder="Value"
        {...(value !== undefined ? { value } : {})}
        defaultValue={defaultValue}
        style={sharedStyle}
        onChange={({ target: { value } }) => onChange(value)}
        allowClear={allowClear}
        disabled={disabled}
      />
    );

  if (type === 'date')
    return (
      <UTCDatePicker
        value={value ? moment(value) : undefined}
        defaultValue={defaultValue}
        onChange={(date, dateString) => onChange(dateString)}
        style={sharedStyle}
        allowClear={allowClear}
        disabled={disabled}
      />
    );

  return <span>Err: Unknown Value Type</span>;
}

import { ASSET_TYPE_BLADE, ASSET_TYPE_TURBINE } from 'utils/constants';

export function getTurbinesFromTasks(tasks: []): any[] {
  const result: number[] = [];

  tasks.forEach(task => {
    if (task.asset.assetType.name === ASSET_TYPE_BLADE && task.asset.ancestors) {
      task.asset.ancestors.forEach(ancestor => {
        if (ancestor.assetType.name === ASSET_TYPE_TURBINE && !result.includes(ancestor.id)) {
          result.push(ancestor.id);
        }
      });
    } else if (
      task.asset.assetType.name === ASSET_TYPE_TURBINE &&
      !result.includes(task.asset.id)
    ) {
      result.push(task.asset.id);
    }
  });

  return result;
}

export function getTurbinesFromTasksForCreateWO(tasks: []): (number | string)[] {
  const result: number[] = [];

  tasks.forEach(task => {
    if (task?.damage?.asset?.ancestors) {
      task.damage.asset.ancestors.forEach(ancestor => {
        if (ancestor.assetType.name === ASSET_TYPE_TURBINE && !result.includes(ancestor.id)) {
          result.push(ancestor.id);
        }
      });
    } else if (task?.blade?.turbine?.id) {
      if (!result.includes(task.blade.turbine.id)) {
        result.push(task.blade.turbine.id);
      }
    } else if (task.turbine && task.turbine.id) {
      if (!result.includes(task.turbine.id)) {
        result.push(task.turbine.id);
      }
    }
  });

  return result;
}

import {
  OTHER_TASK,
  INTERNAL_BLADE_INSPECTION_TASK,
  REPAIR_TASK,
  INSPECT_TASK,
  INSPECTION_TASK,
} from 'utils/constants';
export const descriptionField = {
  name: 'Description',
  type: 'TEXT',
  configured: [
    {
      associatedType: {
        type: REPAIR_TASK,
      },
      displayOrder: -1,
    },
    {
      associatedType: {
        type: INSPECT_TASK,
      },
      displayOrder: -1,
    },
    {
      associatedType: {
        type: OTHER_TASK,
      },
      displayOrder: -1,
    },
    {
      associatedType: {
        type: INTERNAL_BLADE_INSPECTION_TASK,
      },
      displayOrder: -1,
    },
    {
      associatedType: {
        type: INSPECTION_TASK,
      },
      displayOrder: -1,
    },
  ],
};

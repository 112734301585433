import { useQuery } from '@apollo/client';
import GET_POTENTIAL_DAMAGE_ATTRS from 'queries/GetPotentialDamageAttributes.gql';

export function usePotentialDamageAttrs({ pictureId, coordinates }) {
  const { data, loading } = useQuery(GET_POTENTIAL_DAMAGE_ATTRS, {
    variables: { pictureId, coordinates },
    skip: !pictureId || !coordinates || coordinates.length < 4,
  });
  const {
    bladeSide,
    bladeChamber,
    inspectionDate,
    length,
    width,
    radialDistance,
    chordAverage,
    chordPoints,
    distanceFromNacelleCenter,
    towerSide,
    nacelleSide,
    transitionPieceSide,
  } = data?.getPotentialDamageAttributes ?? {};

  const potentialAttrs = {
    'Blade Side': bladeSide,
    'Blade Chamber': bladeChamber,
    Date: inspectionDate,
    Length: length,
    Width: width,
    Distance: radialDistance,
    Chord: chordAverage,
    'Chord Points': chordPoints ? JSON.stringify(chordPoints) : chordPoints,
    'Distance from Nacelle': distanceFromNacelleCenter,
    'Tower Side': towerSide,
    'Nacelle Side': nacelleSide,
    'Transition Piece Side': transitionPieceSide,
  };

  return { loading, potentialAttrs };
}

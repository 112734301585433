// FIXME: clean out "guard method"
const naturalSort = (a: any, b: any): number => {
  const groupA = __guardMethod__(a, 'match', (o: any) => o.match(/\d+|\D+/g)) || [a];
  const groupB = __guardMethod__(b, 'match', (o1: any) => o1.match(/\d+|\D+/g)) || [b];

  for (
    let index = 0, end = Math.min(groupA.length, groupB.length), asc = 0 <= end;
    asc ? index <= end : index >= end;
    asc ? index++ : index--
  ) {
    const aa = groupA[index];
    const bb = groupB[index];

    switch (false) {
      case aa !== bb && +aa !== +bb:
        continue;
      case !!isNaN(aa) || !!isNaN(bb):
        if (+aa < +bb) {
          return -1;
        } else {
          return 1;
        }
      case !+aa && !!aa:
        return -1;
      case !+bb && !!bb:
        return 1;
      case !(aa.toLowerCase() < bb.toLowerCase()):
        return -1;
      case !(aa.toLowerCase() > bb.toLowerCase()):
        return 1;
      default:
        return 0;
    }
  }
  return 0;
};

function __guardMethod__(obj: any, methodName: any, transform: any) {
  if (typeof obj !== 'undefined' && obj !== null && typeof obj[methodName] === 'function') {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}

export const naturalSortBy = (attr: any) => (a: any, b: any) => naturalSort(a[attr], b[attr]);
export default naturalSort;

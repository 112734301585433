import { SingleLocationTurbineChooser } from './SingleLocationTurbineChooser';
import { TurbinesByLocationChooser } from './TurbinesByLocationChooser';
import { TurbineChooserComponentProps } from './types';

const chooserComponent = ({ locationId, ...props }: TurbineChooserComponentProps) => {
  return locationId ? (
    <SingleLocationTurbineChooser locationId={locationId} {...props} />
  ) : (
    <TurbinesByLocationChooser {...props} />
  );
};

export default chooserComponent;

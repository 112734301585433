import { useState, useMemo } from 'react';
import { isObject, pick } from 'lodash';
import moment from 'moment';
import { Input, InputNumber, Select, TimePicker } from 'antd';
import { StyledSelect, StyledMultiSelect } from './editable.style';
import { renderColumn } from 'components/data/helpers';
import { UTCDatePicker } from 'components/UTCDatePicker';
import { CanRender } from 'utils/usePermissions';
import { numberCommaRegex } from 'utils/constants';

const { Option } = Select;
const { RangePicker } = TimePicker;

export function editable(column, options = {}) {
  const { editRule } = column;
  if (!editRule) {
    return column;
  }

  const { onChange } = options;

  return {
    ...column,
    render: (value, record, index) => {
      if (column.edit && record && record.editable) {
        // FIXME: would prefer to get things like `editable` and `disabled` from something besides the record,
        // currently bound by the ant design function signature of these render functions.
        // We're whitelisting props here to safeguard against undefined behavior in column inputs.
        const inputProps = pick(record, [
          'disabled',
          'placeholder',
          'allowClear',
          'loading',
          'REQUIRE_VALID_DAMAGE',
        ]);

        return (
          <CanRender rules={[editRule]} fallbackRender={() => renderColumn(column, record, index)}>
            {column.edit(record, { onChange, ...inputProps })}
          </CanRender>
        );
      }

      return renderColumn(column, record, index);
    },
  };
}

export const standardInput = (key, defaultValue) => {
  return (value, { onChange, disabled }) => (
    <Input
      style={{ margin: '0', width: 'auto' }}
      disabled={disabled}
      defaultValue={defaultValue ? defaultValue : isObject(value) ? value[key] : value}
      onChange={
        onChange &&
        (e => {
          onChange({ [key]: e.target.value });
        })
      }
    />
  );
};

export const numberInput = (key, defaultValue) => {
  return (value, { onChange, disabled }) => (
    <InputNumber
      data-testid={`number-input-${value.title}`}
      defaultValue={defaultValue ? defaultValue : isObject(value) ? value[key] : value}
      formatter={value => `${value}`.replace(numberCommaRegex, ',')}
      parser={value => value.replace(/\$\s?|(,*)/g, '')}
      disabled={disabled}
      onChange={
        onChange &&
        (value => {
          if (value === '' || value === undefined) {
            value = null;
          }
          return onChange({ [key]: value });
        })
      }
    />
  );
};

export const dateInput = (key, defaultValue) => {
  return (value, { onChange, ...props }) => {
    const dateStr = defaultValue ? defaultValue : isObject(value) ? value[key] : value;
    return (
      <UTCDatePicker
        defaultValue={dateStr ? moment(dateStr) : null}
        onChange={value => onChange?.({ [key]: value })}
        {...props}
      />
    );
  };
};

// called initially by useHeaderFields
// 2nd function called by InlineEditable
const MIN_ELEMENTS_FOR_SEARCH = 8;
export const selectInput = (column, selectProps = {}) => {
  // TODO: kinda hacky. A few of our inputs were showing up as {undefined: $value}. Attempting to be more explicit about trying key first, then id, then fallback to undefined. I'm not sure what--if any ramifications--this will have elsewhere though. This pattern is repated a few times in this file.
  const dataKey = column?.key ?? column?.id ?? undefined;
  return (record, { onChange, disabled }) => {
    const { allowClear = true, ...rest } = selectProps;
    const showSearch = column?.options?.length > MIN_ELEMENTS_FOR_SEARCH;
    return (
      <StyledSelect
        disabled={disabled}
        showSearch={showSearch}
        defaultValue={column?.defaultValue}
        onChange={value => onChange({ [dataKey]: value })}
        allowClear={allowClear}
        {...rest}
      >
        {column?.options?.map(o => (
          <Option value={o} key={o}>
            {o}
          </Option>
        ))}
      </StyledSelect>
    );
  };
};

// use this select if you need to set an id as the value,
// but need to display a string for selections.
export const selectObjectInput = column => {
  // TODO: see above in selectInput
  const dataKey = column?.key ?? column?.id ?? undefined;
  return (record, { onChange }) => {
    return (
      <StyledSelect
        defaultValue={column?.defaultValue}
        onChange={value => onChange({ [dataKey]: value })}
        allowClear
      >
        {column?.options?.map(o => (
          <Option value={o.id} key={o.id}>
            {o.name}
          </Option>
        ))}
      </StyledSelect>
    );
  };
};

// Must provide a key: string, name: string, options: array, defaultValue: array
// without all of these, this will blow up.
export const multiSelectInput = column => {
  const defaultValue = column?.defaultValue ?? [];
  const options = column?.options ?? [];
  const availableOptions = options.filter(x => !defaultValue.includes(x));
  // TODO: see above in selectInput
  const dataKey = column?.key ?? column?.id ?? undefined;
  return (record, { onChange, disabled }) => {
    return (
      <StyledMultiSelect
        mode="multiple"
        disabled={disabled}
        placeholder={`Select ${column.name ? column.name : 'value'}s...`}
        defaultValue={defaultValue}
        onChange={value => onChange({ [dataKey]: value })}
        allowClear
      >
        {availableOptions?.map(o => (
          <Option value={o} key={o}>
            {o}
          </Option>
        ))}
      </StyledMultiSelect>
    );
  };
};

export const timeInput = column => {
  return (record, { onChange, disabled }) => {
    const dataKey = column?.key ?? column?.id ?? undefined;
    const format = 'HH:mm';

    // Setup defaultValue with additional checks
    let defaultValue = [moment().startOf('day'), moment().startOf('day')];

    if (Array.isArray(column?.defaultValue)) {
      // Verify we have a start and end time before processing them
      if (column?.defaultValue[0] !== null || column?.defaultValue[1] !== null) {
        defaultValue = [
          moment(column?.defaultValue[0], format),
          moment(column?.defaultValue[1], format),
        ];
      }
    }

    return (
      <RangePicker
        disabled={disabled}
        defaultValue={defaultValue}
        format={format}
        onChange={(time, timeArray) => {
          onChange({
            [dataKey]: {
              timeRangeStartValue: timeArray[0],
              timeRangeEndValue: timeArray[1],
            },
          });
        }}
      />
    );
  };
};

function defaultMutationSave(props, record) {
  const { id, ...input } = record;
  props.mutate({ variables: { id, input } });
}

export function useEditableRecords(props) {
  const { onSave = defaultMutationSave, getData, onRemove, data: dataProp = [] } = props;

  const [editableRecord, setEditableRecord] = useState(null);

  const editableData = useMemo(() => {
    const getEditFlag = record => ({
      ...record,
      editable: editableRecord && record.id === editableRecord.id,
    });

    const data = getData ? getData({ data: dataProp }) : dataProp;
    if (data) {
      return Array.isArray(data) ? data.map(getEditFlag) : getEditFlag(data);
    }
  }, [dataProp, editableRecord, getData]);

  return {
    editableData,
    onEditRecordChange(changedAttr) {
      setEditableRecord({ ...editableRecord, ...changedAttr });
    },
    onEdit(e) {
      setEditableRecord({ id: e.target.value });
    },
    onCancel() {
      setEditableRecord(null);
    },
    onSave(record) {
      // record arg is available for implementing components to call this directly.
      // the argument can sometimes be a DOM Event though, which we don't want to send up.
      if (record && !(record instanceof Event || record.nativeEvent instanceof Event)) {
        onSave(props, record);
      } else {
        onSave(props, editableRecord);
        setEditableRecord(null);
      }
    },
    onRemove(id) {
      if (onRemove) {
        onRemove(props, id);
      }
    },
  };
}

export function withEditableRecords(options = {}) {
  return function (Component) {
    return function EditableComponent(props) {
      const editableAttrs = useEditableRecords({ ...options, ...props });
      return <Component {...props} {...editableAttrs} />;
    };
  };
}

export { inlineEditable } from './InlineEditable';
export { default as EditActions } from './EditActions';
export { BulkEdit } from './BulkEdit';

import { useEffect, useState, useCallback, useMemo } from 'react';
import { Modal } from 'antd';
import { isEqual, isEmpty, isBoolean, merge, pickBy, uniq } from 'lodash';

import { useDamageAttrSelections } from 'components/damages/withDamages';
import { useGetDamageSchemaNamesQuery } from 'types/atlas-graphql';
import { AttributesForm } from './AttributesForm';
import { DamageEditPayload } from './types';

import { useAccountContext } from 'utils/account/AccountContext';
import { useFeatures } from 'utils/features';

interface Props {
  backgroundColor?: string;
  damage: {
    attrs: any;
  };
  formLayout?: string;
  ignoreWorkInProgress?: boolean;
  isImageViewer?: boolean;
  loading: boolean;
  onCancel?: () => void;
  onSave?: (payload: DamageEditPayload) => void | Promise<void>;
  potentialAttrs: any;
  canChangeSchema?: boolean;
  schemaId?: string;
  critical?: boolean;
  shapeChanged: boolean;
  shapeDrawn: boolean;
  shouldHideRealtimePotentialAttrs?: boolean;
  showComment?: boolean;
}

export default function EditableDamageAttributes({
  backgroundColor,
  damage,
  formLayout = 'vertical',
  ignoreWorkInProgress = false,
  isImageViewer = false,
  loading,
  onCancel,
  onSave,
  potentialAttrs,
  canChangeSchema = true,
  schemaId,
  critical,
  shapeChanged,
  shapeDrawn,
  shouldHideRealtimePotentialAttrs = false,
  showComment,
}: Props) {
  const { DAMAGE_CRITICAL } = useFeatures().features;

  const [saving, setSaving] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [combinationsSatisfied, setCombinationsSatisfied] = useState<boolean>(false);
  const [workingSchemaId, setWorkingSchemaId] = useState<string | undefined>();
  const [workingCritical, setWorkingCritical] = useState<boolean | undefined>();
  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);
  const [payload, setPayload] = useState<DamageEditPayload>({});
  const { REQUIRE_VALID_DAMAGE } = useFeatures().features;

  const { data: schemaData } = useGetDamageSchemaNamesQuery({
    variables: { ids: uniq([schemaId, workingSchemaId].filter(Boolean)) },
    skip: !schemaId,
  });

  const {
    columns,
    selections,
    hiddenSelections,
    disabledSelections,
    clearSelection,
    makeSelection,
    clearSelections,
    loading: selectableLoading,
    includesRealtimePotentialAttrs,
  } = useDamageAttrSelections({
    damage,
    schemaId: workingSchemaId,
    potentialAttrs,
    ignoreWorkInProgress,
    shouldHideRealtimePotentialAttrs,
    shouldHideHiddenAttrs: true,
    shouldHideDisabledAttrs: false,
  });

  const jsonSelections = useMemo(() => {
    return merge(
      {},
      ...[...selections, ...hiddenSelections, ...disabledSelections].map(
        ({ name, date, string, number }) => ({
          [name]: string || date || number,
        })
      )
    );
  }, [selections, hiddenSelections, disabledSelections]);

  useEffect(() => {
    /**
     * If filters are available for an attribute, it must have a value for currently available
     * schema combinations. `combinationsSatisfied` is used to indicate whether the form can be submitted
     * based on whether the selected values are a valid combination in the schema.
     */
    if (REQUIRE_VALID_DAMAGE) {
      setCombinationsSatisfied(
        !columns.some(
          ({ filters, key }) => !!filters.length && !Object.keys(jsonSelections).includes(key)
        )
      );
    }
  }, [columns, jsonSelections, REQUIRE_VALID_DAMAGE]);

  // set schemaId
  useEffect(() => {
    if (schemaId) {
      setWorkingSchemaId(schemaId);
    }
  }, [schemaId]);

  // set critical status
  useEffect(() => {
    if (isBoolean(critical)) {
      setWorkingCritical(critical);
    }
  }, [critical]);

  // Need this setState to avoid react state update on unmounted component issues
  useEffect(() => {
    return () => {
      setSaving(false);
    };
  }, [warningModalOpen]);

  const handleChangeWorkingSchemaId = useCallback(
    (newWorkingSchemaId: string | undefined) => {
      clearSelections();
      setWorkingSchemaId(newWorkingSchemaId);
    },
    [clearSelections]
  );

  useEffect(() => {
    if (!damage?.attrs && !Object.keys(jsonSelections).length) {
      return;
    }

    // Allow saving if:
    // - the shape has changed
    // - the schema has changed
    // - some of the attributes are no longer allowed (i.e. they will be deleted) (or)
    // - the current attributes are different from what has been selected (or)
    // - the current critical status is different
    setHasChanged(
      Boolean(
        shapeChanged ||
          schemaId !== workingSchemaId ||
          disabledSelections.length ||
          !isEqual(jsonSelections, damage?.attrs) ||
          critical !== workingCritical
      )
    );
  }, [
    disabledSelections,
    jsonSelections,
    damage,
    shapeChanged,
    schemaId,
    workingSchemaId,
    critical,
    workingCritical,
  ]);

  const handleOnChange = useCallback(
    selection => {
      const { name, ..._rest } = selection;
      const rest = pickBy(_rest, a => a !== undefined);
      if (Object.keys(rest).length === 0) {
        clearSelection({ name });
      } else {
        makeSelection(selection);
      }
    },
    [makeSelection, clearSelection]
  );

  const _handleSave = useCallback(
    async (payload: DamageEditPayload) => {
      if (onSave) {
        setSaving(true);
        await onSave(payload);
        setSaving(false);
      }
    },
    [onSave]
  );

  const handleSave = useCallback(
    (payload: DamageEditPayload) => {
      const { schemaId: schemaIdToSave, critical, comment } = payload;

      const _payload = {
        attrs: [...selections, ...hiddenSelections],
        schemaId: schemaIdToSave,
        critical,
        comment,
      };

      if (canChangeSchema && schemaId && schemaIdToSave !== schemaId) {
        setPayload(_payload);
        setWarningModalOpen(true);
      } else {
        _handleSave(_payload);
      }
    },
    [selections, schemaId, canChangeSchema, _handleSave, hiddenSelections]
  );

  const handleCancel = useCallback(() => onCancel?.(), [onCancel]);

  const sourceSchemaName = (schemaData?.damageSchemas ?? []).find(
    ({ id }) => id === schemaId
  )?.name;
  const targetSchemaName = (schemaData?.damageSchemas ?? []).find(
    ({ id }) => id === workingSchemaId
  )?.name;

  const warningModalCopy =
    sourceSchemaName && targetSchemaName
      ? `You have changed the damage schema for this damage from ${sourceSchemaName} to ${targetSchemaName}. Any damage attributes from ${sourceSchemaName} that are not in ${targetSchemaName} will be removed upon saving. Do you want to continue?`
      : 'You have changed the damage schema for this damage. Any damage attributes from the current schema that are not in the new schema will be removed upon saving. Do you want to continue?';

  return (
    <>
      <AttributesForm
        loading={loading || selectableLoading}
        saving={saving}
        selections={jsonSelections}
        columns={columns}
        onSave={onSave ? handleSave : null}
        onCancel={handleCancel}
        onChange={handleOnChange}
        saveDisabled={
          !shapeDrawn || !onSave || !hasChanged || (REQUIRE_VALID_DAMAGE && !combinationsSatisfied)
        }
        showComment={showComment}
        formLayout={formLayout}
        saveButtonText={isEmpty(damage) ? 'Create' : 'Save'}
        canChangeSchema={canChangeSchema}
        schemaId={workingSchemaId || schemaId}
        setSchemaId={handleChangeWorkingSchemaId}
        canChangeCritical={DAMAGE_CRITICAL}
        critical={workingCritical}
        setCritical={setWorkingCritical}
        includesRealtimePotentialAttrs={includesRealtimePotentialAttrs}
        potentialAttrs={potentialAttrs}
        backgroundColor={backgroundColor}
        isImageViewer={isImageViewer}
      />
      <Modal
        // @ts-ignore
        cancelButtonProps={{ 'data-testid': 'damage-confirm-cancelBtn' }}
        cancelText="Cancel"
        confirmLoading={saving}
        destroyOnClose
        // @ts-ignore
        okButtonProps={{ 'data-testid': 'damage-confirm-confirmBtn' }}
        okText="Continue"
        okType="danger"
        onCancel={() => setWarningModalOpen(false)}
        onOk={() => _handleSave(payload)}
        title="Change Schema?"
        visible={warningModalOpen}
      >
        {warningModalCopy}
      </Modal>
    </>
  );
}

import { useState, useCallback, useEffect } from 'react';

/**
 * antd single/multi selects are going to send the data differently, so this logic
 * is here to make single/multi selects able interface the same way as well as supply a common way
 * these components are going to send changes to a parent
 */

interface IUseAssetChooserState {
  onChange: (locationIds: any[]) => void;
  value: any;
}

export function useAssetChooserState({ onChange, value }: IUseAssetChooserState) {
  const [visible, setVisible] = useState(false);
  const [chooserState, setChooserState] = useState(value);

  useEffect(() => {
    if (!visible) {
      onChange(chooserState);
    }
  }, [chooserState, visible]);

  useEffect(() => {
    setChooserState(value);
  }, [value]);

  const handleChange = useCallback((...args) => {
    const [value] = args;
    if (value && value.length) {
      if (value instanceof Array) {
        setChooserState(value);
      } else {
        setChooserState([value]);
      }
    } else {
      setChooserState(undefined);
    }
  }, []);

  return { handleChange, setVisible, chooserState };
}

import styled from 'styled-components';
import { FlexContainer } from 'utils/layouts/containers';

export const ActionButtons = styled(FlexContainer)`
  justify-content: flex-end;

  .left-btn {
    margin-right: auto;
  }
`;

import React from 'react';
import {
  AtlasGqlMaintenanceActivity,
  AtlasGqlMaintenanceActivityStatus,
} from 'types/atlas-graphql';
import { HeaderWrapper, TextSpan } from './maintenanceActivities.style';

interface IMaintenanceActivityNameHeader {
  maintenanceActivity: AtlasGqlMaintenanceActivity;
}

export const MaintenanceActivityNameHeader: React.FunctionComponent<
  IMaintenanceActivityNameHeader
> = ({ maintenanceActivity }) => {
  const { id, name, status } = maintenanceActivity;

  const renderText = () => {
    const displayName =
      status === AtlasGqlMaintenanceActivityStatus.Ended ? `${name} (Ended)` : `${name}`;

    return <a href={`/recurring-task/${id}`}>{displayName}</a>;
  };

  return (
    <HeaderWrapper>
      <TextSpan>{renderText()}</TextSpan>
    </HeaderWrapper>
  );
};

import { TagProps } from 'antd5';

import { getSeverityColor } from 'utils/color/helpers';
import { Container, StyledTag, CriticalIcon } from './SeverityAndCriticalIndicator.style';
import { Tooltip } from 'components/ui';

export enum CriticalLevel {
  Group,
  Observation,
  Image,
}
type SeverityAndCriticalIndicatorProps = TagProps &
  React.RefAttributes<HTMLElement> & {
    value?: number;
    maxValue?: number;
    isCritical?: boolean;
    criticalLevel?: CriticalLevel;
    margin?: string;
  };

/**
 * This component replaces <SeverityTag />. All legacy uses of <SeverityTag /> in deprecated code that
 * is not ready to be removed yet, but does not need to support the new critical work have not been replaced.
 */

export const SeverityAndCriticalIndicator: React.FunctionComponent<
  SeverityAndCriticalIndicatorProps
> = ({ value, maxValue, isCritical, criticalLevel = CriticalLevel.Group, margin, ...props }) => {
  return (
    <Container margin={margin}>
      {
        <StyledTag
          data-testid="severity-tag"
          color={getSeverityColor(value, maxValue) ?? undefined}
          $borderStyle={!value ? '1px dashed #00000055' : undefined}
          {...props}
        >
          {value ?? '?'}
        </StyledTag>
      }
      {isCritical && <CriticalIndicator criticalLevel={criticalLevel} />}
    </Container>
  );
};

export const CriticalIndicator: React.FunctionComponent<{ criticalLevel?: CriticalLevel }> = ({
  criticalLevel = CriticalLevel.Group,
}) => {
  return (
    <Tooltip
      title={
        {
          Group: 'This Damage contains a critical Observation',
          Observation: 'This is a critical Observation',
          Image: 'One of the Observations on this image is critical',
        }[CriticalLevel[criticalLevel]]
      }
    >
      <CriticalIcon />
    </Tooltip>
  );
};

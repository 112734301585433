import { useState, useEffect } from 'react';
import { Button } from 'components/ui';
import { flatten } from 'lodash';

import { FlexContainer } from 'utils/layouts/containers';
import { SelectableAction, useSelectableRecords } from 'utils/selectable';
import DataTable from 'components/DataTable';
import { AssetChooser } from '../index';
import { useManagedAssetChooser } from 'components/choosers/AssetChooser/useManagedAssetChooser';
import { SchemaSelect } from 'components/damages/DamageSchema/SchemaSelect';
import {
  damageIdColumn,
  siteColumn,
  assetColumn,
  assetTypeColumn,
  assetStatusColumn,
  inspectionsColumn,
  observationsColumn,
} from 'components/DamageTable2/columns';
import { TQueryFilter } from 'components/DataTable/types';
import { TTableColumnDef } from 'horizon/types/TableColumnDef';
import {
  AtlasGqlConfirmationStatus,
  AtlasGqlGetHorizonDamagesQuery,
  AtlasGqlHorizonDamage,
  GetHorizonDamagesDocument,
  useGetDescendantIdsQuery,
} from 'types/atlas-graphql';

import { useDamageAttrs } from 'components/damages/withDamages';
import { generateDamageTableFilters } from 'horizon/routes/Inspections/DamageList/utils';
import { HORIZON_DAMAGE_TABLE_STORAGE_KEY } from 'components/DataTable/Views/helpers/tableStorageKeys';
import { DamageList2ExpandedRow } from 'components/DamageTable2/ExpandedRow';
import { useDamages2Banner } from 'horizon/components/Damages/Damages2Banner';

type DamageChooser2Props = {
  controls?: boolean;
  defaultActiveTabKey?: string;
  externalLoading?: boolean;
  handleAdd: any; // FIXME JDJ: this is the callback for adding the Tasks.
};

/**
 * 4/11/23 JDJ: This is a reduced version of the DamageTable2 component, intended to be used solely for the purpose of selecting
 * HorizonDamages to use when bulk creating Tasks as part of creating a new Work Order in the Work Order Wizard process.
 */
export const DamageChooser2: React.FunctionComponent<DamageChooser2Props> = ({
  defaultActiveTabKey = 'ALL',
  controls = true,
  externalLoading = false,
  handleAdd,
}) => {
  useDamages2Banner(true);
  const [selectedSchema, setSelectedSchema] = useState<string>();
  const [selectedAssets, setSelectedAssets] = useState<string[]>();

  const {
    selected = [],
    rowSelection,
    onClearSelection,
  } = useSelectableRecords<AtlasGqlHorizonDamage>();

  // In this component, we can always assume isHorizonDamage will be true
  const { damageColumns } = useDamageAttrs(selectedSchema, true);

  const { onChange: handleAssetChooserChange, locationIds, turbineIds } = useManagedAssetChooser();

  const { loading: descendantDataLoading } = useGetDescendantIdsQuery({
    variables: {
      filterBy: [{ key: 'assetIds', values: turbineIds ?? locationIds ?? [] }],
    },
    onCompleted: data => {
      setSelectedAssets([
        ...(turbineIds ?? locationIds ?? []),
        ...flatten(
          (data?.assetsWithMetadata?.items ?? []).map(({ descendants }) => [
            ...(descendants ?? []).map(({ id }) => id),
          ])
        ),
      ]);
    },
    skip: !turbineIds && !locationIds,
  });

  useEffect(() => {
    if (!turbineIds && !locationIds) {
      setSelectedAssets(undefined);
    }
  }, [turbineIds, locationIds]);

  const allColumns: TTableColumnDef[] = [
    ...[
      damageIdColumn,
      siteColumn,
      assetColumn,
      assetTypeColumn,
      assetStatusColumn,
      inspectionsColumn,
      observationsColumn,
    ],
    ...damageColumns,
  ];

  const CONFIRMED_TAB_LIST = [
    {
      key: 'ALL',
      tab: 'All',
      value: { filters: { confirmationStatus: undefined } },
    },
    {
      key: 'CONFIRMED',
      tab: 'Confirmed',
      value: { filters: { confirmationStatus: [AtlasGqlConfirmationStatus.Confirmed] } },
    },
    {
      key: 'UNCONFIRMED',
      tab: 'Unconfirmed',
      value: {
        filters: {
          confirmationStatus: [
            AtlasGqlConfirmationStatus.NotConfirmed,
            AtlasGqlConfirmationStatus.ConfirmedUpdated,
          ],
        },
      },
    },
  ];

  const universalFilters: TQueryFilter[] = generateDamageTableFilters({
    schemaId: selectedSchema,
    assetIds: selectedAssets,
  });

  const transformQueryResponse = (data: AtlasGqlGetHorizonDamagesQuery) => {
    if (!data.getHorizonDamages) {
      return { totalCount: 0, items: [] };
    }
    return {
      totalCount: data.getHorizonDamages?.totalCount ?? 0,
      items: ((data.getHorizonDamages?.items ?? []).filter(Boolean) as AtlasGqlHorizonDamage[]).map(
        (damage: AtlasGqlHorizonDamage) => ({
          ...damage,
          attrs: damage.primaryObservationGroupAttrs,
        })
      ),
    };
  };

  const handleSubmit = () => {
    handleAdd(selected);
  };

  return (
    <>
      <DataTable
        loading={externalLoading || descendantDataLoading}
        dataQuery={GetHorizonDamagesDocument}
        size="small"
        tabList={CONFIRMED_TAB_LIST.filter(({ tab }) => tab)}
        initialPresetFilter={CONFIRMED_TAB_LIST.find(tab => tab.key === defaultActiveTabKey) as any}
        transform={transformQueryResponse}
        routeProps={{
          universalFilter: universalFilters,
          variables: { input: {} },
        }}
        columns={allColumns}
        expandedRowRender={(damage: AtlasGqlHorizonDamage) => (
          <DamageList2ExpandedRow damage={damage} />
        )}
        showColumnPicker={true}
        storageKey={HORIZON_DAMAGE_TABLE_STORAGE_KEY}
        controls={
          controls ? (
            <>
              <SchemaSelect
                style={{
                  width: '180px',
                  marginRight: '1em',
                }}
                selected={selectedSchema}
                setSelected={setSelectedSchema}
              />
              {
                <AssetChooser
                  width={'50%'}
                  onChange={handleAssetChooserChange}
                  bladeIds={[]}
                  turbineIds={turbineIds}
                  locationIds={locationIds}
                  callerManagedState={true}
                  settings={{ blade: { visible: false } }}
                  small={false}
                  block={false}
                />
              }
            </>
          ) : undefined
        }
        rowSelection={rowSelection}
        actions={
          <>
            <FlexContainer>
              <SelectableAction selected={selected} onClear={onClearSelection} />
            </FlexContainer>
            <SelectableAction selected={selected} onClear={onClearSelection}>
              <FlexContainer>
                <Button
                  _version={4}
                  type="primary"
                  id="damage-chooser-add-damage-button"
                  disabled={!selected.length}
                  onClick={handleSubmit}
                >
                  Add
                </Button>
              </FlexContainer>
            </SelectableAction>
          </>
        }
      />
    </>
  );
};

import styled from 'styled-components';
import { useHistory, useLocation, Route, RouteProps } from 'react-router-dom';
import { GlobalHeader } from 'components/GlobalHeader';
import Breadcrumbs, { BreadcrumbItemProps } from 'components/Breadcrumbs';
import { ErrorBoundary } from '../ErrorBoundary';
import PageHeader, { PageHeaderProps } from '../PageHeader';
import { FlexContainer, SpacedContainer } from 'utils/layouts/containers';
import pathToRegexp from 'path-to-regexp';
import { Content, Layout } from 'components/ui';

const ContentContainer = styled.div`
  margin: 1rem 1rem 0;
`;

const Title = styled.h1`
  font-size: 1.5em;
  font-weight: 500;
  display: inline;
`;

interface GetTabFromRouteArgs {
  title: React.ReactNode;
  path: string;
  href?: string;
}

const getTabFromRoute = ({ title, path, href }: GetTabFromRouteArgs) => ({
  key: href || path,
  tab: title,
});

interface GetActiveKeyArgs {
  pathname: string;
  routes: (RouteProps<string> & { href?: string })[];
}

const getActiveKey = ({ routes, pathname }: GetActiveKeyArgs) => {
  const route = routes.find(({ path }) => pathToRegexp(path as string, []).exec(pathname));

  return route ? route.href || route.path : null;
};

interface Props extends PageHeaderProps {
  breadcrumbList?: BreadcrumbItemProps[];
  children?: React.ReactNode;
  loading?: boolean;
  routes?: (RouteProps<string> & { hidden?: false })[];
  title?: React.ReactNode;
  wrapperClassName?: string;
}

export function PageHeaderLayout({
  breadcrumbList,
  children,
  loading,
  onTabChange,
  routes,
  tabActiveKey,
  tabList,
  title,
  wrapperClassName,
  ...pageHeaderProps
}: Props) {
  const history = useHistory();
  const { pathname } = useLocation();

  const handleTabChange = (tab: string) => {
    if (onTabChange) {
      return onTabChange(tab);
    }

    // assume that if props.routes are passed in then every tab key is a path
    if (routes) {
      return history.push(tab);
    }
  };

  const activeTab = tabActiveKey || (routes && getActiveKey({ routes, pathname }));
  const tabs = tabList || (routes && routes.filter(({ hidden }) => !hidden).map(getTabFromRoute));
  const content =
    children || (routes && routes.map(route => <Route key={route.path as string} {...route} />));

  const headerContent = (
    <FlexContainer>
      {title && (
        <SpacedContainer>
          <Title data-testid="header-layout-title" id="header-layout-title">
            {title}
          </Title>
        </SpacedContainer>
      )}
      {breadcrumbList && (
        <SpacedContainer>
          <Breadcrumbs data-testid="breadcrumbs" items={breadcrumbList} loading={loading} />
        </SpacedContainer>
      )}
    </FlexContainer>
  );

  return (
    <ErrorBoundary>
      <Layout _version={4}>
        <GlobalHeader content={headerContent} />
        <Content _version={4}>
          <PageHeader
            key="pageheader"
            onTabChange={handleTabChange}
            tabActiveKey={activeTab as string}
            tabList={tabs}
            {...pageHeaderProps}
          />
          <ContentContainer>{content}</ContentContainer>
        </Content>
      </Layout>
    </ErrorBoundary>
  );
}

import { Form, Select } from 'antd';

import { useAllDamageSchemasQuery } from 'types/atlas-graphql';

type DamageEditSchemaProps = {
  schemaId: string;
  onChange: (schemaId: string) => void;
};

const { Option } = Select;

export default function DamageEditSchema({ schemaId, onChange }: DamageEditSchemaProps) {
  const { data, loading } = useAllDamageSchemasQuery();
  const damageSchemas = data?.damageSchemas ?? [];

  if (loading) {
    return null;
  }

  return (
    <Form.Item
      label="Damage Schema"
      name="schemaId"
      style={damageSchemas?.length < 2 ? { visibility: 'hidden', height: 0, marginBottom: 0 } : {}}
      rules={[{ required: false }]}
      initialValue={
        schemaId ?? damageSchemas.find(({ isDefault }) => isDefault)?.id ?? damageSchemas[0]?.id
      }
    >
      <Select
        className="testid-damage-schema-schema"
        style={{ width: '100%' }}
        allowClear={true}
        onChange={onChange}
        getPopupContainer={triggerNode => triggerNode.parentElement}
      >
        {damageSchemas.map(({ id, name, isDefault }) => (
          <Option key={id} value={id}>
            {`${name}${isDefault ? ' (default)' : ''}`}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

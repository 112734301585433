import { useEffect, useMemo, useState } from 'react';
import { message } from 'antd';
import { useStandardFields } from '../useStandardFields';
import { isEqual } from 'lodash';
import { getContextualColumns, sortAndMapFieldsToColumns } from './utils';
import { useFeatures } from 'utils/features';
import GET_WORK_CONTAINER_VALUES from 'horizon/routes/Settings/WorkContainerFields/queries/GetWorkContainerValues.atlas.gql';
import {
  useSaveWorkContainerFieldValuesMutation,
  useGetWorkContainerFieldsQuery,
  useGetWorkContainerValuesLazyQuery,
  AtlasGqlWorkContainerTypeOption,
  AtlasGqlTask,
  useGetTrustedOrganizationsQuery,
  AtlasGqlOrganization,
} from 'types/atlas-graphql';
import { usePermissions } from 'utils/usePermissions';
import { useAccountContext } from 'utils/account/AccountContext';
import { notEmpty } from 'utils/types';
import { deconstructAtlasTask } from 'horizon/routes/WorkOrders/Tasks/TaskDetail/helpers';

type UseHeaderFieldsProps = {
  containerId: string;
  type: AtlasGqlWorkContainerTypeOption | string;
  additionalFields: any[];
  // FIXME JDJ: This could eventually be used to replace both containerId and additional fields
  // But that's a large refactor.
  workContainer?: AtlasGqlTask | undefined | null;
  // This component uses a combination of both rules and the isEditButtonVisible prop
  // to determine whether to show the edit button.
  // This is to continue to deprecate the use of rules going forward but need a way
  // to dynamically show the button for ongoing work. - AN 9/27/23
  isEditButtonVisible?: boolean | undefined;
  // Optional callback. Pass this in if an editable field needs to call the edit task function directly
  onSave?: (input: any) => Promise<void> | undefined;
};

// Fetches and formats the header fields for a given work container
// If no work container is given, fetches and formats the header fields for the given work contianer type
export function useHeaderFields(props: UseHeaderFieldsProps) {
  const { features } = useFeatures();
  const { hasReleaseToggle } = useAccountContext();
  const hasVendorRT = hasReleaseToggle('vendor-dropdown');
  const { FOR_ORGANIZATION_EDIT } = features;

  const { HasRole } = usePermissions();
  const isEditor = HasRole({ role: 'role-editor' });

  const { containerId, type, additionalFields, workContainer, isEditButtonVisible, onSave } = props;
  const [currentFields, setCurrentFields] = useState<any[]>([]);
  const [mergedAdditionalFields, setMergedAdditionalFields] = useState<any[]>([]);
  const [fetchedAdditionalFields, setFetchedAdditionalFields] = useState<any[]>([]);
  const [trustedOrganizations, setTrustedOrganizations] = useState<
    Pick<AtlasGqlOrganization, 'id' | 'name'>[]
  >([]);
  const [getWorkContainerValues, { data }] = useGetWorkContainerValuesLazyQuery();

  const workFieldsResult = useGetWorkContainerFieldsQuery({
    variables: { type: type as AtlasGqlWorkContainerTypeOption },
    skip: !type,
  });

  const [saveWorkContainerFieldValues, { loading }] = useSaveWorkContainerFieldValuesMutation({
    refetchQueries: [{ query: GET_WORK_CONTAINER_VALUES, variables: { containerId } }],
    awaitRefetchQueries: true,
  });

  const isForOrgSet =
    // @ts-ignore forOrganization exists on certain task types, but not on the
    // base AtlasGqlTask type
    workContainer && 'forOrganization' in workContainer && workContainer.forOrganization;

  const { data: trustedOrgsData } = useGetTrustedOrganizationsQuery({
    // Query for trustedOrganizations only if:
    // - The workContainer has a non-null value set for forOrganization. We need this to resolve the name of the org.
    // - The user has the `role-editor` role.
    // - The user has the `FOR_ORGANIZATION_EDIT` feature flag.
    skip: !(workContainer && (isForOrgSet || isEditor || FOR_ORGANIZATION_EDIT)),
  });
  const standardFields = useStandardFields(containerId, isEditButtonVisible);

  useEffect(() => {
    if (trustedOrgsData && trustedOrgsData?.trustedOrganizations) {
      setTrustedOrganizations(trustedOrgsData.trustedOrganizations.filter(notEmpty));
    }
  }, [trustedOrgsData]);

  useEffect(() => {
    if (data?.task?.additionalFields) setFetchedAdditionalFields(data.task.additionalFields);
  }, [data]);

  useEffect(() => {
    if (!additionalFields && containerId) {
      getWorkContainerValues({ variables: { containerId } });
    } else if (additionalFields?.length > 0) {
      setMergedAdditionalFields(additionalFields);
    }
  }, [additionalFields, containerId, getWorkContainerValues]);

  useEffect(() => {
    if (fetchedAdditionalFields && fetchedAdditionalFields.length > 0) {
      setMergedAdditionalFields(fetchedAdditionalFields);
    }
  }, [fetchedAdditionalFields]);

  if (!workFieldsResult) {
    message.error('Unable to fetch Task data');
  }
  const workContainerFields = useMemo(
    () => workFieldsResult?.data?.workContainerFields ?? [],
    [workFieldsResult?.data?.workContainerFields]
  );
  const allFieldsLoading = workFieldsResult?.loading ?? false;

  useEffect(() => {
    if ((mergedAdditionalFields.length || workContainerFields.length) && !loading) {
      const { vendorOrganization } = deconstructAtlasTask(workContainer);

      const allFieldsAndValues = workContainerFields
        .map(field => {
          const fieldWithData = mergedAdditionalFields.find(aField => aField.id === field.id);
          const aField = fieldWithData ?? field;

          // Rename the legacy vendor field if the release toggle is enabled.
          // this also changes the dataIndex, but that seems to be okay.
          if (field.name === 'Vendor' && field.type === 'TEXT') {
            if (vendorOrganization) {
              // when the vendor org is set, do not show the legacy vendor
              return null;
            }

            return {
              ...aField,
              // when the user has the release toggle, force to legacy. when
              // they don't have it, force to non-legacy. this allows the field
              // database value to be either, and we can enforce what we show
              // the user.
              name: hasVendorRT ? 'Vendor (Legacy)' : 'Vendor',
            };
          }

          return aField;
        })
        .filter(notEmpty);

      if (!isEqual(currentFields, allFieldsAndValues)) {
        setCurrentFields(allFieldsAndValues);
      }
    }
  }, [
    currentFields,
    hasVendorRT,
    loading,
    mergedAdditionalFields,
    workContainer,
    workContainerFields,
  ]);

  const workContainerColumns = sortAndMapFieldsToColumns(
    [...currentFields],
    type,
    standardFields,
    containerId,
    saveWorkContainerFieldValues,
    isEditButtonVisible
  );

  const contextualColumns = useMemo(() => {
    if (!workContainer) return [];
    return getContextualColumns(
      workContainer,
      isEditor,
      features,
      trustedOrganizations,
      onSave,
      hasReleaseToggle
    );
  }, [features, hasReleaseToggle, isEditor, onSave, trustedOrganizations, workContainer]);

  const finalColumns = [...workContainerColumns, ...contextualColumns];

  return {
    workContainerColumns: finalColumns,
    fieldsLoading: allFieldsLoading,
  };
}

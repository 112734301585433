import cx from 'classnames';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import COLORS from 'utils/color/definitions';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';

interface IconProps extends AntdIconProps {
  addLeftMargin?: boolean;
}

export const CheckIcon = ({ className, addLeftMargin = true, ...props }: IconProps) => {
  return (
    <CheckCircleTwoTone
      {...props}
      className={cx('confirmed-icon', className)}
      twoToneColor={COLORS.GREEN}
      style={{ marginLeft: addLeftMargin ? '0.25rem' : '0rem' }}
    />
  );
};

export const CloseIcon = (props: IconProps) => {
  return <CloseCircleTwoTone {...props} twoToneColor={COLORS.RED} />;
};

import styled from 'styled-components';
import { Alert } from 'antd5';
import { Tooltip } from 'components/ui';

// When we remove `rtv1:unplanned-tasks-schema-selector we can remove the ternary here and just return the true case
export const FilterControlsContainer = styled.div<{
  hasUnplannedTaskSchemaSelectorToggle: boolean;
}>`
  ${({ hasUnplannedTaskSchemaSelectorToggle }) =>
    hasUnplannedTaskSchemaSelectorToggle
      ? `
        display: flex;
        align-items: flex-start;
        gap: 1em;

        @media screen and (max-width: 1200px) {
          max-width: 440px;
          flex-direction: column;

          &:has(> :nth-child(5):last-child) {
            max-width: 632px;
          }
        }

        @media screen and (min-width: 1740px) {
          max-width: unset;
        }

        > div {
          margin: 0;
        }
        `
      : `
        display: flex;
        flex-wrap: wrap;
        gap: 1em;

        @media screen and (max-width: 1739px) {
          max-width: 440px;

          &:has(> :nth-child(5):last-child) {
            max-width: 632px;
          }
        }

        @media screen and (min-width: 1740px) {
          max-width: unset;
        }

        > div {
          margin: 0;
        }
      `}
`;

export const DamageNumberContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HelpTooltip = styled(Tooltip)`
  margin-left: 0.2rem;
  margin-right: 0.2rem;
`;

export const StyledAlert = styled(Alert)`
  animation: slide-down 0.4s ease-out;
  margin-bottom: 1rem;

  @keyframes slide-down {
    0% {
      opacity: 0;
      margin-top: -40px;
    }

    100% {
      opacity: 1;
      margin-top: 0;
    }
  }
`;

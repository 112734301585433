import styled from 'styled-components';

import GlobalFooter from 'components/GlobalFooter';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  a {
    color: ${({ theme }) => theme.descriptionColor};
  }
`;

const StyledMessage = styled(StyledContainer)`
  font-size: 2rem;
  margin-top: 25vh;
  height: auto;
  a {
    font-weight: 700;
  }
  p {
    font-size: 1.5rem;
  }
`;

export const Unsupported = () => (
  <StyledContainer>
    <StyledMessage>
      Your current configuration is not supported.
      <p>
        Please use the latest version of&nbsp;
        <a target="_blank" href="https://www.mozilla.org/en-US/firefox/" rel="noopener noreferrer">
          Firefox
        </a>
        &nbsp;or&nbsp;
        <a target="_blank" href="https://www.google.com/chrome/" rel="noopener noreferrer">
          Chrome
        </a>
      </p>
    </StyledMessage>
    <GlobalFooter />
  </StyledContainer>
);

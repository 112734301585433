import { AtlasGqlArchiveDescendantsCountPayload } from 'types/atlas-graphql';

export const getCountString = (number: number, typeName: string) => {
  return number > 0 ? `${number} ${typeName}${number > 1 ? 's' : ''}` : '';
};

// Accepts the ArchiveDescendantsCount payload and returns a warning message
// stating how many of each work container type will be impacted by the archive action.
// If no work containers will be affected, returns an empty string.
export const getFullArchiveWarningText = (
  counts: AtlasGqlArchiveDescendantsCountPayload
): string => {
  const { campaignsCount, workOrdersCount, tasksCount } = counts;
  const campaignString = getCountString(campaignsCount, 'Campaign');
  const workOrdersString = getCountString(workOrdersCount, 'Work Order');
  const tasksString = getCountString(tasksCount, 'Task');

  let finalTextString = ``;
  if (campaignsCount > 0 && workOrdersCount > 0 && tasksCount > 0) {
    finalTextString = `${campaignString}, ${workOrdersString}, and ${tasksString}`;
  } else if (campaignsCount > 0 && workOrdersCount > 0) {
    finalTextString = `${campaignString}, and ${workOrdersString}`;
  } else if (campaignsCount > 0 && tasksCount > 0) {
    finalTextString = `${campaignString}, and ${tasksString}`;
  } else if (campaignsCount > 0) {
    finalTextString = campaignString;
  } else if (workOrdersCount > 0 && tasksCount > 0) {
    finalTextString = `${workOrdersString}, and ${tasksString}`;
  } else if (workOrdersCount > 0) {
    finalTextString = workOrdersString;
  } else {
    finalTextString = tasksString;
  }
  return finalTextString;
};

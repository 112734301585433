import { SpacedContainer } from 'utils/layouts/containers';
import styled from 'styled-components';

export const StyledControls = styled.div<{ width?: string; block: boolean }>`
  width: ${({ width }: any) => {
    return width || '100%';
  }};
  display: flex;
  flex-direction: ${({ block }) => block && 'column'};
  &:last-child {
    margin-right: 0.5rem;
  }
  margin-left: 0;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const SelectControl = styled(SpacedContainer)<{ small?: boolean; block?: boolean }>`
  min-width: ${({ small }) => (small ? null : '200px')};
  width: ${({ small }) => (small ? '120px' : null)};
  margin: ${({ block }) => block && '0 0 1rem 0'};
  @media (max-width: 1024px) {
    margin: 0 0 1rem 0;
  }

  .ant-select {
    width: 100%;
  }
`;

import { useMemo, useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { pick, omit, omitBy, isNil, isEqual } from 'lodash';
import { sortBy } from 'components/data/helpers';
import { useQuery } from 'utils/apollo';

import { getInitAttributeOrder } from '../helpers';

import { DAMAGE_EDIT_ATTRIBUTES } from 'utils/access-control/rules';
import { REALTIME_POTENTIAL_ATTRIBUTE_KEYS } from 'components/damages/constants';

import { attrColumns } from 'components/damages/dynamic-new';

import {
  AtlasGqlDamageSchemaAttributeState,
  useGetAvailableSelectionsQuery,
} from 'types/atlas-graphql';
import GET_DAMAGE_DEFINITIONS from 'queries/GetDamageDefinitions.atlas.gql';
import GET_SCHEMA_DAMAGE_DEFINITIONS from 'queries/GetDamageDefinitionsForSchema.atlas.gql';
import GET_DAMAGE_ATTRIBUTES from 'queries/GetDamageAttributes.gql';
import { useFeatures } from 'utils/features';

const sortByLabel = sortBy('label');

export const getAttrColumn = (
  attr,
  includeNoneOption = false,
  definition = null,
  isHorizonDamage = false,
  REQUIRE_VALID_DAMAGE = false,
  criticalOnly = false
) => {
  const { name, valid, state, delta: step, options, id, ...rest } = attr;

  const { numMin, numMax, numRanges, dateMin, dateMax, dateRanges } = attr;

  const getMinMax = ({ type, min: inMin, max: inMax, ranges }) => {
    let min, max;

    if (type === 'number') {
      min = isNil(inMin) ? Math.min(ranges.map(({ min }) => min)) : inMin;
      max = isNil(inMax) ? Math.max(ranges.map(({ max }) => max)) : inMax;
    }

    if (type === 'date') {
      min = isNil(inMin) ? moment.min(ranges.map(({ min }) => min)) : inMin;
      max = isNil(inMax) ? moment.max(ranges.map(({ max }) => max)) : inMax;
    }

    if (REQUIRE_VALID_DAMAGE && !ranges.length) {
      return [];
    }
    return [
      { id: min, label: min },
      { id: max, label: max },
    ];
  };

  let stringOptions = (options ?? [])
    .map(val => ({ id: val, label: val || 'None' }))
    .sort(sortByLabel);
  if (includeNoneOption) {
    stringOptions = stringOptions.concat({ id: '', label: <i>none</i> });
  }

  const values =
    attr.type === 'string'
      ? stringOptions
      : attr.type === 'number'
        ? getMinMax({
            type: attr.type,
            min: numMin,
            max: numMax,
            ranges: numRanges || [],
          })
        : getMinMax({
            type: attr.type,
            min: dateMin,
            max: dateMax,
            ranges: dateRanges || [],
          });

  // generic fallback in case key is something else
  const render = attrColumns[name] || attrColumns.Generic;

  return {
    ...render({
      ...rest,
      definition,
      values,
      isHorizonDamage,
      step,
      id,
      key: name,
      includeNoneOption,
      ...(name === 'Severity' && criticalOnly ? { criticalOnly: true } : {}),
    }),
    valid,
    state,
    disabled: state !== AtlasGqlDamageSchemaAttributeState.Active,
    name,
    editRule: DAMAGE_EDIT_ATTRIBUTES,
  };
};

export function useDamageAttrs(schemaId, isHorizonDamage, criticalOnly = false) {
  const { REQUIRE_VALID_DAMAGE } = useFeatures().features;
  const { data: damageSchemaData, loading: schemaLoading } = useQuery(
    schemaId ? GET_SCHEMA_DAMAGE_DEFINITIONS : GET_DAMAGE_DEFINITIONS,
    {
      variables: schemaId ? { id: schemaId } : {},
    }
  );
  const schemaQuery = schemaId ? damageSchemaData?.damageSchema : damageSchemaData?.getDamageSchema;
  const definitions = schemaQuery?.definitions ?? [];

  const { data: damageAttributesData, loading: attributesLoading } = useQuery(
    GET_DAMAGE_ATTRIBUTES,
    {
      variables: schemaId ? { id: schemaId } : {},
    }
  );
  const damageAttributes = damageAttributesData?.getAllSchemaOptions ?? [];

  const loading = attributesLoading || schemaLoading;

  const damageColumns = useMemo(() => {
    //TODO make this readable
    if (loading) return [];
    return getInitAttributeOrder(
      damageAttributes
        .filter(({ name }) => {
          const definition = definitions.find(d => d.name === name);
          return !definition || definition.state === AtlasGqlDamageSchemaAttributeState.Active;
        })
        .map(attr => {
          const { name } = attr;
          const definition = definitions.find(d => d.name === name);

          return getAttrColumn(
            attr,
            true,
            definition,
            isHorizonDamage,
            REQUIRE_VALID_DAMAGE,
            criticalOnly
          );
        })
        .map(attr => {
          const { name } = attr;
          const definition = definitions.find(d => d.name === name);

          return {
            ...attr,
            sortIndex: definition?.sortIndex,
            state: definition?.state,
            disabled: definition?.state !== AtlasGqlDamageSchemaAttributeState.Active,
          };
        })
    );
  }, [damageAttributes, definitions, isHorizonDamage, loading, REQUIRE_VALID_DAMAGE]);

  return { loading, damageAttributes, damageColumns };
}

export function useDamageAttrSelections({
  damage,
  schemaId,
  potentialAttrs,
  ignoreWorkInProgress,
  shouldHideRealtimePotentialAttrs,
  shouldHideHiddenAttrs,
  shouldHideDisabledAttrs,
}) {
  const { REQUIRE_VALID_DAMAGE } = useFeatures().features;
  const { data: damageSchemaData, loading: schemaLoading } = useQuery(
    schemaId ? GET_SCHEMA_DAMAGE_DEFINITIONS : GET_DAMAGE_DEFINITIONS,
    {
      variables: schemaId ? { id: schemaId } : {},
    }
  );
  const schemaQuery = schemaId ? damageSchemaData?.damageSchema : damageSchemaData?.getDamageSchema;
  const definitions = useMemo(() => schemaQuery?.definitions ?? [], [schemaQuery?.definitions]);
  const schemaIsWorkInProgress = schemaQuery?.workInProgress;

  const [selections, setSelections] = useState([]);
  const [hiddenSelections, setHiddenSelections] = useState([]);
  const [disabledSelections, setDisabledSelections] = useState([]);

  const { combinedAttrs, includesRealtimePotentialAttrs } = useMemo(() => {
    if (!definitions) return {};
    // FIXME: I couldnt think of a better way to handle attributes that were both
    // potential AND that the user would expect to update in real time. The line
    // below makes an assumption about the attributes that are returned by the
    // getOptimisticAttributes query.
    const allowedPotentialAttrs = pick(
      omitBy(potentialAttrs, attr => isNil(attr)),
      definitions
        .filter(({ state }) =>
          [
            AtlasGqlDamageSchemaAttributeState.Active,
            AtlasGqlDamageSchemaAttributeState.Hidden,
          ].includes(state)
        )
        .map(({ name }) => name)
    );
    const realtimePotentialAttrs = pick(allowedPotentialAttrs, REALTIME_POTENTIAL_ATTRIBUTE_KEYS);
    // Exclude any potential attributes that arent in the customers schema
    // or are disabled by the customers schema
    return {
      combinedAttrs: {
        ...allowedPotentialAttrs,
        ...damage?.attrs,
        // The realtime attributes are allowed to overwrite already saved attributes
        // in the UI.
        ...(shouldHideRealtimePotentialAttrs ? {} : realtimePotentialAttrs),
      },
      includesRealtimePotentialAttrs:
        !shouldHideRealtimePotentialAttrs &&
        Object.values(realtimePotentialAttrs).some(v => !isNil(v)),
    };
  }, [definitions, potentialAttrs, damage, shouldHideRealtimePotentialAttrs]);

  const attrsToSelection = useCallback(
    attrs => {
      return Object.entries(attrs).map(([name, v]) => {
        const d = definitions.find(d => d.name === name);
        return {
          name,
          ...(d.type === 'string' ? { string: v } : {}),
          ...(d.type === 'number' ? { number: v } : {}),
          ...(d.type === 'date' ? { date: v } : {}),
        };
      });
    },
    [definitions]
  );

  useEffect(() => {
    if (!definitions) return;

    // This handles cases where the attrs has an attribute that isn't
    // in the schema. (Shouldn't be possible)
    const attrs = omit(
      combinedAttrs,
      Object.keys(combinedAttrs).filter(n => !definitions.find(({ name }) => name === n))
    );

    const enabledAttrs = pick(
      attrs,
      definitions
        .filter(({ state }) => state === AtlasGqlDamageSchemaAttributeState.Active)
        .map(({ name }) => name)
    );

    const hiddenAttrs = pick(
      attrs,
      definitions
        .filter(({ state }) => state === AtlasGqlDamageSchemaAttributeState.Hidden)
        .map(({ name }) => name)
    );

    const disabledAttrs = pick(
      attrs,
      definitions
        .filter(({ state }) => state === AtlasGqlDamageSchemaAttributeState.Disabled)
        .map(({ name }) => name)
    );

    const newSelections = attrsToSelection(enabledAttrs);
    const newHiddenSelections = attrsToSelection(hiddenAttrs);
    const newDisabledSelections = attrsToSelection(disabledAttrs);

    if (!isEqual(newSelections, selections)) {
      setSelections(newSelections);
    }
    if (!isEqual(newHiddenSelections, hiddenSelections)) {
      setHiddenSelections(newHiddenSelections);
    }
    if (!isEqual(newDisabledSelections, disabledSelections)) {
      setDisabledSelections(newDisabledSelections);
    }
    // determine why all items can't be added to the dependency array
    // eslint-disable-next-line
  }, [
    combinedAttrs,
    attrsToSelection,
    definitions,
    setDisabledSelections,
    setHiddenSelections,
    setSelections,
  ]);

  const makeSelection = useCallback(
    selection => {
      setSelections([...selections.filter(s => s.name !== selection.name), selection]);
    },
    [selections, setSelections]
  );

  const clearSelections = useCallback(() => {
    setSelections([]);
  }, [setSelections]);

  const clearSelection = useCallback(
    ({ name }) => {
      setSelections(selections.filter(s => s.name !== name));
    },
    [selections, setSelections]
  );

  const filterInvalid = useCallback(
    s => {
      const { name, string } = s;
      const d = (definitions ?? []).find(d => d.name === name);
      if (d && d.type === 'string' && !d.options.includes(string)) return false;

      return true;
    },
    [definitions]
  );

  const {
    data: availableSelectionsData,
    loading: selectableLoading,
    previousData: previousSelectionsData,
  } = useGetAvailableSelectionsQuery({
    variables: {
      ...(schemaId ? { schemaId } : {}),
      selections: selections
        .filter(filterInvalid)
        .map(({ attr, ...rest }) => ({ ...attr, ...rest })),
    },
    skip: schemaLoading,
    fetchPolicy: 'network-only',
  });

  const injectColumnInvalidReason = useCallback(
    s => {
      if (!selections || !definitions) return s;
      const sel = selections.find(({ name }) => name === s.name);

      let valid = true;
      if (s && s.type === 'string' && sel && !s.options.includes(sel.string)) valid = false;
      return {
        valid,
        ...s,
      };
    },
    [definitions, selections]
  );

  const selectable = useMemo(() => {
    // when the available selections are loading, if the query had previously
    // run, we want to attempt to use the previous data to prevent a jarring
    // disappearing/reappearing of form components.
    if (selectableLoading) {
      return (
        availableSelectionsData?.getAvailableDamageSchemaAttributeSelections ??
        previousSelectionsData?.getAvailableDamageSchemaAttributeSelections ??
        []
      );
    }

    // when the available selections are NOT loading, we don't want to use the
    // previous selections to make sure the user isn't viewing stale data
    return availableSelectionsData?.getAvailableDamageSchemaAttributeSelections ?? [];
  }, [availableSelectionsData, previousSelectionsData, selectableLoading]);

  const columns = useMemo(() => {
    if (!selectable) return [];
    if (schemaIsWorkInProgress && !ignoreWorkInProgress) {
      return getInitAttributeOrder([
        ...definitions.filter(({ state }) => state === AtlasGqlDamageSchemaAttributeState.Active),
        ...(!shouldHideHiddenAttrs
          ? definitions.filter(({ state }) => state === AtlasGqlDamageSchemaAttributeState.Hidden)
          : []),
        ...(!shouldHideDisabledAttrs
          ? definitions.filter(({ state }) => state === AtlasGqlDamageSchemaAttributeState.Disabled)
          : []),
      ])
        .map(injectColumnInvalidReason)
        .map(attr => {
          const { name } = attr;
          const definition = definitions.find(d => d.name === name);

          return getAttrColumn(attr, false, definition, false, REQUIRE_VALID_DAMAGE);
        });
    }
    return getInitAttributeOrder([
      ...selectable.map(({ attr, ...rest }) => ({
        ...attr,
        sortIndex: definitions.find(d => d.id === attr.id)?.sortIndex,
        ...rest,
      })),
      ...(!shouldHideHiddenAttrs
        ? hiddenSelections.map(({ name }) => definitions.find(d => d.name === name))
        : []),
      ...(!shouldHideDisabledAttrs
        ? disabledSelections.map(({ name }) => definitions.find(d => d.name === name))
        : []),
    ])
      .map(injectColumnInvalidReason)
      .map(attr => {
        const { name } = attr;
        const definition = definitions.find(d => d.name === name);

        return getAttrColumn(attr, false, definition, false, REQUIRE_VALID_DAMAGE);
      });
  }, [
    definitions,
    selectable,
    hiddenSelections,
    disabledSelections,
    ignoreWorkInProgress,
    schemaIsWorkInProgress,
    injectColumnInvalidReason,
    REQUIRE_VALID_DAMAGE,
    shouldHideHiddenAttrs,
    shouldHideDisabledAttrs,
  ]);

  return {
    columns,
    selections,
    hiddenSelections,
    disabledSelections,
    loading: selectableLoading || schemaLoading,
    makeSelection,
    clearSelection,
    clearSelections,
    includesRealtimePotentialAttrs,
  };
}

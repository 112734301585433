import styled from 'styled-components';

export const MaintenanceContainer = styled.div`
  align-items: center;
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  justify-content: space-around;
  margin: 0;
  min-height: 100vh;
  padding: 0 2em;
  text-align: center;
`;

export const HorizonLogo = styled.img`
  width: 75vw;

  @media screen and (min-width: 900px) {
    width: 60vw;
  }
`;

export const SkySpecsLogo = styled.img`
  width: 40vw;

  @media screen and (min-width: 900px) {
    width: 20vw;
  }
`;

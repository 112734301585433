import { Fragment, useCallback } from 'react';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Button } from 'components/ui';

export const ZoomControl = ({ onChange }) => {
  const handleZoom = useCallback(
    ({ currentTarget: { value } }) => {
      onChange({ [value]: true });
    },
    [onChange]
  );

  return (
    <Fragment>
      <Button
        _version={4}
        id="ol-control-zoom-in"
        title="Zoom In"
        value="zoomIn"
        onClick={handleZoom}
      >
        <ZoomInOutlined />
      </Button>
      <Button
        _version={4}
        id="ol-control-zoom-out"
        title="Zoom Out"
        value="zoomOut"
        onClick={handleZoom}
      >
        <ZoomOutOutlined />
      </Button>
    </Fragment>
  );
};

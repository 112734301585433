import _compact from 'lodash/compact';

export function formatColumns(columns) {
  const columnOptions = columns.map(({ title, visible, ...column }) => {
    // dataIndex is used for some dynamic columns
    if (title) {
      return {
        key: column.key || column.dataIndex,
        title,
        visible: visible === undefined ? true : visible,
        fixed: column.fixed,
        hidden: column.className?.includes('hidden-column'),
      };
    }

    return null;
  });

  return _compact(columnOptions);
}

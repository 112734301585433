import { FC, useCallback, useState } from 'react';
import {
  AtlasGqlUpdateCommentInput,
  useCreateCommentMutation,
  useGetCommentsQuery,
  useUpdateCommentMutation,
} from 'types/atlas-graphql';
import CommentList, { CommentListProps } from './CommentList';
import { defaultRefetchConfig } from './refetchConfig';

interface CommentsProps
  extends Pick<CommentListProps, 'createRule' | 'areWriteActionsVisible' | 'refetchConfig'> {
  threadId: string;
  loading?: boolean;
}

const Comments: FC<CommentsProps> = ({
  threadId,
  loading,
  refetchConfig = defaultRefetchConfig,
  ...props
}) => {
  const [editId, setEditId] = useState<string | null>(null);
  const { data, loading: commentsLoading } = useGetCommentsQuery({
    variables: { threadId },
    skip: !threadId,
  });

  const [createComment, { loading: saveLoading }] = useCreateCommentMutation({
    ...refetchConfig,
  });

  const [editComment, { loading: editLoading }] = useUpdateCommentMutation({
    ...refetchConfig,
  });

  const handleSave = useCallback(
    (text: string) => {
      createComment({ variables: { input: { threadId, text } } });
    },
    [threadId, createComment]
  );

  const handleEdit = useCallback(
    ({ id, text }: AtlasGqlUpdateCommentInput) => {
      setEditId(id);
      editComment({ variables: { input: { id, text } } });
    },
    [editComment]
  );

  const comments = data?.comments ?? [];

  return (
    <CommentList
      comments={comments}
      onSave={handleSave}
      onEdit={handleEdit}
      saveLoading={saveLoading}
      editLoading={editLoading}
      editId={editId}
      loading={loading || commentsLoading}
      refetchConfig={refetchConfig}
      {...props}
    />
  );
};

export default Comments;

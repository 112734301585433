import styled from 'styled-components';
import { Card, Collapse, List } from 'antd';
import COLORS from 'utils/color/definitions';

export const Pane = styled.div`
  border: 1px solid #d9d9d9;
  flex: 50%;
  display: flex;
  flex-direction: column;
`;

export const EmptyImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 30%;
  min-height: 30%;
`;

export const DetailCard = styled(Card)<{
  width?: string;
  padding?: string;
  actions?: JSX.Element[];
  $hasCollapseAction?: boolean;
}>`
  flex: ${({ width }) => (!!width ? null : 1)};
  width: ${({ width }) => width ?? null};

  .ant-card-body {
    display: flex;
    height: calc(100% - 36px - ${({ actions }) => (actions?.length ? '58' : '0')}px);
    overflow: auto;
    padding: 0;
  }

  .ant-card-actions {
    display: flex;
    justify-content: flex-end;
    margin-left: 12px;
    margin-right: 12px;

    > li {
      text-align: left;
      border: none;
      width: unset !important;
      flex-grow: 1;

      &:last-of-type {
        flex-grow: ${({ $hasCollapseAction }) => ($hasCollapseAction ? 0 : 1)};
      }
    }
  }
`;

export const ListItem = styled(List.Item)<{ selected?: boolean; disabled?: boolean }>`
  border-left: 4px solid ${({ selected }) => (selected ? COLORS.TEAL : 'transparent')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 12px;
  justify-content: flex-start;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  padding: 8px !important;

  div {
    margin-right: 8px;
    overflow: hidden;

    &:nth-of-type(1) {
      flex-grow: 1;
    }

    &:nth-of-type(2) {
      text-align: right;
      min-width: 25%;
    }

    p {
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.inspection-detail {
        color: #ccc;
        font-size: 11px;
        font-style: italic;
      }
    }
  }
`;

export const InspectionCollapse = styled(Collapse)<{ small?: boolean }>`
  width: 100%;

  .ant-collapse-header {
    padding: 6px 12px 6px 32px !important;
    font-size: ${({ small }) => (small ? '12px' : '14px')};

    .ant-collapse-arrow {
      left: 12px !important;
      ${({ small }) => small && `padding-top: 5px !important;`}
    }

    div {
      margin-right: 8px;
      overflow: hidden;

      &:nth-of-type(1) {
        flex-grow: 1;
      }

      &:nth-of-type(2) {
        text-align: right;
        min-width: 25%;
      }

      p {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.inspection-detail {
          color: #ccc;
          font-size: 11px;
          font-style: italic;
        }
      }
    }
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

import styled, { css } from 'styled-components';
import { Card, Table } from 'antd';

import { FlexContainer, SpacedContainer } from 'utils/layouts/containers';

export const HeaderContainer = styled(FlexContainer)`
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Control = styled(SpacedContainer)`
  margin-left: 0;
`;

export const TitleContainer = styled(Control)`
  margin-right: 1rem;
`;

export const TableCard = styled(({ clickable, fillwidth, disableUserSort, ...rest }) => (
  <Card {...rest} />
))`
  .ant-table-thead {
    white-space: nowrap;
  }
  .ant-card-head {
    padding: 0 1em !important;
  }
  .ant-card-head-wrapper {
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .ant-card-head-title {
    max-width: 100%;
    padding-right: 1em; /* prevents overflow state while loading */
  }
  .ant-card-extra {
    padding: 1rem 0;
  }

  .hidden-column {
    display: none !important;
  }

  tr {
    cursor: ${props => (props.clickable ? 'pointer' : 'default')};
  }

  width: ${props => (props.fillWidth ? '100%' : 'auto')};

  ${({ disableUserSort }) =>
    disableUserSort
      ? `.ant-table-column-has-sorters {
    cursor: default !important;
    pointer-events: none;
  }`
      : ''};
`;

export const ArchivedRowStyle = css`
  .ant-table-row.archived > td,
  .ant-table-row.archived:hover > td {
    color: ${({ theme }) => theme.labelColor};
    background-color: ${({ theme }) => theme.selectedListItemBackground};
    font-style: italic;
    border-bottom: 1px solid ${({ theme }) => theme.grayLightOpactiy};
    border-top: 1px solid ${({ theme }) => theme.grayLightOpactiy};
  }
`;

// When a row is selected, transition to selected color in 0.5s;
// when deselected, fade selected color out in 1.5s
export const SelectedRowTransitionStyle = css`
  tr.ant-table-row.ant-table-row-selected > td {
    -webkit-transition: background-color 0.5s ease-in;
    -moz-transition: background-color 0.5s ease-in;
    -o-transition: background-color 0.5s ease-in;
    transition: background-color 0.5s ease-in;
  }
  tr.ant-table-row:not(.ant-table-row-selected) > td {
    -webkit-transition: background-color 1.5s ease-in;
    -moz-transition: background-color 1.5s ease-in;
    -o-transition: background-color 1.5s ease-in;
    transition: background-color 1.5s ease-in;
  }
`;

export const StyledServerTable = styled(Table)`
  ${ArchivedRowStyle}
  ${SelectedRowTransitionStyle}
`;

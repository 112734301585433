import styled from 'styled-components';
import { Spinner } from 'components/ui';

export const ActionsContainer = styled.span`
  display: flex;
  align-items: start;
  margin-left: 0.5em;
  white-space: normal;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const InlineEditableContainer = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  max-width: 100%;
`;

export const StyledSpinner = styled(Spinner)`
  min-height: unset;
  width: 3rem;
  .ant-spin-dot {
    font-size: 1.2rem !important;
  }
`;

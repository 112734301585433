import { createContext, useContext, useEffect } from 'react';
import { WarningOutlined } from '@ant-design/icons';
import { StyledAlert } from './GlobalBanner.style';

export type GlobalBannerContextType = {
  message: string | null | undefined;
  setMessage: (message: string | null | undefined) => void;
};

export const GlobalBannerContext = createContext<GlobalBannerContextType>({
  message: undefined,
  setMessage: () => {},
});

type BannerProps = {
  message: string;
};

export const Banner: React.FunctionComponent<BannerProps> = ({ message }) => (
  <StyledAlert
    banner
    showIcon={false}
    message={
      <>
        <WarningOutlined />
        &nbsp;
        {message}
      </>
    }
  />
);

export const useBanner = (message: string | null | undefined) => {
  const { message: currentMessage, setMessage } =
    useContext<GlobalBannerContextType>(GlobalBannerContext);

  /**
   * When the banner should be shown, `GlobalBannerContext.message` is set with the desired
   * message. When nullish, the banner is not rendered.
   * Components can call `useBanner('message')` to render the banner, and the message will be unset
   * when the component is unmounted (and therefore the banner will be removed).
   */
  useEffect(() => {
    setMessage(message);

    return () => {
      setMessage(undefined);
    };
  }, [message, setMessage, currentMessage]);
};

import { TFieldValue } from 'horizon/components/Assets/AssetDetails/types';
import {
  AtlasGqlAssetFieldType,
  AtlasGqlAssetFieldValue,
  AtlasGqlAuxiliaryComponent,
  AtlasGqlBlade,
  AtlasGqlGetAssetFieldsForTypeQuery,
  AtlasGqlSite,
  Maybe,
} from 'types/atlas-graphql';
import { Link } from 'react-router-dom';
import { StatusTag } from 'components/StatusTag';
import { None } from 'components/data/helpers';
import {
  assetColumn,
  assetLocationColumn,
  assetParentColumn,
  assetParentTypeColumn,
  assetSiteColumn,
  assetTurbineColumn,
  bladeAuxiliaryComponentsColumn,
  bladeNameColumn,
  bladeSiteColumn,
  bladeTurbineColumn,
  siteTurbinesCountColumn,
  turbineSiteColumn,
} from './multiAssetTable';
import { AuxiliaryComponentList } from 'components/AuxiliaryComponentList';
import { TAnyAssetType, TColumnData } from './types';
import { getUniqueIdentifier } from 'horizon/components/Assets/AssetDetails/helpers';

export function getFormattedAssetFieldData(
  fieldName: string,
  assetFieldData: Maybe<AtlasGqlAssetFieldValue> | undefined
): JSX.Element | number | string | boolean {
  if (assetFieldData) {
    // @ts-ignore JD: The codegen typing for the assetFieldData doesn't match the exact expectations for the function.
    const value = extractFormattedDataFromAssetField(assetFieldData);
    const isStatus = fieldName === 'Status';
    return formatFieldDataToDisplay(value, isStatus);
  } else {
    return <None />;
  }
}

export function extractFormattedDataFromAssetField(field: TFieldValue) {
  switch (field.fieldDefinition?.fieldType) {
    case AtlasGqlAssetFieldType.Bool:
      return field.boolValue;
    case AtlasGqlAssetFieldType.Text:
      return field.textValue;
    case AtlasGqlAssetFieldType.Date:
      return field.dateValue ?? undefined;
    case AtlasGqlAssetFieldType.Number:
      return field.numberValue;
    case AtlasGqlAssetFieldType.Float:
      // We only want to display at max 2 decimal points for Capacity and Length.
      if (field.floatValue && ['Capacity', 'Length'].includes(field.fieldDefinition?.name)) {
        return field.floatValue.toFixed(2);
      }
      return field.floatValue;
    case AtlasGqlAssetFieldType.Select:
      return field.selectValue;
    case AtlasGqlAssetFieldType.MultiSelect:
      if (field.multiSelectValue) {
        return field.multiSelectValue.join(', ');
      } else {
        return undefined;
      }
    default:
      return undefined;
  }
}

/// Function to take in a field value and return the correct display format of that asset type field
export function formatFieldDataToDisplay(
  value: string | number | boolean | null | undefined,
  isStatus: boolean = false
): JSX.Element | number | string | boolean {
  if (isStatus) {
    const statusValue = value?.toString() ?? '';
    return value ? <StatusTag value={statusValue} /> : <None />;
  } else {
    return value ? value : <None />;
  }
}

// Function to extract column data required by the all asset types view
export function extractAllTypesColumnData(asset: TAnyAssetType): any[] {
  const columnsData: TColumnData[] = [];

  // Unique Identifier
  const uniqueIdentifierLink = getUniqueIdentifierLink(asset);
  columnsData.push({ key: assetColumn.dataIndex, value: uniqueIdentifierLink });

  // Location
  const location = extractLocationOrSiteFromAsset(asset);
  columnsData.push({ key: assetLocationColumn.dataIndex, value: location });

  const parentAsset = asset.parent;
  // Parent Unique Identifier
  const parentUniqueIdentifier = parentAsset ? getUniqueIdentifierLink(parentAsset) : <None />;
  columnsData.push({ key: assetParentColumn.dataIndex, value: parentUniqueIdentifier });

  // Parent Type
  const parentType = asset.parent?.assetType?.name ?? 'Asset';
  const parentTypeValue = parentAsset ? parentType : <None />;
  columnsData.push({ key: assetParentTypeColumn.dataIndex, value: parentTypeValue });

  return columnsData;
}

// Function to generate assetType-specific columns for first class assets
export function extractTypeSpecificColumnData(asset: TAnyAssetType): any[] {
  const columnsData: TColumnData[] = [];
  // Filter out non-first-class assets
  if (!('__typename' in asset)) {
    return columnsData;
  }
  switch (asset.__typename) {
    case 'Site': {
      // Column - Turbine count
      const allTurbines = asset.children?.filter(child => child?.assetType?.name === 'Turbine');
      columnsData.push({
        key: siteTurbinesCountColumn.dataIndex,
        value: allTurbines ? allTurbines.length : 0,
      });
      break;
    }
    case 'Turbine': {
      // Column - Site Name and link
      // Have to cast site to be able to resolve the name
      const site = asset.parent as AtlasGqlSite;
      columnsData.push({
        key: turbineSiteColumn.dataIndex,
        value: site ? <Link to={`/assets/${site.id}`}>{site.name}</Link> : <None />,
      });
      break;
    }
    case 'Blade': {
      // Column - Formatted Blade Name and link
      const bladeAsset = asset as AtlasGqlBlade;
      columnsData.push({
        key: bladeNameColumn.dataIndex,
        value: getUniqueIdentifierLink(bladeAsset),
      });
      // Column - Turbine Name and link
      const turbine = bladeAsset.turbine;
      columnsData.push({
        key: bladeTurbineColumn.dataIndex,
        value: turbine ? <Link to={`/assets/${turbine.id}`}>{turbine.name}</Link> : <None />,
      });

      // Column - Site Name and link
      const site = bladeAsset.location;
      columnsData.push({
        key: bladeSiteColumn.dataIndex,
        value: site ? <Link to={`/assets/${site.id}`}>{site.name}</Link> : <None />,
      });

      // Column - Aux Components
      const auxComponents: AtlasGqlAuxiliaryComponent[] = bladeAsset.auxiliaryComponents
        ? bladeAsset.auxiliaryComponents
        : [];
      columnsData.push({
        key: bladeAuxiliaryComponentsColumn.dataIndex,
        value: <AuxiliaryComponentList auxiliaryComponents={auxComponents} compact={true} />,
      });
      break;
    }
    default: {
      // Column - Turbine Name and link
      const turbine = asset?.ancestors?.find(ancestor => ancestor.assetType?.name === 'Turbine');
      columnsData.push({
        key: assetTurbineColumn.dataIndex,
        value: turbine ? getUniqueIdentifierLink(turbine) : <None />,
      });

      // Column - Site Name and link
      const site = asset?.ancestors?.find(ancestor => ancestor.assetType?.name === 'Site');
      columnsData.push({
        key: assetSiteColumn.dataIndex,
        value: site ? getUniqueIdentifierLink(site) : <None />,
      });
    }
  }
  return columnsData;
}

export function generateColumnFieldOptions(fieldName: string): any {
  return {
    text: fieldName,
    value: fieldName,
  };
}

/**
 * Function that returns an object useful for presenting titlecase field value
 * options to the user and storing an uppercase copy as the input value
 * @param fieldName
 * @returns an object formatted { text: 'InputString', value: 'INPUTSTRING' }
 */
export function generateUpperCaseValueFieldOptions(fieldName: string): any {
  return {
    text: fieldName,
    value: fieldName.toUpperCase(),
  };
}

export function getOptionsForField(
  fieldName: string,
  assetTypeFieldsData: AtlasGqlGetAssetFieldsForTypeQuery
): string[] {
  const options = assetTypeFieldsData.assetType.fields?.find(
    field => field.name === fieldName
  )?.fieldOptions;

  return options ? options : [];
}

export function getUniqueIdentifierLink(
  asset: TAnyAssetType
): JSX.Element | number | string | boolean {
  const uniqueIdentifier = getUniqueIdentifier(asset);
  return uniqueIdentifier ? <Link to={`/assets/${asset.id}`}>{uniqueIdentifier}</Link> : <None />;
}

function extractLocationOrSiteFromAsset(asset: TAnyAssetType): string | JSX.Element {
  let locationValue: any = '';
  // If Site, return or derive address
  if ('address' in asset && asset.address) {
    locationValue = getAddressForSite(asset);
  } else {
    // Else populate using Asset's Site name
    switch (asset.assetType?.name) {
      case 'Blade': {
        const bladeAsset = asset as AtlasGqlBlade;
        locationValue = getUniqueIdentifierLink(bladeAsset);
        break;
      }
      case 'Turbine': {
        const parent = asset.parent as AtlasGqlSite;
        locationValue = getUniqueIdentifierLink(parent);
        break;
      }
      default: {
        const siteAncestor = asset.ancestors?.find(
          ancestor => ancestor?.assetType?.name === 'Site'
        );
        if (siteAncestor) {
          locationValue = getUniqueIdentifierLink(siteAncestor);
        } else {
          // Fallback if no site is findable - None
          locationValue = <None />;
        }
      }
    }
  }
  return locationValue;
}

function getAddressForSite(site: AtlasGqlSite): string | JSX.Element {
  if ('address' in site && site.address) {
    return site.address;
  } else {
    const addressValues = [site.city, site.state, site.country].filter(x => x !== null);
    if (addressValues.length > 0) {
      return addressValues.join(', ');
    }
  }
  return <None />;
}

import { Divider, Radio } from 'antd5';
import styled from 'styled-components';

const RadioGroup = Radio.Group;

export const SectionTitle = styled.em`
  display: block;
  font-style: normal;
  margin-bottom: 0.5rem;
`;

export const StyledDivider = styled(Divider)`
  margin: 0em 0em 0.25em 0em;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  user-select: none;
`;

import { Link } from 'react-router-dom';

import { None } from './helpers';
import { AtlasGqlGenericAsset } from 'types/atlas-graphql';
import { extractFormattedDataFromAssetField } from './assets/utils';
import { TFieldValue } from 'horizon/components/Assets/AssetDetails/types';

function getValueForField(record: AtlasGqlGenericAsset, fieldName: string | undefined) {
  const fields = record?.fieldValues ?? [];
  if (!fields.length || !fieldName?.length) return <None />;
  const field = fields.find(
    f => f?.fieldDefinition?.name?.toLowerCase() === fieldName.toLowerCase()
  ) as TFieldValue;
  if (field) {
    const value = extractFormattedDataFromAssetField(field);
    return value;
  }
}

export const position = {
  key: 'position',
  title: 'Position',
  dataIndex: 'position',
  filterProperty: 'position',
  getFromColumn: true,
  get: (record: AtlasGqlGenericAsset) => getValueForField(record, 'position'),
  render: (position: string, record: AtlasGqlGenericAsset, dataIndex: string) => {
    return position ?? <None />;
  },
};

export const serial = {
  key: 'serial',
  title: 'Serial No.',
  dataIndex: 'serial',
  getFromColumn: true,
  get: (record: AtlasGqlGenericAsset) => getValueForField(record, 'serial number'),
  render: (serial: string, record: AtlasGqlGenericAsset) => {
    if (!record) return <None />;
    const { id } = record;
    return (
      <Link to={`/assets/${id}`}>
        {serial || (
          <i>
            {record.assetType?.name} {serial} (No Serial)
          </i>
        )}
      </Link>
    );
  },
};

export const make = {
  key: 'make',
  title: 'Make',
  dataIndex: 'make',
  getFromColumn: true,
  get: (record: AtlasGqlGenericAsset) => getValueForField(record, 'make'),
  render: (make: string) => {
    return make ?? <None />;
  },
};

export const model = {
  key: 'model',
  title: 'Model',
  dataIndex: 'model',
  getFromColumn: true,
  get: (record: AtlasGqlGenericAsset) => getValueForField(record, 'model'),
  render: (model: string) => {
    return model ?? <None />;
  },
};

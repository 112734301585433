import { useApolloContext, useQuery } from 'utils/apollo';
import { graphql } from '@apollo/client/react/hoc';
import { keyBy } from 'lodash';
import GET_AUXILIARY_COMPONENTS from './GetAuxillaryComponents.gql';

export const withAuxiliaryComponents = () =>
  graphql(GET_AUXILIARY_COMPONENTS, {
    props({ data, ownProps: { auxiliaryComponents = [] } }) {
      const { loading, auxiliaryComponents: auxiliaryComponentList } = data;

      const lookup = keyBy(auxiliaryComponentList, 'id');

      return {
        loading,
        auxiliaryComponents: auxiliaryComponents.map(aux => {
          const value = lookup[aux];
          if (value) {
            return value;
          }
          // in the event this value isn't in the enum list...going to just show it as we got it
          return { id: aux, name: aux };
        }),
      };
    },
  });

export const useAuxiliaryComponentOptions = () => {
  const { papiClient } = useApolloContext();
  const { data, loading } = useQuery(GET_AUXILIARY_COMPONENTS, { client: papiClient });

  return {
    loading,
    auxiliaryComponentOptions: data?.auxiliaryComponents,
  };
};

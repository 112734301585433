import { Fragment, memo, useRef } from 'react';
import { Alert } from 'antd';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  ErrorWrapper,
  FilesSelected,
  SelectedFile,
  StyledList,
  StyledFilename,
  StyledRemoveButton,
  StyledFileNameRow,
} from './SelectedFileList.style';
import { FileUploadProgressSummary } from '../FileUploadProgressSummary';
import { memoize } from 'lodash';
import { areEqual } from 'react-window';
import { useUploadContext } from 'components/FileUploader';
import { FilteredFiles, UploadFile } from '../../types';
import COLORS from '../../../../utils/color/definitions';
import { Form } from 'antd5';
import { AtlasGqlAttachmentRecordType } from 'types/atlas-graphql';
import { EditAttachmentForm } from 'components/RecordAttachments/EditAttachmentModal/EditAttachmentForm';

const DEFAULT_ITEM_SIZE = 35;
const TALL_ITEM_SIZE = 200;

interface Data {
  fileName: string;
  listRef: React.Ref<any>;
  onRemove: (name: string) => void;
  selectedFiles: UploadFile[];
  setSelectedFiles: any;
  uploading?: boolean;
  validFilenames: string[];
  displayAttachmentOptions?: boolean;
  attachmentRecordParentType?: AtlasGqlAttachmentRecordType;
}

interface SelectedFileItemProps {
  data: Data;
  index: number;
  style?: React.CSSProperties;
}

const SelectedFileItem = memo(({ data, index, style }: SelectedFileItemProps) => {
  const {
    validFilenames,
    selectedFiles,
    setSelectedFiles,
    uploading,
    onRemove,
    listRef,
    displayAttachmentOptions = false,
    attachmentRecordParentType = AtlasGqlAttachmentRecordType.Other,
  } = data;
  const file = selectedFiles[index];
  const { status } = file;

  const loadingIcon = <LoadingOutlined style={{ color: COLORS.TEAL }} spin />;
  const completeIcon = <CheckCircleOutlined style={{ color: COLORS.GREEN }} />;
  const warningIcon = <WarningOutlined style={{ color: COLORS.RED }} />;
  const pendingIcon = <ClockCircleOutlined />;

  // Callback to handle mapping the form values back to the files
  // so that they can be passed with the file data to the mutation call.
  const handleAttachmentValuesChange = () => {
    const values = form.getFieldsValue();
    if (setSelectedFiles) {
      const tempSelectedFiles = [...selectedFiles];
      tempSelectedFiles[index] = { ...file, ...values };
      setSelectedFiles(tempSelectedFiles);
    }
  };

  // Instantiate the form
  const [form] = Form.useForm();

  return (
    <SelectedFile key={file.sourceName + index} style={style}>
      <StyledFileNameRow>
        <StyledFilename invalid={!validFilenames.includes(file.sourceName)}>
          {<b>{file.sourceName}</b>}
        </StyledFilename>
        {uploading ? (
          status === 'error' ? (
            warningIcon
          ) : status === 'completed' ? (
            completeIcon
          ) : status === 'uploading' ? (
            loadingIcon
          ) : (
            pendingIcon
          )
        ) : (
          <StyledRemoveButton
            _version={4}
            type="link"
            danger={true}
            value={file.sourceName}
            icon={<CloseOutlined />}
            onClick={e => {
              onRemove((e.currentTarget as HTMLButtonElement).value);
              (listRef as any)?.current?.resetAfterIndex(index, false);
            }}
          />
        )}
      </StyledFileNameRow>
      {displayAttachmentOptions && (
        <EditAttachmentForm
          form={form}
          handleChange={handleAttachmentValuesChange}
          categoryOnChange={handleAttachmentValuesChange}
          parentType={attachmentRecordParentType}
          disabled={uploading || !file.valid}
          // There's no pre-set values, so set both defaults to undefined
          defaultCategoryId={undefined}
          defaultDescription={undefined}
        />
      )}
    </SelectedFile>
  );
}, areEqual);

// Memoized wrapper around the set of data that each list item will need
const createItemData = memoize(
  (
    validFilenames,
    selectedFiles,
    setSelectedFiles,
    uploading,
    fileName,
    onRemove,
    listRef,
    displayAttachmentOptions,
    attachmentRecordParentType
  ) => ({
    validFilenames,
    selectedFiles,
    setSelectedFiles,
    uploading,
    fileName,
    onRemove,
    listRef,
    displayAttachmentOptions,
    attachmentRecordParentType,
  })
);

export interface SelectedFileListProps {
  errorMessage?: React.ReactNode;
  fileName: string;
  filteredFiles: FilteredFiles;
  corruptFiles: string[];
  onRemove: (name: string) => void;
  showErrorSummary?: boolean;
  uploading?: boolean;
  validFiles: UploadFile[];
  displayAttachmentOptions?: boolean;
  attachmentRecordParentType?: AtlasGqlAttachmentRecordType;
}

export function SelectedFileList({
  errorMessage,
  fileName,
  filteredFiles,
  corruptFiles = [],
  onRemove,
  showErrorSummary,
  uploading,
  validFiles,
  displayAttachmentOptions = false,
  attachmentRecordParentType,
}: SelectedFileListProps) {
  const { selectedFiles, setSelectedFiles } = useUploadContext();
  const validFilesCount = validFiles.length;
  const validFilenames = validFiles.map(file => file.sourceName);
  const invalidCount = selectedFiles.length - validFilesCount;

  function itemKey(index: number, data: { selectedFiles: UploadFile[] }) {
    // Find the item at the specified index.
    // In this case "data" is an Array that was passed to List as "itemData".
    const itemKey = data?.selectedFiles[index].sourceName;

    // Return a value that uniquely identifies this item.
    // Typically this will be a UID of some sort.
    return itemKey;
  }

  const listRef = useRef<any>();

  const itemData = createItemData(
    validFilenames,
    selectedFiles,
    setSelectedFiles,
    uploading,
    fileName,
    onRemove,
    listRef,
    displayAttachmentOptions,
    attachmentRecordParentType
  );

  const finalInvalidCount =
    corruptFiles.length === 0 ? invalidCount : invalidCount - corruptFiles.length;

  return (
    <Fragment>
      {showErrorSummary ? (
        <ErrorSummary validFilesCount={validFilesCount} />
      ) : (
        <Fragment>
          {errorMessage && <Alert message={errorMessage} type="error" />}

          {uploading ? (
            <FileUploadProgressSummary />
          ) : (
            <Fragment>
              {corruptFiles.length > 0 && (
                <Alert
                  message={`${corruptFiles.length} files will not be uploaded because they are corrupt.`}
                  type="warning"
                />
              )}
              {finalInvalidCount > 0 && (
                <Alert
                  message={`${finalInvalidCount} files will not be uploaded because they are invalid.`}
                  type="warning"
                />
              )}
              {filteredFiles.status && (
                <Alert
                  message={`${filteredFiles.count} .DS_Store files have been filtered out because they are not necessary`}
                  type="warning"
                />
              )}
              <FilesSelected>
                {selectedFiles.length} {`file${selectedFiles.length === 1 ? '' : 's'} selected`}
              </FilesSelected>
            </Fragment>
          )}

          <StyledList
            height={330}
            itemCount={selectedFiles.length}
            // The itemSize needs to be taller if displaying the Attachment details sub-form
            itemSize={() => (displayAttachmentOptions ? TALL_ITEM_SIZE : DEFAULT_ITEM_SIZE)}
            itemData={itemData}
            width={496}
            itemKey={itemKey}
            ref={listRef}
          >
            {SelectedFileItem as any}
          </StyledList>
        </Fragment>
      )}
    </Fragment>
  );
}

interface ErrorSummaryProps {
  validFilesCount: number;
}

function ErrorSummary({ validFilesCount }: ErrorSummaryProps) {
  const { selectedFiles } = useUploadContext();
  const errors = selectedFiles.filter(({ status }) => status === 'error');

  return (
    <Fragment>
      <p>{validFilesCount - errors.length} files uploaded successfully.</p>
      <ErrorWrapper>
        <p>The following files were not uploaded:</p>
        <ul>
          {errors.map(file => (
            <li key={file.sourceName}>{file.sourceName}</li>
          ))}
        </ul>
      </ErrorWrapper>
    </Fragment>
  );
}

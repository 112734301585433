import styled from 'styled-components';
import { FlexContainer } from 'utils/layouts/containers';
import COLORS from 'utils/color/definitions';
import { Collapse, Typography } from 'antd';
import { Button } from 'components/ui';

const { Text, Title } = Typography;

export const StyledError = styled(FlexContainer)`
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
`;

export const ErrorContainer = styled(FlexContainer)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 10% 25% 10% 25%;
`;

export const FallbackContainer = styled(FlexContainer)`
  flex-direction: column;
  justify-content: center;
  align-content: stretch;
  margin: 0 15% 0 15%;
`;

export const StyledText = styled(Text)`
  margin: 1rem 0;
  text-align: center;
`;

export const StyledImage = styled.img`
  padding: 0.5em 1em;
  height: 150px;
`;

export const StyledButton = styled(Button)`
  padding: 10 5;
`;

export const StyledCollapse = styled(Collapse)`
  align-self: stretch;
`;

export const StyledErrorMessage = styled.pre`
  align-self: flex-start;
  text-align: start;
  padding: 10px;
  background: ${COLORS.YELLOW} background;
`;

export const StyledTitle = styled(Title)`
  text-align: center;
`;

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducer from './reducers';

const initialState = {
  damageViewer: {
    images: [],
  },
};

export const reduxStore = createStore(
  reducer,
  initialState,
  // @ts-ignore Property '__REDUX_DEVTOOLS_EXTENSION__' does not exist on type 'Window & typeof globalThis'
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const ReduxProvider = (props: any) => <Provider store={reduxStore} {...props} />;

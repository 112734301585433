import { useContext, useMemo, useEffect, useCallback } from 'react';
import { Form } from 'antd';
import { isEmpty, omit } from 'lodash';
import { TableLayout } from 'rc-table/lib/interface';

import { useFeatures } from 'utils/features';
import { TABLE_SCROLL_WIDTH } from 'utils/constants';

import { ClientDataTable } from './ClientDataTable';
import { ServerDataTable, ServerDataTableContextProvider } from './ServerDataTable';
import { Actions } from './Actions';
import { CustomizedDataBanner } from './CustomizedDataBanner';
import { useFormattedColumns } from './useFormattedColumns';
import { usePresetFilters } from './usePresetFilters';
import { DataTableProps, DataTableContext } from './index';
import { usePriority } from './prioritizable';
import { HeaderContainer, Control, TitleContainer, TableCard } from './DataTable.style';
import { EFilterSortClearType, ESortOptions } from './types';

import { rowKey } from './utils';

/**
 * "This place is not a place of honor...
 *  no highly esteemed deed is commemorated here...
 *  nothing valued is here."
 *
 * @param props
 * @returns
 */

export const DataTable: React.FunctionComponent<DataTableProps> = props => {
  const {
    cardType,
    columns: columnDefs = [],
    controls,
    dataQuery,
    data,
    disableUserSort = false,
    fillWidth,
    id,
    initialPresetFilter,
    clearSortAndFilterType: _clearSortAndFilterType,
    showSavedViews: _showSavedViews,
    savedViewCustomFields,
    onSavedViewChange,
    loading,
    setLoading,
    setTotalCount,
    onClick,
    // FIXME ARS - we are allowing this two-way binding so the AssetChooser and ExportReport can work
    onDataQueryChange,
    onSavePriority,
    // FIXME ARS - onTabChange this should eventually not be allowed to be a prop but allow it for now
    onTabChange: deprecatedOnTabChange,
    rowSelection,
    scrollWidth = TABLE_SCROLL_WIDTH,
    tabBarExtraContent,
    tabBarPriorityContent,
    tabList,
    title,
    storageKey,
    persistTableState = true,
    initialSort,
    isChangePriorityVisible = true,
    setShouldShowMultisort,
    onFiltersChange = () => {},
    onClearSelection = () => {},
    isExpandAllVisible = false,
    isExpandAllEnabled = false,
    handleExpandAll,
    allExpanded = false,
    changePriorityDisabledText = undefined,
  } = props || {};

  const { SAVED_VIEWS } = useFeatures().features;
  const showSavedViews = SAVED_VIEWS && !!_showSavedViews && !!id;

  const {
    initialPagination,
    pagination,
    updatePagination,
    filteredInfo,
    updateFilteredInfo,
    sortedInfo,
    updateSortedInfo,
    tableView,
    customizedColumnSet,
    multiSortDef,
    setPersistedState,
  } = useContext(DataTableContext) || {};

  const {
    handleOpenPriority,
    handleCancelPriority,
    handleDragUpdate,
    handleSavePriority,
    isPrioritizing,
    disableSavePriority,
    loading: priorityLoading,
    prioritizedItems,
  } = usePriority({ data, onSavePriority });

  // When we're prioritzing a table that is also filtered we need to disable multisort. This useMemo handles that for us. Initially implemented for WorkOrderTasksTable.
  useMemo(() => {
    // Return early if we don't need to check setShouldShowMultisort
    if (!setShouldShowMultisort) {
      return;
    }

    // If we are priortizing check if we're also filtered
    if (isPrioritizing) {
      // If we're not filtered we can return true
      if (!filteredInfo) return setShouldShowMultisort(true);
      // Check for any values in our filtered values
      // @ts-ignore issue with the type on filteredInfo thinking it's an unknown[]
      const filteredValues = Object.values(filteredInfo).some(x => x);
      return setShouldShowMultisort(!!filteredValues ? false : true);
    }

    // Otherwise we can still show multisort
    return setShouldShowMultisort(true);
  }, [isPrioritizing, filteredInfo, setShouldShowMultisort]);

  const { activeTabKey, onTabChange } = usePresetFilters({
    initialPresetFilter,
    tabList,
    deprecatedOnTabChange,
  });

  function getSortOrder(sortOrder: ESortOptions) {
    if (sortOrder === 'DESC') {
      return 'descend';
    }
    return 'ascend';
  }

  useEffect(() => {
    if (initialSort) {
      updateSortedInfo({ columnKey: initialSort.key, order: getSortOrder(initialSort.sort) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // initialSort may not be the same object between renders, depending on where the context provider is.
    // stringifying serves the purpose of checking the relevant equality
  }, [JSON.stringify(initialSort)]);

  const clearSortAndFilterType =
    _clearSortAndFilterType === EFilterSortClearType.RESET &&
    (initialPresetFilter?.value?.filters || initialPresetFilter?.value?.sortOrder)
      ? EFilterSortClearType.RESET
      : EFilterSortClearType.CLEAR;

  const handleTableChange = (pagination: any, filters: any, sorter: any, _extra?: any) => {
    // FIXME: after antd v4 upgrade, sorter can contain an undefined column
    const appliedSort = sorter && !sorter.column ? {} : { ...sorter };

    if (appliedSort.column?.sortKey) {
      // allow the sort key to be overridden from the column's default `index` value
      appliedSort.columnKey = appliedSort.column.sortKey;
    }

    updatePagination(pagination);
    updateSortedInfo(appliedSort);
    updateFilteredInfo(filters);
    onFiltersChange(filters);

    const { columnKey, order } = appliedSort;
    persistTableState &&
      setPersistedState({ filteredInfo: filters, sortedInfo: { columnKey, order }, pagination });
  };

  const handleClearSortAndFilter = () => {
    updatePagination(
      isEmpty(initialPagination)
        ? initialPagination
        : { ...initialPagination, pageSize: pagination && pagination?.pageSize }
    );

    if (clearSortAndFilterType === EFilterSortClearType.CLEAR) {
      // clears filters/sorters
      updateFilteredInfo({});
      onFiltersChange({});
      updateSortedInfo({});
      persistTableState && setPersistedState({});

      // FIXME ARS - both of these following callbacks are... :-( they just highlight some code smell that we will chip away at
      if (onDataQueryChange) {
        onDataQueryChange({});
      }
    } else {
      // resets filters/sorters to their initial values
      const { filters = {}, sortOrder = {} } = initialPresetFilter?.value ?? {};
      updateFilteredInfo(filters);
      onFiltersChange(filters);
      updateSortedInfo(sortOrder);

      persistTableState &&
        setPersistedState({ filteredInfo: filters, sortedInfo: sortOrder, pagination });
    }
  };

  const titleWithControls = (
    <HeaderContainer>
      {title && <TitleContainer>{title}</TitleContainer>}
      {controls && (
        <Control>
          <Form layout="inline">{controls}</Form>
        </Control>
      )}
    </HeaderContainer>
  );

  // necessary otherwise title will always show
  const showTitle = title || controls;

  const onRow = useCallback(
    (record: any): React.HTMLAttributes<HTMLElement> => {
      if (onClick) {
        return { onClick: () => onClick(record) };
      }

      return {};
    },
    [onClick]
  );

  const filteredCustomCols =
    customizedColumnSet && Array.isArray(customizedColumnSet)
      ? customizedColumnSet.filter((col: { visible: boolean }) => col.visible)
      : undefined;

  const formattedColumns = useFormattedColumns({
    columns: (filteredCustomCols || columnDefs).map(column => ({
      ...column,
      serverSortAndFilter: !!dataQuery,
    })),
    isPrioritizing,
  });

  const tableProps = {
    loading,
    setLoading,
    setTotalCount,
    rowKey,
    onRow,
    scroll: { x: scrollWidth },
    onTableChange: handleTableChange,
    onDataQueryChange,
    onDragUpdate: handleDragUpdate,
    onClearSortAndFilter: handleClearSortAndFilter,
    ...props,
    columns: formattedColumns,
    pagination,
    filteredInfo,
    sortOrder: sortedInfo,
    tableLayout: props.tableLayout ?? 'auto', // antd v3.24+ requires this to make sure no cols overlap
  };

  useEffect(() => {
    const firstTabKey = tabList?.[0]?.key;
    if (isPrioritizing && firstTabKey && activeTabKey !== firstTabKey) {
      onTabChange(firstTabKey);
    }
  }, [isPrioritizing, activeTabKey, tabList, onTabChange]);

  const showBanner = !!(multiSortDef || customizedColumnSet || tableView);

  // Currently (11/2022), the only location where actions should
  // not exist in the table header area is the inspection details page.
  // AntD specifies the table header and the tab area as separate blocks,
  // so we need to combine the two for this single instance.
  const shouldOverrideActionsLocation = !!tabBarExtraContent;
  const TableActions = (
    <>
      <Actions
        tableId={id}
        // @ts-ignore JD - There's a minor type mis-match between AntD's column expectations, and ours.
        columnDefs={columnDefs}
        filters={filteredInfo}
        sorters={sortedInfo ? [sortedInfo] : undefined}
        storageKey={storageKey}
        onClearSortAndFilter={handleClearSortAndFilter}
        onClearSelection={onClearSelection}
        onTableChange={handleTableChange}
        priorityContent={tabBarPriorityContent}
        additionalContent={tabBarExtraContent}
        hasSelectedRows={rowSelection?.selectedRowKeys?.length}
        rowReordering={
          onSavePriority
            ? {
                rowCount: data ? data.length : 0,
                priorityLoading,
                onSavePriority: handleSavePriority,
                onCancelPriority: handleCancelPriority,
                onOpenPriority: handleOpenPriority,
                isPrioritizing,
                disableSavePriority,
                changePriorityDisabledText,
              }
            : null
        }
        {...props}
        clearSortAndFilterType={clearSortAndFilterType}
        showSavedViews={showSavedViews}
        savedViewCustomFields={savedViewCustomFields}
        onSavedViewChange={onSavedViewChange}
        isChangePriorityVisible={isChangePriorityVisible}
        isExpandAllVisible={isExpandAllVisible}
        handleExpandAll={handleExpandAll}
        isExpandAllEnabled={isExpandAllEnabled}
        allExpanded={allExpanded}
      />
    </>
  );

  const { hasServerDataTableContextProviderParent, renderInServerContext, ...serverTableProps } =
    tableProps;

  return (
    <DataTableWrapper
      renderInServerContext={renderInServerContext}
      shouldRenderInServerContext={Boolean(dataQuery && !hasServerDataTableContextProviderParent)}
    >
      <TableCard
        id={id}
        bodyStyle={{ padding: '1px 0 0 0' }}
        title={showTitle && titleWithControls}
        extra={!shouldOverrideActionsLocation ? TableActions : undefined}
        tabList={tabList}
        tabBarExtraContent={shouldOverrideActionsLocation ? TableActions : undefined}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        type={cardType}
        clickable={!!onClick}
        fillwidth={!!fillWidth}
        disableUserSort={disableUserSort}
      >
        {showBanner && (
          <CustomizedDataBanner
            tableId={id}
            storageKey={storageKey}
            // @ts-ignore JD - There's a minor type mis-match between AntD's column expectations, and ours.
            columnDefs={columnDefs}
            onTableChange={handleTableChange}
            onClearSortAndFilter={handleClearSortAndFilter}
            onClearSelection={onClearSelection}
            savedViewCustomFields={savedViewCustomFields}
            onSavedViewChange={onSavedViewChange}
          />
        )}
        {dataQuery ? (
          <ServerDataTable
            {...omit(serverTableProps, 'title')}
            dataQuery={dataQuery}
            tableLayout={serverTableProps.tableLayout as TableLayout}
          />
        ) : (
          <ClientDataTable
            {...omit(tableProps, 'title')}
            data={prioritizedItems || data}
            draggable={isPrioritizing}
            rowSelection={isPrioritizing ? undefined : rowSelection}
            tableLayout={tableProps.tableLayout as TableLayout}
            isPrioritizing={isPrioritizing}
            handleOrderUpdate={handleDragUpdate}
          />
        )}
      </TableCard>
    </DataTableWrapper>
  );
};

interface DataTableWrapperProps {
  children: React.ReactNode;
  renderInServerContext?: React.ReactNode;
  shouldRenderInServerContext: boolean;
}

function DataTableWrapper({
  children,
  renderInServerContext,
  shouldRenderInServerContext,
}: DataTableWrapperProps) {
  if (shouldRenderInServerContext) {
    return (
      <ServerDataTableContextProvider>
        {children}
        {renderInServerContext}
      </ServerDataTableContextProvider>
    );
  }

  return <>{children}</>;
}

import styled from 'styled-components';
import { Row, Col } from 'antd';

export const SpinnerContainer = styled.div`
  height: 100%;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExpandedRowContainer = styled(Row)`
  left: 8px;
  position: sticky;
  min-height: 250px;
  width: calc(100vw - ${window.innerWidth < 992 ? 79 : 200}px - 64px);
  white-space: normal;
  z-index: 1;
`;

export const StyledRow = styled(Row)`
  height: 100%;
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ControlsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  > button,
  > span {
    margin-top: 12px;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const AttributesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  .ant-btn {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }
`;

import { useCallback } from 'react';
import { message } from 'antd'; // Intentionally pulling message from antd v4 for style consistency
import { LinkOutlined, ShareAltOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from 'components/ui';

type CopyButtonProps = {
  onClick?: (arg0: any) => void;
  value?: string;
  legacy?: boolean;
  useIconButton?: boolean;
  [key: string]: any;
};

export const CopyButton: React.FunctionComponent<CopyButtonProps> = ({
  onClick,
  value,
  legacy,
  useIconButton,
  ...props
}) => {
  const handleCopy = useCallback(
    e => {
      message.success(`Link copied to clipboard`);
      onClick && onClick(e);
    },
    [onClick]
  );

  return (
    <CopyToClipboard text={value} onCopy={handleCopy}>
      {useIconButton ? (
        <Button
          title="Copy link"
          id="ol-copy-link"
          {...props}
          icon={legacy ? <LinkOutlined /> : <ShareAltOutlined />}
        />
      ) : (
        <Button title="Copy link" id="ol-copy-link" {...props}>
          {legacy ? <LinkOutlined /> : <ShareAltOutlined />}
        </Button>
      )}
    </CopyToClipboard>
  );
};

import { ClickMarker } from './';
import { TTurbineToUpdateProps } from '../types';
import { AtlasGqlTurbine } from 'types/atlas-graphql';

interface IPointsLayerProps {
  mapRef: any;
  items: Array<AtlasGqlTurbine>;
  getIcon: Function;
  icon: string;
  selectedTurbineIndex: number;
  setSelectedTurbine: (selectedTurbine: number) => void;
  isDraggable: boolean;
  setHasDraggingStarted: boolean;
  setTurbineToUpdate?: (turbineToUpdate: TTurbineToUpdateProps) => void;
  isLabelLayer?: boolean;
}

export function PointsLayer(props: IPointsLayerProps) {
  const {
    mapRef,
    items,
    getIcon,
    icon,
    selectedTurbineIndex,
    setSelectedTurbine,
    isDraggable,
    setHasDraggingStarted,
    setTurbineToUpdate,
    // setting isLabelLayer true exposes marker labels and makes the
    // marker icon transparent
    isLabelLayer = false,
  } = props;

  // @ts-ignore
  return items.map((i, idx) => {
    return (
      <ClickMarker
        mapRef={mapRef}
        icon={getIcon ? getIcon(i) : icon}
        isIconTransparent={isLabelLayer}
        key={idx}
        index={idx}
        isDraggable={isDraggable}
        openPopup={selectedTurbineIndex === idx}
        setSelectedTurbine={setSelectedTurbine}
        setTurbineToUpdate={setTurbineToUpdate}
        setHasDraggingStarted={setHasDraggingStarted}
        label={isLabelLayer ? i.name : ''}
        // TODO: Find out everything that's in ...i and declare it
        {...i}
      />
    );
  });
}

import { getFieldValue } from 'utils/useHeaderFields';

export function sortFields({ form, formValues, allFields, standardFieldKeys, uneditableFields }) {
  const fieldValues = form ? form.getFieldsValue() : formValues;
  return allFields.reduce(
    (acc, curr) => {
      if (
        fieldValues[curr.name] &&
        standardFieldKeys[curr.name] &&
        !Boolean(uneditableFields[curr.name])
      ) {
        acc.standard[standardFieldKeys[curr.name].dataIndex] = fieldValues[curr.name];
      } else if (
        fieldValues[curr.name] &&
        !standardFieldKeys[curr.name] &&
        !Boolean(uneditableFields[curr.name])
      ) {
        acc.custom[curr.id] = getFieldValue({ value: fieldValues[curr.name] }, { type: curr.type });
      }

      return acc;
    },
    { standard: {}, custom: {} }
  );
}

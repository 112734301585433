import { Link } from 'react-router-dom';
import { sortBy, None } from './helpers';
import { get } from 'lodash';

export const name = {
  key: 'site',
  title: 'Site',
  sorter: sortBy('name'),
  get: record => get(record, 'name'),
  render: record => {
    const { name, id } = record || {};
    return [name, id].every(Boolean) ? <Link to={`/sites/${id}`}>{name}</Link> : <None />;
  },
  filterProperty: 'name',
};

export const city = {
  title: 'City',
  dataIndex: 'city',
  filterProperty: 'city',
  sorter: sortBy('city'),
  get: record => get(record, 'city'),
};

export const state = {
  title: 'State',
  dataIndex: 'state',
  filterProperty: 'state',
  sorter: sortBy('state'),
  get: record => get(record, 'state'),
};

export const address = {
  title: 'Location',
  key: 'location',
  filterProperty: 'address',
  sorter: sortBy('address'),
  get: record => get(record, 'address'),
  render(record) {
    const { address } = record || {};
    return address || <None />;
  },
};

export const numTurbines = {
  key: 'numTurbines',
  title: '# Turbines',
  dataIndex: 'turbines',
  render: turbines => (turbines ? turbines.length : <None />),
  sorter: sortBy('turbines.length'),
  get: record => get(record, ['turbines', 'length']),
};

import { getTaskAssetInfo } from 'components/choosers/helpers';
import {
  AtlasGqlOtherAssetTask,
  AtlasGqlInspectionTask,
  AtlasGqlInternalBladeInspectionTask,
  AtlasGqlWorkOrder,
  AtlasGqlWorkCampaign,
} from 'types/atlas-graphql';
import { TComponentFilters } from './types';

export function getIdsAsArray(assetIds: string[] | string | undefined): string[] | undefined {
  if (assetIds) {
    return assetIds instanceof Array ? assetIds : [assetIds];
  }
  return undefined;
}

export function getLowestLevelAssetIds(
  siteIds: string[] | undefined,
  turbineIds: string[] | undefined,
  componentIds: string[] | undefined
) {
  if (componentIds) {
    return componentIds;
  } else if (turbineIds) {
    return turbineIds;
  } else if (siteIds) {
    return siteIds;
  }
  return undefined;
}

export function getComponentFiltersFromTaskAsset(
  taskAsset:
    | AtlasGqlOtherAssetTask
    | AtlasGqlInspectionTask
    | AtlasGqlInternalBladeInspectionTask
    | AtlasGqlWorkOrder
    | AtlasGqlWorkCampaign
    | undefined
): TComponentFilters {
  // Extract the asset from the Task
  const asset = getTaskAssetInfo(taskAsset);

  if (!asset || !asset?.__typename) {
    return {
      siteIds: [],
      turbineIds: [],
      componentIds: [],
    };
  }

  switch (asset.__typename) {
    case 'Site': {
      return {
        siteIds: [asset.id],
        turbineIds: [],
        componentIds: [],
      };
    }
    case 'Turbine': {
      return {
        siteIds: [asset?.parent?.id],
        turbineIds: [asset.id],
        componentIds: [],
      };
    }
    default: {
      // If there is a type, and it's not Site or Turbine, assume it is a Component
      const turbineId =
        asset?.ancestors?.find((a: any) => a?.__typename === 'Turbine')?.id ?? undefined;
      const siteId = asset?.ancestors?.find((a: any) => a?.__typename === 'Site')?.id ?? undefined;
      return {
        siteIds: [siteId],
        turbineIds: [turbineId],
        componentTypes: [asset.assetType.name.toLowerCase()],
        componentIds: [asset.id],
      };
    }
  }
}

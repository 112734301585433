import styled, { css } from 'styled-components';

interface DamagePolygonProps {
  coordinates?: any[] | null;
  points: any;
  selected: boolean;
  editingClue: boolean;
  isHovering: boolean;
}

export const OSDContainer = styled.div`
  height: 100%;
`;
// an ugly hack with the @supports query to make Edge at least sort of work
// it doesn't support vector-effect and so we need to go back to the 'old way' for stroke width
export const DamagePolygon = styled.polygon.attrs((props: DamagePolygonProps) => ({
  points: props.coordinates ? props.coordinates.map(xy => xy.join(',')).join(' ') : props.points,
  selected: props.selected,
  isHovering: props.isHovering,
  coordinates: props.coordinates,
}))`
  fill: none;
  stroke: ${({ theme, color }) => (color ? theme[color] : theme.red)};
  stroke-opacity: ${({ selected }) => (selected ? 1 : 0.6)};
  ${({ onClick }) => (onClick ? 'cursor: pointer;' : '')};
  vector-effect: non-scaling-stroke;
  @supports (vector-effect: non-scaling-stroke) {
    stroke-width: ${({ selected }) => (selected ? '3px' : '2px')};
  }
  stroke-width: 0.004px;
  pointer-events: visible;
  ${props =>
    props.isHovering &&
    css`
      stroke-width: 6px !important;
    `};
`;

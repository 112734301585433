import React, { useState } from 'react';

import { Spinner } from 'components/ui';
import { useGetTaskAssetQuery } from 'types/atlas-graphql';
import { IComponentChooserProps, ComponentChooser } from '../ComponentChooser';
import { getComponentFiltersFromTaskAsset } from '../helpers';
import { workOrderId } from 'horizon/routes/WorkOrders/WorkOrderDetail/mocks';

export interface ITaskComponentChooserProps extends IComponentChooserProps {
  workContainerId?: string;
  showLoadingIndicator?: boolean;
}

/**
 * Uses Component Chooser but given a workContainerId (in the abstract sense) default the selection of the chooser to the lowest common asset of the work container
 */
export const TaskComponentChooser: React.FunctionComponent<ITaskComponentChooserProps> = ({
  workContainerId,
  showLoadingIndicator = false,
  settings,
  onChange,
  loadState,
  siteIds: selectedSiteIds,
  turbineIds: selectedTurbineIds,
  componentChooserState,
  setComponentChooserState,
}) => {
  const [localInitialized, setLocalInitialized] = useState<boolean>(false);

  // Get TaskAsset for provided task
  const { data: taskAssetData, loading: taskAssetLoading } = useGetTaskAssetQuery({
    variables: { taskId: workContainerId },
    skip: !workContainerId,
    // network-only for this query because we always want to get the most up-to-date TaskAsset
    // And the cache could very quickly become stale
    fetchPolicy: 'network-only',
  });

  if (taskAssetLoading) {
    if (showLoadingIndicator) {
      return <Spinner />;
    }
    return null;
  }

  let defaultAssets;
  if (workOrderId && !localInitialized && !taskAssetLoading) {
    defaultAssets = getComponentFiltersFromTaskAsset(taskAssetData?.task as any);

    // We don't care about auto-populating anything beyond the Sites and Turbines
    onChange({
      siteIds: defaultAssets.siteIds,
      turbineIds: defaultAssets.turbineIds,
      assetIds: defaultAssets.turbineIds,
      componentTypes: undefined,
      componentIds: undefined,
    });
    setLocalInitialized(true);
  }

  return (
    <ComponentChooser
      {...{
        settings,
        onChange,
        loadState,
        componentChooserState,
        setComponentChooserState,
      }}
    />
  );
};

import { useMemo } from 'react';
import { startCase, groupBy, flatten } from 'lodash';
import { Tag } from 'antd5';
import { Spinner } from 'components/ui';

import { uuidRegex as uuidRegexString } from 'utils/constants';
import { getFilterBy, getSortBy } from 'components/DataTable/paging';
import { None } from 'components/data/helpers';

import {
  StyledListContainer,
  StyledList,
  StyledListItem,
  Label,
  Description,
  StyledOperator,
} from '../Views.style';
import { useNameMapping } from '../useNameMapping';
import { TExtensionColumn, TTableViewFilters } from '../types';
import { TDisplayColumnData } from 'components/data/types';
import { AtlasGqlFilterBy, AtlasGqlSortBy } from 'types/atlas-graphql';

const operatorCase = (value: string) => startCase(value.toLowerCase());

const formatFilters = (
  filters: AtlasGqlFilterBy[] | undefined,
  columnTitles: { [key: string]: React.ReactNode },
  mapping: { [key: string]: string }
) => {
  const groupedFilters = groupBy(filters ?? [], 'key');

  return Object.keys(groupedFilters).map(key => {
    const filters = groupedFilters[key];

    const formattedValues: React.ReactNode[] = [];
    filters.forEach((filter, filter_idx) => {
      const { operator, values } = filter;
      values.forEach((val, val_idx) => {
        formattedValues.push(
          <div key={`${key}_${filter_idx}_${val_idx}`} style={{ display: 'flex' }}>
            <StyledOperator>{operatorCase(operator ?? '')}</StyledOperator>
            <Tag>{(val && mapping[val]) ?? val}</Tag>
          </div>
        );
      });
    });

    return {
      label: columnTitles[key] ?? startCase(key),
      value: formattedValues,
    };
  });
};

const formatSorts = (
  sorts: AtlasGqlSortBy[] | undefined,
  multiSorts: AtlasGqlSortBy[] | undefined,
  columnTitles: { [key: string]: React.ReactNode }
) => {
  const combinedSorts = [...(sorts ?? []), ...(multiSorts ?? [])];

  return combinedSorts.map(({ key, sort }) => {
    const formattedSort = sort === 'ASC' ? 'ASCENDING' : 'DESCENDING';
    return {
      label: columnTitles[key] ?? startCase(key),
      value: <Tag>{operatorCase(formattedSort)}</Tag>,
    };
  });
};

const formatColumns = (
  columns: TExtensionColumn[] | undefined,
  columnDefs: TDisplayColumnData[],
  columnTitles: { [key: string]: React.ReactNode }
) => {
  const hiddenColumns = (columns ?? []).filter(({ visible }) => visible === false);

  let columnsMoved = false;
  columns?.forEach((column, index) => {
    if (column.visible === true && column.key !== columnDefs?.[index]?.key) {
      columnsMoved = true;
    }
  });

  return [
    {
      label: 'Columns Hidden',
      value:
        hiddenColumns.length >= 1 ? (
          hiddenColumns
            .map(({ key }) => columnTitles[key] ?? startCase(key))
            .reduce((prev, curr) => [prev, ', ', curr])
        ) : (
          <None />
        ),
    },
    {
      label: 'Columns Moved',
      value: columnsMoved ? 'Yes' : 'No',
    },
  ];
};

const renderListItem = (item: { label: string; value: any }) => (
  <StyledListItem>
    <div>
      <Label>{item.label}:</Label>
      <Description>{item.value}</Description>
    </div>
  </StyledListItem>
);

type SummaryProps = {
  tableView: TTableViewFilters;
  columnDefs: TDisplayColumnData[];
};

export const Summary: React.FunctionComponent<SummaryProps> = ({ tableView, columnDefs }) => {
  const { filters, sorts, multiSorts, columns } = useMemo(() => {
    return {
      filters: getFilterBy({
        filters: tableView.filters,
        columns: columnDefs,
      }) as AtlasGqlFilterBy[],
      sorts: getSortBy(tableView.sorts) as AtlasGqlSortBy[],
      multiSorts: (tableView.multiSorts ?? []) as AtlasGqlSortBy[],
      columns: tableView.columns ?? [],
    };
  }, [tableView, columnDefs]);

  const columnTitles = useMemo(
    () =>
      columnDefs.reduce((acc: { [key: string]: React.ReactNode }, col: TDisplayColumnData) => {
        const { key, title } = col;
        return { ...acc, [key]: title };
      }, {}),
    [columnDefs]
  );

  const uuidRegex = new RegExp(`^${uuidRegexString}$`, 'i');
  const filtersUuids = flatten(filters.map(f => f.values)).filter(f => f && uuidRegex.test(f));

  const { mapping, loading } = useNameMapping(filtersUuids as string[]);

  return loading ? (
    <Spinner />
  ) : (
    <StyledListContainer>
      {filters.length > 0 && (
        <StyledList
          size="small"
          header="Filters"
          dataSource={formatFilters(filters, columnTitles, mapping)}
          renderItem={renderListItem}
        />
      )}
      {(sorts.length > 0 || multiSorts.length > 0) && (
        <StyledList
          size="small"
          header="Sorts"
          dataSource={formatSorts(sorts, multiSorts, columnTitles)}
          renderItem={renderListItem}
        />
      )}
      {columns.length > 0 && (
        <StyledList
          size="small"
          header="Customized Columns"
          dataSource={formatColumns(columns, columnDefs, columnTitles)}
          renderItem={renderListItem}
        />
      )}
    </StyledListContainer>
  );
};

import { useMemo } from 'react';
import { isNil, isNumber, first, flatMap, groupBy, sortBy, values, meanBy } from 'lodash';
import { useAccountContext } from 'utils/account/AccountContext';
import {
  AtlasGqlPicture,
  AtlasGqlInspection,
  AtlasGqlTargetBlade_External,
  AtlasGqlTargetBlade_Internal,
  AtlasGqlTargetTower_External,
  AtlasGqlTargetTransition_Piece,
  AtlasGqlTargetGeneric,
  AtlasGqlTargetGearbox,
  AtlasGqlTargetNacelle_External,
  useGetRelatedPicturesQuery,
  AtlasGqlTurbine,
  AtlasGqlBlade,
  AtlasGqlGenericAsset,
  AtlasGqlHorizonDamageObservation,
} from 'types/atlas-graphql';
import {
  BladeTarget,
  TowerOrTransitionPieceTarget,
  PictureTarget,
} from 'horizon/routes/Inspections/types';
import { RELATED_PICTURES_SORTING } from 'utils/release-toggles';

// get the average distance/height from an array of observations
const getAverageObservationAttribute = (
  observations: AtlasGqlHorizonDamageObservation[],
  attribute: 'Distance' | 'Height'
): number | undefined => {
  const filteredObservations = observations.filter(({ attrs }) => isNumber(attrs?.[attribute]));
  return filteredObservations.length > 0 ? meanBy(observations, `attrs.${attribute}`) : undefined;
};

// sorts pictures by distance/height and then groups them
const sortAndGroupPictures = (
  pictures: AtlasGqlPicture[],
  targetType: string,
  refDistance: number,
  refHeight: number
): AtlasGqlPicture[] => {
  // sort pictures by distance/height from a reference distance/height
  // note: pictures that have zero distance/height are sorted after those with valid values
  const sortedPictures = sortBy(pictures, picture => {
    const { target, horizonDamageObservations } = picture;
    const observations = (horizonDamageObservations ?? []) as AtlasGqlHorizonDamageObservation[];

    // using distance
    if (['BLADE_EXTERNAL', 'BLADE_INTERNAL'].includes(targetType)) {
      const picDistance = (target as BladeTarget)?.radialDistance;
      const obsDistance = getAverageObservationAttribute(observations, 'Distance');
      const distance = picDistance || obsDistance || undefined;

      if (isNumber(distance)) {
        return Math.abs(refDistance - distance);
      }
    }

    // using height
    if (['TOWER_EXTERNAL', 'TRANSITION_PIECE'].includes(targetType)) {
      const picHeight = (target as TowerOrTransitionPieceTarget)?.height;
      const obsHeight = getAverageObservationAttribute(observations, 'Height');
      const height = picHeight || obsHeight || undefined;

      if (isNumber(height)) {
        return Math.abs(refHeight - height);
      }
    }

    // default case
    return undefined;
  });

  // group pictures by blade for blade inspections
  const groups = values(
    groupBy(sortedPictures, picture => {
      const { target } = picture;

      if (targetType === 'BLADE_EXTERNAL') {
        const bladeTarget = target as AtlasGqlTargetBlade_External;
        const bladeLabel = bladeTarget.bladeAsset?.label;

        if (bladeLabel) {
          return bladeLabel;
        }
      }

      if (targetType === 'BLADE_INTERNAL') {
        const bladeTarget = target as AtlasGqlTargetBlade_Internal;
        const bladeLabel = bladeTarget.bladeAsset?.label;

        if (bladeLabel) {
          return bladeLabel;
        }
      }

      return picture;
    })
  );

  // return the first of each group
  return flatMap(groups.map(g => first(g)!));
};

// only inspections that came back with pictures that include the current
// picture's target asset id should be returned.
const pictureTargetComparison = (
  pictures: AtlasGqlPicture[],
  targetType: string,
  assetId: string
): boolean => {
  return pictures.some(picture => {
    const { target } = picture;

    if (targetType === 'BLADE_EXTERNAL' || targetType === 'BLADE_INTERNAL') {
      return (target as BladeTarget)?.bladeAsset?.id === assetId;
    }
    if (targetType === 'TOWER_EXTERNAL') {
      return (target as AtlasGqlTargetTower_External)?.tower?.id === assetId;
    }
    if (targetType === 'TRANSITION_PIECE') {
      return (target as AtlasGqlTargetTransition_Piece)?.transitionPiece?.id === assetId;
    }
    if (targetType === 'NACELLE_EXTERNAL') {
      return (target as AtlasGqlTargetNacelle_External)?.nacelle?.id === assetId;
    }
    if (targetType === 'GEARBOX') {
      return (target as AtlasGqlTargetGearbox)?.gearbox?.id === assetId;
    }
    if (targetType === 'GENERIC') {
      return (target as AtlasGqlTargetGeneric)?.asset?.id === assetId;
    }
    return false;
  });
};

export type useRelatedPicturesProps = {
  picture?: AtlasGqlPicture;
};

export const useRelatedPictures = ({
  picture,
}: useRelatedPicturesProps): {
  loading: boolean;
  pictures: AtlasGqlPicture[];
  inspections: AtlasGqlInspection[];
} => {
  const { hasReleaseToggle } = useAccountContext();
  const hasRelatedPicturesSortingReleaseToggle = hasReleaseToggle(RELATED_PICTURES_SORTING);

  const target = picture?.target;
  const targetType = (target as PictureTarget)?.type;

  // assets
  const bladeAsset = (target as BladeTarget)?.bladeAsset;
  const tower = (target as AtlasGqlTargetTower_External)?.tower;
  const transitionPiece = (target as AtlasGqlTargetTransition_Piece)?.transitionPiece;
  const nacelle = (target as AtlasGqlTargetNacelle_External)?.nacelle;
  const gearbox = (target as AtlasGqlTargetGearbox)?.gearbox;
  const genericAsset = (target as AtlasGqlTargetGeneric)?.asset;

  const asset = bladeAsset ?? tower ?? transitionPiece ?? nacelle ?? gearbox ?? genericAsset;
  const assetId = asset?.id;

  // variables
  const { id: inspectionId } = picture?.inspection ?? {};
  const bladeLabel = bladeAsset?.label;
  const bladeSide = (target as BladeTarget)?.bladeSide;
  const bladeChamber = (target as AtlasGqlTargetBlade_Internal)?.bladeChamber;
  const towerSide = (target as AtlasGqlTargetTower_External)?.towerSide;
  const transitionPieceSide = (target as AtlasGqlTargetTransition_Piece)?.transitionPieceSide;
  const nacelleSide = (target as AtlasGqlTargetNacelle_External)?.nacelleSide;

  // distance and height
  const observations = (picture?.horizonDamageObservations ??
    []) as AtlasGqlHorizonDamageObservation[];

  const picDistance = (target as BladeTarget)?.radialDistance;
  const obsDistance = getAverageObservationAttribute(observations, 'Distance');
  const refDistance = picDistance || obsDistance || 0;

  const picHeight = (target as TowerOrTransitionPieceTarget)?.height;
  const obsHeight = getAverageObservationAttribute(observations, 'Height');
  const refHeight = picHeight || obsHeight || 0;

  const variables = {
    id: inspectionId,
    bladeLabel,
    bladeSide,
    bladeChamber,
    towerSide,
    transitionPieceSide,
    nacelleSide,
    ...(hasRelatedPicturesSortingReleaseToggle
      ? {}
      : {
          distance: picDistance,
          height: picHeight,
        }),
  };

  // query for related pictures
  const { data = {}, loading } = useGetRelatedPicturesQuery({
    // @ts-ignore
    variables,
    skip: isNil(inspectionId),
  });

  // pictures
  const pictures = useMemo(() => {
    const pictures = (data.inspection?.pictures ?? []) as AtlasGqlPicture[];

    if (hasRelatedPicturesSortingReleaseToggle) {
      return sortAndGroupPictures(pictures, targetType, refDistance, refHeight);
    }

    return pictures;
  }, [
    data.inspection?.pictures,
    targetType,
    refDistance,
    refHeight,
    hasRelatedPicturesSortingReleaseToggle,
  ]);

  // inspections
  const inspections = useMemo(() => {
    const inspectionAsset = data.inspection?.asset as
      | AtlasGqlTurbine
      | AtlasGqlBlade
      | AtlasGqlGenericAsset
      | undefined;

    // only inspections that came back with pictures that include the current
    // picture's target asset id should be returned.
    const inspections = (inspectionAsset?.inspections?.nodes ?? []).filter(inspection => {
      const pictures = (inspection.pictures ?? []) as AtlasGqlPicture[];
      return pictureTargetComparison(pictures, targetType, assetId);
    });

    if (hasRelatedPicturesSortingReleaseToggle) {
      return inspections.map(inspection => {
        const pictures = (inspection.pictures ?? []) as AtlasGqlPicture[];
        return {
          ...inspection,
          pictures: sortAndGroupPictures(pictures, targetType, refDistance, refHeight),
        };
      });
    }

    return inspections;
  }, [
    data.inspection,
    targetType,
    assetId,
    refDistance,
    refHeight,
    hasRelatedPicturesSortingReleaseToggle,
  ]);

  if (isNil(inspectionId)) {
    return {
      loading: false,
      pictures: [],
      inspections: [],
    };
  }

  return { loading, pictures, inspections };
};

import { Tag } from 'antd5';
import styled from 'styled-components';
import { WarningFilled } from '@ant-design/icons';
import COLORS from 'utils/color/definitions';

export const Container = styled.span<{ margin?: string }>`
  align-items: center;
  display: inline-flex;
  margin: ${({ margin }) => margin ?? ''};

  span {
    &:nth-child(2) {
      margin-left: 8px;
    }
  }
`;

export const StyledTag = styled(Tag)<{ $borderStyle?: string }>`
  &.ant-tag {
    margin-right: 0;
    ${({ $borderStyle }) =>
      $borderStyle &&
      `
      border: ${$borderStyle};
    `};
  }
`;

export const CriticalIcon = styled(WarningFilled)`
  color: ${COLORS.RED};
  cursor: pointer;
  font-size: 1.25rem;
`;

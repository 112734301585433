import { Select } from 'antd';

import {
  useGetSubComponentOptionsForChooserQuery,
  AtlasGqlSortDirection,
  AtlasGqlFilterOperator,
} from 'types/atlas-graphql';
import { useAssetChooserState } from 'components/choosers/AssetChooser/useAssetChooserState';
import { SuffixIcon, ClearIcon } from '../styles';
import { filterOption } from '../../helpers';

export interface ISubComponentChooserProps {
  disabled: boolean;
  value?: string[]; // Currently selected items
  assetTypeNames?: string[]; // Array of asset type names to filter for
  assetParentIds?: string[]; // Array of asset UUIDs to query the children of
  onChange: any;
  mode?: 'multiple' | 'tags' | undefined;
}

export const SubComponentChooser: React.FunctionComponent<ISubComponentChooserProps> = ({
  disabled,
  value,
  assetTypeNames = [],
  assetParentIds = [],
  onChange,
  mode = 'multiple',
}) => {
  const { data, loading } = useGetSubComponentOptionsForChooserQuery({
    skip: disabled,
    variables: {
      filterBy: [
        {
          key: 'assetTypeNames',
          values: assetTypeNames,
          operator: AtlasGqlFilterOperator.Equals,
        },
        {
          key: 'childrenOfIds',
          values: assetParentIds,
          operator: AtlasGqlFilterOperator.Equals,
        },
      ],
      sortBy: [
        {
          key: 'typeName',
          sort: AtlasGqlSortDirection.Asc,
        },
      ],
    },
  });

  const { handleChange, setVisible, chooserState } = useAssetChooserState({
    onChange,
    value,
  });

  return (
    <Select
      id="sub-component-chooser"
      placeholder="Select Component..."
      loading={loading}
      allowClear
      showSearch
      filterOption={filterOption}
      mode={mode}
      maxTagCount={1}
      value={loading ? undefined : chooserState}
      onChange={handleChange}
      onDropdownVisibleChange={setVisible}
      disabled={disabled}
      showArrow={chooserState ? false : true}
      clearIcon={<ClearIcon />}
      suffixIcon={<SuffixIcon />}
    >
      {data?.assetsWithMetadata?.items?.map(asset => (
        <Select.Option key={asset.id} className="sub-component-chooser-option" value={asset.id}>
          {asset.uniqueIdLabel}
        </Select.Option>
      ))}
    </Select>
  );
};

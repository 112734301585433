import { Fragment, useState, useEffect } from 'react';
import { Button } from 'components/ui';
import {
  ColumnContainer,
  FormActions,
  FormActionsRight,
  ToggleWrapper,
  StyledCheckbox,
} from './ColumnPicker.style';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { ColumnListItem } from './ColumnListItem';
import { reorder } from 'utils/reorder';
import { formatColumns } from './helpers/formatColumns';

export function ColumnPicker({ columns = [], customizedColumnSet, onClose }) {
  const [orderedColumns, setOrderedColumns] = useState(getInitialColumns());
  const [checked, setChecked] = useState(columns.every(column => column.visible));
  const [indeterminate, setIndeterminate] = useState(columns.some(column => column.visible));

  useEffect(() => {
    if (orderedColumns.every(column => !column.visible)) {
      setChecked(false);
      setIndeterminate(false);
    } else if (orderedColumns.every(column => column.visible)) {
      setIndeterminate(false);
      setChecked(true);
    } else {
      // some, but not all checked
      setChecked(false);
      setIndeterminate(true);
    }
  }, [orderedColumns]);

  function getDefaultColumns() {
    return formatColumns(columns);
  }

  function getInitialColumns() {
    return customizedColumnSet ? formatColumns(customizedColumnSet) : getDefaultColumns();
  }

  function toggleVisibility(toggledColumn) {
    const newColumns = [...orderedColumns];
    const index = newColumns.findIndex(column => column.title === toggledColumn.title);
    newColumns[index].visible = !toggledColumn.visible;
    setOrderedColumns(newColumns);
  }

  function toggleAll(value) {
    const newColumns = [...orderedColumns];
    newColumns.forEach(column => {
      if (column.fixed || column.hidden) {
        column.visible = true;
      } else {
        column.visible = value;
      }
    });
    setOrderedColumns(newColumns);
  }

  function handleToggleAllClick() {
    if (checked) {
      setChecked(false);
      toggleAll(false);
    } else if (indeterminate) {
      setChecked(true);
      toggleAll(true);
    } else {
      // check all
      setChecked(true);
      toggleAll(true);
    }
  }

  function renderColumns() {
    return orderedColumns.map((column, index) => (
      <ColumnListItem
        key={column.key}
        index={index}
        column={column}
        toggleVisibility={toggleVisibility}
      />
    ));
  }

  function handleDragEnd(result) {
    if (!result.destination) return;

    if (result.destination.index === result.source.index) return;

    const newColumns = reorder(orderedColumns, result.source.index, result.destination.index);
    setOrderedColumns(newColumns);
  }

  function handleSave() {
    onClose({ orderedColumns });
  }

  function disableSave() {
    return !orderedColumns.some(column => column.visible === true);
  }

  function handleReset() {
    setOrderedColumns(getDefaultColumns());
  }

  return (
    <Fragment>
      <ColumnContainer>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="columnList">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {renderColumns()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ColumnContainer>
      <ToggleWrapper>
        <StyledCheckbox
          checked={checked}
          indeterminate={indeterminate}
          onClick={handleToggleAllClick}
        />
        Toggle all columns
      </ToggleWrapper>
      <FormActions>
        <Button _version={4} onClick={handleReset}>
          Reset
        </Button>
        <FormActionsRight>
          <Button _version={4} onClick={onClose}>
            Cancel
          </Button>
          <Button _version={4} type="primary" onClick={handleSave} disabled={disableSave()}>
            Save
          </Button>
        </FormActionsRight>
      </FormActions>
    </Fragment>
  );
}

import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { storage } from '../storage';

const getItemFromStorage = <V>(key: string, initialValue: V) => {
  const value = storage.getItem(key);
  if (value) {
    return value;
  }
  return initialValue;
};

export const currentCustomerAtom = atomWithStorage<string | undefined>('customerId', undefined, {
  getItem: getItemFromStorage,
  setItem: storage.setItem,
  removeItem: storage.removeItem,
  subscribe: (key, set, initialValue) => {
    if (typeof window === 'undefined' || typeof window.addEventListener === 'undefined') {
      return () => {};
    }

    const storageEventListener = (storageEvent: StorageEvent) => {
      if (storageEvent.storageArea === localStorage && storageEvent.key === key) {
        set(storageEvent.newValue ?? initialValue);
      }
    };
    window.addEventListener('storage', storageEventListener);

    return () => {
      window.removeEventListener('storage', storageEventListener);
    };
  },
});

export const activeCustomerAtom = atomWithStorage<string | undefined>(
  'customerId:active',
  undefined,
  {
    getItem: getItemFromStorage,
    setItem: storage.setItem,
    removeItem: storage.removeItem,
  }
);

export const customerIdMismatchAtom = atom(get => {
  const currentCustomer = get(currentCustomerAtom);
  const activeCustomer = get(activeCustomerAtom);
  return Boolean(currentCustomer && activeCustomer && currentCustomer !== activeCustomer);
});

export const releaseTogglesDisabledAtom = atomWithStorage<string[]>(
  'disabled-release-toggles',
  storage.getItem('disabled-release-toggles') ?? []
);

export const releaseTogglesAtom = atom<string[]>([]);

export const featuresAtom = atom<Record<string, boolean>>({});

import { useAtom } from 'jotai';
import { activeCustomerAtom, currentCustomerAtom, customerIdMismatchAtom } from './atoms';

export const useCurrentCustomerId = () => {
  return useAtom(currentCustomerAtom);
};

export const useActiveCustomerId = () => {
  return useAtom(activeCustomerAtom);
};

export const useCustomerIdMismatch = () => {
  return useAtom(customerIdMismatchAtom);
};

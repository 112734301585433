import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AuthContext, { LogoutArgs } from './AuthContext';
import { storage } from 'utils/storage';
import Bugsnag from '@bugsnag/js';
import { decodeJwt } from 'jose';
import { pick } from 'lodash';
import { isDev, isLocal } from '../env';
import { AccountDataMessageTypes, channel } from '../account/channels';

export const AUTH0_SCOPE = 'openid profile email offline_access';

interface Props {
  children: React.ReactNode;
}

function AuthContextProvider({ children }: Props) {
  const auth0 = useAuth0();
  const { getAccessTokenSilently, logout: auth0Logout } = auth0;
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    // Add a convenient way to force a React refresh in local and dev for debugging
    if (isLocal || isDev) {
      // @ts-ignore
      window.incrementCounter = () => setCounter(c => c + 1);
    }
  }, []);

  const getAuthHeader = useCallback(async () => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        // the `scope` param is not actually sent with the request, so when
        // refreshing a token and the `email` scope does not exist, the `email`
        // claim does not get added to the token. this workaround allows us to
        // send in the "scope" and the auth0 "Enhance Claim" action will check
        // its existence
        //
        // @see https://community.auth0.com/t/original-granted-scopes-not-not-available-in-rules-action-when-refreshing-token/72419
        // @see https://github.com/auth0/auth0-spa-js/issues/896
        _scope: AUTH0_SCOPE,
      },
    });
    Bugsnag.addMetadata(
      'Access Token',
      pick(decodeJwt(token), ['aud', 'azp', 'iat', 'exp', 'iss', 'scope', 'sub'])
    );
    return { Authorization: `Bearer ${token}` };
  }, [getAccessTokenSilently]);

  // the logout function will almost never be called directly except by logoutAndBroadcast
  const logout = useCallback(
    (args?: LogoutArgs) => {
      // Delete all cookies https://stackoverflow.com/a/27374365
      document.cookie.split(';').forEach(cookie => {
        document.cookie = cookie
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/');
      });

      storage.removeItems(['customerId']);

      // if broadcast is truthy then publish message to other windows to logout the user
      if (args?.broadcast) {
        channel.postMessage({
          type: AccountDataMessageTypes.USER_LOGOUT,
        });
      }

      auth0Logout({
        logoutParams: {
          // auth0 logout is configured to return to /auth_redirect
          returnTo: `${window.location.origin}/auth_redirect`,
        },
      });
    },
    [auth0Logout]
  );

  return (
    <AuthContext.Provider value={{ ...auth0, getAuthHeader, logout, counter }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;

import { Fragment } from 'react';
import { capitalize, isNil, isNumber, range, get, round, isFinite } from 'lodash';
import { Tag, Select, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { DebouncedInputNumber } from 'components/inputs';
import { getDecimalPlaces } from 'utils/math';
import { UTCDatePicker } from 'components/UTCDatePicker';

import moment from 'moment';

import { SeverityAndCriticalIndicator } from 'components/SeverityAndCriticalIndicator';
import { getFilter, getOnFilter, getFilterDropdown } from 'components/data/helpers/filters';

import { sortBy, None, isDate } from 'components/data/helpers';
import { AtlasGqlDamageSchemaAttributeUnit } from 'types/atlas-graphql';
import {
  getPrimaryObservationGroupForDamage,
  getObservationGroupByInspectionId,
  getTaskTargetInspectionId,
} from 'horizon/components/Damages/utils';

const handleChange = (val, key, type, onChange) => {
  if (!onChange) return;
  const typeAttrs = {
    ...(type === 'string' ? { string: val } : {}),
    ...(type === 'number' ? { number: val && parseFloat(val) } : {}),
    ...(type === 'date' ? { date: val } : {}),
  };
  onChange({ name: key, ...typeAttrs });
};

const getSelectOptions = ({ type, values, step }) => {
  const [minValue, maxValue] = values || [];
  return type === 'number'
    ? range(parseInt(minValue.label), parseInt(maxValue) + 1, step)
    : values || [];
};

const getDateMinMax = ({ values }) => {
  const [minValue, maxValue] = values || [];

  const { id: min } = minValue || {};
  const { id: max } = maxValue || {};

  return [min, max];
};

const getMinMax = ({ min: inMin, max: inMax, values }) => {
  const [minValue, maxValue] = values || [];
  const { id: min } = minValue || {};
  const { id: max } = maxValue || {};

  const parsedMin = inMin !== undefined ? inMin : parseFloat(min);
  const parsedMax = inMax !== undefined ? inMax : parseFloat(max);

  return [!isNaN(parsedMin) ? parsedMin : undefined, !isNaN(parsedMax) ? parsedMax : undefined];
};

const UnitSpan = styled.em`
  color: #808080;
`;

const getUnit = ({ unit }) => {
  switch (unit) {
    case AtlasGqlDamageSchemaAttributeUnit.Meter:
      return <UnitSpan>m</UnitSpan>;
    case AtlasGqlDamageSchemaAttributeUnit.SquareMeter:
      return (
        <UnitSpan>
          m<sup>2</sup>
        </UnitSpan>
      );
    case AtlasGqlDamageSchemaAttributeUnit.Percent:
      return <UnitSpan>%</UnitSpan>;
    default:
      return null;
  }
};

const getDescription = ({ description }) => {
  return capitalize(description);
};

const HelpTooltip = styled(Tooltip)`
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  cursor: pointer;
`;

export const attrColumns = {
  Severity: config => {
    const { definition } = config;
    // using the definitionMax for what the damage _could_ be
    // (e.g. a damage defined prior to schema updates )
    // and the configMin and configMax for what the damage _should_ be
    // as defined by the currently set damage schema.
    const [configMin, configMax] = getMinMax(config);
    const [, definitionMax] = getMinMax(definition || {});
    return {
      // added config here so we could use this config on the WorkOrderTasksTable column for Severity
      config: config,
      title: 'Severity',
      key: 'Severity',
      allKeys: ['critical'],
      dataIndex: ['attrs', 'Severity'],
      sorter: sortBy('attrs.Severity'),
      filters: getFilter(config),
      filterDropdown: getFilterDropdown(config),
      filterProperty: 'attrs.Severity',
      onFilter: getOnFilter({ ...config, key: `attrs.Severity` }),
      additionalFilterFunctions: {
        critical: (value, record) => {
          const isCritical = !!getObservationGroupByInspectionId(
            record.horizonDamage,
            getTaskTargetInspectionId(record.targets) ?? ''
          )?.groupCritical;

          if (value === 'true') {
            return isCritical;
          } else if (value === 'false') {
            return !isCritical;
          } else {
            return true;
          }
        },
      },
      type: config.type,
      get: record => get(record, ['attrs', 'Severity']),
      render(Severity, record) {
        /**
         * This is a slighly hacky way of getting the correct critical
         * value, depending on whether the parent object (from the table)
         * is a damage (need the primary group's critical value) or a task
         * (need the locked group's critical value)
         */
        const isCritical =
          record?.critical ??
          ((record?.hasOwnProperty('observationGroups') &&
            getPrimaryObservationGroupForDamage(record)?.groupCritical) ||
            (['DamageInspectTask', 'DamageRepairTask'].includes(record?.__typename) &&
              record?.hasOwnProperty('horizonDamage') &&
              getObservationGroupByInspectionId(
                record.horizonDamage,
                getTaskTargetInspectionId(record.targets)
              )?.groupCritical));

        /**
         * Due to the nature of the buried column definitions, it'll be simpler to
         * check the critical release toggle inside the severity tag component. During
         * rollout, that component will conditionally revert to the legacy one.
         */
        return (
          <SeverityAndCriticalIndicator
            value={Severity}
            isCritical={isCritical}
            maxValue={definitionMax}
            criticalLevel={record?.criticalLevel}
          />
        );
      },
      edit(record, { onChange, loading, ...props }) {
        const { allowClear, REQUIRE_VALID_DAMAGE, ...inputNumberProps } = props;

        const { step = 1 } = config || {};
        const value = record?.attrs?.Severity;
        const { editable } = record;

        return (
          <DebouncedInputNumber
            data-testid="damage-number-input-severity"
            loading={loading}
            value={value}
            min={configMin}
            max={configMax}
            step={step}
            onChange={val => {
              handleChange(val, 'Severity', config.type, onChange);
            }}
            {...inputNumberProps}
            disabled={
              !editable ||
              (REQUIRE_VALID_DAMAGE &&
                !(isFinite(configMin) && isFinite(configMax)) &&
                !isFinite(value))
            }
          />
        );
      },
    };
  },

  Tags: config => ({
    title: 'Tags',
    key: 'Tags',
    sorter: sortBy('attrs.Tags[0]'),
    filterProperty: 'attrs.Tags',
    filters: getFilter(config),
    type: config.type,
    render: ({ attrs = {} }) => {
      const { Tags } = attrs;
      if (!Tags) return <None />;

      return (
        <Fragment>
          {Tags.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </Fragment>
      );
    },
  }),

  Generic: ({ key, ...config } = {}) => {
    const { definition } = config;
    const description = getDescription(config);
    const unit = getUnit(config);
    const tooltip = description && (
      <HelpTooltip title={description} placement="right">
        <QuestionCircleOutlined />
      </HelpTooltip>
    );

    return {
      // added config here so we could use this config on the WorkOrderTasksTable column for Damage Type
      config: config,
      key,
      title: (
        <span>
          {key}
          {tooltip}
        </span>
      ),
      sorter: sortBy(`attrs.${key}`),
      get: record => get(record, ['attrs', key]),
      filters: getFilter(config),
      filterDropdown: getFilterDropdown(config),
      onFilter: getOnFilter({ key: `attrs.${key}`, ...config }),
      filterProperty: `attrs.${key}`,
      dataIndex: ['attrs', key],
      type: config.type,
      description,
      unit,
      render(record) {
        if (isNil(record)) return <None />;
        if (Array.isArray(record)) return record.join(', ');
        if (typeof record === 'string' && !isDate(record)) return record;

        let value;
        if (isNumber(record) && isNumber(definition?.delta)) {
          const decimalPlaces = getDecimalPlaces(definition?.delta);
          value = round(record, decimalPlaces);
        } else {
          value = record;
        }

        return (
          <span>
            {value.toString()}
            <span style={{ paddingLeft: '0.2rem' }}>{unit}</span>
          </span>
        );
      },
      edit(record, { onChange, loading, ...props }) {
        const { type, step = 1 } = config || {};
        const value = record?.attrs?.[key];
        const { editable } = record;

        if (type === 'number') {
          const { allowClear, REQUIRE_VALID_DAMAGE, ...inputNumberProps } = props;

          let [min, max] = getMinMax(config);
          const parsedValue = isNil(value) ? undefined : parseFloat(value);
          if (min > parsedValue) min = parsedValue;
          if (max < parsedValue) max = parsedValue;

          return (
            <DebouncedInputNumber
              data-testid={`damage-number-input-${key}`}
              loading={loading}
              value={parsedValue}
              min={min}
              max={max}
              step={step}
              onChange={val => {
                handleChange(val, key, config.type, onChange);
              }}
              {...inputNumberProps}
              disabled={
                !editable ||
                (REQUIRE_VALID_DAMAGE &&
                  !(isFinite(min) && isFinite(max)) &&
                  !isFinite(parsedValue))
              }
            />
          );
        }

        // FIXME: cant figure out how to incorporate "width" in editabledamageattributes
        if (type === 'date') {
          const { REQUIRE_VALID_DAMAGE } = props;
          const [min, max] = getDateMinMax(config);

          return (
            <UTCDatePicker
              data-testid={`damage-date-picker-${key}`}
              placeholder={null}
              loading={loading}
              disabledDate={current =>
                current &&
                (current < moment.utc(min).startOf('day') || current > moment.utc(max).endOf('day'))
              }
              value={value ? moment(value) : undefined}
              onChange={(date, dateString) =>
                handleChange(dateString || undefined, key, config.type, onChange)
              }
              style={{ width: '100%' }}
              {...props}
              disabled={
                !editable ||
                moment.utc(min).startOf('day') > moment.utc(max).endOf('day') ||
                (REQUIRE_VALID_DAMAGE && isNil(min) && isNil(max) && !value)
              }
              getPopupContainer={triggerNode => triggerNode.parentElement}
            />
          );
        }

        const options = getSelectOptions(config);

        return (
          <Select
            data-testid={`damage-select-${key}`}
            loading={loading}
            dropdownMatchSelectWidth={false}
            value={value === null ? undefined : value}
            {...(options.length === 1 ? { placeholder: options[0].label } : {})}
            onChange={val => handleChange(val, key, config.type, onChange)}
            {...props}
            disabled={!editable || (!options.length && !value)}
            getPopupContainer={triggerNode => triggerNode.parentElement}
          >
            {options.map(value => (
              <Select.Option key={value.id} value={value.id}>
                {value.label}
              </Select.Option>
            ))}
          </Select>
        );
      },
    };
  },
};

export function extractAssetIdFromDamageSchema(damage: any): string {
  if (!damage) return '';
  let observations = [];
  observations =
    'getDamageHeads' in damage ? damage?.getDamageHeads[0].observations : damage?.observations;
  if (!observations) return '';
  const assetIds = observations.map((obs: any) => {
    if (obs.__typename === 'Annotation' || obs.__typename === 'HorizonAnnotation') {
      return obs.assetId;
    } else if (obs.__typename === 'Picture') {
      switch (obs.target.__typename) {
        case 'TargetBLADE_EXTERNAL':
        case 'TargetBLADE_INTERNAL': {
          return obs.target?.bladeAsset?.id;
        }
        case 'TargetTOWER_EXTERNAL': {
          return obs.target?.tower?.id;
        }
        case 'TargetTRANSITION_PIECE': {
          return obs.target.transitionPiece ? obs.target.transitionPiece?.id : '';
        }
        default: {
          return '';
        }
      }
    }
  });
  return assetIds ? assetIds[0] : '';
}

import { CheckCircleOutlined, WarningOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Button } from 'components/ui';
import {
  ContentHeader,
  StyledLink,
  StyledContent,
  StyledDate,
  StyledHeader,
  StyledItem,
  StyledList,
  StyledMessage,
} from './NotificationsPopover.style';
import { useMutation } from 'utils/apollo';
import COLORS from 'utils/color/definitions';
import UPDATE_NOTIFICATIONS from 'components/Notifications/queries/UpdateNotifications.gql';
import { AtlasGqlNotification } from 'types/atlas-graphql';

interface NotificationIconProps {
  type: string;
}

const NotificationIcon = ({ type }: NotificationIconProps) => {
  if (type === 'SUCCESS') {
    return <CheckCircleOutlined className="status-icon" style={{ color: COLORS.GREEN }} />;
  }
  if (type === 'ERROR') {
    return <WarningOutlined className="status-icon" style={{ color: COLORS.RED }} />;
  }

  return <InfoCircleOutlined className="status-icon" style={{ color: COLORS.TEAL }} />;
};

const fromNowDisplay = (date: string) => moment(date).fromNow();

interface StyledLinkItemProps {
  children: React.ReactNode;
  href: string;
  onClick: () => Promise<unknown>;
  unread: boolean;
}

function StyledLinkItem({ href, children, ...props }: StyledLinkItemProps) {
  const { pathname, search } = new URL(href) || {};

  return (
    <StyledItem hasLink={Boolean(href)} {...props}>
      {href ? (
        <StyledLink to={`${pathname}${search}`} replace>
          {children}
        </StyledLink>
      ) : (
        children
      )}
    </StyledItem>
  );
}

export interface NotificationsPopoverProps {
  notifications: AtlasGqlNotification[];
}

export function NotificationsPopover({ notifications }: NotificationsPopoverProps) {
  const [updateNotifications] = useMutation(UPDATE_NOTIFICATIONS);

  const hasUnread = notifications.some(({ isRead }) => !isRead);

  async function handleMarkAllAsRead() {
    const updatedNotifications = notifications.map(({ id }) => ({ id, isRead: true }));
    await updateNotifications({ variables: { inputs: updatedNotifications } });
  }

  async function handleMarkAsRead(id: string, isRead: boolean) {
    if (!isRead) {
      await updateNotifications({ variables: { inputs: [{ id, isRead: true }] } });
    }
  }

  return (
    <StyledContent>
      <ContentHeader>
        <StyledHeader>Notifications</StyledHeader>
        <Button
          _version={4}
          type="link"
          size="small"
          onClick={handleMarkAllAsRead}
          disabled={!hasUnread}
        >
          Mark all as read
        </Button>
      </ContentHeader>
      <StyledList>
        {notifications.map(({ message, id, isRead, type, timestamp, link }) => (
          <StyledLinkItem
            key={id}
            unread={!isRead}
            href={link ? link : ''}
            onClick={() => handleMarkAsRead(id, isRead)}
          >
            <NotificationIcon type={type} />
            <StyledMessage>
              <p data-testid="notification-message">{message}</p>
              <StyledDate>{fromNowDisplay(timestamp)}</StyledDate>
            </StyledMessage>
          </StyledLinkItem>
        ))}
      </StyledList>
    </StyledContent>
  );
}

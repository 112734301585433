import styled from 'styled-components';

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;

  button:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

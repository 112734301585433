import { useEffect } from 'react';
import { message } from 'antd';
import { useFeatures } from 'utils/features';

export const useNeitherDamagesFlagAlert = () => {
  const { features, loading } = useFeatures();
  const { HORIZON_DAMAGES, LEGACY_DAMAGES } = features;

  useEffect(() => {
    if (!loading && !HORIZON_DAMAGES && !LEGACY_DAMAGES) {
      message.error('Sorry, your account is in a bad state. Please contact support.');
    }
  }, [HORIZON_DAMAGES, LEGACY_DAMAGES, loading]);
};

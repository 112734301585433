export const IMAGE_ADD = 'IMAGE_ADD';
export const IMAGE_REMOVE = 'IMAGE_REMOVE';
export const IMAGE_UPDATE = 'IMAGE_UPDATE';
export const IMAGES_CLEAR = 'IMAGES_CLEAR';

interface AddImageAction<T = any> {
  type: 'IMAGE_ADD';
  image: T;
}

interface RemoveImageAction {
  type: 'IMAGE_REMOVE';
  index: number;
}

interface UpdateImageAction<T = any> {
  type: 'IMAGE_UPDATE';
  index: number;
  updates: T;
}

interface ClearImagesAction {
  type: 'IMAGES_CLEAR';
}

export type DamageViewerAction =
  | AddImageAction
  | RemoveImageAction
  | UpdateImageAction
  | ClearImagesAction;

export const addImage = <T>(image: T) => {
  return {
    type: IMAGE_ADD,
    image,
  };
};

export const removeImage = (index: number) => {
  return {
    type: IMAGE_REMOVE,
    index,
  };
};

export const updateImage = <T>(updates: T, index: number) => {
  return {
    type: IMAGE_UPDATE,
    updates,
    index,
  };
};

export const clearImages = () => {
  return {
    type: IMAGES_CLEAR,
  };
};

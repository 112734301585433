import styled from 'styled-components';
import COLORS from 'utils/color/definitions';
import { CloseCircleOutlined, DownOutlined } from '@ant-design/icons';

export const ClearIcon = styled(CloseCircleOutlined)`
  &:hover {
    color: ${COLORS.RED};
  }
`;

export const SuffixIcon = styled(DownOutlined).attrs({
  className: 'ant-select-suffix',
})``;

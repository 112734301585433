import { TTableColumnDef } from 'horizon/types/TableColumnDef';
import {
  EColumnTypeOptions,
  TTextColumnFilter,
  TTextColumnConfig,
  TNumberColumnFilter,
  TNumberColumnConfig,
  TDateColumnConfig,
} from './types';
import { getFilterDropdown, getOnFilter, sortBy } from '../helpers';

/**
 * Function that can be used to dynamically generate number column shape for an asset type, with a range selector filter
 * @param key - String that corresponds to the key that the data for this column will be mapped to.
 * @param title - String to display as the column's name.
 * @param enableSorting - Flag to determine whether the column should have sorting enabled.
 * @param fieldOptions - Array of filter value numbers, the min and max values will be used to determine the min and max of the range selector.
 * @param step - Optional number parameter to set the step value of the range selector. If no value provided, defaults to 0.1
 * @returns A column for the provided field data.
 */
export function generateNumberColumn(
  key: string,
  title: string,
  enableSorting: boolean,
  fieldOptions: TNumberColumnFilter[],
  step: number | null = null
): TTableColumnDef {
  return {
    key,
    title,
    dataIndex: title,
    filterDropdown: getFilterDropdown(getNumberFieldDropdownOptionConfig(key, fieldOptions, step)),
    filterMultiple: false,
    filterProperty: key,
    sorter: enableSorting ? sortBy(key) : false,
  };
}

/**
 * Function that can be used to dynamically generate text column shape for an asset type, with a select dropdown filter
 * @param key - String that corresponds to the key that the data for this column will be mapped to.
 * @param title - String to display as the column's name.
 * @param enableSorting - Flag to determine whether the column should have sorting enabled.
 * @param fieldOptions - Array of TColumnFilter options to use as the selectable filter options in the dropdown.
 * @returns A column for the provided field data.
 */
export function generateSelectColumn(
  key: string,
  title: string,
  enableSorting: boolean,
  fieldOptions: TTextColumnFilter[]
): TTableColumnDef {
  return {
    key,
    title,
    dataIndex: title,
    filters: fieldOptions,
    filterDropdown: getFilterDropdown(getSelectFieldDropdownOptionConfig(key, fieldOptions)),
    filterMultiple: false,
    filterProperty: key,
    sorter: enableSorting ? sortBy(key) : false,
  };
}

export function generateSelectColumnFull(
  key: string,
  title: string,
  dataIndex: string,
  enableSorting: boolean,
  fieldOptions: TTextColumnFilter[],
  filterMultiple: boolean,
  filterProperty: string
): TTableColumnDef {
  return {
    key,
    title,
    dataIndex,
    filters: fieldOptions,
    filterDropdown: getFilterDropdown(getSelectFieldDropdownOptionConfig(key, fieldOptions)),
    filterMultiple,
    filterProperty,
    sorter: enableSorting ? sortBy(key) : false,
  };
}

export function generateDateColumn(
  key: string,
  title: string,
  dataIndex: string,
  enableSorting: boolean
): TTableColumnDef {
  const filterConfig = getDateFieldDropdownOptionConfig(key);
  return {
    key,
    title,
    dataIndex,
    filterDropdown: getFilterDropdown(filterConfig),
    //@ts-ignore - JD filterConfig is missing a few optional fields for getOnFilter.
    onFilter: getOnFilter(filterConfig),
    filterMultiple: false,
    filterProperty: key,
    sorter: enableSorting ? sortBy(key) : false,
  };
}

function getSelectFieldDropdownOptionConfig(
  key: string,
  fieldOptions: TTextColumnFilter[]
): TTextColumnConfig {
  return {
    key,
    type: EColumnTypeOptions.STRING,
    options: fieldOptions.map(x => x.text),
    exactMatch: false,
    includeNoneOption: true,
    noneOptionValue: 'IS_NULL',
  };
}

/**
 * @param key - String that corresponds to the key that the data for this column will be mapped to.
 * @param options - Array of filter value numbers, the min and max values will be used to determine the min and max of the range selector.
 * @param step - Optional parameter to indicate the step incremement to be used in the filter.
 * @returns The config for a number column filter.
 */
function getNumberFieldDropdownOptionConfig(
  key: string,
  options: TNumberColumnFilter[],
  step: number | null = null
): TNumberColumnConfig {
  return {
    key,
    type: EColumnTypeOptions.NUMBER,
    values: options,
    exactMatch: false,
    includeNoneOption: true,
    noneOptionValue: 'IS_NULL',
    step,
  };
}

export function getDateFieldDropdownOptionConfig(key: string): TDateColumnConfig {
  return {
    key,
    type: EColumnTypeOptions.DATE,
    exactMatch: false,
    includeNoneOption: true,
    noneOptionValue: 'IS_NULL',
  };
}

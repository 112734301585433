import { Image } from '../ImageViewer/Image';
import { isFinite, orderBy } from 'lodash';
import {
  AtlasGqlHorizonDamageObservation,
  AtlasGqlImageRgb,
  AtlasGqlPicture,
  AtlasGqlTarget,
  AtlasGqlTargetBlade_External,
} from 'types/atlas-graphql';
import { BladeTarget, TowerOrTransitionPieceTarget } from 'horizon/routes/Inspections/types';
import {
  ThumbnailContainer,
  AttributesContainer,
  AttributesContainerZoomable,
  DistanceTag,
  ThumbnailImage,
  StyledSvg,
  StyledPolygon,
  RotationContainer,
  ExpandButton,
  ExpandButtonContainer,
  LazyLoadContainer,
} from './ImageThumbnail.style';
import { useRef, useState } from 'react';
import { DamageTooltip } from 'components/damages/DamageTooltip';
import { Spinner } from 'components/ui';
import { getRotation } from 'components/ImageViewer/ImageViewer';
import { ModalLink, useHistory } from 'utils/router';
import {
  SeverityAndCriticalIndicator,
  CriticalLevel,
} from 'components/SeverityAndCriticalIndicator';
import { useResizeObserver } from 'utils/hooks';
import { FullscreenOutlined } from '@ant-design/icons';

export type ImageThumbnailProps = {
  picture: Pick<AtlasGqlPicture, 'id' | 'filename' | 'homography' | 'image' | 'target'>;
  observations?: AtlasGqlHorizonDamageObservation[];
  allowZoom?: boolean;
  shouldRotate?: boolean;
  scaleFilter?: number;
  aspectRatio?: string;
  scrollParent?: Element | null;
  scrollParentSelector?: string;
  shouldShowSeverity?: boolean;
  shouldShowDistance?: boolean;
  primaryIndicator?: React.ReactNode;
  $isInCarousel?: boolean;
};

export const ImageThumbnail: React.FunctionComponent<ImageThumbnailProps> = ({
  picture,
  observations,
  allowZoom = false,
  shouldRotate = false,
  scaleFilter,
  aspectRatio,
  scrollParent,
  scrollParentSelector,
  shouldShowSeverity,
  shouldShowDistance,
  primaryIndicator,
  $isInCarousel = false,
}) => {
  const history = useHistory();
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [scale, setScale] = useState<number>(0);
  const imageRef = useRef<HTMLImageElement>(null);
  const imageRect: DOMRectReadOnly | undefined = useResizeObserver(imageRef);

  const { target, image, filename, homography } = picture;
  const radialDistance = (target as BladeTarget)?.radialDistance;
  const height = (target as TowerOrTransitionPieceTarget)?.height;
  const { signedUrl, signedThumbnailUrl } = image as AtlasGqlImageRgb; // TODO: figure out the types here - does this work?
  const url = signedUrl?.url;
  const thumbnailUrl = signedThumbnailUrl?.url;
  const rotationDegrees: number =
    target &&
    (target as AtlasGqlTarget & { __typename: string }).__typename === 'TargetBLADE_EXTERNAL'
      ? getRotation(target as AtlasGqlTargetBlade_External)
      : 0;

  const nf = new (Intl.NumberFormat as any)(undefined, {
    maximumFractionDigits: 1,
    unit: 'meter',
    unitDisplay: 'narrow',
    style: 'unit',
  });

  const handleLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setScale(event.currentTarget.height / event.currentTarget.width);
    setImageLoaded(true);
  };

  // The main intent here is to override any exif data that might
  // cause the image to rotate unnecesarily.
  // https://sharp.pixelplumbing.com/api-resize#resize
  const fullSizeImageWithoutEXIF = `${url}&fit=inside`;

  const highestSeverityObservation: AtlasGqlHorizonDamageObservation | undefined = orderBy(
    observations,
    ({ attrs }) => (attrs?.Severity ? Number(attrs.Severity) : 0),
    'desc'
  )[0];
  const highestSeverity: number | undefined = highestSeverityObservation?.attrs?.Severity;
  const isCritical: boolean = !!observations?.some(({ critical }) => !!critical);
  const picturePath: string = !!highestSeverityObservation
    ? `/pictures/${picture.id}?observation=${highestSeverityObservation.id}`
    : `/pictures/${picture.id}`;

  const handleClickObservation = (observationId: string) => {
    history.push(`/pictures/${picture.id}${observationId ? `?observation=${observationId}` : ''}`, {
      useModal: true,
    });
  };

  return (
    <ThumbnailContainer aspectRatio={aspectRatio}>
      {allowZoom ? (
        <>
          <Image
            isNewFullSizeImageViewer={false}
            isThumbnail={true}
            target={target}
            image={image}
            homography={homography}
            observations={(observations ?? []).map(o => ({
              ...o,
              selected: o.id === highestSeverityObservation?.id ? true : false,
            }))}
            setObservation={handleClickObservation}
            primaryIndicator={primaryIndicator}
            thumbnailHeaderAttributes={
              shouldShowSeverity || shouldShowDistance ? (
                <AttributesContainerZoomable>
                  {shouldShowSeverity && (!!highestSeverity || isCritical) && (
                    <SeverityAndCriticalIndicator
                      value={highestSeverity}
                      isCritical={isCritical}
                      margin={'0 0.5rem 0 0'}
                      criticalLevel={CriticalLevel.Observation}
                    />
                  )}
                  {shouldShowDistance && (
                    <DistanceTag data-testid="distance-tag">
                      {isFinite(radialDistance ?? height) ? (
                        `${nf.format(radialDistance ?? height)}`
                      ) : (
                        <em>N/A</em>
                      )}{' '}
                    </DistanceTag>
                  )}
                </AttributesContainerZoomable>
              ) : undefined
            }
          />
          <ModalLink to={picturePath}>
            <ExpandButton
              icon={<FullscreenOutlined />}
              $isInCarousel={$isInCarousel}
              title="Expand"
            />
          </ModalLink>
        </>
      ) : (
        <ModalLink style={{ height: '100%' }} to={picturePath}>
          <RotationContainer
            degrees={shouldRotate && rotationDegrees}
            data-private="logrocket-hide"
          >
            {scrollParent && (
              <LazyLoadContainer
                height={imageRect?.height}
                placeholder={<Spinner />}
                scrollContainer={scrollParentSelector}
              >
                {!imageLoaded && <Spinner />}
                <ThumbnailImage
                  ref={imageRef}
                  scaleFilter={scaleFilter}
                  alt={filename ?? `Picture ${picture.id}`}
                  onLoad={handleLoad}
                  src={
                    scaleFilter === 1
                      ? fullSizeImageWithoutEXIF || (thumbnailUrl as string)
                      : (thumbnailUrl as string) || fullSizeImageWithoutEXIF
                  }
                />
                {imageLoaded && (
                  <ExpandButtonContainer
                    height={imageRect?.height ? `${imageRect.height}px` : '100%'}
                    width={imageRect?.width ? `${imageRect.width}px` : '100%'}
                  >
                    <ExpandButton
                      icon={<FullscreenOutlined />}
                      $isInCarousel={$isInCarousel}
                      title="Expand"
                    />{' '}
                    <AttributesContainer>
                      {shouldShowSeverity && (!!highestSeverity || isCritical) && (
                        <SeverityAndCriticalIndicator
                          value={highestSeverity}
                          isCritical={isCritical}
                          margin={'0 0.5rem 0 0'}
                          criticalLevel={CriticalLevel.Image}
                        />
                      )}
                      {shouldShowDistance && (
                        <DistanceTag data-testid="distance-tag">
                          {isFinite(radialDistance ?? height) ? (
                            `${nf.format(radialDistance ?? height)}`
                          ) : (
                            <em>N/A</em>
                          )}{' '}
                        </DistanceTag>
                      )}
                    </AttributesContainer>
                  </ExpandButtonContainer>
                )}
                {!!scale && (
                  <StyledSvg viewBox={`0 0 1 ${scale}`}>
                    {observations?.map(({ id, coordinates }) => (
                      <DamageTooltip
                        key={id}
                        id={id}
                        isHorizonDamage={true}
                        onClick={() => {
                          handleClickObservation(id);
                        }}
                      >
                        <g>
                          {coordinates && (
                            <StyledPolygon
                              data-testid="damage-polygon"
                              pointerEvents="visible"
                              points={coordinates.map((xy: any) => xy.join(',')).join(' ')}
                            />
                          )}
                        </g>
                      </DamageTooltip>
                    ))}
                  </StyledSvg>
                )}
              </LazyLoadContainer>
            )}
          </RotationContainer>
        </ModalLink>
      )}
    </ThumbnailContainer>
  );
};

import './App.css';
import { lazy, Suspense as ReactSuspense, useEffect, useState } from 'react';
import COLORS from 'utils/color/definitions';

import { ConfigProvider } from 'antd5';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import GlobalFooter from 'components/GlobalFooter';
import { SideNav, useNavTabs } from 'components/SideNav';
import PageNotFoundRedirect from 'components/PageNotFoundRedirect';
import { Portals } from 'components/Portals';
import { Content, Layout, Spinner } from 'components/ui';
import { uuidRegex } from 'utils/constants';
import { notifyBugsnag } from 'utils/bugsnag';
import { usePermissions } from 'utils/usePermissions';
import { WORK_ORDER_COLLABORATOR } from 'utils/access-control/roles';
import { NEW_IMAGE_VIEWER } from 'utils/release-toggles';
import { Home } from './routes/Home';
import Reset from './routes/Reset';
import OrgRedirect from './components/OrgRedirect';
import { AcknowledgementModal } from 'components/AcknowledgementModal';
import { OrgMismatchModal } from './components/OrgMismatch';
import { Banner, GlobalBannerContext } from './components/GlobalBanner';
import { useCustomerIdMismatch } from 'utils/account/hooks';
import { useAccountContext } from 'utils/account/AccountContext';
import { AtlasGqlAcknowledgementType, useGetAcknowledgementsQuery } from 'types/atlas-graphql';

const AlertsRoot = lazy(() => import('./routes/Alerts/AlertsRoot'));
const ReportsRoot = lazy(() => import('./routes/Reports/ReportsRoot'));
const AnalyticsRoot = lazy(() => import('./routes/Analytics/AnalyticsRoot'));
const ApiExplorerRoot = lazy(() => import('./routes/ApiExplorer/ApiExplorerRoot'));
const AssetDetails = lazy(() => import('horizon/components/Assets/AssetDetails'));
const DeviceFirmwareRoot = lazy(() => import('./routes/DeviceFirmware/DeviceFirmwareRoot'));
const FleetRoot = lazy(() => import('./routes/Fleet/FleetRoot'));
const InspectionsRoot = lazy(() => import('./routes/Inspections/InspectionsRoot'));
const PicturesRoot = lazy(() => import('./routes/Pictures/PicturesRoot'));
const SettingsRoot = lazy(() => import('./routes/Settings/SettingsRoot'));
const TransmissionsLineRoot = lazy(
  () => import('./routes/TransmissionLines/TransmissionLinesRoot')
);
const WorkOrdersRoot = lazy(() => import('./routes/WorkOrders/WorkOrdersRoot'));

let previousLocation;

function SideNavWrapper() {
  const tabs = useNavTabs();

  return <SideNav tabs={tabs} />;
}

function Suspense({ children }) {
  return <ReactSuspense fallback={<Spinner />}>{children}</ReactSuspense>;
}

export default function App() {
  const history = useHistory();
  const location = useLocation();
  const useModal = location.state?.useModal ?? false;
  const [globalBannerMessage, setGlobalBannerMessage] = useState();
  const [showAcknowledgeModal, setShowAcknowledgeModal] = useState(false);
  const [customerIdMismatch] = useCustomerIdMismatch();
  const { hasReleaseToggle } = useAccountContext();

  const { data } = useGetAcknowledgementsQuery({
    variables: {
      acknowledgementType: AtlasGqlAcknowledgementType.Gdpr,
    },
    onCompleted: data => {
      if (data?.acknowledgements.length > 0) {
        setShowAcknowledgeModal(true);
      }
    },
    onError: error => {
      notifyBugsnag(`GetAcknowledgements error: ${error.message}`);
    },
  });

  const acknowledgementsByUser = data?.acknowledgements ?? {};

  const { HasRole } = usePermissions();
  const isWoCollaborator = HasRole({ role: WORK_ORDER_COLLABORATOR });

  useEffect(() => {
    if (history.action === 'REPLACE' || useModal) return;

    previousLocation = location;
  }, [location, history, useModal]);

  const fullRoutes = (
    <Switch location={useModal && previousLocation ? previousLocation : location}>
      <Route
        exact={true}
        path="/"
        render={props => {
          // handle old "hash" routes with a simple redirect
          const { hash } = props.location;
          return hash ? <Redirect to={hash.replace('#', '')} /> : <Home {...props} />;
        }}
      />

      <Route
        path="/analytics"
        render={() => (
          <Suspense>
            <AnalyticsRoot />
          </Suspense>
        )}
      />

      <Route
        path="/alerts"
        render={() => (
          <Suspense>
            <AlertsRoot />
          </Suspense>
        )}
      />
      <Route
        path={['/transmission-lines', '/transmission-line-uploads']}
        render={() => (
          <Suspense>
            <TransmissionsLineRoot />
          </Suspense>
        )}
      />

      <Route
        path="/settings"
        render={() => (
          <Suspense>
            <SettingsRoot />
          </Suspense>
        )}
      />

      <Route
        path="/device-firmware-management"
        render={() => (
          <Suspense>
            <DeviceFirmwareRoot />
          </Suspense>
        )}
      />

      <Route
        path="/fleet"
        render={() => (
          <Suspense>
            <FleetRoot />
          </Suspense>
        )}
      />

      <Route
        path={['/damages', '/damages2', '/inspections', '/inspection-campaigns']}
        render={() => (
          <Suspense>
            <InspectionsRoot />
          </Suspense>
        )}
      />

      <Route
        path={['/reports', '/report-deliveries']}
        render={() => (
          <Suspense>
            <ReportsRoot />
          </Suspense>
        )}
      />

      <Route
        path={[`/sites/:id${uuidRegex}`, `/turbines/:id${uuidRegex}`, `/blades/:id${uuidRegex}`]}
        render={({ location }) => {
          const assetsRoute = `/${['assets', ...location.pathname.split('/').slice(2)].join('/')}`;

          return <Redirect to={assetsRoute} />;
        }}
      />

      <Route
        path={[`/assets/:id${uuidRegex}`]}
        render={() => (
          <Suspense>
            <AssetDetails />
          </Suspense>
        )}
      />

      <Route
        path={[
          '/work-order-create',
          '/work-orders',
          '/campaigns',
          '/unplanned-tasks',
          '/tasks',
          '/recurring-task',
        ]}
        render={() => (
          <Suspense>
            <WorkOrdersRoot />
          </Suspense>
        )}
      />

      <Route
        path={['/pictures', '/picture-review']}
        render={() => (
          <Suspense>
            <PicturesRoot />
          </Suspense>
        )}
      />

      <Route exact={true} path="/reset" component={Reset} />

      <Route
        path="/api/:apiName"
        render={() => (
          <Suspense>
            <ApiExplorerRoot />
          </Suspense>
        )}
      />

      <Route path="/o/:orgId" render={() => <OrgRedirect />} />
      <Route component={PageNotFoundRedirect} />
    </Switch>
  );

  const woCollaboratorRoutes = (
    <Switch location={useModal && previousLocation ? previousLocation : location}>
      <Route
        exact={true}
        path="/"
        render={props => {
          // handle old "hash" routes with a simple redirect
          const { hash } = props.location;
          return hash ? <Redirect to={hash.replace('#', '')} /> : <Redirect to={'/work-orders'} />;
        }}
      />

      <Route
        path="/settings"
        render={() => (
          <Suspense>
            <SettingsRoot />
          </Suspense>
        )}
      />

      <Route
        path={['/work-orders', '/campaigns', '/unplanned-tasks', '/tasks']}
        render={() => (
          <Suspense>
            <WorkOrdersRoot />
          </Suspense>
        )}
      />

      <Route
        path={['/reports', '/report-deliveries']}
        render={() => (
          <Suspense>
            <ReportsRoot />
          </Suspense>
        )}
      />

      <Route
        path={[`/inspections/:id${uuidRegex}`]}
        render={() => (
          <Suspense>
            <InspectionsRoot />
          </Suspense>
        )}
      />

      <Route
        path={['/pictures']}
        render={() => (
          <Suspense>
            <PicturesRoot />
          </Suspense>
        )}
      />

      <Route exact={true} path="/reset" component={Reset} />

      <Route
        path="/api/:apiName"
        render={() => (
          <Suspense>
            <ApiExplorerRoot />
          </Suspense>
        )}
      />

      <Route path="/o/:orgId" render={() => <OrgRedirect />} />
      <Route component={PageNotFoundRedirect} />
    </Switch>
  );

  const stickySidebar = hasReleaseToggle('sticky_sidebar');

  return (
    <ConfigProvider
      // Documentation on the API for theme is here: https://ant.design/docs/react/customize-theme#api
      // Site to test out how those changes work is here: https://ant.design/theme-editor
      theme={{
        token: {
          colorPrimary: COLORS.TEAL,
          colorLink: COLORS.LINK_COLOR,
          colorBgBase: COLORS.LIGHT_GRAY,
          borderRadius: 0,
          fontFamily: 'Lato',
          colorBorder: COLORS.BORDER_COLOR,
          sizePopupArrow: 9,
        },
        component: {
          Dropdown: {
            colorBgElevated: COLORS.LIGHT_GRAY,
          },
        },
      }}
    >
      <GlobalBannerContext.Provider
        value={{ message: globalBannerMessage, setMessage: setGlobalBannerMessage }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: stickySidebar ? '100dvh' : undefined,
            maxHeight: stickySidebar ? '100dvh' : undefined,
          }}
        >
          {globalBannerMessage && <Banner message={globalBannerMessage} />}
          <Layout style={{ flexGrow: 1 }}>
            <AcknowledgementModal
              showModal={showAcknowledgeModal}
              setShowModal={setShowAcknowledgeModal}
              acknowledgements={acknowledgementsByUser}
            />
            {customerIdMismatch ? <OrgMismatchModal /> : null}
            <SideNavWrapper />
            {/* This classname is the same as the constant APP_SCROLL_CONTAINER_CLASSNAME but has to be a string literal here for the use-case to work properly */}
            <Layout className="scrollable-app-content-wrapper" style={{ overflowY: 'scroll' }}>
              <Content>
                {isWoCollaborator ? woCollaboratorRoutes : fullRoutes}

                {/* Not sure why there's a separate Switch... */}
                {useModal && previousLocation ? (
                  <Switch>
                    <Route
                      path={
                        hasReleaseToggle(NEW_IMAGE_VIEWER)
                          ? ['/pictures', '/picture-review']
                          : '/pictures/:ids'
                      }
                      render={() => (
                        <Suspense>
                          <PicturesRoot />
                        </Suspense>
                      )}
                    />

                    <Route
                      path="/transmission-lines"
                      render={() => (
                        <Suspense>
                          <TransmissionsLineRoot />
                        </Suspense>
                      )}
                    />
                  </Switch>
                ) : null}
                <GlobalFooter />
              </Content>

              <Portals />
            </Layout>
          </Layout>
        </div>
      </GlobalBannerContext.Provider>
    </ConfigProvider>
  );
}

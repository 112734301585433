import { AtlasGqlReportContext, useGetReportTemplatesQuery } from 'types/atlas-graphql';
import { ReportTemplate, ReportTemplatesForContext } from './types';
import { notEmpty } from 'utils/types';

export function useGetReportTemplatesForContext(
  contexts: AtlasGqlReportContext[]
): ReportTemplatesForContext[] {
  const { data = {}, loading } = useGetReportTemplatesQuery({
    variables: { contexts },
  });

  const reportTemplates = data?.reportTemplates || [];

  return contexts.map(context => {
    const templatesForContext = (reportTemplates || []).filter(t => t.context === context);
    return {
      context,
      templates: templatesForContext.map(template => ({
        template,
        disabled: undefined,
      })),
      loading,
    };
  });
}

/**
 * Helper function that sets the disabled flag on templates based on whether or not
 * the item count from the arguments is greater than the maximum defined in the template
 * @param contextTemplates An array of report template objects
 * @param reportArgInfoForContextLu An object mapping report arguements to a specific context name
 * @returns Updated templates, where the disabled flag has been properly set
 */
export function getAugmentedTemplates(contextTemplates: any[], reportArgInfoForContextLu: any) {
  return contextTemplates.map(({ context, templates, ...rest }) => {
    const reportArgsInfo = reportArgInfoForContextLu[context];
    // A template is disabled if any of the report arguments for the template's
    // context would yield an item count that is too big for the template.
    let nonNullItemCounts: number[] = [];
    if (reportArgsInfo?.reportArguments?.length) {
      nonNullItemCounts = reportArgsInfo.reportArguments
        .map(({ itemCount }: { itemCount: number }) => itemCount)
        .filter(notEmpty);
    }

    const maxItemCount: number = nonNullItemCounts.length ? Math.max(...nonNullItemCounts) : -1;

    const newTemplates: ReportTemplate[] = templates.map(({ template }) => {
      const { itemLimit } = template;
      return {
        disabled: reportArgsInfo.optionDisabled
          ? true
          : maxItemCount > 0 && itemLimit
            ? maxItemCount > itemLimit
            : false,
        template,
      };
    });
    return { context, templates: newTemplates, ...rest };
  });
}

import { useCallback, useState, useEffect, useMemo } from 'react';
import { useApolloContext, useMutation } from 'utils/apollo';
import { Slider, Card, Space } from 'antd';
import { Button, Tooltip } from 'components/ui';
import { BarChartOutlined } from '@ant-design/icons';
import SET_IMAGE_LEVELS from './Levels/SetImageLevels.gql';
import styled from 'styled-components';

const SliderInput = props => <Slider tooltipVisible={false} {...props} />;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

function toValue({ a = 1, b = 0 } = {}) {
  const min = -b / a;
  const max = (255 - b) / a;

  return [min, max];
}

function SaveButton({ value, onSave, ...props }) {
  const { papiClient } = useApolloContext();
  const [setImageLevels, { loading }] = useMutation(SET_IMAGE_LEVELS, {
    client: papiClient,
    refetchQueries: ['GetPicture'],
  });

  const handleClick = useCallback(async () => {
    await setImageLevels({
      variables: value,
    });

    onSave();
  }, [value, setImageLevels, onSave]);

  return <Button _version={4} onClick={handleClick} loading={loading} {...props} />;
}

export function Levels({ onChange, defaultValue = {} }) {
  const { id, customerId, a, b } = defaultValue;

  const [levels, setLevels] = useState({ a, b });
  const [show, setShow] = useState(false);

  const hasLevels = useMemo(() => a !== 1 || b !== 0, [a, b]);

  // Reset the levels control to be hidden
  useEffect(() => {
    if (id) {
      setShow(false);
    }
  }, [id]);

  const handleChange = useCallback(
    ([min, max]) => {
      const a = Number((255 / (max - min)).toFixed(4));
      const b = Math.round(-a * min);
      const levels = { a, b };

      setLevels(levels);
      onChange({ levels });
    },
    [onChange]
  );

  const toggleShow = useCallback(() => setShow(prev => !prev), []);
  const handleReset = useCallback(() => {
    hasLevels ? handleChange(toValue({ a, b })) : handleChange([0, 255]);
  }, [handleChange, hasLevels, a, b]);

  const handleCancel = useCallback(() => {
    handleReset();
    setShow(false);
  }, [handleReset]);

  return show ? (
    <Card>
      <SliderInput
        style={{ margin: '0 0 .5rem 0' }}
        value={toValue(levels)}
        id="image-levels"
        max={255}
        min={0}
        onChange={handleChange}
        range
        step={1}
      />

      <SpaceBetween style={{ marginBottom: '1rem' }}>
        <span>Shadows</span>
        <span>Highlights</span>
      </SpaceBetween>

      <SpaceBetween>
        <Button _version={4} size="small" onClick={handleReset}>
          Reset
        </Button>

        <Space>
          <SaveButton
            id="save-image-levels"
            size="small"
            onSave={toggleShow}
            type="primary"
            value={{ id, customerId, levels }}
          >
            Save
          </SaveButton>

          <Button _version={4} id="cancel-image-levels" size="small" onClick={handleCancel}>
            Cancel
          </Button>
        </Space>
      </SpaceBetween>
    </Card>
  ) : (
    <Tooltip title="Adjust image levels">
      <Button
        _version={4}
        type={hasLevels ? 'primary' : undefined}
        id="show-image-levels"
        onClick={toggleShow}
      >
        <BarChartOutlined />
      </Button>
    </Tooltip>
  );
}

import styled from 'styled-components';
import { Button } from 'components/ui';
import { VariableSizeList as List } from 'react-window';

export const StyledList = styled(List)`
  padding-left: 0;
`;

export const SelectedFile = styled.li`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 1rem 0.25rem 0;
  justify-content: flex-start;
  .ant-form {
    margin: 0 0 0 1rem;
  }
`;

export const StyledFileNameRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledFilename = styled.span<{ invalid?: boolean }>`
  flex: 1;
  min-width: 200px;
  margin-right: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ invalid }) => (invalid ? 'red' : 'inherit')};
`;

export const StyledRemoveButton = styled(Button)`
  width: 1.125rem;
  height: 1.125rem;
  font-size: 0.75rem !important;

  span {
    font-size: 0.75rem !important;
    vertical-align: text-top;
  }
`;

export const FilesSelected = styled.p`
  margin: 0.5rem 0;
`;

export const ErrorWrapper = styled.div`
  background-color: #fff2f0;
  padding: 0.5rem;
  ul {
    color: ${({ theme }) => theme.red};
    margin: 0;
    padding-left: 1rem;
  }
`;

import { useRef, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import pLimit from 'p-limit';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { range } from 'lodash';
import { Modal } from 'antd';
import { Button } from 'components/ui';
import { useBrowserNotifications, sendBrowserNotification } from 'utils/browserNotifications';
import { ActionButtons } from './FileUploadModal.style';
import { SelectedFileList } from './SelectedFileList';
import { notifyBugsnag } from 'utils/bugsnag';
import { updateIfCr2Files, isCr2File, extractJpgFromCr2 } from '../helpers';
import { useUploadContext } from '../UploadContext';
import { FileData, ModalAction, ModalActionArgs, ModalUploadCompleteInput } from '../types';
import {
  useCompleteUploadMutation,
  useCreateMultipartUploadMutation,
  useCreateSignedUploadMutation,
  useGetUploadSessionPartsLazyQuery,
} from 'types/atlas-graphql';
import { useAccountContext } from 'utils/account/AccountContext';

const limit = pLimit(10);
axiosRetry(axios, {
  retries: 3,
  // Only make a retry attempt if it's not a cancellation error
  retryCondition: error => !axios.isCancel(error),
  onRetry: (retryCount, error, _requestConfig) =>
    console.log(
      `Upload request has failed ${retryCount} time(s). Request will be retried ${
        3 - retryCount
      } more time(s). ${error}`
    ),
});

export type OnCompleteStatus = 'CANCEL' | 'SUCCESS' | 'ERROR' | 'OK';

export interface FileUploadModalProps {
  action: ModalAction[] | ((args: ModalActionArgs[]) => Promise<ModalAction[] | undefined>);
  fileInputRef: React.Ref<HTMLInputElement>;
  fileName: string;
  onComplete: (args: { status: OnCompleteStatus }) => void;
  onFileComplete: (args: ModalUploadCompleteInput) => Promise<unknown>;
}

export function FileUploadModal({
  fileInputRef,
  action,
  onComplete,
  onFileComplete,
  fileName,
}: FileUploadModalProps) {
  const { notificationsEnabled, askPermission } = useBrowserNotifications();
  const {
    filteredFiles,
    corruptImgFiles,
    setCorruptImgFiles,
    modalProps,
    modalVisible,
    selectedFiles,
    setModalProps,
    setModalVisible,
    setSelectedFiles,
    setUploading,
    uploading,
    validFiles,
    setInitialSelection,
  } = useUploadContext();

  const displayAttachmentOptions = modalProps?.modalAttachmentProps?.displayAttachmentOptions;
  const attachmentRecordParentType = modalProps?.modalAttachmentProps?.attachmentRecordParentType;

  const source = useRef(axios.CancelToken.source());
  const { customer } = useAccountContext();
  const organizationId = customer?.id ?? null;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showErrorSummary, setShowErrorSummary] = useState<boolean>(false);
  const [createSignedUpload] = useCreateSignedUploadMutation();
  const [createMultipartUpload] = useCreateMultipartUploadMutation();
  const [completeUpload] = useCompleteUploadMutation();
  const [getUploadSessionParts, { data: uploadSessionPartsData }] =
    useGetUploadSessionPartsLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const handleUploadCancel = useCallback(() => {
    source.current.cancel('Request was canceled.');
    onComplete && onComplete({ status: 'CANCEL' });
    setUploading(false);
    setSelectedFiles(previousSelectedFiles =>
      previousSelectedFiles.filter(
        ({ percent, status }) => percent !== 100 && status !== 'completed'
      )
    );
    source.current = axios.CancelToken.source();
    if (modalProps?.isMetadataUpload || modalProps?.isBulkUpload) {
      // clearing initial selection here on cancel.  If this is
      // not cleared, whenever a new multi-upload is attempted
      // the files from the previous cancelled upload are still
      // present in the selected file list.
      setInitialSelection([]);
      handleReset();
    }
    // eslint-disable-next-line
  }, [onComplete, setSelectedFiles, setUploading, modalProps]);

  useEffect(() => {
    if (modalProps?.uploadSession?.uploadId) {
      getUploadSessionParts({ variables: { uploadId: modalProps?.uploadSession?.uploadId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalProps?.uploadSession?.uploadId]);

  useEffect(() => {
    if (modalProps?.canceledUpload) {
      handleUploadCancel();
    }

    return () => {
      source.current.cancel('Component unmounted.');
    };
  }, [handleUploadCancel, modalProps]);

  function handleReset() {
    if (modalProps?.onClose) {
      modalProps?.onClose();
    }
    setModalVisible(false);
    if (typeof fileInputRef === 'object' && fileInputRef?.current) {
      // user might have left the route and continued async
      fileInputRef.current.value = '';
    }
    setSelectedFiles([]);
    setUploading(false);
    setCorruptImgFiles([]);
    setErrorMessage(null);
    setShowErrorSummary(false);
    setModalProps(null);
  }

  function handleCloseModal() {
    if (uploading) {
      setModalVisible(false);
    } else {
      handleReset();
    }
  }

  function removeSelectedFile(name: string) {
    setSelectedFiles(selectedFiles.filter(file => file.sourceName !== name));

    if (selectedFiles.length === 1) {
      // when the last file is being removed, close the modal
      handleCloseModal();
    }
  }

  async function handleFileUploadComplete({
    filename,
    path,
    category,
    description,
  }: ModalUploadCompleteInput) {
    setSelectedFiles(previousSelectedFiles =>
      previousSelectedFiles.map(f => (f.sourceName === path ? { ...f, status: 'completed' } : f))
    );
    await onFileComplete({
      filename,
      path,
      category,
      description,
    });
  }

  function handleFinishUpload({ status }: { status: OnCompleteStatus }) {
    if (onComplete) {
      onComplete({ status });
    }
    handleReset();
  }

  function handleCornisUploadFlag() {
    const filesToUpload = updateIfCr2Files(validFiles);
    setSelectedFiles(filesToUpload);

    return filesToUpload;
  }

  interface PartResponse {
    PartNumber: number;
    ETag: string;
  }

  async function handleUpload() {
    setErrorMessage(null);
    setUploading(true);

    let err;
    // Flag that is set when an upload is cancelled.
    let cancelled: boolean = false;

    const filesToUpload = handleCornisUploadFlag();

    const fileNames: ModalActionArgs[] = filesToUpload.map(file => ({ fileName: file.sourceName }));

    if (modalProps?.acceptedFileTypes?.includes('application/zip')) {
      // There are a few assumptions being made in here for the current
      // setup of TLine being a single .zip file. Some of these will need
      // to change in the near future as we expand .zip uploads to other
      // areas of the app.
      const uploadSession = modalProps.uploadSession ?? null;
      const mainFile = filesToUpload[0].data;
      const recordId = uploadSession?.recordId ?? uuid();
      const bundleId = uploadSession?.bundleId ?? moment().format('x');

      if (!mainFile) {
        err = true;
        if (onComplete) {
          onComplete({ status: 'ERROR' });
        }
      }

      if (mainFile) {
        setUploading(true);
        const input = {
          recordId: recordId,
          dataType: 'tline-compressed',
          organizationId,
          bundleId,
          files: [{ fileName: filesToUpload[0].sourceName }],
          params: {},
        };

        let sessionUploadId = uploadSession?.uploadId;
        const uploadedParts: Array<{ eTag: string; partNumber: number }> = [];
        if (sessionUploadId) {
          if (uploadSessionPartsData?.uploadSessionByUploadId?.parts) {
            uploadedParts.push(...uploadSessionPartsData?.uploadSessionByUploadId?.parts);
          }
        } else {
          const { data: createMultipartUploadData } = await createMultipartUpload({
            variables: { input },
          });
          const { UploadId: uploadId } = createMultipartUploadData?.createMultipartUpload[0] || {};
          sessionUploadId = uploadId;
        }

        const transformSessionParts = uploadedParts.map((part: any) => {
          return {
            ETag: part.eTag,
            PartNumber: part.partNumber,
          };
        });

        const completeInput = {
          ...input,
          uploadId: sessionUploadId,
          parts: {
            Parts: (transformSessionParts as PartResponse[]) ?? [],
          },
        };

        let chunkSize = 5242880;
        let numberOfParts = Math.ceil(mainFile.size / chunkSize);

        // AWS multipart uploads have a limit of 10000 parts.
        // If we are above that, recalc size and parts
        if (numberOfParts > 10000) {
          chunkSize = Math.ceil(mainFile.size / 10000);
          numberOfParts = Math.ceil(mainFile.size / chunkSize);
        }
        const sessionParts = uploadedParts.map((part: any) => part.partNumber) || [];

        // Set the file to "uploading" so the user has some visual indication
        // that work is being done, even if we aren't technically "uploading"
        // any of the parts yet.
        setSelectedFiles(previousSelectedFiles =>
          previousSelectedFiles.map(f =>
            f.sourceName === filesToUpload[0].sourceName ? { ...f, status: 'uploading' } : f
          )
        );

        // Because our upload sessions are happening concurrently, we cannot be sure when resuming an upload
        // that there is a correct "last part" that has been uploaded
        // (e.g. just because the greatest part number is 5 doesn't mean that 1-4 have completed)
        // Here we populate a "missingParts" array with every UploadSession part that has not yet completed
        const missingPartNumbers = range(1, numberOfParts + 1).filter(
          n => sessionParts.indexOf(n) === -1
        );
        const signedUploadInput = {
          recordId: recordId,
          dataType: 'tline-compressed',
          files: missingPartNumbers.map(mpn => ({
            fileName: filesToUpload[0].sourceName,
            partNumber: mpn,
          })),
          uploadId: sessionUploadId,
        };
        const missingParts: Array<any> = [];
        const { data: createSignedUploadData } = await createSignedUpload({
          variables: { input: signedUploadInput },
        });
        if (createSignedUploadData?.createSignedUpload) {
          const uploadParts = createSignedUploadData?.createSignedUpload.map(su => ({
            url: su?.url,
            partNumber: su?.partNumber,
          }));
          missingParts.push(...uploadParts);
        }

        await Promise.all(
          missingParts.map(
            async ({ partNumber, url }: { partNumber: number; url: string }, index: number) => {
              const partEnd = Math.min(partNumber * chunkSize, mainFile.size);
              const chunk = mainFile.slice(partNumber * chunkSize - chunkSize, partEnd);
              await limit(async () => {
                try {
                  // If the cancelled flag is set, don't attempt to perform
                  // any more uploads.  If this is not here, all subsequent uploads
                  // will be attempted and immediately fail due to the cancel token being
                  // set.
                  if (cancelled) {
                    return;
                  }
                  if (url) {
                    await axios
                      .put(url, chunk, { cancelToken: source.current.token })
                      .then(response => {
                        setSelectedFiles(previousSelectedFiles =>
                          previousSelectedFiles.map(f => {
                            // Our completed percentage is the total number of file chunks (ex. 20)
                            // minus the number of missing values (ex. 5) plus the index of the current value
                            // divided by the number of file chunks (ex. ((20 - 5 + 0) / 20) * 100 = 75% complete)
                            const currentChunkPercentCalc = Math.round(
                              ((numberOfParts - missingParts.length + index) / numberOfParts) * 100
                            );
                            return f.sourceName === filesToUpload[0].sourceName
                              ? {
                                  ...f,
                                  // Because the chunks can upload out of order, we only want to show forward progress.
                                  percent:
                                    f.percent > currentChunkPercentCalc
                                      ? f.percent
                                      : currentChunkPercentCalc,
                                }
                              : f;
                          })
                        );
                        completeInput.parts.Parts.push({
                          ETag: response.headers.etag,
                          PartNumber: partNumber,
                        });
                        return;
                      });
                  }
                } catch (error: any) {
                  const message = `[File Upload error]: ${JSON.stringify(error)}`;
                  notifyBugsnag(message);
                  setUploading(false);
                  setErrorMessage(error.message);
                  if (axios.isCancel(error)) {
                    // set cancelled flag to prevent more upload attempts
                    cancelled = true;
                    throw error.message;
                  } else {
                    setSelectedFiles(
                      selectedFiles.map(f =>
                        f.sourceName === filesToUpload[0].sourceName ? { ...f, status: 'error' } : f
                      )
                    );
                  }
                }
              });
            }
          )
        ).then(async () => {
          completeInput.parts.Parts.sort(
            (a: PartResponse, b: PartResponse) => a.PartNumber - b.PartNumber
          );
          setSelectedFiles(previousSelectedFiles =>
            previousSelectedFiles.map(f =>
              f.sourceName === filesToUpload[0].sourceName ? { ...f, status: 'completed' } : f
            )
          );
          await completeUpload({ variables: { input: completeInput } });
        });
      }
    } else {
      const urls = typeof action === 'function' ? await action(fileNames) : action;

      if (!urls) {
        // if URLs are undefined, prevent upload.
        setErrorMessage('Unable to upload at this time. Press Upload to retry.');
        setUploading(false);
        err = true;
        if (onComplete) {
          onComplete({ status: 'ERROR' });
        }
        return;
      }

      const fileUploads = filesToUpload.map((file, index) =>
        limit(async () => {
          // If the cancelled flag is set, don't attempt to perform
          // any more uploads.  If this is not here, all subsequent uploads
          // will be attempted and immediately fail due to the cancel token being
          // set.
          if (cancelled) {
            return;
          }
          // FIXME: this check will be gone when we make attachments more like inspection uploads
          const currentFile = urls.length > 1 ? index : 0;
          const isCr2 = isCr2File(file);

          const { url, fields = {} } = urls[currentFile] || {};

          const formData: FormData = new FormData();

          Object.keys(fields).forEach(key => {
            const value = fields[key];
            formData.append(key, value);
          });

          const fileData: File | FileData = isCr2
            ? await extractJpgFromCr2(file.data as File)
            : (file.data as FileData);
          formData.append('file', fileData as File);

          try {
            setSelectedFiles(previousSelectedFiles =>
              previousSelectedFiles.map(f =>
                f.sourceName === file.sourceName ? { ...f, status: 'uploading' } : f
              )
            );
            await axios.post(url, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              cancelToken: source.current.token,
            });

            if (displayAttachmentOptions) {
              const category: string | undefined =
                'category' in file ? (file.category as string) : undefined;
              const description: string | undefined =
                'description' in file ? (file.description as string) : undefined;
              await handleFileUploadComplete({
                filename: file.name,
                path: file.sourceName,
                category: category,
                description: description,
              });
            } else {
              await handleFileUploadComplete({ filename: file.name, path: file.sourceName });
            }
          } catch (error: any) {
            const message = `[File Upload error]: ${JSON.stringify(error)}`;
            notifyBugsnag(message);
            if (axios.isCancel(error)) {
              // set cancelled flag to prevent more upload attempts
              cancelled = true;
              setUploading(false);
              setErrorMessage(error.message);
              throw error.message;
            } else {
              err = true;

              setSelectedFiles(previousSelectedFiles =>
                previousSelectedFiles.map(f =>
                  f.sourceName === file.sourceName ? { ...f, status: 'error' } : f
                )
              );
            }
          }
        })
      );

      await Promise.all(fileUploads);
    }

    if (err) {
      setShowErrorSummary(true);
      handleFinishUpload({ status: 'ERROR' });
    } else {
      sendBrowserNotification({
        notificationsEnabled,
        subject: 'File upload',
        message: 'Your file upload is complete.',
      });

      handleFinishUpload({ status: 'SUCCESS' });
    }
  }

  useEffect(
    () => {
      if (modalProps?.isMetadataUpload) {
        handleUpload();
      }
      return () => {
        source.current.cancel('Component unmounted.');
      };
    },
    [modalProps] // eslint-disable-line
  );

  return (
    <Modal
      title="Upload Files"
      visible={modalVisible}
      onCancel={handleCloseModal}
      bodyStyle={{ padding: '1rem 1.5rem' }}
      destroyOnClose={true}
      footer={
        uploading ? (
          <Button _version={4} onClick={handleUploadCancel}>
            Cancel
          </Button>
        ) : !showErrorSummary ? (
          <ActionButtons>
            {typeof notificationsEnabled === 'undefined' && (
              <Button _version={4} onClick={askPermission} className="left-btn">
                Browser Notifications
              </Button>
            )}
            <Button _version={4} onClick={handleReset}>
              Cancel
            </Button>
            <Button
              _version={4}
              disabled={!validFiles.length}
              data-testid="upload-modal-submit-button"
              htmlType="submit"
              onClick={handleUpload}
              type="primary"
            >
              Upload
            </Button>
          </ActionButtons>
        ) : (
          <Button _version={4} onClick={() => handleFinishUpload({ status: 'OK' })}>
            Ok
          </Button>
        )
      }
    >
      <SelectedFileList
        errorMessage={errorMessage}
        fileName={fileName}
        filteredFiles={filteredFiles}
        corruptFiles={corruptImgFiles}
        onRemove={removeSelectedFile}
        showErrorSummary={showErrorSummary}
        uploading={uploading}
        validFiles={validFiles}
        displayAttachmentOptions={displayAttachmentOptions}
        attachmentRecordParentType={attachmentRecordParentType}
      />
    </Modal>
  );
}

import { useHistory } from 'react-router';
import { message } from 'antd';
import { flatten } from 'lodash';
import { AtlasGqlHorizonDamage, useDeleteHorizonDamageMutation } from 'types/atlas-graphql';
import { DeleteConfirmation } from 'components/DeleteConfirmation';

interface DeleteDamageProps {
  damage: AtlasGqlHorizonDamage;
}

export const DeleteDamage: React.FunctionComponent<DeleteDamageProps> = ({ damage }) => {
  const history = useHistory();
  const [deleteDamage] = useDeleteHorizonDamageMutation({
    variables: { input: { id: damage.id } },
    update: cache => {
      cache.evict({ id: cache.identify(damage) });
    },
    onCompleted: () => {
      message.success('Damage has been deleted');
      history.push('/damages2');
    },
  });

  const hasMultipleObservations: boolean =
    (flatten(damage.observationGroups?.map(group => group?.observations ?? [])) ?? []).length > 1;
  const warningCopy: string = hasMultipleObservations
    ? 'This action will delete the Damage, the linked Observations,' +
      ' all Propagation Details, and any Comments that may have been made.' +
      ' This action is irreversible. If you wish to preserve any of the linked data,' +
      ' use the Large Image Viewer to remove any unwanted Observations.'
    : 'This action will delete the Damage, the linked Observation,' +
      ' and any Comments that have been made. This action is irreversible.';

  return (
    <DeleteConfirmation
      id="delete-damage-button"
      type="button"
      deleteBtnType="default"
      title="Delete Damage"
      warningContent={warningCopy}
      disabled={!!damage.tasks?.length}
      useToolTip
      toolTipMessage="Damage deletion is not available due to associated Tasks."
      errorInfo="Error deleting damage. Please try again."
      onDelete={async () => {
        await deleteDamage();
      }}
    />
  );
};

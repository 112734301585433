import { groupBy } from 'lodash';
import { Menu, Select } from 'antd';
import { TTaskTemplatesChooserType } from './types';

export function formatTemplatesMenu(
  templatesData: {
    __typename?: 'TaskTemplate' | undefined;
    id: any;
    name: string;
    workContainerType: { __typename?: 'WorkContainerType' | undefined; id: any; name: string };
  }[],
  displayType: TTaskTemplatesChooserType
) {
  const groupedTemplates = groupBy(templatesData, 'workContainerType.name');
  const allTemplateItemOptions = Object.keys(groupedTemplates).map(groupName => {
    return displayType === 'menu' ? (
      <Menu.ItemGroup key={groupName} title={groupName}>
        {groupedTemplates[groupName].map(template => {
          return <Menu.Item key={`${groupName}_${template.id}`}>{template.name}</Menu.Item>;
        })}
      </Menu.ItemGroup>
    ) : (
      <Select.OptGroup key={groupName} title={groupName}>
        {groupedTemplates[groupName].map(template => {
          return (
            <Select.Option key={`${groupName}_${template.id}`} value={template.id}>
              {template.name}
            </Select.Option>
          );
        })}
      </Select.OptGroup>
    );
  });
  return allTemplateItemOptions;
}

import { Button, Modal, ModalActionButtons } from 'components/ui';
import { Summary } from '../Summary';
import { StyledCollapse } from '../Views.style';

import { AtlasGqlTableView } from 'types/atlas-graphql';
import { TDisplayColumnData } from 'components/data/types';

const { Panel } = StyledCollapse;

export type ConditionsState = {
  isOpen: boolean;
  tableView?: Partial<AtlasGqlTableView>;
  columnDefs?: TDisplayColumnData[];
};

type ConditionsModalProps = {
  conditionsState: ConditionsState;
  onClose: () => void;
};

export const ConditionsModal: React.FunctionComponent<ConditionsModalProps> = ({
  conditionsState,
  onClose,
}) => {
  const { isOpen, tableView, columnDefs } = conditionsState;

  return (
    <Modal
      open={isOpen && !!tableView}
      title="Conditions"
      onCancel={onClose}
      destroyOnClose
      footer={null}
    >
      <StyledCollapse defaultActiveKey={['table_conditions']}>
        <Panel key={'table_conditions'} header="Table Conditions">
          {tableView && columnDefs && <Summary tableView={tableView} columnDefs={columnDefs} />}
        </Panel>
      </StyledCollapse>
      <ModalActionButtons>
        <Button type="primary" onClick={onClose}>
          Close
        </Button>
      </ModalActionButtons>
    </Modal>
  );
};

import { useState, useEffect } from 'react';
import { LOGO_ICON } from 'utils/constants';

export function useBrowserNotifications() {
  const [notificationsEnabled, setNotificationsEnabled] = useState(undefined);

  function checkForEnabledNotifications() {
    if (!('Notification' in window)) {
      // if browser does not support notifications, don't show button to enable
      setNotificationsEnabled(false);
    }

    // check whether notification permissions have alredy been granted
    else if (Notification.permission === 'granted') {
      setNotificationsEnabled(true);
    }
  }

  useEffect(() => {
    checkForEnabledNotifications();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function askPermission() {
    if (Notification.permission !== 'denied' || Notification.permission === 'default') {
      Notification.requestPermission(function(permission) {
        if (permission === 'granted') {
          setNotificationsEnabled(true);
        }
      });
    }
  }

  return { notificationsEnabled, askPermission };
}

export function sendBrowserNotification({
  notificationsEnabled,
  subject,
  message,
  icon = LOGO_ICON,
}) {
  if (notificationsEnabled) {
    new Notification(subject, {
      body: message,
      icon,
    });
  }
}

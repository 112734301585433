import { ReactElement } from 'react';
import { TablePaginationConfig } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';

import { TypedDocumentNode, DocumentNode, ApolloClient, ApolloQueryResult } from '@apollo/client';
import { TTableColumnDef } from './../../horizon/types/TableColumnDef';
import { AtlasGqlTableView } from 'types/atlas-graphql';

export enum ESortOptions {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum EFilterSortClearType {
  CLEAR = 'Clear',
  RESET = 'Reset',
}

export type TQueryFilter = {
  key: string;
  values: string[];
  operator?: string;
};

export type TQuerySort = {
  key: string;
  sort: ESortOptions;
};

export type TRouteProps = {
  client?: ApolloClient<any>;
  context?: any;
  universalFilter?: TQueryFilter[];
  universalSortBy?: TQuerySort[];
  usePartialData?: boolean;
  notifyOnNetworkStatusChange?: boolean;
  variables?: any;
  history?: History;
  location?: Location;
  match?: any;
  skip?: boolean;
};

export type TUseServerDataInput = {
  setTotalCount?: (totalCount: number) => void;
  query: DocumentNode | TypedDocumentNode<any, any>;
  setLoading?: (loading: boolean) => void;
  transform?: Function;
  columns?: TTableColumnDef[];
  routeProps?: TRouteProps;
  onDataQueryChange?: Function;
  rowSelection?: TableRowSelection<any>;
};

export type TablePagination = TablePaginationConfig & {
  pageSize?: number;
};

export type TPersistedState = {
  filteredInfo?: {
    [key: string]: any[] | null;
  };
  sortedInfo?: TSorter;
  pagination?: TablePagination | false;
};

export type TDataTableContext = {
  initialRouteVariables?: TRouteProps;
  initialPagination?: TablePagination | false;
  pagination?: TablePagination | false;
  updatePagination: (args: any) => void;
  filteredInfo?: {
    [key: string]: any[] | null;
  };
  updateFilteredInfo: (args: any) => void;
  sortedInfo?: TSorter;
  updateSortedInfo: (args: any) => void;
  customizedColumnSet?: unknown[];
  updateCustomizedColumnSet: (args: any) => void;
  multiSortDef?: unknown[];
  updateMultiSortDef: (args: any) => void;
  tableView?: AtlasGqlTableView;
  updateTableView: (args: any) => void;
  setPersistedState: (args: any) => void;
};

export type TServerDataTableContext = {
  client?: ApolloClient<any>;
  loading?: boolean;
  refetch?: (variables?: Partial<any>) => Promise<ApolloQueryResult<any>>;
  refetchAwait?: (variables?: Partial<any>) => Promise<void>;
  tableFilters?: any[];
};

export type TDataTableStorageKey = {
  COLUMN_PICKER: string;
  MULTI_SORT: string;
};

export type TSorter = {
  column: any;
  columnKey: string;
  field: any;
  order: string;
};

export type TDropdownAction = {
  content: string | ReactElement;
  onClick?: MenuClickEventHandler;
  disabled?: boolean;
  hoverable?: boolean;
};

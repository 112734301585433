import styled from 'styled-components';

export const FormActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2rem;
`;

export const FormActionsRight = styled.div`
  button {
    margin-left: 0.5rem;
  }
`;

export const StyledSelectWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 100px;
  grid-gap: 0.5rem;
  align-items: center;
  margin-bottom: 1.5rem;
`;

import { chunk } from 'lodash';
import { Label, Description } from 'horizon/components/Damages/DamageStyles.style';
import { Row, Col } from 'antd';
import { AtlasGqlHorizonDamage } from 'types/atlas-graphql';

type LayoutProps = {
  number: 2 | 3 | 4;
  threshold?: number;
};

type AttributesProps = {
  attrs: { [key: string]: any };
  damageColumns: any[];
  layout?: LayoutProps;
  critical?: boolean | null;
};

export const Attributes: React.FunctionComponent<AttributesProps> = ({
  attrs,
  damageColumns,
  layout,
  critical,
}) => {
  const { number = 1, threshold = 0 } = layout ?? {};
  const numberOfDisplayColumns = damageColumns.length > threshold ? number : 1;
  const chunkedDamageColumns = chunk(
    damageColumns,
    Math.ceil(damageColumns.length / numberOfDisplayColumns)
  );

  const columnProps = {
    xs: 24,
    xxl: Math.round(24 / numberOfDisplayColumns),
  };

  return (
    <Row gutter={16}>
      {chunkedDamageColumns.map((columns, index) => (
        <Col key={index} {...columnProps}>
          {columns.map(column => {
            const key = column.key;
            const title = column.title;
            const value = column.render(attrs[key], { critical });
            return (
              <div key={key}>
                <Label>{title}</Label>
                <Description>{value}</Description>
              </div>
            );
          })}
        </Col>
      ))}
    </Row>
  );
};

import {
  AtlasGqlBlade,
  AtlasGqlGenericAsset,
  AtlasGqlSite,
  AtlasGqlTurbine,
} from 'types/atlas-graphql';

export enum EColumnTypeOptions {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  DEFAULT = 'default',
}

export type TColumnData = {
  key: string;
  value: JSX.Element | string | boolean | number;
};

export type TTextColumnFilter = {
  text: string;
  value: string;
};

export type TNumberColumnFilter = {
  label: number;
};

export type TTextColumnConfig = {
  key: string;
  type: EColumnTypeOptions;
  options: string[];
  exactMatch: boolean;
  includeNoneOption: boolean;
  noneOptionValue: string;
};

export type TNumberColumnConfig = {
  key: string;
  type: EColumnTypeOptions;
  values: TNumberColumnFilter[];
  exactMatch: boolean;
  includeNoneOption: boolean;
  noneOptionValue: string;
  step?: number | null;
};

export type TDateColumnConfig = {
  key: string;
  type: EColumnTypeOptions;
  exactMatch: boolean;
  includeNoneOption: boolean;
  noneOptionValue: string;
};

export type TTurbineFields = {
  statusOptions: string[];
};

export type TBladeFields = {
  serialNumberVerifiedOptions: string[];
  bladePositionOptions: string[];
  assetStatusOptions: string[];
};

export type TAnyAssetType = AtlasGqlBlade | AtlasGqlTurbine | AtlasGqlSite | AtlasGqlGenericAsset;

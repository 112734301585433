import { List } from 'antd';
import styled from 'styled-components';

export const StyledList = styled(List)<{ mapheight: string; overflowy: string }>`
  overflow-y: ${({ overflowy }) => overflowy};
  max-height: ${({ mapheight }) => mapheight};
`;

export const StyledListItem = styled(List.Item)<{ backgroundcolor: string }>`
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  cursor: pointer;
`;

export const ListItemLabel = styled.span`
  font-size: 0.9rem;
  min-width: 4rem;
  margin-right: 0.5rem;
  text-align: right;
`;

export const ListItemValue = styled.span`
  font-size: 0.9rem;
  margin: 0;
`;

export const RowContainer = styled.div`
  display: flex;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
`;

import { useState, useEffect } from 'react';
import { Select } from 'antd';

import { filterOption } from '../helpers';
import { naturalSortBy } from 'utils/naturalSort';
import { useAssetChooserState } from '../AssetChooser/useAssetChooserState';
import { ClearIcon, SuffixIcon } from 'components/SelectChooser/SelectChooser.style';

import {
  AtlasGqlFilterOperator,
  AtlasGqlTurbine,
  useGetSiteTurbinesForChooserQuery,
} from '../../../types/atlas-graphql';
import { LocationTurbineChooserComponentProps } from './types';

export function SingleLocationTurbineChooser({
  value,
  onChange,
  mode,
  locationId,
  // The disabled parameter will prevent loading select options, this disables the input element.
  disableInput = false,
}: LocationTurbineChooserComponentProps) {
  const { handleChange, setVisible, chooserState } = useAssetChooserState({
    onChange,
    value,
  });

  const [turbines, setTurbines] = useState<Array<AtlasGqlTurbine>>([]);

  const { data, loading } = useGetSiteTurbinesForChooserQuery({
    variables: {
      filterBy: [
        { key: 'childrenOfIds', operator: AtlasGqlFilterOperator.Equals, values: [locationId] },
        { key: 'assetTypeNames', operator: AtlasGqlFilterOperator.Equals, values: ['turbine'] },
      ],
    },
  });

  useEffect(() => {
    if (data?.assetsWithMetadata?.items) {
      const newTurbines = data?.assetsWithMetadata?.items;
      // You have to call sort on a copy of newTurbines, rather than on the array itself.
      const sortedTurbines = [...newTurbines].sort(naturalSortBy('name'));
      setTurbines(sortedTurbines as AtlasGqlTurbine[]);
    }
  }, [data]);

  return (
    <Select
      id="single-location-turbine-chooser"
      placeholder="Select Turbines..."
      loading={loading}
      allowClear
      showSearch
      filterOption={filterOption}
      mode={mode}
      maxTagCount={2}
      value={loading ? undefined : chooserState}
      onChange={handleChange}
      onDropdownVisibleChange={setVisible}
      disabled={disableInput}
      showArrow={chooserState ? false : true}
      clearIcon={<ClearIcon />}
      suffixIcon={<SuffixIcon />}
      virtual={false}
    >
      {turbines.map(turbine => (
        <Select.Option
          key={turbine.id}
          className="single-location-turbine-chooser-option"
          value={turbine.id}
        >
          {turbine.name}
        </Select.Option>
      ))}
    </Select>
  );
}

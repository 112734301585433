import { Select } from 'antd5';

import { filterOption } from '../helpers';
import { useBladeDetailsQuery as useAtlasBladeQuery } from 'types/atlas-graphql';
import { useGetBladeDetailsQuery as usePapiBladeQuery } from 'types/papi-graphql';
import { useAssetChooserState } from '../AssetChooser/useAssetChooserState';
import { ClearIcon, SuffixIcon } from 'components/SelectChooser/SelectChooser.style';
import { useAccountContext } from 'utils/account/AccountContext';
import { useMemo } from 'react';
import { notEmpty } from 'utils/types';
import { useApolloContext } from 'utils/apollo';

export type BladeChooserProps = {
  turbineId: string;
  value?: string | string[];
  onChange: (value: string | string[]) => void;
  disabled?: boolean;
  mode?: 'multiple';
};

const useBladeChooserData = ({ turbineId }: { turbineId: string }) => {
  const { hasReleaseToggle } = useAccountContext();
  const { papiClient } = useApolloContext();

  const hasRT = hasReleaseToggle('atlas_blade_chooser');

  const { data, loading } = usePapiBladeQuery({
    client: papiClient,
    variables: { turbineId },
    skip: hasRT || !turbineId,
  });

  const { data: atlasData, loading: atlasLoading } = useAtlasBladeQuery({
    variables: { turbineId },
    skip: !hasRT || !turbineId,
  });

  return useMemo(() => {
    if (!hasRT) {
      return { data, loading };
    }

    if (atlasLoading) {
      return { data: undefined, loading: true };
    }

    if (atlasData?.assetWithMetadata.__typename !== 'Turbine') {
      return { data: undefined, loading: false };
    }

    const blades =
      atlasData?.assetWithMetadata?.descendants?.flatMap((x: any) => {
        if (x.__typename !== 'Blade') {
          return [];
        }
        return {
          id: x.id,
          label: x.uniqueIdLabel,
          serial: x.serial,
        };
      }) ?? [];

    return {
      data: {
        turbine: {
          id: turbineId,
          blades,
        },
      },
      loading: atlasLoading,
    };
  }, [hasRT, data, atlasData, loading, atlasLoading]);
};

export default function BladeChooser({
  turbineId,
  value,
  onChange,
  disabled,
  mode = 'multiple',
}: BladeChooserProps) {
  const { data, loading } = useBladeChooserData({ turbineId });
  const blades = data?.turbine?.blades ?? [];

  const { handleChange, setVisible, chooserState } = useAssetChooserState({ onChange, value });

  return (
    <Select
      id="single-turbine-blade-chooser"
      placeholder="Select Blades..."
      loading={loading}
      allowClear
      showSearch
      filterOption={filterOption}
      mode={mode}
      maxTagCount={1}
      value={loading ? undefined : chooserState}
      onChange={handleChange}
      onDropdownVisibleChange={setVisible}
      disabled={disabled}
      showArrow={!chooserState}
      clearIcon={<ClearIcon />}
      suffixIcon={<SuffixIcon />}
      virtual={false}
    >
      {/* PAPI blades can be null */}
      {blades.filter(notEmpty).map(blade => (
        <Select.Option
          key={blade.id}
          className="single-turbine-blade-chooser-option"
          value={blade.id ?? ''}
        >
          {blade.serial ? `${blade.label} (#${blade.serial})` : blade.label}
        </Select.Option>
      ))}
    </Select>
  );
}

import { useMemo } from 'react';

import {
  AtlasGqlSite,
  AtlasGqlAsset,
  AtlasGqlFilterOperator,
  useAssetSearchQuery,
} from 'types/atlas-graphql';
import { getAssetColumnCopy } from 'components/data/assets/assetColumnRenderers';

export const useNameMapping = (
  ids: string[]
): {
  mapping: { [key: string]: string };
  loading: boolean;
} => {
  // get assets
  const { data: assetsData, loading: assetsLoading } = useAssetSearchQuery({
    variables: {
      filterBy: [
        {
          key: 'assetIds',
          operator: AtlasGqlFilterOperator.Equals,
          values: ids,
        },
      ],
      limit: ids.length,
    },
    skip: ids.length < 1,
  });

  // map assetId and assetName in key:value pairs
  const assetMapping = useMemo(() => {
    const allAssets = (assetsData?.assetsWithMetadata.items ?? []) as AtlasGqlAsset[];
    return allAssets.reduce<{ [key: string]: string }>((acc, asset) => {
      const { id: assetId } = asset;
      if (assetId) {
        if (asset.assetType?.name === 'Site') {
          acc[assetId] = (asset as AtlasGqlSite).name ?? assetId;
        } else {
          acc[assetId] = getAssetColumnCopy({
            asset,
            includeSite: true,
            separatorText: ' | ',
          });
        }
      }

      return acc;
    }, {});
  }, [assetsData]);

  return {
    mapping: { ...assetMapping },
    loading: assetsLoading,
  };
};

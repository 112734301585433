import { Fragment } from 'react';
import { Input, Select, Tag } from 'antd';
import { Tooltip } from 'components/ui';
import moment, { Moment } from 'moment';
import { get, orderBy } from 'lodash';
import COLORS from 'utils/color/definitions';
import { TASK_STATUSES } from 'utils/constants';
import { getStatusColor } from 'utils/color/helpers';
import { startCase, formatDate } from 'utils/format';
import {
  None,
  defaultNone,
  sortBy,
  getOnFilter,
  getFilterDropdown,
  truncateTooltipper,
} from '../../helpers';
import { isOverdue } from 'utils/helpers';
import { Link } from 'react-router-dom';
import {
  AtlasGqlAlert,
  AtlasGqlInspectionDelivery,
  AtlasGqlInspectionTask,
  AtlasGqlOrganization,
  AtlasGqlWorkOrder,
} from 'types/atlas-graphql';
import { UTCDatePicker } from 'components/UTCDatePicker';
import { inlineEditable } from 'utils/editable/InlineEditable/InlineEditable';
import { InfoCircleOutlined } from '@ant-design/icons';

const { TextArea } = Input;

export const createdFrom = {
  key: 'createdFrom',
  title: 'Created From',
  dataIndex: ['alert', 'id'],
  filterProperty: 'alert.id',
  sorter: sortBy('alert.id'),
  get: (record: AtlasGqlAlert) => get(record, ['alert', 'id']),
  render: (id: string) =>
    id ? <Link to={`/alerts/${id}`}>Alert {id.split('-')[0].toUpperCase()}</Link> : <None />,
};

const createdDateFilterConfig = {
  key: 'created',
  type: 'date',
  filterAttr: '',
  exactMatch: '',
  values: '',
};
export const created = {
  key: 'created',
  title: 'Created',
  dataIndex: 'created',
  filterProperty: 'created',
  filterDropdown: getFilterDropdown(createdDateFilterConfig),
  sorter: sortBy('created'),
  get: (record: any) => get(record, 'created'),
  render: (created: string) => (created ? formatDate(created) : <None />),
};

export const createdBy = {
  key: 'createdBy',
  title: 'Created By',
  dataIndex: ['createdBy', 'email'],
  filterProperty: 'createdBy.email',
  sorter: sortBy('createdBy.email'),
  get: (record: any) => get(record, ['createdBy', 'email']),
  render: (email: string) => email || <None />,
};

export const createdAndCreatedBy = {
  key: 'created',
  title: 'Created',
  sorter: sortBy('created'),
  filterProperty: 'created',
  filterDropdown: getFilterDropdown(createdDateFilterConfig),
  onFilter: getOnFilter(createdDateFilterConfig),
  onHeaderCell: () => ({
    'data-testid': 'tasks-created-and-by-header',
  }),
  get: (record: any) => get(record, 'created'),
  render: (record: any) => {
    const { created, createdBy } = record || {};
    if (!created) {
      return <None />;
    }

    const formattedDate = formatDate(created);
    const { email } = createdBy || {};

    if (email) {
      const title = (
        <Fragment>
          <b>By:</b> {email}
        </Fragment>
      );
      return <Tooltip title={title}>{formattedDate}</Tooltip>;
    } else {
      return formattedDate;
    }
  },
};

export const shortDescription = {
  key: 'description',
  title: 'Description',
  dataIndex: 'description',
  sorter: sortBy('description'),
  filterProperty: 'description',
  get: (record: any) => get(record, 'description'),
  render: (des: string) => truncateTooltipper(des, 55),
  edit(record: any, { onChange, ...props }: { onChange: (data: any) => void }) {
    const { description } = record || {};

    return (
      <TextArea
        autoSize
        defaultValue={description}
        onChange={onChange && (e => onChange({ description: e.target.value }))}
        {...props}
      />
    );
  },
};

export const description = {
  ...shortDescription,
  render: (description: string) =>
    description ? (
      <TextArea
        value={description}
        style={{
          cursor: 'inherit',
          backgroundColor: 'inherit',
          color: 'inherit',
          resize: 'none',
          border: 0,
        }}
        disabled
        autoSize
      />
    ) : (
      <None />
    ),
};

export const name = {
  key: 'name',
  title: 'Name',
  dataIndex: 'name',
  sorter: sortBy('name'),
  filterProperty: 'name',
  get: (record: any) => get(record, 'name'),
  render: (name: string) => truncateTooltipper(name, 60),
  edit(record: any, { onChange, ...props }: { onChange: (data: any) => void }) {
    const { name } = record || {};
    return (
      <Input
        style={{ maxWidth: '300px' }}
        defaultValue={name}
        onChange={onChange && (e => onChange({ name: e.target.value }))}
        {...props}
      />
    );
  },
};

const statusFilters = Object.values(TASK_STATUSES).map(value => ({
  text: startCase(value),
  value,
}));

const statusFilterConfig = {
  key: 'status',
  type: 'string',
  filterAttr: '',
  exactMatch: false,
  values: '',
  includeNoneOption: false,
};

export const status = {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
  sorter: sortBy('status'),
  filterProperty: 'status',
  filters: statusFilters,
  filterDropdown: getFilterDropdown(statusFilterConfig),
  onFilter: getOnFilter(statusFilterConfig),
  get: (record: any) => get(record, 'status'),
  render: (status: string) =>
    status ? <Tag color={getStatusColor(status)}>{startCase(status)}</Tag> : <None />,
  edit(record: any, { onChange, ...props }: { onChange: (data: any) => void }) {
    const { status: value } = record || {};
    return (
      <Select
        defaultValue={value}
        onChange={onChange && (status => onChange({ status }))}
        {...props}
      >
        {Object.values(TASK_STATUSES).map(status => (
          <Select.Option key={status} value={status}>
            {startCase(status)}
          </Select.Option>
        ))}
      </Select>
    );
  },
};

const completedDateFilterConfig = {
  key: 'completedDate',
  type: 'date',
  filterAttr: '',
  exactMatch: '',
  values: '',
};
export const completedDate = {
  title: 'Completed Date',
  dataIndex: 'completedDate',
  key: 'completedDate',
  sorter: sortBy('completedDate'),
  get: (record: any) => get(record, 'completedDate'),
  render: (completedDate: string) => (completedDate ? formatDate(completedDate) : <None />),
  getInitialValue: (record: any) =>
    record.completedDate ? moment(record.completedDate) : undefined,
  filterDropdown: getFilterDropdown(completedDateFilterConfig),
  onFilter: getOnFilter(completedDateFilterConfig),
  edit(record: any, { onChange, ...props }: { onChange: (data: any) => void }) {
    const { completedDate: value } = record || {};
    return (
      <UTCDatePicker
        defaultValue={value && moment(value)}
        onChange={(date: Moment | null) => {
          onChange?.({ completedDate: date });
        }}
        {...props}
      />
    );
  },
};

const dueDateFilterConfig = {
  key: 'dueDate',
  type: 'date',
  filterAttr: '',
  exactMatch: '',
  values: '',
};
export const dueDate = {
  title: 'Due Date',
  dataIndex: 'dueDate',
  key: 'dueDate',
  // @ts-ignore TREVOR: Ask riley about what this wants
  render: defaultNone((dueDate, record) => {
    const formattedDate = formatDate(dueDate);

    if (!isOverdue(record)) {
      return formattedDate;
    }

    return (
      <Tooltip title="Overdue">
        <Tag color={COLORS.RED}>{formattedDate}</Tag>
      </Tooltip>
    );
  }),
  filterProperty: 'dueDate',
  sorter: sortBy('dueDate'),
  get: (record: any) => get(record, 'dueDate'),
  getInitialValue: (record: any) => (record.dueDate ? moment(record.dueDate) : undefined),
  filterDropdown: getFilterDropdown(dueDateFilterConfig),
  onFilter: getOnFilter(dueDateFilterConfig),
  edit(record: any, { onChange, ...props }: { onChange: (data: any) => void }) {
    const { dueDate: value } = record || {};
    return (
      <UTCDatePicker
        defaultValue={value && moment(value)}
        onChange={(date: Moment | null) => {
          onChange?.({ dueDate: date });
        }}
        {...props}
      />
    );
  },
};

/*
 * This function builds the header column for forOrganization.
 *
 * @param organizations - array of trusted orgs for the user
 * @param isEditor - boolean based on Horizon role
 * @param features - object based on Horizon feature flags
 * @param task - inspection task the request is being made against
 * @param handleForOrgSave - function to save the forOrganization field
 * @returns an inlineEditable column for the forOrganization field
 */
export const getForOrganization = (
  organizations: Pick<AtlasGqlOrganization, 'id' | 'name'>[],
  isEditor: boolean,
  features: Record<string, boolean>,
  task: AtlasGqlInspectionTask,
  handleForOrgSave?: (input: any) => Promise<void> | undefined
) => {
  const { FOR_ORGANIZATION_EDIT } = features;

  const { forOrganization } = task;

  // Check if we have deliveries. If not, set to empty array.
  const deliveries = task?.inspection?.deliveries ?? [];

  // Check if we can edit the for org field
  const canEditForOrg = () => {
    // If it's been delivered already no one can change it
    if (deliveries.length > 0) return false;

    // If there's currently a forOrganization set only those with FOR_ORGANIZATION_EDIT can set it
    if (FOR_ORGANIZATION_EDIT) return true;

    // Editors without FOR_ORGANIZATION_EDIT can set it if there's no forOrganization set yet
    if (!forOrganization && isEditor) return true;

    // Otherwise, default to false
    return false;
  };

  const columnDef = {
    key: 'forOrganization',
    title: 'For Organization',
    dataIndex: 'forOrganization',
    get: (record: any) => get(record, 'forOrganization'),
    render: (forOrganizationId: any) => {
      const foundOrg = organizations.find((o: any) => o.id === forOrganizationId);

      const renderTooltip = () => {
        // No one can edit if it has been delivered
        if (deliveries.length > 0)
          return 'Field cannot be edited because associated inspection has already been delivered to target organization.';

        // Default to nothing
        return '';
      };

      return <Tooltip title={renderTooltip()}>{foundOrg?.name ?? <None />}</Tooltip>;
    },
    ...(canEditForOrg() &&
      handleForOrgSave && {
        edit(record: any, { onChange }: any) {
          const { forOrganization } = record || {};
          const foundOrg = organizations.find((o: any) => o.id === forOrganization);
          const sortedOrgs = orderBy(organizations, ['name'], ['asc']);

          return (
            <Select
              style={{
                minWidth: '200px',
              }}
              defaultValue={foundOrg?.name ?? undefined}
              onChange={
                onChange &&
                (e => {
                  onChange({ forOrganization: e });
                })
              }
              showSearch
              filterOption
              optionFilterProp="children"
            >
              {Object.values(sortedOrgs).map(org => (
                <Select.Option key={org?.id} value={org?.id}>
                  {org?.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
        // Editing access is restricted by feature flag. `TASK_EDIT` is turned on for the editor role.
        editRule: 'TASK_EDIT',
      }),
  };

  return inlineEditable(columnDef, { onSave: handleForOrgSave });
};

const getMostRecentDeliveryForTargetCustomer = (
  deliveries: AtlasGqlInspectionDelivery[],
  forOrganization: string
) =>
  deliveries
    ?.filter(d => d?.targetOrganizationId === forOrganization)
    .sort((a, b) => new Date(a.created || '').getTime() - new Date(b.created || '').getTime())[
    deliveries.length - 1
  ];

export const deliveryStatus = {
  key: 'deliveryStatus',
  title: 'Delivery Status',
  dataIndex: ['inspection', 'deliveries'],
  get: record => get(record, ['inspection', 'deliveries']),
  render: (deliveries: AtlasGqlInspectionDelivery[], record) => {
    const delivery = getMostRecentDeliveryForTargetCustomer(deliveries, record?.forOrganization);
    const status = delivery?.status;
    return status ? <Tag color={getStatusColor(status)}>{startCase(status)}</Tag> : 'Not Delivered';
  },
};

export const deliveryDate = {
  key: 'deliveryDate',
  title: 'Date Delivered',
  dataIndex: ['inspection', 'deliveries'],
  get: record => get(record, ['inspection', 'deliveries']),
  render: (deliveries: AtlasGqlInspectionDelivery[], record) => {
    const delivery = getMostRecentDeliveryForTargetCustomer(deliveries, record?.forOrganization);
    const created = delivery?.created;
    return created ? formatDate(created) : 'N/A';
  },
};

// this differs from the work order's vendorOrgColumn in that it's found on the
// parent work order
export const taskVendorOrgHeaderColumn = {
  key: 'taskVendorOrg',
  title: 'Vendor',
  dataIndex: ['parent'],
  render: (workOrder?: AtlasGqlWorkOrder | null) => {
    if (!workOrder) {
      return (
        <>
          <None />{' '}
          <Tooltip title="Vendor is determined by work order. You may add this task to a work order and enter the vendor there.">
            <InfoCircleOutlined />
          </Tooltip>
        </>
      );
    }

    return (
      <>
        {workOrder?.vendorOrganization?.name || <None />}{' '}
        <Tooltip
          title={
            <>
              {"Vendor is determined by the task's "}
              <Link to={`/work-orders/${workOrder?.id}`}>work order</Link>
              {'. You may update the value from the work order.'}
            </>
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </>
    );
  },
};

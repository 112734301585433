import { Collapse } from 'antd5';
import styled from 'styled-components';
import COLORS from 'utils/color/definitions';

export const SwitchContainer = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => (disabled ? `color: ${COLORS.SECONDARY_TEXT_COLOR}` : '')};
  margin-bottom: 0.5rem;
  max-width: 160px;
  text-align: right;

  button {
    width: 44px;
  }

  span {
    color: #00000055;
    cursor: pointer;
    margin: 0 0.25rem 0 0;
  }
`;

export const AdvancedFiltersCollapse = styled(Collapse)`
  margin: 0 0 8px 8px;
  width: 324px;

  .ant-collapse-expand-icon {
    padding-inline-end: 0 !important;
  }

  .ant-collapse-content-box {
    background: white;
    padding: 0 8px 6px 36px !important;
  }
`;

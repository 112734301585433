import { Modal } from 'antd';
import { DamageLink, ObservationLink } from 'horizon/components/Damages/utils';
import { AtlasGqlHorizonDamageObservationGroup } from 'types/atlas-graphql';
import { Button } from 'components/ui';

type RemoveConfirmationModalProps = {
  damageId: string;
  observationId: string;
  groupForObservation?: AtlasGqlHorizonDamageObservationGroup;
  isOpen: boolean;
  loading: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const RemoveConfirmationModal: React.FunctionComponent<RemoveConfirmationModalProps> = ({
  damageId,
  observationId,
  groupForObservation,
  isOpen,
  loading,
  onConfirm,
  onClose,
}) => {
  const observation = groupForObservation?.observations?.find(o => o?.id === observationId);
  const remainingObservationsInGroup =
    groupForObservation?.observations?.filter(o => o?.id !== observationId) ?? [];

  const damageLink = <DamageLink damageId={damageId} target={'_blank'} />;
  const observationLink = (
    <ObservationLink
      observationId={observationId}
      pictureId={observation?.picture?.id}
      target={'_blank'}
    />
  );

  return (
    <Modal
      title={'Unlink Observation'}
      visible={isOpen}
      onCancel={onClose}
      destroyOnClose={true}
      footer={
        <>
          <Button _version={4} onClick={onClose}>
            Cancel
          </Button>
          <Button _version={4} onClick={onConfirm} loading={loading} type="primary">
            Unlink
          </Button>
        </>
      }
    >
      {groupForObservation?.isUserEdited ? (
        remainingObservationsInGroup.length === 1 ? (
          <>
            Observation {observationLink} will be unlinked from Damage {damageLink}. Any Observation
            Group attributes that were manually altered will not be preserved.
          </>
        ) : (
          <>
            Observation {observationLink} will be unlinked from Damage {damageLink}. Observation
            Group attributes will not be applied to Observation {observationLink}.
          </>
        )
      ) : (
        <>
          Observation {observationLink} will be unlinked from Damage {damageLink}.
        </>
      )}
    </Modal>
  );
};

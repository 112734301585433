import { useSafeState } from 'utils/hooks';

interface IUseManagedAssetChooser {
  assetChooserOnChangeCallback?: any;
  componentId?: string | null;
  storage?: any | null;
}

export const useManagedAssetChooser = ({
  assetChooserOnChangeCallback,
  componentId = null,
  storage = null,
}: IUseManagedAssetChooser = {}) => {
  const [locationIds, setLocationIds] = useSafeState(undefined);
  const [turbineIds, setTurbineIds] = useSafeState(undefined);
  const [bladeIds, setBladeIds] = useSafeState(undefined);
  const [assetIds, setAssetIds] = useSafeState(undefined);
  const [orgId, setOrgId] = useSafeState(undefined);
  const [initialized, setInitialized] = useSafeState(false);

  const loadState = () => {
    const state = storage.getItem(componentId, true);

    if (storage && componentId) {
      setInitialized(true);
      return state || {};
    }
    return {};
  };

  const doSaveState = (data: any) => {
    if (initialized && componentId && storage) {
      storage.setItem(componentId, data, true);
    }
  };

  const handleAssetChooserChange = ({
    locationIds,
    turbineIds,
    bladeIds,
    assetIds,
    orgId,
  }: any) => {
    doSaveState({ locationIds, turbineIds, bladeIds, assetIds, orgId });
    setLocationIds(locationIds);
    setTurbineIds(turbineIds);
    setBladeIds(bladeIds);
    setAssetIds(assetIds);
    setOrgId(orgId);
    if (assetChooserOnChangeCallback) {
      assetChooserOnChangeCallback({ locationIds, turbineIds, bladeIds, assetIds, orgId });
    }
  };

  const handleClearAssetChooserSelection = () => {
    initialized && doSaveState({});
    setLocationIds(undefined);
    setTurbineIds(undefined);
    setBladeIds(undefined);
    setAssetIds(undefined);
    setOrgId(undefined);
    if (assetChooserOnChangeCallback) {
      assetChooserOnChangeCallback({ assetIds: null });
    }
  };

  return {
    onChange: handleAssetChooserChange,
    onClearSelected: handleClearAssetChooserSelection,
    loadState,
    locationIds,
    turbineIds,
    bladeIds,
    assetIds,
    orgId,
    callerManagedState: true,
  };
};

import styled from 'styled-components';
import { Table } from 'antd';

import COLORS from 'utils/color/definitions';
import { ArchivedRowStyle } from 'components/DataTable/DataTable.style';

export const StyledPopoverTable = styled(Table)`
  .ant-table-row {
    background-color: ${COLORS.WHITE};
  }
`;

export const StyledTasksPopoverTable = styled(Table)`
  .ant-table-row {
    background-color: ${COLORS.WHITE};
  }
  ${ArchivedRowStyle}
`;

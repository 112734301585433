import { Progress } from 'antd';
import { ProgressWrapper, Summary, ProgressElement } from './GenericProgressToast.style';
import { ProgressDetails } from './GenericProgressContext';

export function GenericProgressToast({
  description,
  totalQuantity,
  quantityCompleted,
}: ProgressDetails) {
  return (
    <ProgressElement>
      <span>{description}:</span>
      <ProgressWrapper>
        <Summary>
          {quantityCompleted} / {totalQuantity}
        </Summary>
        <Progress
          percent={Math.round((quantityCompleted / totalQuantity) * 100)}
          showInfo={false}
          status="active"
        />
      </ProgressWrapper>
    </ProgressElement>
  );
}

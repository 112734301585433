import styled, { keyframes, css } from 'styled-components';
import { Button } from 'components/ui';

const pulse = keyframes`
0% {
    box-shadow: 0 0 0 0 rgba(48, 174, 228, 0.4);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(48, 174, 228, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(48, 174, 228, 0);
  }
`;

export const PulseButton = styled(({ viewingProgress, ...rest }) => (
  <Button _version={4} {...rest} />
))`
  border: none;
  box-shadow: none;
  animation: ${({ viewingProgress }) =>
    viewingProgress
      ? 'none'
      : css`
          ${pulse} 2s infinite;
        `};
  margin-top: 1.25rem;
  &:hover {
    animation: none;
  }
  svg {
    height: 1.25rem;
    width: 1.25rem;
  }
`;

import { Link } from 'react-router-dom';
import { get } from 'lodash';

import { None, divide, moreTooltipper, sortBy, ASSET_TYPENAMES } from '../../helpers';
import naturalSort from 'utils/naturalSort';
import { getUniqueIdentifier } from 'horizon/components/Assets/AssetDetails/helpers';

export const removeAssetLink = column => {
  switch (column.key) {
    case 'site':
      return {
        ...column,
        render: record => {
          const location = getLocation(record);
          const { name } = location || {};
          return location ? name : <None />;
        },
      };
    case 'turbine':
      return {
        ...column,
        render: record => {
          const turbine = getTurbine(record);
          const { name } = turbine || {};
          return turbine ? name : <None />;
        },
      };
    case 'blade':
      return {
        ...column,
        render: record => {
          const blade = getBlade(record);
          return blade ? getBladeDisplayName(blade) : <None />;
        },
      };
    case 'asset':
      return {
        ...column,
        render: task => {
          if (!task || (!task.asset && !task.turbine)) return <None />;

          const asset = task.asset || task.turbine;

          if (asset.__typename === ASSET_TYPENAMES.siteLocation) {
            const location = asset;
            return location.name;
          }

          if (asset.__typename === ASSET_TYPENAMES.turbine) {
            const turbine = asset;
            const { location } = turbine;
            return divide([location.name, `Turbine ${turbine.name}`]);
          }

          if (asset.__typename === ASSET_TYPENAMES.blade) {
            const blade = asset;
            const { turbine } = blade;
            const { location } = blade;

            return divide([
              location && location.name,
              turbine && `Turbine ${turbine.name}`,
              blade && `Blade ${blade.label} ${blade.serial && `(#${blade.serial})`}`,
            ]);
          }

          return <None />;
        },
      };
    default:
      return <None />;
  }
};

function getAncestorOfType(asset, typenames) {
  const ancestorsArray = asset.ancestors ?? [];
  return ancestorsArray.find(asset => typenames.includes(asset.__typename)) ?? null;
}

// task: the task record to extract the asset from
// typenames: an array of strings representing acceptable typenames for the asset to be.
// Ex: looking for a site pass in typenames = ['Site']
// Ex: looking for blade OR any generic asset, pass in typenames = ['Blade', 'GenericAsset']
function getAsset(task, typenames) {
  // If there's no task or asset, there's no way to determine site.
  if (task === null || !task.asset) {
    return null;
  }

  const asset = task.asset;

  // If direct asset is an instance of typename, we've got it.
  if (typenames.includes(asset.__typename)) {
    return asset;
  }

  // If not, find the instance of typename in ancestors.
  return getAncestorOfType(asset, typenames);
}

function getLocation(task) {
  if (!task) {
    return null;
  }

  return get(task, getLocationIndex(task.asset));
}

function getLocationIndex(asset) {
  if (!asset) {
    return null;
  }

  if (asset.__typename === ASSET_TYPENAMES.siteLocation) {
    return ['asset'];
  }
  if (asset.__typename === ASSET_TYPENAMES.turbine) {
    return ['asset', 'location'];
  }
  if (asset.__typename === ASSET_TYPENAMES.blade) {
    return ['asset', 'turbine', 'location'];
  }

  // PAPI Unplanned Tasks Table will set this for handling locations for generic assets
  if (asset.__typename === ASSET_TYPENAMES.asset) {
    return ['asset', 'location'];
  }
}

function getTurbine(record) {
  if (!record) {
    return null;
  }

  return get(record, getTurbineIndex(record.asset));
}

function getTurbineIndex(asset) {
  if (!asset) {
    return null;
  }

  if (asset.__typename === ASSET_TYPENAMES.turbine) {
    return ['asset'];
  }
  if (asset.__typename === ASSET_TYPENAMES.blade) {
    return ['asset', 'turbine'];
  }

  // PAPI Unplanned Tasks Table will set this for handling turbines for generic assets
  if (asset.__typename === ASSET_TYPENAMES.asset) {
    return ['asset', 'turbine'];
  }
}

function getBlade(record) {
  if (!record) {
    return null;
  }

  return get(record, getBladeIndex(record.asset));
}

function getBladeIndex(asset) {
  if (!asset) {
    return null;
  }

  if (asset.__typename === ASSET_TYPENAMES.blade) {
    return ['asset'];
  }
}

function getBladeDisplayName(blade) {
  if (blade) {
    const { label, serial } = blade;
    return `${label} ${serial ? `(#${serial})` : ''}`;
  }
}

function getAssetBladeDisplayName(blade) {
  if (blade) {
    const { label, serial } = blade;
    const trimmedSerial = serial.trim();
    return `${label} ${trimmedSerial !== '(No Serial Number)' ? `(#${serial})` : trimmedSerial}`;
  }
}

export const location = {
  title: 'Asset',
  key: 'asset',
  render: task => {
    if (!task || (!task.asset && !task.turbine && !task.blade)) return <None />;

    const asset = task.asset || task.turbine || task.blade;

    if (asset.__typename === ASSET_TYPENAMES.siteLocation) {
      const location = asset;
      return (
        <Link key={location.id} to={`/sites/${location.id}`}>
          {location.name}
        </Link>
      );
    }

    if (asset.__typename === ASSET_TYPENAMES.turbine) {
      const turbine = asset;
      const { location } = turbine;
      return divide([
        <Link key={location.id} to={`/sites/${location.id}`}>
          {location.name}
        </Link>,
        <Link key={turbine.id} to={`/turbines/${turbine.id}`}>
          Turbine {turbine.name}
        </Link>,
      ]);
    }

    if (asset.__typename === ASSET_TYPENAMES.blade) {
      const blade = asset;
      const { turbine } = blade;
      const { location } = blade;

      return divide([
        location && (
          <Link key={location.id} to={`/sites/${location.id}`}>
            {location.name}
          </Link>
        ),
        turbine && (
          <Link key={turbine.id} to={`/turbines/${turbine.id}`}>
            Turbine {turbine.name}
          </Link>
        ),
        blade && (
          <Link key={blade.id} to={`/blades/${blade.id}`}>
            Blade {blade.label} {blade.serial && `(#${blade.serial})`}
          </Link>
        ),
      ]);
    }

    return <None />;
  },
};

export const taskSites = {
  title: 'Sites',
  key: 'site',
  dataIndex: 'tasks',
  sorter: (a, b) => {
    const taskA = a.tasks.find(task => getLocation(task));
    const taskB = b.tasks.find(task => getLocation(task));
    const aValue = get(getLocation(taskA), 'name', null);
    const bValue = get(getLocation(taskB), 'name', null);
    return aValue || bValue ? naturalSort(aValue, bValue) : 0;
  },
  filterProperty: 'tasks',
  onFilter: (value, record) => {
    const inputValue = value.toLowerCase();
    const siteNames = record.tasks.map(task => {
      let name = get(getLocation(task), 'name', null);
      if (name) {
        return name.toLowerCase();
      } else {
        return name;
      }
    });
    return siteNames.join(' ').indexOf(inputValue) !== -1;
  },
  render: tasks => {
    if (!tasks || !tasks.length) return <None />;
    const sites = tasks
      .map(record => {
        const location = getLocation(record);
        return get(location, 'name');
      })
      .filter(Boolean)
      .sort();
    return moreTooltipper([...new Set(sites)]);
  },
};

export const assetSite = {
  title: 'Site',
  key: 'site',
  sorter: (a, b) => {
    const aValue = get(getLocation(a), 'name', null);
    const bValue = get(getLocation(b), 'name', null);
    return aValue || bValue ? naturalSort(aValue, bValue) : 0;
  },
  get(record) {
    const locationIndex = getLocationIndex(record.asset);
    return locationIndex ? get(record, [...locationIndex, 'name']) : null;
  },
  render: record => {
    const location = getLocation(record);
    const { id, name } = location || {};
    return location ? (
      <Link key={id} to={`/sites/${id}/tasks`}>
        {name}
      </Link>
    ) : (
      <None />
    );
  },
};

export const legacyWorkOrderSites = {
  title: 'Sites',
  key: 'site',
  dataIndex: 'tasks',
  render: tasks => {
    if (!tasks || !tasks.length) return <None />;
    const sites = tasks
      .map(record => {
        const location = getLocation(record);
        return get(location, 'name');
      })
      .filter(Boolean)
      .sort();
    return moreTooltipper([...new Set(sites)]);
  },
};

export const workOrderSites = {
  title: 'Sites',
  key: 'site',
  dataIndex: 'tasks',
  render: tasks => {
    if (!tasks || !tasks.length) return <None />;
    const sites = tasks
      .map(record => {
        const site = getAsset(record, [ASSET_TYPENAMES.site]);
        // We want name for a Site
        return get(site, 'name');
      })
      .filter(Boolean)
      .sort();
    return moreTooltipper([...new Set(sites)]);
  },
};

export const taskTurbines = {
  key: 'turbine',
  title: 'Turbines',
  dataIndex: 'tasks',
  sorter: (a, b) => {
    const taskA = a.tasks.find(task => getTurbine(task));
    const taskB = b.tasks.find(task => getTurbine(task));
    const aValue = get(getTurbine(taskA), 'name', null);
    const bValue = get(getTurbine(taskB), 'name', null);
    return aValue || bValue ? naturalSort(aValue, bValue) : 0;
  },
  filterProperty: 'tasks',
  onFilter: (value, record) => {
    const inputValue = value.toLowerCase();
    const turbineNames = record.tasks.map(task => {
      let name = get(getTurbine(task), 'name', null);
      if (name) {
        return name.toLowerCase();
      } else {
        return name;
      }
    });
    return turbineNames.join(' ').indexOf(inputValue) !== -1;
  },
  render: tasks => {
    if (!tasks || !tasks.length) return <None />;
    const turbines = tasks
      .map(record => {
        const turbine = getTurbine(record);
        return get(turbine, 'name');
      })
      .filter(Boolean)
      .sort();

    return moreTooltipper([...new Set(turbines)]);
  },
};

export const assetTurbine = {
  title: 'Turbine',
  key: 'turbine',
  sorter: (a, b) => {
    const aValue = get(getTurbine(a), 'name', null);
    const bValue = get(getTurbine(b), 'name', null);
    return aValue || bValue ? naturalSort(aValue, bValue) : 0;
  },
  get(record) {
    const turbineIndex = getTurbineIndex(record.asset);
    return turbineIndex ? get(record, [...turbineIndex, 'name']) : null;
  },
  render: record => {
    const turbine = getTurbine(record);
    const { id, name } = turbine || {};
    return turbine ? (
      <Link key={id} to={`/turbines/${id}/tasks`}>
        {name}
      </Link>
    ) : (
      <None />
    );
  },
};

export const legacyWorkOrderTurbines = {
  key: 'turbine',
  title: 'Turbines',
  dataIndex: 'tasks',
  render: tasks => {
    if (!tasks || !tasks.length) return <None />;
    const turbines = tasks
      .map(record => {
        const turbine = getTurbine(record);
        return get(turbine, 'name');
      })
      .filter(Boolean)
      .sort();

    return moreTooltipper([...new Set(turbines)]);
  },
};

export const workOrderTurbines = {
  key: 'turbine',
  title: 'Turbines',
  dataIndex: 'tasks',
  render: tasks => {
    if (!tasks || !tasks.length) return <None />;
    const turbines = tasks
      .map(record => {
        const turbine = getAsset(record, [ASSET_TYPENAMES.turbine]);
        return get(turbine, 'name');
      })
      .filter(Boolean)
      .sort();

    return moreTooltipper([...new Set(turbines)]);
  },
};

export const taskBlades = {
  title: 'Blades',
  key: 'blade',
  dataIndex: 'tasks',
  sorter: (a, b) => {
    const taskA = a.tasks.find(task => getBlade(task));
    const taskB = b.tasks.find(task => getBlade(task));
    const aValue = taskA ? taskA.asset.label : null;
    const bValue = taskB ? taskB.asset.label : null;
    return aValue || bValue ? naturalSort(aValue, bValue) : 0;
  },
  filterProperty: 'tasks',
  onFilter: (value, record) => {
    const inputValue = value.toLowerCase();
    const bladeNames = record.tasks.map(task => {
      const blade = getBlade(task);
      if (blade) {
        return blade.label.toLowerCase();
      } else {
        return null;
      }
    });
    return bladeNames.join('').indexOf(inputValue) !== -1;
  },
  render: tasks => {
    if (!tasks || !tasks.length) return <None />;
    const blades = tasks
      .map(record => {
        const blade = getBlade(record);
        return getBladeDisplayName(blade);
      })
      .filter(Boolean)
      .sort();
    return moreTooltipper([...new Set(blades)]);
  },
};

export const assetBlade = {
  title: 'Blade',
  key: 'blade',
  sorter: sortBy('asset.label'),
  get(record) {
    const bladeIndex = getBladeIndex(record.asset);
    return bladeIndex ? get(record, [...bladeIndex, 'label']) : null;
  },
  render: record => {
    const blade = getBlade(record);
    const { id } = blade || {};
    return blade ? (
      <Link key={id} to={`/blades/${id}/tasks`}>
        {getBladeDisplayName(blade)}
      </Link>
    ) : (
      <None />
    );
  },
};

export const atlasAssetBlade = {
  title: 'Blade',
  key: 'component',
  sorter: sortBy('asset.position'),
  render: data => {
    const asset = data?.asset;
    if (!asset) return <None />;
    const { id, position } = asset || {};
    const uniqueId = getUniqueIdentifier(asset);
    return position ? (
      <Link key={id} to={`/blades/${id}`}>
        {getAssetBladeDisplayName({ label: position, serial: uniqueId })}
      </Link>
    ) : (
      <None />
    );
  },
};

export const legacyWorkOrderBlade = {
  title: 'Blades',
  key: 'blade',
  dataIndex: 'tasks',
  render: tasks => {
    if (!tasks || !tasks.length) return <None />;
    const blades = tasks
      .map(record => {
        const blade = getBlade(record);
        return getBladeDisplayName(blade);
      })
      .filter(Boolean)
      .sort();
    return moreTooltipper([...new Set(blades)]);
  },
};

export const workOrderComponent = {
  title: 'Component',
  key: 'asset',
  dataIndex: 'tasks',
  render: tasks => {
    if (!tasks || !tasks.length) return <None />;
    const components = tasks
      .map(record => {
        const component = getAsset(record, [ASSET_TYPENAMES.blade, ASSET_TYPENAMES.asset]);
        return get(component, 'uniqueIdLabel');
      })
      .filter(Boolean)
      .sort();
    return moreTooltipper([...new Set(components)]);
  },
};

// include bugsnag first to catch any load/init issues
import { ErrorBoundary } from 'components/ErrorBoundary';
import ReactDOM from 'react-dom';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from 'styled-components';
// import { DevTools as JotaiDevTools } from 'jotai-devtools'; // Uncomment to enable jotai devtools
import skyspecsTheme from 'utils/color/theme-skyspecs';
import { LastLocationProvider } from 'react-router-last-location';
import { Router } from 'horizon/components/Router';
import { Unsupported } from 'components/Unsupported';
import { AUTH0_SCOPE, AuthContextProvider, Session } from 'utils/auth';
import { ApolloClientProvider } from 'utils/apollo';
import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  CI_MERGE_REQUEST_IID,
  CI_PROJECT_ID,
  CI_PROJECT_PATH,
  HORIZON_ENDPOINT,
  isProdReview,
} from 'utils/env';
import { ReduxProvider } from 'utils/state';
import { isIE } from 'utils/document';
import { FileUploaderProvider } from 'components/FileUploader';
import { GenericProgressContextProvider } from 'horizon/components/GlobalProgressIndicator/GenericProgress/GenericProgressContext';
import { ImageNavButtonDisabledProvider } from 'components/damages/DamageModal/ImageNavButton.Context';
import { Helmet } from 'react-helmet';
import { AuthRedirect, Logout } from './routes/Auth0';
import Bootstrap from './Bootstrap';
import { AccountContextProvider } from 'utils/account/AccountContextProvider';
import 'utils/i18n';
import Maintenance from './components/Maintenance';

const showGitlabReview = CI_MERGE_REQUEST_IID && !isProdReview;

function EntryPoint() {
  return (
    <Switch>
      <Route exact={true} path="/auth_redirect" component={AuthRedirect} />
      <Route exact={true} path="/logout" component={Logout} />
      <Route
        path="/"
        render={() => (
          <Session>
            <AccountContextProvider>
              <Bootstrap />
            </AccountContextProvider>
          </Session>
        )}
      />
    </Switch>
  );
}

function AuthProvider() {
  const { replace } = useHistory();
  const auth0RedirectUri =
    typeof window !== 'undefined' ? window.location.origin : HORIZON_ENDPOINT;

  return (
    <Auth0Provider
      authorizationParams={{
        audience: 'https://api.int.skyspecs.com/fleet/graphql',
        redirect_uri: auth0RedirectUri,
        scope: AUTH0_SCOPE,
      }}
      cacheLocation="localstorage"
      clientId={AUTH0_CLIENT_ID}
      domain={AUTH0_DOMAIN}
      onRedirectCallback={appState => {
        // `returnTo` and `state` are originally set in Session, but then sent
        // to `loginWithRedirect()` in AuthRedirect
        replace(appState?.returnTo || window?.location?.pathname || '/', {
          state: appState?.state ? JSON.parse(appState.state) : undefined,
        });
      }}
      useRefreshTokens={true}
      useRefreshTokensFallback={true} // If the refresh token fails, try to re-auth using silent session auth
    >
      <AuthContextProvider>
        <ApolloClientProvider>
          {/*<JotaiDevTools /> /!* uncomment to enable jotai devtools *!/*/}
          <EntryPoint />
        </ApolloClientProvider>
      </AuthContextProvider>
    </Auth0Provider>
  );
}

if (isIE()) {
  ReactDOM.render(<Unsupported />, document.getElementById('root'));
} else {
  ReactDOM.render(
    <ErrorBoundary>
      <Helmet>
        {showGitlabReview && (
          <script
            defer
            src="https://gitlab.com/assets/webpack/visual_review_toolbar.js"
            id="review-app-toolbar-script" // must be exactly this
            data-project-id={CI_PROJECT_ID}
            data-merge-request-id={CI_MERGE_REQUEST_IID}
            data-project-path={CI_PROJECT_PATH}
            data-require-auth
            data-mr-url="https://gitlab.com"
          />
        )}
      </Helmet>
      <GenericProgressContextProvider>
        <FileUploaderProvider>
          {/* Possibly find a closer parent component that uses ImageNavButtonDisabledProvider
            this provider only controls disabling shortcutkeys when viewing an image that has
            an ImageNavButtons component.
        */}
          <ImageNavButtonDisabledProvider>
            <ThemeProvider theme={skyspecsTheme}>
              <Router>
                <Maintenance>
                  <LastLocationProvider>
                    <ReduxProvider>
                      <AuthProvider />
                    </ReduxProvider>
                  </LastLocationProvider>
                </Maintenance>
              </Router>
            </ThemeProvider>
          </ImageNavButtonDisabledProvider>
        </FileUploaderProvider>
      </GenericProgressContextProvider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
}

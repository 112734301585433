import { useRef, useState } from 'react';
import { ImageThumbnail, ImageThumbnailProps } from './ImageThumbnail';
import { StyledCarousel, EmptyImage, CarouselContainer } from './ImageThumbnail.style';
import EmptyState from 'components/EmptyState';
import { Button } from 'components/ui';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { CarouselRef } from 'antd5/es/carousel';

type ThumbnailCarouselProps = {
  thumbnails: {
    [K in keyof Omit<ImageThumbnailProps, 'handleClickObservation' | 'allowZoom'>]?:
      | ImageThumbnailProps[K]
      | null
      | undefined;
  }[]; // Partial<T>, but allow null | undefined (to allow for null or undefined picture, which is handled when rendering)
  initialSlideIndex?: number;
  allowZoom?: boolean;
  shouldShowSeverity?: boolean;
  shouldShowDistance?: boolean;
};

export const ThumbnailCarousel: React.FunctionComponent<ThumbnailCarouselProps> = ({
  thumbnails,
  initialSlideIndex,
  allowZoom = false,
  shouldShowSeverity,
  shouldShowDistance,
}) => {
  const carouselRef = useRef<CarouselRef>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(initialSlideIndex ?? 0);

  return (
    <CarouselContainer>
      {thumbnails.length > 1 && (
        <>
          <Button
            className="prev-button"
            title="Previous Image"
            disabled={currentSlide === 0}
            icon={<LeftOutlined />}
            onClick={() => {
              carouselRef.current?.prev();
            }}
          />
          <Button
            className="next-button"
            title="Next Image"
            disabled={currentSlide === thumbnails.length - 1}
            icon={<RightOutlined />}
            onClick={() => {
              carouselRef.current?.next();
            }}
          />
        </>
      )}
      <StyledCarousel
        ref={carouselRef}
        initialSlide={initialSlideIndex ?? 0}
        afterChange={setCurrentSlide}
      >
        {thumbnails.map((thumbnailProps, i) =>
          !!thumbnailProps.picture ? (
            <ImageThumbnail
              key={i}
              {...(thumbnailProps as Omit<ImageThumbnailProps, 'handleClickObservation'>)}
              allowZoom={allowZoom}
              shouldShowSeverity={shouldShowSeverity}
              shouldShowDistance={shouldShowDistance}
              $isInCarousel={thumbnails.length > 1}
            />
          ) : (
            <EmptyImage key={i}>
              <EmptyState text="No Image Available" />
            </EmptyImage>
          )
        )}
      </StyledCarousel>
    </CarouselContainer>
  );
};

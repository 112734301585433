import { isFinite } from 'lodash';

// Returns the number of decimal places in a number value
export const getDecimalPlaces = (n: any) => {
  if (!isFinite(n)) {
    return 0;
  }

  let decimals = 0;
  while (Math.round(10 ** decimals * n) / 10 ** decimals !== n) {
    decimals += 1;
  }

  return decimals;
};

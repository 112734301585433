import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components/ui';
import { FlexContainer } from 'utils/layouts/containers';
import { TSelectableActionInput } from './types';

const SelectedItems = styled.span`
  margin-right: 0.5rem;
`;

const ClearButton = styled(Button)`
  padding: 0 0.5rem;
`;

export function useSelectableRecords<T>(onSelectionChange: Function | null = null) {
  const [selectedRows, setSelectedRows] = useState<T[]>([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const handleSelect = useCallback(
    (keys: any, rows: any) => {
      setSelectedRows(rows);
      setSelectedKeys(keys);

      onSelectionChange && onSelectionChange(rows);
    },
    [onSelectionChange]
  );

  const handleClearSelection = useCallback(() => {
    setSelectedRows([]);
    setSelectedKeys([]);

    onSelectionChange && onSelectionChange([]);
  }, [onSelectionChange]);

  const rowSelection = useMemo(() => {
    return {
      onChange: handleSelect,
      selectedRowKeys: selectedKeys,
      preserveSelectedRowKeys: true,
    };
  }, [handleSelect, selectedKeys]);

  return {
    rowSelection,
    onClearSelection: handleClearSelection,
    selected: selectedRows,
  };
}

export const SelectableAction = (input: TSelectableActionInput) => {
  const { selected = [], showSelected = true, onClear, children = null } = input;
  const { length } = selected;
  const selectedVisible = !!length && showSelected;

  return (
    <FlexContainer>
      {selectedVisible && (
        <SelectedItems>
          {length}
          {` item${length === 1 ? '' : 's'} `}
          selected
          <ClearButton _version={4} type="link" onClick={onClear}>
            clear
          </ClearButton>
        </SelectedItems>
      )}
      {children}
    </FlexContainer>
  );
};

import { Fragment } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Divider, Modal } from 'antd';

import LinkButton from 'components/LinkButton';

const EditActions = ({
  isInputValid = true,
  onEdit,
  onCancel,
  onSave,
  onRemove,
  removeText = 'Remove',
  onRemoveMessaging = 'This action is permanent. Are you sure you want to delete?',
  record,
  editing,
  canEdit = () => false,
  canRemove = () => false,
}) => {
  const buttonProps = {
    value: record.id,
    type: 'button',
  };
  if (!canEdit() && !canRemove()) return null;

  if (editing) {
    return (
      <Fragment>
        <LinkButton data-testid="cancelEditBtn" {...buttonProps} onClick={onCancel}>
          Cancel
        </LinkButton>
        <Divider type="vertical" />
        {isInputValid ? (
          <LinkButton
            id="edit-save-link-button"
            data-testid="saveEditBtn"
            {...buttonProps}
            onClick={onSave}
          >
            Save
          </LinkButton>
        ) : null}
      </Fragment>
    );
  }
  return (
    <Fragment>
      {canEdit() && (
        <LinkButton data-testid="edit-button" {...buttonProps} onClick={onEdit}>
          Edit
        </LinkButton>
      )}
      {canRemove() && onRemove && (
        <Fragment>
          <Divider type="vertical" />
          <LinkButton
            {...buttonProps}
            onClick={() => {
              Modal.confirm({
                title: 'Are you sure?',
                icon: <DeleteOutlined />,
                content: onRemoveMessaging,
                onOk: () => {
                  onRemove(record.id);
                },
                okText: removeText,
              });
            }}
          >
            {removeText}
          </LinkButton>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditActions;

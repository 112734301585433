import { Spin as SpinV5, SpinProps as SpinPropsV5 } from 'antd5';
import { Spin as SpinV4, SpinProps as SpinPropsV4 } from 'antd';
import { Version4Props, Version5Props } from '../types';

interface PropsV5 extends Version5Props, SpinPropsV5 {}
interface PropsV4 extends Version4Props, SpinPropsV4 {}
type Props = PropsV5 | PropsV4;

export function Spin({ _version = 5, ...props }: Props) {
  if (_version === 4) {
    const { tip, ...remainingProps } = props;
    return <SpinV4 tip={typeof tip === 'string' ? tip : undefined} {...remainingProps} />;
  }

  return <SpinV5 {...props} />;
}

import {
  AtlasGqlOrganizationRelationshipScope,
  useGetOrganizationRelationshipsQuery,
} from 'types/atlas-graphql';
import { notEmpty } from 'utils/types';

interface UseOrganizationRelationshipsArgs {
  scope?: AtlasGqlOrganizationRelationshipScope;
  skip?: boolean;
  types?: ('vendor' | 'delivery')[];
}

export function useOrganizationRelationships({
  scope = AtlasGqlOrganizationRelationshipScope.RelationshipOwner,
  skip,
  types,
}: UseOrganizationRelationshipsArgs = {}) {
  const { data, error, loading } = useGetOrganizationRelationshipsQuery({
    skip,
    variables: { scope, types },
  });

  const relationships = data?.organizationRelationships?.records;
  let errors = data?.organizationRelationships?.errors;

  if (!errors && error) {
    errors = [{ message: error.message, __typename: 'UserInputError' }];
  }

  if (loading) {
    return { loading, relationships: [] };
  }

  return { loading, errors, relationships };
}

interface UseOrganizationVendorRelationshipsArgs {
  skip?: boolean;
}

export function useOrganizationVendorRelationships({
  skip,
}: UseOrganizationVendorRelationshipsArgs = {}) {
  const { errors, loading, relationships } = useOrganizationRelationships({
    skip,
    scope: AtlasGqlOrganizationRelationshipScope.RelationshipOwner,
    types: ['vendor'],
  });

  const vendors = relationships?.map(r => r.relationshipPartner).filter(notEmpty) ?? [];
  const errorsString = errors?.map(e => e.message).join('. ');

  return { errors, errorsString, loading, vendors };
}

import { ImageViewer } from 'components/ImageViewer';
import EmptyState from 'components/EmptyState';
import { AtlasGqlHorizonDamageObservation } from 'types/atlas-graphql';

import { Pane, EmptyImage } from './ComparerPane.style';

/**
 * This component and its children are deprecated and will be removed during cleanup of
 * rtv1:new_image_viewer
 *
 * Further maintenance should not be needed, check with Riley with any questions.
 */

type ComparerPaneProps = {
  observation: AtlasGqlHorizonDamageObservation | undefined;
  children: JSX.Element;
};

export const ComparerPane: React.FunctionComponent<ComparerPaneProps> = ({
  observation,
  children,
}) => {
  const picture = observation?.picture;

  return (
    <Pane>
      {picture ? (
        <ImageViewer
          // JZ: `key` added to force remount on image change in the compare-o-tron. Used to
          // solve a bug in `ImageViewer` where component wouldn't update the image as expected
          key={picture.id}
          id={picture.id}
          image={picture.image}
          target={picture.target}
          homography={picture.homography}
          isHorizonDamage={true}
          defaultObservationId={observation.id}
          observations={[{ ...observation, selected: true }]}
        />
      ) : (
        <EmptyImage>
          <EmptyState text="No Image Available" />
        </EmptyImage>
      )}
      {children}
    </Pane>
  );
};

import { storage } from 'utils/storage';

export function columnPickerStorage({ storageKey }) {
  const customerId = storage.getItem('customerId');
  const key = `${storageKey}:${customerId}`;
  function get() {
    return storage.getItem(key, true);
  }

  function set(value) {
    storage.setItem(key, value, true);
  }

  function remove() {
    storage.removeItem(key);
  }

  return {
    get,
    set,
    remove,
  };
}

import { sortBy } from 'components/data/helpers';

const sortByName = sortBy('name');
const sortByIndex = sortBy('sortIndex');

export function getInitAttributeOrder(definitions = []) {
  // If no sortIndex sort by name, otherwise use sortIndex;
  return [...definitions].sort(
    typeof definitions[0]?.sortIndex === 'number' ? sortByIndex : sortByName
  );
}

import styled from 'styled-components';
import { Modal as AntdModal } from 'antd5';
import COLORS from 'utils/color/definitions';

// antd5 fixes:
//    - too much padding around modal content
//    - use white as bg color for modal content instead of light grey
//    - X close button looks wacky
export const Modal = styled(AntdModal)`
  .ant-modal-content {
    background-color: ${COLORS.WHITE};
    padding: unset;
  }

  .ant-modal-close-x {
    height: unset;
    width: unset;
  }

  .ant-modal-title {
    font-weight: 500;
  }

  .ant-modal-header {
    background: #fff;
  }
`;

import { SpacedContainer } from 'utils/layouts/containers';
import styled from 'styled-components';
import COLORS from 'utils/color/definitions';
import { CloseCircleOutlined, DownOutlined } from '@ant-design/icons';
import { COMPONENT_PICKER_WIDTH_SMALL } from 'utils/constants';

export const StyledControls = styled.div<{ width?: string; block?: boolean }>`
  width: ${({ width }) => {
    return width || '100%';
  }};
  display: flex;
  flex-direction: ${({ block }) => block && 'column'};
  flex-wrap: wrap;
  gap: 1em;
  margin-left: 0;
  @media (max-width: ${COMPONENT_PICKER_WIDTH_SMALL}px) {
    width: 70%;
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  > div {
    margin: 0;
  }
`;

export const SelectControl = styled(SpacedContainer)<{ small?: boolean; block?: boolean }>`
  min-width: ${({ small }) => (small ? null : '200px')};
  width: ${({ small }) => (small ? '120px' : null)};
  margin: ${({ block }) => block && '0 0 1rem 0'};
  @media (max-width: ${COMPONENT_PICKER_WIDTH_SMALL}px) {
    margin: 0 1rem 1rem 0;
  }

  .ant-select {
    width: 100%;
  }
`;

export const SuffixIcon = styled(DownOutlined).attrs({
  className: 'ant-select-suffix',
})``;

export const ClearIcon = styled(CloseCircleOutlined)`
  &:hover {
    color: ${COLORS.RED};
  }
`;

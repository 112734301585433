import { useEffect, useState } from 'react';

import { Menu } from 'antd';

import { useGetTaskTemplatesLazyQuery } from 'types/atlas-graphql';
import { useFeatures } from 'utils/features';

import { formatTemplatesMenu } from './helpers';
import { ITaskTemplatesChooserProps } from './types';
import { StyledMenu, StyledSelect } from './styles';

const { SubMenu } = Menu;

export const TaskTemplatesChooser: React.FunctionComponent<ITaskTemplatesChooserProps> = ({
  workContainerTypes,
  onClickHandler,
  displayType,
  taskStepsRequired = false,
  disabled = false,
}) => {
  const { TASK_TEMPLATES } = useFeatures().features;

  const [templatesOptions, setTemplatesOptions] = useState<JSX.Element[]>();

  const [
    getAllTaskTemplates,
    {
      data: { getTaskTemplates: templatesData = null } = {},
      loading: templatesLoading,
      error: templatesError,
    },
  ] = useGetTaskTemplatesLazyQuery();

  useEffect(() => {
    if (TASK_TEMPLATES) {
      getAllTaskTemplates({
        variables: {
          workContainerTypes,
          taskStepsRequired,
        },
      });
    }
  }, [TASK_TEMPLATES, getAllTaskTemplates, workContainerTypes, taskStepsRequired]);

  useEffect(() => {
    if (templatesData) {
      if (templatesData.length > 0) {
        setTemplatesOptions(formatTemplatesMenu(templatesData, displayType));
      } else {
        setTemplatesOptions([
          <Menu.Item disabled={true} key="none">
            No Templates have been created
          </Menu.Item>,
        ]);
      }
    }
  }, [templatesData, templatesLoading, templatesError, displayType]);

  return displayType === 'menu' ? (
    <StyledMenu onClick={e => onClickHandler(e)}>
      <SubMenu key="templates-menu" title="Task Templates">
        {templatesOptions?.length && templatesOptions?.length > 0 ? (
          <Menu.ItemGroup
            //@ts-ignore This style is valid and works, but TS doesn't like it.
            style={{
              overflowY: 'auto',
              maxHeight: '300px',
            }}
          >
            {templatesOptions}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item disabled={true} key="none">
            No Templates have been created
          </Menu.Item>
        )}
      </SubMenu>
    </StyledMenu>
  ) : (
    <StyledSelect
      onSelect={e => onClickHandler(e)}
      placeholder={'Select Task Template...'}
      disabled={disabled}
      allowClear={true}
      onClear={() => onClickHandler(undefined)}
    >
      {templatesOptions?.length && templatesOptions?.length && templatesOptions}
    </StyledSelect>
  );
};

import { useEffect, useRef, useState } from 'react';
// TODO: Add leaflet types to package.json when we TS-ify this map
import { icon } from 'leaflet';
import styled from 'styled-components';

import { Marker, Popup, Tooltip } from 'components/Map';
import { MARKER_BLUE } from 'utils/constants';

const iconDefaults = {
  iconSize: [30, 30],
  shadowSize: [50, 64],
  iconAnchor: [15, 30],
  shadowAnchor: [4, 62],
  popupAnchor: [-3, -76],
};

const createIcon = (iconUrl, iconProps, selected) =>
  icon({ iconUrl, ...iconDefaults, ...iconProps, className: selected ? 'active' : '' });

const StyledText = styled.p`
  color: 'cyan';
  font-size: 20px;
  font-weight: 700;
`;

// TODO: TS-ify this
export function ClickMarker(props) {
  const {
    mapRef,
    content,
    label = '',
    icon,
    iconProps,
    isIconTransparent,
    selected,
    openPopup = false,
    setSelectedTurbine,
    index,
    position,
    isDraggable,
    setHasDraggingStarted,
    // TODO: Make this optional since it's not used eg. in the site details map view
    setTurbineToUpdate,
    name,
  } = props;

  const markerIcon = createIcon(icon || MARKER_BLUE, iconProps, selected);
  const [popupRefReady, setPopupRefReady] = useState(false);
  const [markerRefReady, setMarkerRefReady] = useState(false);
  let popupRef = useRef();

  // if the popups ref and markers ref are in the DOM, then allow us to open
  // the popup on the map.
  useEffect(() => {
    if (popupRefReady && markerRefReady && openPopup) {
      popupRef.openOn(mapRef);
    }
  }, [openPopup, popupRefReady, markerRefReady, mapRef]);

  const { lat, lng } = position;

  return (
    <Marker
      position={[lat, lng]}
      icon={markerIcon}
      opacity={isIconTransparent ? 0 : 1}
      draggable={isDraggable}
      ref={_r => {
        setMarkerRefReady(true);
      }}
      eventHandlers={{
        click: () => {
          // When pin is selected from map, also select it in the list
          setSelectedTurbine(index);
        },
        dragstart: () => {
          // before setting new values, need to set selected turbine
          setSelectedTurbine(index);
          setHasDraggingStarted(true);
        },
        dragend: e => {
          setTurbineToUpdate({
            name: name,
            lat: e.target._latlng.lat,
            lng: e.target._latlng.lng,
          });
        },
      }}
    >
      {label && (
        <Tooltip permanent interactive direction="right" className="custom-tooltip">
          <StyledText>{label}</StyledText>
        </Tooltip>
      )}
      {content && (
        <Popup
          ref={r => {
            popupRef = r;
            setPopupRefReady(true);
          }}
        >
          {content}
        </Popup>
      )}
    </Marker>
  );
}

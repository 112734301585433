import { latLngBounds, latLng } from 'leaflet';
import { extent } from 'd3';

export const getBounds = layers => {
  if (!layers.length) return undefined;

  return layers.map(latLng).reduce((bounds, point) => {
    return bounds.extend(point.toBounds(10));
  }, latLngBounds());
};

export const getCenter = layers => {
  const bounds = getBounds(layers);
  return bounds ? bounds.getCenter() : null;
};

// converts an array of observations to geoJSON for use in map-based viewers.
export const getObservationFeatures = ({ observations, imageWidth, imageHeight }) => ({
  type: 'FeatureCollection',
  features: [
    ...observations.map(observation => ({
      type: 'Feature',
      properties: observation,
      geometry: {
        type: 'Polygon',
        coordinates: [
          observation.coordinates.map(([x, y]) => [
            x * imageWidth,

            // coordinates in data are relative to top-left origin,
            // whereas the openlayers view has a bottom-left origin.
            imageHeight - y * imageWidth,
          ]),
        ],
      },
    })),
  ],
});

export const getCoordinatesFromGeoJSON = ({ geoJson, imageWidth, imageHeight }) => {
  const feature = geoJson.type === 'FeatureCollection' ? geoJson.features[0] : geoJson;
  const [coordinates] = feature.geometry.coordinates;
  return coordinates.map(([x, y]) => [x / imageWidth, (imageHeight - y) / imageWidth]);
};

export const convertFeaturesToObservations = ({ features, imageWidth, imageHeight }) => {
  return features.features.map(feature => {
    const { id } = feature.properties;

    return {
      id,
      coordinates: getCoordinatesFromGeoJSON({ geoJson: feature, imageWidth, imageHeight }),
    };
  });
};

export function getXYBounds(coordinates = []) {
  const ranges = coordinates.reduce(
    (values, [x, y]) => {
      const [xList, yList] = values;
      xList.push(x);
      yList.push(y);

      return values;
    },
    [[], []]
  );

  const [[xMin, xMax], [yMin, yMax]] = ranges.map(r => extent(r));

  return [
    [xMin, yMax],
    [xMax, yMin],
  ];
}

import { Rotate } from './Rotate';
import { Filters } from './Filters';
import { Zoom } from './Zoom';
import {
  ControlsContainer,
  ButtonContainer,
  ThumbnailControlsContainer,
  ThumbnailButtonContainer,
} from './Controls.style';

type ControlsProps = {
  onChange: (arg: any) => void;
  value?: {
    rotate?: boolean;
    filter?: string;
    levels?: { url: string; width: number; height: number }[];
  };
  homography?: number[];
  isThumbnail?: boolean;
};

export const Controls: React.FunctionComponent<ControlsProps> = ({
  onChange,
  value,
  homography,
  isThumbnail = false,
}) => {
  const { rotate, filter } = value ?? {};

  if (isThumbnail) {
    return (
      <ThumbnailControlsContainer>
        <ThumbnailButtonContainer>
          <Zoom onChange={onChange} />
        </ThumbnailButtonContainer>
        <ThumbnailButtonContainer>
          <Rotate onChange={onChange} value={rotate} disabled={!homography} />
        </ThumbnailButtonContainer>
      </ThumbnailControlsContainer>
    );
  }

  return (
    <ControlsContainer>
      <ButtonContainer>
        <Rotate onChange={onChange} value={rotate} disabled={!homography} />
      </ButtonContainer>
      <ButtonContainer>
        <Filters onChange={onChange} value={filter} />
      </ButtonContainer>
      <ButtonContainer>
        <Zoom onChange={onChange} />
      </ButtonContainer>
    </ControlsContainer>
  );
};

import styled from 'styled-components';
import { Col } from 'antd';
import { Modal } from 'antd';

export const ComparerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
`;

export const ComparerModal = styled(Modal)`
  position: fixed;
  top: 2.5vh;
  left: 2.5vw;
  height: 95vh !important;
  width: 95vw !important;

  .ant-modal-content {
    height: 100%;
    width: 100%;
  }

  .ant-modal-close {
    top: -10px;
    right: -10px;
  }
`;

export const StyledCol = styled(Col)`
  margin-bottom: 0px;
  padding-bottom: 0px;
  min-height: 0px;
`;

import { FC, KeyboardEventHandler, useState } from 'react';
import { Input, Modal } from 'antd5';
import styled from 'styled-components';
import { AtlasGqlGetCommentsQuery, AtlasGqlUpdateCommentInput } from 'types/atlas-graphql';
import { SingularElement } from 'utils/types';
import { Button } from 'components/ui';

const { TextArea } = Input;

const StyledLinkButton = styled(Button)`
  font-size: 0.75rem !important;
  padding-left: 0 !important;
  height: unset !important;
  color: rgba(0, 0, 0, 0.65) !important;
  &:hover {
    color: rgba(3, 6, 8, 0.66) !important;
  }
`;

interface EditCommentProps {
  comment: SingularElement<AtlasGqlGetCommentsQuery['comments']>;
  onEdit: (input: AtlasGqlUpdateCommentInput) => void;
  loading?: boolean;
}

const EditComment: FC<EditCommentProps> = ({ comment: { text, id }, onEdit, loading }) => {
  const [open, setOpen] = useState(false);
  const [updatedText, setUpdatedText] = useState<string | null>(null);

  const cancelEdit = () => {
    setOpen(false);
    setUpdatedText(null);
  };

  const editComment = () => {
    onEdit({ id, text: updatedText ?? '' });
    setOpen(false);
    setUpdatedText(null);
  };

  const stopPropagation: KeyboardEventHandler = e => e.stopPropagation();

  return (
    <>
      <StyledLinkButton id="edit-comment-button" type="link" onClick={() => setOpen(true)}>
        <span>Edit</span>
      </StyledLinkButton>
      <Modal
        open={open}
        destroyOnClose
        title="Edit Comment"
        confirmLoading={loading}
        okText="Save Changes"
        okButtonProps={{
          id: 'confirm-edit-comment-button',
          disabled: !updatedText,
          type: 'default',
        }}
        onOk={editComment}
        onCancel={cancelEdit}
      >
        <TextArea
          id="edit-comment-input"
          rows={4}
          onChange={e => setUpdatedText(e.target.value)}
          onKeyDown={stopPropagation}
          onKeyUp={stopPropagation}
          defaultValue={text}
        />
      </Modal>
    </>
  );
};

export default EditComment;

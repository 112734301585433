import {
  renderAssetSite,
  renderAssetNameWithAncestors,
} from 'components/data/assets/assetColumnRenderers';
import { getAssetFieldValueString } from 'utils/asset-helpers';
import { getAssetByType } from 'horizon/components/Assets/AssetDetails/helpers';
import { AtlasGqlAsset } from 'types/atlas-graphql';
import { Label, Description } from 'horizon/components/Damages/DamageStyles.style';

type AssetProps = {
  asset: AtlasGqlAsset;
};

export const Asset: React.FunctionComponent<AssetProps> = ({ asset }) => {
  const assetType = asset.assetType?.name;
  const assetMake = getAssetFieldValueString(asset, 'Make');
  const assetModel = getAssetFieldValueString(asset, 'Model');
  const turbine = getAssetByType(asset as AtlasGqlAsset, 'Turbine');
  const turbineMake = turbine && getAssetFieldValueString(turbine, 'Make');
  const turbineModel = turbine && getAssetFieldValueString(turbine, 'Model');

  return (
    <div>
      <div>
        <Label>Site</Label>
        <Description>{renderAssetSite(asset)}</Description>
      </div>
      <div>
        <Label>Asset</Label>
        <Description>{renderAssetNameWithAncestors(asset, 'damages2')}</Description>
      </div>
      <div>
        <Label>Asset Type</Label>
        {assetType && <Description>{assetType}</Description>}
      </div>
      <div>
        <Label>{assetType ? `${assetType} Make` : 'Make'}</Label>
        {assetMake && <Description>{assetMake}</Description>}
      </div>
      <div>
        <Label>{assetType ? `${assetType} Model` : 'Model'}</Label>
        {assetModel && <Description>{assetModel}</Description>}
      </div>
      {turbineMake && assetType !== 'Turbine' && (
        <div>
          <Label>Turbine Make</Label>
          <Description>{turbineMake}</Description>
        </div>
      )}
      {turbineModel && assetType !== 'Turbine' && (
        <div>
          <Label>Turbine Model</Label>
          <Description>{turbineModel}</Description>
        </div>
      )}
    </div>
  );
};

import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SpacedContainer = styled.div`
  margin: 0 0.5rem;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

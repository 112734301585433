import { createContext, useContext, useReducer } from 'react';

import { omit } from 'lodash';

export type ProgressDetails = {
  description: string;
  totalQuantity: number;
  quantityCompleted: number;
};

export type ProgressDetailsUpdate = { action: 'INITIALIZE' | 'UPDATE' } & (
  | ProgressDetails
  | {
      description: string;
      quantityCompletedIncrement?: number;
      markComplete?: boolean;
    }
);

export type GenericProgressContextType = {
  actionInProgress: boolean;
  progressDetails: { [description: string]: ProgressDetails };
  dispatchProgressDetails: React.Dispatch<ProgressDetailsUpdate>;
};

export const defaultGenericProgressContext: GenericProgressContextType = {
  actionInProgress: false,
  progressDetails: {},
  dispatchProgressDetails: () => {},
};

export const GenericProgressContext = createContext<GenericProgressContextType>(
  defaultGenericProgressContext
);

export function useGenericProgressContext() {
  return useContext(GenericProgressContext);
}

const progressDetailsReducer = (
  state: { [description: string]: ProgressDetails },
  progressDetailsUpdate: ProgressDetailsUpdate
): { [description: string]: ProgressDetails } => {
  const { action, description } = progressDetailsUpdate;

  // Initialize progress details element
  if (
    action === 'INITIALIZE' &&
    'quantityCompleted' in progressDetailsUpdate &&
    'totalQuantity' in progressDetailsUpdate
  ) {
    // Progress initialized with same name as running progress - combine them
    if (description in state) {
      return {
        ...state,
        [description]: {
          ...state[description],
          totalQuantity: state[description].totalQuantity + progressDetailsUpdate.totalQuantity,
          quantityCompleted:
            state[description].quantityCompleted + progressDetailsUpdate.quantityCompleted,
        },
      };
    }

    // Add new progress details element
    return {
      ...state,
      [description]: progressDetailsUpdate,
    };
  }

  // Update existing progress details element
  if (action === 'UPDATE' && description in state) {
    const allCompleted: boolean =
      'quantityCompletedIncrement' in progressDetailsUpdate &&
      state[description].quantityCompleted +
        (progressDetailsUpdate.quantityCompletedIncrement ?? 0) ===
        state[description].totalQuantity;

    // Close completed progress if marked complete or quantity complete === total
    if (
      ('markComplete' in progressDetailsUpdate && progressDetailsUpdate.markComplete) ||
      allCompleted
    ) {
      return omit(state, description);
    }

    // Increment quantity complete by provided amount
    if ('quantityCompletedIncrement' in progressDetailsUpdate) {
      return {
        ...state,
        [description]: {
          ...state[description],
          quantityCompleted:
            state[description].quantityCompleted +
            (progressDetailsUpdate.quantityCompletedIncrement ?? 0),
        },
      };
    }
  }

  // Inapplicable case - return current state
  return state;
};

export function GenericProgressContextProvider({ children }: { children: React.ReactNode }) {
  const [progressDetails, dispatchProgressDetails] = useReducer(progressDetailsReducer, {});

  return (
    <GenericProgressContext.Provider
      value={{
        actionInProgress: !!Object.keys(progressDetails).length,
        progressDetails,
        dispatchProgressDetails,
      }}
    >
      {children}
    </GenericProgressContext.Provider>
  );
}

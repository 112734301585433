import React from 'react';
import { MAINTENANCE_MODE_ENABLED } from 'utils/env';
import { HorizonLogo, MaintenanceContainer, SkySpecsLogo } from './Maintenance.style';

interface Props {
  children: React.ReactNode;
}

function Maintenance({ children }: Props) {
  if (!MAINTENANCE_MODE_ENABLED) {
    return <>{children}</>;
  }

  return (
    <MaintenanceContainer>
      <HorizonLogo alt="horizon-logo" src="/logos/horizon/teal/horizon_logo_teal_full.png" />
      <main>
        <h1>Sorry! We're currently down for maintenance.</h1>
        <h2>We'll be back up as soon as possible.</h2>
        <p>
          Questions?{' '}
          <a
            href="https://support.skyspecs.com/hc/en-us/requests/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Support
          </a>
        </p>
      </main>
      <SkySpecsLogo alt="skyspecs-logo" src="/logos/skyspecs/skyspecs-black.png" />
    </MaintenanceContainer>
  );
}

export default Maintenance;

import { chunk } from 'lodash';
import { Row } from 'antd';
import { AtlasGqlHorizonDamage } from 'types/atlas-graphql';
import { Label, Description } from 'horizon/components/Damages/DamageStyles.style';
import { StyledCol } from '../CompareOTron.style';
import { DamageLink, AssetLink, getPrimaryObservationForDamage } from '../../utils';
import { useDamageAttrs } from 'components/damages/withDamages';
import { Spinner } from 'components/ui';

type DamageSummaryProps = {
  damage: AtlasGqlHorizonDamage;
  observationsOpen?: boolean;
};

export const DamageSummary: React.FunctionComponent<DamageSummaryProps> = ({
  damage,
  observationsOpen,
}) => {
  const schemaId = getPrimaryObservationForDamage(damage)?.schema?.id;
  const attrs = damage?.primaryObservationGroupAttrs ?? {};

  const { damageColumns, loading: loadingDamageAttrs } = useDamageAttrs(schemaId, true);
  const filteredDamageColumns = damageColumns.filter(column => column.key in attrs);

  const chunkedDamageColumns = chunk(
    filteredDamageColumns,
    Math.round(filteredDamageColumns.length / 2)
  );

  const { asset } = damage;

  return loadingDamageAttrs ? (
    <Spinner />
  ) : (
    <Row style={{ flex: '1', padding: '1rem' }}>
      {chunkedDamageColumns.map((columns, index) => (
        <StyledCol key={index} span={observationsOpen ? 24 : 12}>
          {index === 0 && (
            <>
              <div>
                <Label>Damage</Label>
                <Description>
                  <DamageLink damageId={damage.id} target={'_blank'} />
                </Description>
              </div>
              <div>
                <Label>Asset</Label>
                <Description>{asset && <AssetLink asset={asset} target={'_blank'} />}</Description>
              </div>
            </>
          )}
          {columns.map(column => {
            const key = column.key;
            const value = column.render(attrs[key]);
            return (
              <div key={key}>
                <Label>{key}</Label>
                <Description>{value}</Description>
              </div>
            );
          })}
        </StyledCol>
      ))}
    </Row>
  );
};

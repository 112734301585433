import { TTurbineProps } from '../types';
import {
  StyledList,
  StyledListItem,
  ListItemLabel,
  ListItemValue,
  RowContainer,
} from './PointsList.style';
import { None } from 'components/data/helpers';
import COLORS from 'utils/color/definitions';

interface ITurbinePointMapProps {
  data: Array<TTurbineProps>;
  onItemClick: any;
  selectedTurbineIndex: number;
  mapHeight: string;
}

export function PointsList(props: ITurbinePointMapProps) {
  const { data, onItemClick, selectedTurbineIndex, mapHeight } = props;

  function handleItemClick(item: TTurbineProps, index: number) {
    onItemClick(item, index);
  }

  const selectedBackgroundColor = (index: number) =>
    selectedTurbineIndex === index ? COLORS.SELECTED_LIST_ITEM_BACKGROUND : COLORS.WHITE;

  return (
    <StyledList
      overflowy={'auto'}
      mapheight={mapHeight}
      itemLayout="vertical"
      dataSource={data}
      renderItem={(item: TTurbineProps, index: number) => (
        <StyledListItem
          key={index}
          backgroundcolor={selectedBackgroundColor(index)}
          onClick={_e => {
            handleItemClick(item, index);
          }}
        >
          <RowContainer>
            <ListItemLabel>Name: </ListItemLabel>
            <ListItemValue>{item?.content?.props?.turbine?.name ?? <None />}</ListItemValue>
          </RowContainer>
          <RowContainer>
            <ListItemLabel>Latitude: </ListItemLabel>
            <ListItemValue>{item?.position?.lat ?? <None />}</ListItemValue>
          </RowContainer>
          <RowContainer>
            <ListItemLabel>Longitude: </ListItemLabel>
            <ListItemValue>{item?.position?.lng ?? <None />}</ListItemValue>
          </RowContainer>
        </StyledListItem>
      )}
    />
  );
}

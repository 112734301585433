import { AtlasGqlHorizonDamage } from 'types/atlas-graphql';
import { isFinite } from 'lodash';
import { ColorCodingAttribute } from './BladeDamagePlotContainer';
import COLORS from 'utils/color/definitions';
import {
  PointProps,
  Circle,
  Square,
  Diamond,
  Triangle,
  POINT_COLORS,
  POINT_SHAPES,
  MORE_OPACITY,
  LESS_OPACITY,
  DEFAULT_OPACITY,
} from './pointShapes';

export type ShapeAndColor = {
  ShapeComponent: React.FunctionComponent<PointProps>;
  color: string;
};
export type ColorCodingMap = { [key: string]: ShapeAndColor };

export const NullShapeAndColor: ShapeAndColor = {
  ShapeComponent: Diamond,
  color: COLORS.DARK_GRAY,
};

export const severityColorCodingMap: ColorCodingMap = {
  '5': { ShapeComponent: Circle, color: COLORS.SEV_MAX_COLOR },
  '4': { ShapeComponent: Square, color: COLORS.SEV_LESS_COLOR },
  '1-3': { ShapeComponent: Triangle, color: COLORS.DARK_GRAY },
};
const getShapeAndColorForSeverity = (severity?: number): ShapeAndColor => {
  if (!severity) {
    return NullShapeAndColor;
  }
  if (severity >= 5) {
    return severityColorCodingMap['5'];
  }
  if (severity >= 4) {
    return severityColorCodingMap['4'];
  }
  return severityColorCodingMap['1-3'];
};

export const getColorCodingMap = (
  colorCodingAttributeValues: (string | number)[]
): ColorCodingMap => {
  let colorIndex = -1;
  let shapeIndex = -1;

  return colorCodingAttributeValues.reduce<ColorCodingMap>((acc, value) => {
    if (!(String(value) in acc)) {
      colorIndex += 1;
      shapeIndex += 1;

      return {
        ...acc,
        [String(value)]: {
          ShapeComponent: POINT_SHAPES[shapeIndex % POINT_SHAPES.length],
          color: POINT_COLORS[colorIndex % POINT_COLORS.length],
        },
      };
    }
    return acc;
  }, {});
};

/**
 * Given x/y coordinates, damage attributes, and optionally an attribute to color code
 * by with a mapping, returns an svg element representing the damage
 */
const getPointComponent = ({
  key,
  damageId,
  x,
  y,
  attributes,
  colorCodingAttribute,
  colorCodingMap,
  selectedDamageIds,
}: {
  key: number; // React key
  damageId: string;
  x: number;
  y: number;
  attributes: AtlasGqlHorizonDamage['primaryObservationGroupAttrs'];
  colorCodingAttribute?: ColorCodingAttribute;
  colorCodingMap?: ColorCodingMap;
  selectedDamageIds?: string[];
}): JSX.Element => {
  const opacity = selectedDamageIds?.length
    ? selectedDamageIds.includes(damageId)
      ? MORE_OPACITY
      : LESS_OPACITY
    : DEFAULT_OPACITY;
  const pointProps = {
    key,
    x,
    y,
    opacity,
  };

  if (colorCodingAttribute === ColorCodingAttribute.Severity) {
    const { ShapeComponent, color } = getShapeAndColorForSeverity(attributes['Severity']);
    return <ShapeComponent {...pointProps} color={color} />;
  } else if (colorCodingAttribute === ColorCodingAttribute.OpSeverity) {
    const { ShapeComponent, color } =
      colorCodingMap?.[attributes['OpSeverity']] ?? NullShapeAndColor;
    return <ShapeComponent {...pointProps} color={color} />;
  } else if (colorCodingAttribute === ColorCodingAttribute.Type) {
    const { ShapeComponent, color } = colorCodingMap?.[attributes['Type']] ?? NullShapeAndColor;
    return <ShapeComponent {...pointProps} color={color} />;
  } else {
    return <Circle {...pointProps} color={COLORS.DARK_GRAY} />;
  }
};

/**
 * Map function for getting points for edge damages: given distance, xScale, yScale, and attribute
 * to color code, returns an SVG shape, color-coded if appropriate, at radial distance (x) and 50%
 * chord (y), to show all damages along a single line in the y axis.
 *
 * Damages without distance will return null.
 */
export const mapEdgeDamagesToPoints =
  ({
    xScale,
    yScale,
    colorCodingAttribute,
    colorCodingMap,
    selectedDamageIds,
  }: {
    xScale: d3.ScaleLinear<number, number, never>;
    yScale: d3.ScaleLinear<number, number, never>;
    colorCodingAttribute?: ColorCodingAttribute;
    colorCodingMap?: ColorCodingMap;
    selectedDamageIds?: string[];
  }) =>
  (
    { id, primaryObservationGroupAttrs }: AtlasGqlHorizonDamage,
    index: number
  ): JSX.Element | null => {
    if (isFinite(primaryObservationGroupAttrs['Distance'])) {
      return getPointComponent({
        key: index,
        damageId: id,
        x: xScale(primaryObservationGroupAttrs['Distance']),
        y: yScale(50),
        attributes: primaryObservationGroupAttrs,
        colorCodingAttribute,
        colorCodingMap,
        selectedDamageIds,
      });
    }
    return null;
  };

/**
 * Map function for getting points for side damages: given distance, chord, max blade width,
 * blade length, xScale, yScale, and attribute to color code, returns an SVG shape, color-coded
 * if appropriate, at radial distance (x) and chord position (y), projected from the distance
 * and relative blade width at that distance.
 *
 * Damages without distance or chord will return null.
 */
export const mapSideDamagesToPoints =
  ({
    widths,
    maxWidth,
    length,
    xScale,
    yScale,
    colorCodingAttribute,
    colorCodingMap,
    selectedDamageIds,
  }: {
    widths: number[];
    maxWidth: number;
    length: number;
    xScale: d3.ScaleLinear<number, number, never>;
    yScale: d3.ScaleLinear<number, number, never>;
    colorCodingAttribute?: ColorCodingAttribute;
    colorCodingMap?: ColorCodingMap;
    selectedDamageIds?: string[];
  }) =>
  (
    { id, primaryObservationGroupAttrs }: AtlasGqlHorizonDamage,
    index: number
  ): JSX.Element | null => {
    const { Distance: distance, Chord: chord } = primaryObservationGroupAttrs;

    if (isFinite(distance) && isFinite(chord)) {
      const widthAtDistance = widths[Math.round((100 * distance) / length)];
      const projectedChord = (widthAtDistance / maxWidth) * chord;

      if (isFinite(projectedChord)) {
        return getPointComponent({
          key: index,
          damageId: id,
          x: xScale(distance),
          y: yScale(projectedChord),
          attributes: primaryObservationGroupAttrs,
          colorCodingAttribute,
          colorCodingMap,
          selectedDamageIds,
        });
      }
    }
    return null;
  };

import { Form } from 'antd';

import { getInput } from '../helpers';

export function FormInputs({ fields, type, uneditableFields, form, isRepairOrInspect }) {
  function showRules(field, type) {
    return !isRepairOrInspect(type) && field.name === 'Description'
      ? {
          rules: [{ required: true, message: 'Please enter a description' }],
        }
      : {};
  }

  function sortFields() {
    return [...fields]?.sort(
      (valA, valB) =>
        valA?.configured?.find(c => c?.associatedType?.type === type)?.displayOrder -
        valB?.configured?.find(c => c?.associatedType?.type === type)?.displayOrder
    );
  }

  return sortFields().map(field => (
    <Form.Item
      name={field.name}
      label={field.name === 'Parent' ? 'Work Order' : field.name}
      key={field.name}
      {...showRules(field, type)}
    >
      {getInput({ field, uneditableFields, form })}
    </Form.Item>
  ));
}

export type TDataQueryChangeArgs = {
  assetIds?: string[];
  componentTypes?: string[];
  filterBy: { key: string; operator: string; values: string[] }[];
  fuzzySearch: string[];
  isInitialFetch: boolean;
  sortBy: { key: string; sort: string }[];
  totalCount: number;
};

export enum EWorkContainerArchiveOptions {
  archived = 'archived',
  unarchived = 'unarchived',
}

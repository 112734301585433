import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from './Spin';

const CenteredContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 10vh;
  width: 100%;

  .ant-spin-dot {
    overflow: hidden;
  }
`;

interface Props {
  className?: string;
  size?: number;
}

export function Spinner({ className = 'spinner', size = 2 }: Props) {
  return (
    <CenteredContainer data-testid="spinner" className={className}>
      <Spin
        _version={4}
        indicator={
          <LoadingOutlined
            spin={true}
            style={{
              color: `${({ theme }: { theme: any }) => theme.blue}`,
              fontSize: `${size}rem`,
            }}
          />
        }
      />
    </CenteredContainer>
  );
}

/**
 * Component for drawing a generic blade edge shape (i.e. leading edge,trailing edge).
 */

export const EdgeShape: React.FunctionComponent = () => (
  <svg viewBox="0 0 2304 65" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 63.9998C316.656 63.9707 406.255 60.4943 521.103 56.0383C600.954 52.9401 693.011 49.3683 881.741 46.1523C1181.12 41.0509 2130.31 36.8448 2277.82 36.8448V36.8401L2277.86 36.8448C2286.24 36.8448 2303 35.9759 2303 32.5C2303 29.0241 2286.24 28.1552 2277.86 28.1552C2130.34 28.1552 1181.15 23.9491 881.768 18.8477C693.037 15.6318 600.976 12.06 521.121 8.9618C406.267 4.50571 316.663 1.02926 1 1.00018V63.9998Z"
      fill="#F7F7F7"
      stroke="#0000001F"
      style={{ transform: 'translate(48px, -20px) scaleX(0.97)' }}
    />
  </svg>
);

import { useCallback, useState } from 'react';
import { InputNumber } from 'antd';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant';

import { ValueInput } from './';

export function DebouncedInputNumber({
  disabled,
  defaultValue,
  loading,
  min,
  max,
  onChange,
  value: inputValue,
  ...props
}) {
  // For damage edit form use cases, defaultValue appears to always be null ...
  // However, I am leaving it here as the first option in case there are other
  // users of this component that do pass a default value instead of an initial
  // value object.
  const [value, setValue] = useState(defaultValue || inputValue);

  const nullEmptyToUndef = x => (x === null || x === '' ? undefined : x);

  const dispatch = (val, onChange) => onChange(val);
  const debouncedOnChange = useConstant(() => AwesomeDebouncePromise(dispatch, 300));
  const callOnChange = useCallback(
    (val, onChange) => {
      debouncedOnChange(val, onChange);
    },
    [debouncedOnChange]
  );

  // FIXME: cant figure out how to incorporate "width" in editabledamageattributes
  return (
    <InputNumber
      defaultValue={value}
      min={min}
      max={max}
      disabled={disabled || max < min}
      {...(min === max && min !== undefined && min !== null ? { placeholder: min } : {})}
      onChange={val => {
        if (min !== undefined && val < min) return;
        if (max !== undefined && val > max) return;
        const newVal = nullEmptyToUndef(val);
        setValue(newVal);
        callOnChange(newVal, onChange);
      }}
      {...(min <= max ? { placeholder: `${min} to ${max}` } : {})}
      style={{ width: '100%' }}
      {...props}
    />
  );
}

export function DebouncedValueInput({ onChange, ...props }) {
  const dispatch = (val, onChange) => onChange(val);
  const debouncedOnChange = useConstant(() => AwesomeDebouncePromise(dispatch, 300));
  const callOnChange = useCallback(
    (val, onChange) => {
      debouncedOnChange(val, onChange);
    },
    [debouncedOnChange]
  );

  return (
    <ValueInput
      onChange={val => {
        callOnChange(val, onChange);
      }}
      {...props}
    />
  );
}

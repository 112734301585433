import 'leaflet/dist/leaflet.css';
import { useState, useEffect } from 'react';
import { MapContainer, TileLayer, LayersControl } from 'react-leaflet';
import styled from 'styled-components';
import { Select } from 'antd';
import { MarkerClusterGroupLayer } from './MarkerClusterGroupLayer';
import COLORS from 'utils/color/definitions';
import { Spinner } from 'components/ui';

// create single entry point for map stuff
export * from 'react-leaflet';

export const WORLD_BOUNDS = [
  [85, -180],
  [-85, 180],
];

const StyledMap = styled(MapContainer)`
  width: 100%;
  height: ${props => (props.height ? props.height : '100%')};
  z-index: 1;
  position: relative;

  .custom-tooltip {
    all: 'revert';
    fill-color: none;
    background-color: none;
    border-color: none;
    background: none;
    border: none;
    box-shadow: none;
    margin: '0px';
    cursor: none;
    direction: 'center';
    interactive: false;
    fill: false;
  }

  & .marker-cluster-small {
    background-color: ${COLORS.TEAL_RGBA};
  }

  & .marker-cluster-small div {
    background-color: ${COLORS.TEAL_RGBA};
  }

  & .marker-cluster-medium {
    background-color: ${COLORS.TEAL_RGBA};
  }

  & .marker-cluster-medium div {
    background-color: ${COLORS.TEAL_RGBA};
  }

  & .marker-cluster-large {
    background-color: ${COLORS.TEAL_RGBA};
  }

  & .marker-cluster-large div {
    background-color: ${COLORS.TEAL_RGBA};
  }
`;

const StyledSelect = styled(Select)`
  z-index: 1000;
  position: absolute !important;
  right: 0;
  margin: 0.25rem !important;
`;

function BaseMapToggle({ onChange, value }) {
  return (
    <StyledSelect
      options={[
        { label: 'Satellite View', value: 'World_Imagery' },
        { label: 'Street View', value: 'World_Street_Map' },
      ]}
      value={value}
      onChange={onChange}
    />
  );
}

export default function Leaflet({
  mapRef,
  setMapRef = () => {},
  mapRefReady,
  setMapRefReady = () => {},
  // Required bounds format: [[maxLat, minLng], [minLat, maxLng]]
  // If no bounds provided, use world bounds
  bounds,
  children,
  enableClustering,
  spiderfy = false,
  satelliteView,
  toggleBaseMap,
  sideList,
  // if true, expose layers control and include layerOverlays
  layersControl,
  ...props
}) {
  const server = satelliteView ? 'World_Imagery' : 'World_Street_Map';
  const [baseMap, setBaseMap] = useState(server);

  // In the fleet map where we use the layersControl, each child is a layer
  // In other cases, each child is a point
  const [turbineLayer, labelsLayer] = layersControl ? children : [[], []];

  // if we're not on the sideList for create asset preview, then just set the bounds
  // to the current bounds of all turbines on the map.
  useEffect(() => {
    if (mapRef && !sideList && bounds && mapRefReady) {
      mapRef.fitBounds(bounds);
    }
  }, [mapRef, bounds, mapRefReady, sideList]);

  return (
    <>
      {!mapRefReady && <Spinner />}
      <StyledMap
        bounds={WORLD_BOUNDS}
        maxBounds={WORLD_BOUNDS}
        zoomDelta={0.25}
        zoomSnap={0}
        minZoom={1}
        // whenCreated prop fires after the map is created and we need a way to set the ref.
        // the old way on v2 doesn't work so you need to set ref after map is created.
        // https://github.com/PaulLeCam/react-leaflet/issues/841
        whenCreated={mapRef => {
          setMapRef(mapRef);
          // Setting mapRefReady prevents a race condition that causes the map to crash
          // when trying to set the initial map bounds for the first item in mapList
          setMapRefReady(true);
        }}
        {...props}
      >
        <TileLayer
          attribution={`Tiles © <a href="https://services.arcgisonline.com/arcgis/rest/services">ArcGIS</a>`}
          url={`https://server.arcgisonline.com/ArcGIS/rest/services/${baseMap}/MapServer/tile/{z}/{y}/{x}.png`}
          // passing a key forces the map to re-render when toggling between street and satellite view
          key={baseMap}
        />

        {layersControl ? (
          <LayersControl position="bottomleft" collapsed={false}>
            {/* Checked Setting enables the turbine base layer by default */}
            <LayersControl.Overlay checked name="Turbines">
              <MarkerClusterGroupLayer
                layer={turbineLayer}
                spiderfy={spiderfy}
                enableClustering={enableClustering}
              />
            </LayersControl.Overlay>

            <LayersControl.Overlay name="Turbine Labels">
              <MarkerClusterGroupLayer
                layer={labelsLayer}
                spiderfy={spiderfy}
                enableClustering={enableClustering}
              />
            </LayersControl.Overlay>
          </LayersControl>
        ) : (
          <MarkerClusterGroupLayer
            layer={children}
            spiderfy={spiderfy}
            enableClustering={enableClustering}
          />
        )}

        {toggleBaseMap && <BaseMapToggle onChange={setBaseMap} value={baseMap} />}
      </StyledMap>
    </>
  );
}

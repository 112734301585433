import { Modal, Input } from 'antd';
import styled from 'styled-components';

import { DamageNumberText } from 'components/data/helpers';

const StyledInput = styled(Input)`
  margin-top: 1rem;
  width: 3rem;
  text-transform: uppercase;
  font-family: monospace;
`;

const StyledWarning = styled.div`
  font-size: 1.1rem;
`;

export function DeleteDamageModal({
  forceIdCheck = true,
  visible,
  disabled,
  damageNumber,
  isDeleting,
  onDelete,
  onChange,
  onCancel,
}) {
  return forceIdCheck ? (
    <Modal
      visible={visible}
      destroyOnClose
      title="Delete damage?"
      okType="danger"
      okText="Delete"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={onDelete}
      confirmLoading={isDeleting}
      okButtonProps={{ disabled, id: 'delete-damage-confirm-button' }}
      cancelButtonProps={{ id: 'delete-damage-cancel-button' }}
    >
      <StyledWarning style={{ marginBottom: '1rem' }}>
        Deleting a damage <strong>cannot</strong> be undone. This will permanently delete
        damage&nbsp;
        <strong>
          <DamageNumberText>{damageNumber}</DamageNumberText>
        </strong>
        .&nbsp;Please type the damage number to confirm.
      </StyledWarning>

      <StyledInput
        id="delete-damage-input"
        placeholder="Damage ID"
        size="large"
        onChange={onChange}
        onPressEnter={onDelete}
        onPaste={e => {
          e.preventDefault();
        }}
      />
    </Modal>
  ) : (
    <Modal
      visible={visible}
      destroyOnClose
      title="Delete damage?"
      okType="danger"
      okText="Delete"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={onDelete}
      confirmLoading={isDeleting}
      okButtonProps={{ id: 'quick-delete-damage-confirm-button' }}
      cancelButtonProps={{ id: 'quick-delete-damage-cancel-button' }}
    >
      <StyledWarning style={{ marginBottom: '1rem' }}>
        Deleting a damage <strong>cannot</strong> be undone. This will permanently delete
        damage&nbsp;
        <strong>
          <DamageNumberText>{damageNumber}</DamageNumberText>
        </strong>
        .&nbsp;
      </StyledWarning>
    </Modal>
  );
}

import { useState } from 'react';
import { Button, Tooltip } from 'components/ui';
import { UpdateToPrimaryModal } from './UpdateToPrimaryModal';

type UpdateToPrimaryButtonProps = {
  taskId: string;
  disabled?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
};

export const UpdateToPrimaryButton: React.FunctionComponent<UpdateToPrimaryButtonProps> = ({
  taskId,
  disabled,
  onClose,
  onSuccess,
  onError,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  return (
    <>
      <Tooltip
        title={
          "This Task is not showing attributes from the Damage's Primary Observation Group. " +
          'Click to update Damage attributes shown in this Task.'
        }
      >
        <Button onClick={() => setModalIsOpen(true)} disabled={disabled}>
          Update to Primary
        </Button>
      </Tooltip>
      <UpdateToPrimaryModal
        taskIds={[taskId]}
        isOpen={modalIsOpen}
        onClose={() => {
          onClose && onClose();
          setModalIsOpen(false);
        }}
        onSuccess={onSuccess}
        onError={onError}
      />
    </>
  );
};

import { useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { useAccountContext } from './AccountContext';
import { useAuthContext } from 'utils/auth';
import { storage } from 'utils/storage';

export enum AccountDataMessageTypes {
  ORG_MISMATCH = 'ORG_MISMATCH',
  USER_DATA_REFETCH = 'USER_DATA_REFETCH',
  USER_LOGOUT = 'USER_LOGOUT',
}

export type OrgMismatchMessage = {
  type: AccountDataMessageTypes.ORG_MISMATCH;
  customerId: string;
};

export type UserDataRefetchMessage = {
  type: AccountDataMessageTypes.USER_DATA_REFETCH;
};

export type UserLogoutMessage = {
  type: AccountDataMessageTypes.USER_LOGOUT;
};

type AccountDataMessage = OrgMismatchMessage | UserDataRefetchMessage | UserLogoutMessage;

// @see https://github.com/pubkey/broadcast-channel#using-the-broadcastchannel
export const channel: BroadcastChannel<AccountDataMessage> = new BroadcastChannel('user-data');

export function useAccountDataChannel() {
  const { customer, refetchUser } = useAccountContext();
  const { logout } = useAuthContext();

  useEffect(() => {
    channel.onmessage = message => {
      switch (message.type) {
        case AccountDataMessageTypes.ORG_MISMATCH:
          if (message.customerId !== customer?.id) {
            reinitializeApp();
          }
          break;
        case AccountDataMessageTypes.USER_DATA_REFETCH:
          // publish if false to avoid infinite loops due to windows publishing to eachother
          refetchUser({ publish: false });
          break;
        case AccountDataMessageTypes.USER_LOGOUT:
          // calling logut without the broadcast param to not cause a storm
          logout();
          break;
      }
    };

    return () => {
      channel.onmessage = () => {};
    };
  }, [customer, refetchUser, logout]);
}

export function reinitializeApp() {
  // reload the window, which will auto-select the customer ID that's stored
  // in local storage
  storage.removeItem('customerId:active');
  window.location.reload();
}
